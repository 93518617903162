import React, { ReactElement } from 'react'
import { Box, Text } from 'theme-ui'
import DevicesImage from 'images/tutorialFlows/Devices/devices-slide.svg'
import ProvisionImage from 'images/tutorialFlows/Devices/provision-slide.svg'
import {
  Highlight,
  TutorialBaseSlide,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import { CONTROLDText } from 'ui/CONTROLD'

export function EndpointsSlide(): ReactElement {
  return (
    <TutorialBaseSlide
      Illustration={DevicesImage}
      description={
        <Box>
          <Text>
            An <Highlight svg={tutorialSlideIcons.endpoints}>Endpoint</Highlight> is a DNS resolver
            (which supports all DNS protocols) that enforces one or more{' '}
            <Highlight svg={tutorialSlideIcons.profiles}>Profiles</Highlight>. <br /> <br /> Create
            an <Highlight svg={tutorialSlideIcons.endpoints}>Endpoint</Highlight> for a relevant
            platform (Windows, Mac, Linux, iOS, Android, etc.) and configure a {CONTROLDText}{' '}
            resolver on it using the handy Help Configure button. <br /> <br /> Once you do this,
            all DNS queries will be subject to the{' '}
            <Highlight svg={tutorialSlideIcons.endpoints}>Endpoint&apos;s</Highlight> enforced{' '}
            <Highlight svg={tutorialSlideIcons.profiles}>Profile(s)</Highlight>. <br /> <br /> You{' '}
            can also schedule automatic{' '}
            <Highlight svg={tutorialSlideIcons.profiles}>Profile</Highlight> swaps using the
            scheduler.
          </Text>
        </Box>
      }
    />
  )
}

export function ProvisionSlide(): ReactElement {
  return (
    <TutorialBaseSlide
      Illustration={ProvisionImage}
      description={
        <Box>
          <Text>
            On the <Highlight svg={tutorialSlideIcons.provision}>Provisioning</Highlight> page, you
            can generate codes that allow you to deploy {CONTROLDText} to hundreds of Windows, Mac
            and Linux devices using a single command with your favorite RMM platform.
          </Text>
        </Box>
      }
    />
  )
}
