import React, { Dispatch, ReactElement, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import { SimpleActionMenuItemProps, Svg } from 'ui'
import Check from 'images/analytics/check.svg'
import SettingsDropdown from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsDropdowns/SettingsDropdown'
import { ActionType, ProvisionActionType } from './ProvisionState'
import { useGetStatLevelsQuery } from 'store/api/analytics'

interface AnalyticsDropdownProps {
  deviceStats?: number
  settingsDispatch: Dispatch<ActionType>
  boundaryDataTestId?: string
}
export default function AnalyticsDropdown({
  deviceStats,
  settingsDispatch,
  boundaryDataTestId,
}: AnalyticsDropdownProps): ReactElement {
  const hideRef = useRef<() => void>()
  const { data: statLevelsData } = useGetStatLevelsQuery('')

  const allLevels = [...(statLevelsData?.levels ?? [])]
  const statOptions: SimpleActionMenuItemProps[] = allLevels.map(level => {
    const title = level.title.split(' ')[0]

    return {
      isSelected: level.PK === deviceStats,
      isBackgroundHighlighted: true,
      children: (
        <Flex
          data-testid={level.title}
          sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Text>{title}</Text>
          <Flex>
            {(deviceStats ?? 0) === level.PK && <Svg data-testid="check-icon" svg={Check}></Svg>}
          </Flex>
        </Flex>
      ),
      buttonContent: <Text>{title}</Text>,
      onClick: async () => {
        settingsDispatch({
          type: ProvisionActionType.STATS,
          payload: level.PK,
        })
        hideRef.current?.()
      },
    }
  })

  return (
    <SettingsDropdown
      testId="provision-analytics-dropdown"
      items={statOptions}
      hideRef={hideRef}
      boundaryDataTestId={boundaryDataTestId}
      buttonSx={{ minWidth: '8rem', justifyContent: 'flex-end' }}
    />
  )
}
