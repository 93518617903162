import React, { ReactElement } from 'react'
import { Box } from 'theme-ui'
import { Svg } from 'ui'
import { keyframes } from '@emotion/react'
import TutorialArrowIcon from 'images/ui/tutorial-arrow.svg'

const scrollUpFirstAnimation = keyframes`
    0% {
      transform: translate(0, -40px);
      opacity: 0;
    }
    60% {
      transform: translate(0, 0);
      opacity: 0.8;
    }
  `

const scrollUpSecondAnimation = keyframes`
    0% {
      transform: translate(0, -40px);
      opacity: 0;
    }
    60% {
      transform: translate(0, 0px);
      opacity: 0.6;
    }
  `

const TutorialArrow = ({
  rotateDegree,
  testId,
}: {
  testId?: string
  rotateDegree?: number
}): ReactElement => {
  return (
    <Box
      data-testid={`${testId}-tutorial-arrow`}
      sx={{
        '& > svg': {
          transform: `rotate(${rotateDegree}deg)`,
          '& > path': {
            '&.first-path': {
              animation: `${scrollUpFirstAnimation} 1s ease-in-out infinite`,
              animationDelay: '0.8s',
            },
            '&.second-path': {
              animation: `${scrollUpSecondAnimation} 1s ease-in-out infinite`,
            },
          },
        },
      }}
    >
      <Svg svg={TutorialArrowIcon} width="4.8rem" height="4.8rem" />
    </Box>
  )
}

export default TutorialArrow
