import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import Tippy from '@tippyjs/react'
import { IconButton, ThemeUIStyleObject } from 'theme-ui'
import useCopyToClipboard from 'utils/copyToClipboard'
import CheckMarkIcon from 'images/whitelist-icon.svg'
import CopyIcon from 'images/copy-icon.svg'
import { Svg } from 'ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import CopyIconRedesign from 'images/copy-icon-clipboard.svg'

export default function CopyToClipboard({
  data,
  ariaLabelText,
  smallIcon,
  iconCustomStyle,
  children,
  fillSvg,
  sx,
  theme,
  variant,
}: {
  data: string
  ariaLabelText?: string
  smallIcon?: boolean
  iconCustomStyle?: ThemeUIStyleObject
  sx?: ThemeUIStyleObject
  variant?: string
  children?: ReactNode
  fillSvg?: string
  theme?: string
}): ReactElement {
  const { isCopied, copyToClipboard, resetCopiedState } = useCopyToClipboard(data)
  const [isVisible, setIsVisible] = useState(false)
  const hideTooltip = (): void => setIsVisible(false)
  const showTooltip = (): void => {
    setIsVisible(true)
  }
  const mediumIconSizeSx = {
    minHeight: '3.2rem',
    minWidth: '3.2rem',
    height: '3.2rem',
    width: '3.2rem',
  }

  const smallIconSizeSx = {
    minHeight: '2.4rem',
    minWidth: '2.4rem',
    height: '2.4rem',
    width: '2.4rem',
  }

  const timerRef = useRef<number>()

  const isMobileOrTablet = useBreakpointIndex() < 2

  useEffect(() => {
    return (): void => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const handleClick = useCallback(
    (e): void => {
      e.preventDefault()
      showTooltip()
      copyToClipboard()
      clearTimeout(timerRef.current)
      // https://github.com/Microsoft/TypeScript/issues/30128#issuecomment-807394387
      timerRef.current = +setTimeout(() => setTimeout(hideTooltip, 1000))
    },
    [copyToClipboard],
  )
  return (
    <Tippy
      content={isMobileOrTablet || isCopied ? 'Copied' : 'Copy to clipboard'}
      theme={theme || 'screen'}
      arrow={false}
      offset={[0, 16]}
      onHidden={(): void => {
        resetCopiedState()
        hideTooltip()
      }}
      visible={isVisible}
    >
      <IconButton
        aria-label={ariaLabelText}
        className="copy-icon-wrapper"
        data-testid="copy-icon-wrapper"
        variant={variant ? variant : 'none'}
        sx={{
          height: 'auto',
          width: 'auto',
          cursor: 'pointer',
          ':hover svg path': {
            fill: 'white',
          },
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          ...sx,
        }}
        onClick={handleClick}
        onMouseEnter={isMobileOrTablet ? undefined : showTooltip}
        onMouseLeave={isMobileOrTablet || isCopied ? undefined : hideTooltip}
        onKeyDown={stopPropagationOnKeyPress}
      >
        {children}
        {isCopied ? (
          <Svg
            svg={CheckMarkIcon}
            fill={fillSvg || 'white50'}
            sx={iconCustomStyle || (smallIcon ? smallIconSizeSx : mediumIconSizeSx)}
          />
        ) : (
          <Svg
            svg={variant ? CopyIconRedesign : CopyIcon}
            fill={fillSvg || 'white50'}
            sx={iconCustomStyle || (smallIcon ? smallIconSizeSx : mediumIconSizeSx)}
          />
        )}
      </IconButton>
    </Tippy>
  )
}
