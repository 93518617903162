import React, { ReactElement } from 'react'
import { Divider, Flex } from 'theme-ui'
import { trayHeaderHeight } from 'components/TrayOrModalDialog/Tray.mobile'
import { Text } from '@theme-ui/components'
import CopyToClipboard from 'components/CopyToClipboard'
import { BASE_URL, DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { useAppSelector } from 'store/hooks'
import { useGetProvisionQuery } from 'store/api/provision'
import { Button, ExternalLink } from 'ui'
import { downloadFile } from 'utils/downloadFile'

export function InstructionsView({
  resolverId,
  deviceType,
}: {
  resolverId?: string
  deviceType?: string
}): ReactElement {
  const selectProvisionPk = useAppSelector(s => s.organization.selectProvisionPk || '')
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: provisionData } = useGetProvisionQuery('', {
    skip: !!(resolverId && deviceType) || !sessionToken,
  })
  const provision = provisionData?.provisions.find(provision => provision.PK === selectProvisionPk)
  const isWindows = provision?.icon?.includes('windows') || deviceType?.includes('windows')

  const cmd =
    provision?.ctrld.cmd ||
    (isWindows
      ? `powershell -Command "(Invoke-WebRequest -Uri '${BASE_URL}/dl' -UseBasicParsing).Content | Set-Content '%TEMP%\\ctrld_install.bat'" && %TEMP%\\ctrld_install.bat ${resolverId} forced`
      : `sh -c 'sh -c "$(curl -sSL ${BASE_URL}/dl)" -s ${resolverId} forced'`)
  const startServiceCurl = `${isWindows ? 'ctrld.exe' : 'ctrld'} start ${
    resolverId ? '--cd=' : '--cd-org '
  }${resolverId}`

  return (
    <Flex
      sx={{
        pt: ['2.4rem', '4.8rem'],
        pb: '4rem',
        px: ['1.6rem', '4.8rem'],
        flexDirection: 'column',
        gap: '3.2rem',
        alignItems: 'center',
        textAlign: 'center',
        maxHeight: [`calc(100% - ${trayHeaderHeight})`, '100%', '100%'],
        overflowY: 'auto',
      }}
      className="show-scrollbar"
    >
      <Text sx={{ fontSize: ['1.6rem', '1.8rem'] }}>
        Execute the following installer command in your favorite RMM.
      </Text>

      <Flex sx={{ width: '100%', flexDirection: 'column', alignItems: 'center', gap: '2.4rem' }}>
        {!provision?.ctrld.cmd && (
          <Text sx={{ fontSize: ['1.6rem', '1.8rem'] }}>
            {isWindows ? 'Windows' : 'MacOS/ Linux Endpoints'}
          </Text>
        )}

        <Flex
          data-testid="terminal-container"
          sx={{
            width: ['100%', '60rem'],
            backgroundColor: 'black50',
            p: ['1.6rem', '2.4rem'],
            borderRadius: '1.6rem',
            textAlign: 'left',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Text sx={{ width: '58rem', color: 'white50', fontSize: '1.4rem' }}>{cmd}</Text>
          <CopyToClipboard smallIcon data={cmd} fillSvg="white50" />
        </Flex>
      </Flex>

      {provision?.ctrld.cmd && (
        <>
          <Divider sx={{ width: '100%', color: 'white15' }} />

          <Text
            sx={{
              fontSize: ['1.6rem', '1.8rem'],
            }}
          >
            Or download the self-contained install script and deploy it.
          </Text>

          <Button
            data-testid="download-install"
            variant="secondary"
            aria-label="download script"
            sx={{
              width: 'fit-content',
              borderRadius: '5.2rem',
              border: 'none',
              py: '1.6rem',
              px: '2.4rem',
              fontSize: ['1.6rem', '1.8rem'],
              fontWeight: 'bold',
            }}
            onClick={() => {
              if (provision?.ctrld) {
                downloadFile({
                  content: decodeURIComponent(provision.ctrld.script),
                  fileTitle: provision.ctrld.filename,
                  fileType: 'text/plain',
                })
              }
            }}
          >
            Download Install Script
          </Button>
        </>
      )}

      <Divider sx={{ width: '100%', color: 'white15' }} />

      <Text
        sx={{
          fontSize: ['1.6rem', '1.8rem'],
        }}
      >
        {!provision?.ctrld.cmd
          ? 'Alternatively, you can avoid using the installer, download and run the binaries manually.'
          : 'Alternatively, download and deploy the binary manually.'}
      </Text>

      {!provision?.ctrld.cmd && (
        <>
          <Text sx={{ maxWidth: '49.8rem', fontSize: ['1.6rem', '1.8rem'] }}>
            <strong>Step 1:</strong> Download the appropriate binary for target architecture{' '}
            <ExternalLink
              data-testid="download-manually"
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/ctrld#download-manually`}
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              here
            </ExternalLink>
          </Text>

          <Flex
            sx={{ width: '100%', flexDirection: 'column', gap: '2.4rem', alignItems: 'center' }}
          >
            <Text
              sx={{
                maxWidth: '49.8rem',
                fontSize: ['1.6rem', '1.8rem'],
              }}
            >
              <strong>Step 2:</strong> Start the service
            </Text>

            <Flex
              data-testid="terminal-container"
              sx={{
                width: ['100%', '60rem'],
                backgroundColor: 'black50',
                p: ['1.6rem', '2.4rem'],
                borderRadius: '1.6rem',
                textAlign: 'left',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Text sx={{ width: '58rem', color: 'white50', fontSize: '1.4rem' }}>
                {startServiceCurl}
              </Text>
              <CopyToClipboard smallIcon data={startServiceCurl} fillSvg="white50" />
            </Flex>
          </Flex>
        </>
      )}
      <Button
        variant="secondary"
        aria-label="open provisioning doc"
        sx={{
          width: 'fit-content',
          borderRadius: '5.2rem',
          border: 'none',
          py: '1.6rem',
          px: '2.4rem',
        }}
        onClick={() => {
          window.open(
            `${DOCS_CONTROL_D_DOMAIN}/docs/provisioning`,
            '_blank',
            'noopener, noreferrer',
          )
        }}
      >
        <Text sx={{ fontSize: ['1.6rem', '1.8rem'], fontWeight: 'bold' }}>View Documentation</Text>
      </Button>
    </Flex>
  )
}
