import React, { ReactElement } from 'react'
import { Flex, IconButton } from 'theme-ui'
import { Svg } from 'ui'
import LogoIcon from 'images/logos/org-homepage.svg'
import BurgerMenuIcon from 'images/organization/homepage/headerNavMenu/burger-menu.svg'
import { noRedirectHomePagePath } from 'layouts'
import { ButtonGroup } from 'components/HomePage/Header/index'
import { NoRedirectLink } from 'components/NoRedirectLink'

export function MobilesHeader({
  setIsMobileNavMenuOpen,
}: {
  setIsMobileNavMenuOpen?: (boolean) => void
}): ReactElement {
  return (
    <>
      <NoRedirectLink
        aria-label="Logo"
        testId="organization-homepage-logo"
        to={noRedirectHomePagePath}
        style={{ display: 'flex', textDecoration: 'none' }}
      >
        <Svg svg={LogoIcon} />
      </NoRedirectLink>
      <Flex sx={{ gap: '1.6rem' }}>
        <ButtonGroup />
        <IconButton
          name="link"
          aria-label="navigation menu"
          data-testid="mobile-nav-menu"
          onClick={() => setIsMobileNavMenuOpen?.(true)}
          sx={{
            height: '4rem',
            width: '4rem',
            borderRadius: '100%',
            ':hover': {
              backgroundColor: 'borderFaint',
            },
          }}
        >
          <Svg svg={BurgerMenuIcon} />
        </IconButton>
      </Flex>
    </>
  )
}
