import React, { ReactElement } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import CloseIcon from 'images/profileManagement/close-icon.svg'
import LinkIcon from 'images/combined-shape.svg'
import NewLinkIcon from 'images/contact/link-icon.svg'
import { Button, TrayRenderProps, Svg, CircleIconWrapperButton } from 'ui'
import TrayHeader from 'components/TrayHeader'
import { useAppSelector } from 'store/hooks'
import CustomThemeProvider from 'ui/Theme/ThemeProvider'
import useColorModeHelper from 'utils/useColorModeHelper'

const BlockListLayout = ({ dismiss }: TrayRenderProps): ReactElement => {
  const selectedFilter = useAppSelector(s => s.filters.selectedFilter)
  const { name: title, description, sources, additional } = selectedFilter || {}

  const iconClick = (source: string): void => {
    typeof window !== `undefined` && window.open(source)
  }

  const { isDarkMode } = useColorModeHelper()

  return (
    <CustomThemeProvider>
      <Flex sx={{ height: '100%', flexDirection: 'column' }}>
        <TrayHeader
          sx={{
            p: '1.6rem',
            alignItems: 'center',
          }}
          leftComponent={
            <CircleIconWrapperButton
              data-testid="blocklist-close-button"
              ariaLabel="close tray"
              onClick={dismiss}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: isDarkMode ? 'transparent' : 'white10',
                p: isDarkMode ? 0 : '0.4rem',
                width: isDarkMode && 'fit-content',
                height: isDarkMode && 'fit-content',
                '&:hover': {
                  ':not([disabled])': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              <Svg
                svg={CloseIcon}
                fill="white50"
                sx={{
                  width: '2.4rem',
                  height: '2.4rem',
                }}
              />
            </CircleIconWrapperButton>
          }
        >
          <Text
            variant="cardLabel"
            tabIndex={0}
            sx={{
              textAlign: 'center',
              width: '100%',
              color: 'labelColor',
            }}
          >
            {title}
          </Text>
        </TrayHeader>
        <Flex
          className="show-scrollbar"
          sx={{
            overflowY: 'auto',
            flexDirection: 'column',
            maxWidth: '100vw',
          }}
        >
          <Text
            variant="trayText"
            tabIndex={0}
            sx={{
              color: 'textColor',
              px: '1.6rem',
              pt: '1.6rem',
            }}
          >
            {description}
          </Text>
          {additional && (
            <Text
              variant="trayText"
              tabIndex={0}
              sx={{
                color: 'textColor',
                fontSize: '1.6rem',
                px: '1.6rem',
                '& strong': {
                  color: 'labelColor',
                },
              }}
            >
              {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
              <span dangerouslySetInnerHTML={{ __html: additional }} />
            </Text>
          )}
          {!!sources?.length && (
            <Box sx={{ mt: '1.6rem', px: '1.6rem' }}>
              <Text
                variant="sectionTitle"
                sx={{
                  mt: '2.4rem',
                  mb: '0.8rem',
                  color: 'labelColor',
                }}
              >
                Sources
              </Text>
              <Box
                as="ul"
                sx={{
                  listStyle: 'none',
                  pl: !isDarkMode && 0,
                  backgroundColor: 'color15',
                  p: isDarkMode && '1.6rem',
                  mt: isDarkMode && '1.6rem',
                  borderRadius: isDarkMode && '0.8rem',
                }}
              >
                {sources?.map((source, index) => {
                  return (
                    <Box
                      as="li"
                      sx={{
                        pt: !isDarkMode && '1.6rem',
                        pb: !isDarkMode && '1.6rem',
                        borderBottom: (theme): string =>
                          `1px solid ${isDarkMode ? 'transparent' : theme.colors?.white10}`,
                      }}
                      key={`${source}${index}`}
                    >
                      <Flex>
                        <Text sx={{ wordBreak: 'break-all', maxWidth: '90%', color: 'textColor' }}>
                          {source}
                        </Text>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button
                          variant="simple"
                          ariaLabel={`open ${new URL(source).hostname} in the new tab`}
                          sx={{ p: 0 }}
                          onClick={(): void => iconClick(source)}
                        >
                          <Svg
                            svg={isDarkMode ? NewLinkIcon : LinkIcon}
                            fill=""
                            sx={{
                              width: isDarkMode ? '1.6rem' : '2.4rem',
                              height: isDarkMode ? '1.6rem' : '2.4rem',
                            }}
                          />
                        </Button>
                      </Flex>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          )}
          <Box sx={{ mb: '2.4rem' }} />
        </Flex>
      </Flex>
    </CustomThemeProvider>
  )
}

export default BlockListLayout
