import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import omit from 'lodash/omit'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useQueryString from 'utils/useQueryString'
import { Button } from 'ui'
import { setDeviceForEdit } from 'store/multiprofile'
import { DeviceDialogType } from 'components/Dashboard/Devices/DeviceTrayOrModalDialog'
import { CONTROLDText } from 'ui/CONTROLD'

export default function OnlyLegacyDNS({
  selectedDevicePk,
}: {
  selectedDevicePk?: string
}): ReactElement {
  const isLoggedIn = useAppSelector(s => !!s.persistData.sessionToken)
  const dispatch = useAppDispatch()
  const { qs, nav } = useQueryString()
  return (
    <Flex
      sx={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Text
        sx={{
          width: ['100%', '57.6rem', '62.8rem'],
          textAlign: 'center',
          fontSize: '1.8rem',
          color: 'white50',
          margin: 0,
        }}
      >
        This platform currently only supports Legacy DNS.
        {isLoggedIn
          ? ` Please add a Legacy Resolver to this Endpoint in order to proceed with this tutorial.`
          : ''}
        {!isLoggedIn && !!qs.resolverUid
          ? ` Please ask the person who sent you the ${CONTROLDText} provisioning link to add a Legacy DNS resolver to the Endpoint.`
          : ''}
      </Text>

      {isLoggedIn &&
        !qs.resolverUid && ( // should be logged in and not in provisioning flow to see the edit device button
          <Button
            variant="secondary"
            data-testid="edit-device-button"
            sx={{
              alignSelf: 'center',
              p: '1.3rem 2.8rem 1.4rem',
              width: '30rem',
              fontSize: '1.8rem',
              fontWeight: 'bold',
              flexShrink: 0,
              color: 'white',
              border: '2px solid',
              borderColor: 'plum50',
              borderRadius: '59px',
              backgroundColor: 'transparent',
              mb: '2.4rem',
              mt: ['2.4rem', 0],
            }}
            onClick={() => {
              dispatch(setDeviceForEdit(selectedDevicePk))
              nav({
                ...omit(qs, 'helpPane', 'setupOs', 'step'),
                deviceDialog: DeviceDialogType.EDIT,
              })
            }}
          >
            Edit Endpoint
          </Button>
        )}
    </Flex>
  )
}
