import pick from 'lodash/pick'
import { PersistedState } from 'redux-persist/es/types'
import { TutorialState, TutorialType } from './tutorial/tutorial'

const profileManagementTutorialType = 'profile-management'
const devicesTutorialType = 'devices'

type StateType = PersistedState & TutorialState

export const migrateSessionsState = (
  state: StateType,
  itemKey: string,
): Promise<PersistedState> => {
  if (typeof window === 'undefined') {
    return Promise.resolve(state)
  }

  const item = window.localStorage.getItem(itemKey)

  if (!item) {
    const updatedState = getStateAfterProfileManagementMigration(state)

    return Promise.resolve(updatedState)
  }

  const sessionsState = JSON.parse(item)
  const sessionsViewedStateByUserPk = JSON.parse(sessionsState.viewedStateByUserPk)

  const initialState = {
    tutorialType: sessionsState.tutorialType,
    viewedStateByUserPk: {},
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _persist: sessionsState._persist,
  }

  for (const key in sessionsViewedStateByUserPk) {
    initialState.viewedStateByUserPk[key] = {}
    initialState.viewedStateByUserPk[key].wasTutorialDenied =
      sessionsViewedStateByUserPk[key].wasTutorialDenied

    for (const key2 in sessionsViewedStateByUserPk[key]) {
      key2 !== 'wasTutorialDenied' &&
        (initialState.viewedStateByUserPk[key][key2] = pick(
          sessionsViewedStateByUserPk[key][key2],
          'hasBeenViewed',
        ))
    }
  }

  window.localStorage.removeItem(itemKey)

  const updatedState = getStateAfterProfileManagementMigration(initialState)

  return Promise.resolve(updatedState)
}

const getStateAfterProfileManagementMigration = (state: StateType): StateType => {
  if (!state?.viewedStateByUserPk) {
    return state
  }

  const viewedStates = Object.entries(state?.viewedStateByUserPk)

  // check if any user has profile-management
  if (viewedStates.some(viewedState => viewedState[1][profileManagementTutorialType])) {
    const viewedStateByUserPk = Object.fromEntries(
      viewedStates.map(([key, value]) => {
        // if profile-management exist, setting value for devices and profiles, and then remove profile-management prop
        if (value[profileManagementTutorialType]) {
          value[TutorialType.ENDPOINTS] = value[profileManagementTutorialType]
          value[TutorialType.PROFILES] = value[profileManagementTutorialType]

          delete value[profileManagementTutorialType]

          return [key, value]
        }
        if (value[devicesTutorialType]) {
          value[TutorialType.ENDPOINTS] = value[devicesTutorialType]
          delete value[devicesTutorialType]
        }
        return [key, value]
      }),
    )

    return { ...state, viewedStateByUserPk } as StateType
  }

  return state
}
