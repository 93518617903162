import React, { ElementType, ReactChild, ReactElement, useState } from 'react'
import { Flex } from 'theme-ui'
import { Dropdown, Svg } from 'ui'
import DropdownIcon from 'images/profileManagement/context-menu-arrow-down.svg'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { Instance } from 'tippy.js'
import ExternalSvgIcon, { ExternalIcon } from 'components/Dashboard/ExternalSvgIcon'

export default function LogFilterDropdown({
  children,
  isDisabled,
  selectedValue,
  icon,
  externalIcon,
  testId,
  onShow,
  onCreate,
  isLocation,
}: {
  children: ReactChild | ReactChild[]
  isDisabled?: boolean
  selectedValue: string
  icon?: ElementType
  externalIcon?: ExternalIcon
  testId: string
  onShow?: () => void
  onCreate?: (instance: Instance) => void
  isLocation?: boolean
}): ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dropdown
      data-testid={testId}
      tippyprops={{
        appendTo: 'parent',
        theme: 'screen',
        offset: [0, 8],
        onHide: () => {
          setIsOpen(false)
        },
        onShow: () => {
          onShow?.()
          setIsOpen(true)
        },
        onCreate,
        maxWidth: 'fit-content',
        zIndex: 300,
        placement: 'bottom-start',
      }}
      sx={{
        p: 0,
      }}
      variant="transparent"
      dropdowncontent={isOpen ? children : <></>}
      disabled={isDisabled}
    >
      <Flex
        sx={{
          width: 'fit-content',
          justifyContent: 'space-between',
          fontSize: ['1.6rem', '1.6rem', '1.8rem'],
          color: isOpen ? 'white' : 'white50',
          alignItems: 'center',
          mr: '1.4rem',
          '& > svg:last-of-type': {
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
          },
          ':hover': {
            color: 'white',
            '& div svg path': {
              fill: !isLocation && 'white',
            },
            '& > svg path': {
              fill: 'white',
            },
          },
        }}
      >
        <Flex sx={{ alignItems: 'center', gap: '0.4rem' }}>
          {externalIcon && (
            <ExternalSvgIcon
              icon={externalIcon.icon}
              type={externalIcon.type}
              fill={isLocation ? undefined : isOpen ? 'white' : 'white50'}
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                flexShrink: 0,
              }}
            />
          )}
          {icon && (
            <Svg
              svg={icon}
              fill={isLocation ? undefined : isOpen ? 'white' : 'white50'}
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                flexShrink: 0,
              }}
            />
          )}
          <TextWithOverFlowAndTippyPopup
            content={selectedValue ?? ''}
            sxText={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textAlign: 'left',
              maxWidth: '20rem',
            }}
          />
        </Flex>
        <Svg
          svg={DropdownIcon}
          fill={isOpen ? 'white' : 'white50'}
          sx={{
            width: '1.6rem',
            height: '1.6rem',
            ml: ['0.4rem', '0.4rem', '0.8rem'],
            flexShrink: 0,
          }}
        />
      </Flex>
    </Dropdown>
  )
}
