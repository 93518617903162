import React, { ElementType, useState } from 'react'
import { Button, Svg } from 'ui'
import { Text, Box, Flex, ThemeUIStyleObject } from 'theme-ui'
import DropdownIcon from 'images/ui/down-chevron.svg'
import ArrowUpDownIcon from 'images/ui/arrow-up-down.svg'

export interface DropdownItemData {
  text: string
  description?: string
  pk?: string
  Icon?: React.ElementType
  dropdownItemIconStyle?: ThemeUIStyleObject
}

export interface DropdownNativeProps {
  sx?: ThemeUIStyleObject
  iconSx?: ThemeUIStyleObject
  disabled?: boolean
  disabledOptionPk?: string
  items: DropdownItemData[]
  selectedItem?: DropdownItemData
  onSelectedItemChange: (selectedItem) => void
  initialSelectedItem?: DropdownItemData
  hideText?: boolean
  name?: string
  selectedItemIconFill?: string
  customArrowIcon?: ElementType
  useInitialItemTextAsTitle?: boolean
  addDefaultSelectorOption?: boolean
  arrowUpDownIcon?: boolean
}

export function DropdownNative({
  disabled = false,
  disabledOptionPk,
  sx = {},
  items,
  selectedItem,
  onSelectedItemChange,
  initialSelectedItem,
  hideText = false,
  selectedItemIconFill = '',
  customArrowIcon,
  name,
  useInitialItemTextAsTitle = false,
  addDefaultSelectorOption,
  arrowUpDownIcon,
  iconSx,
  ...attrs
}: DropdownNativeProps): React.ReactElement {
  const [itemToDisplay, setItemToDisplay] = useState(selectedItem || initialSelectedItem)

  // if you prefer to control component state from the outside..
  const controlledOnSelectedItemChange = onSelectedItemChange || setItemToDisplay
  const controlledItemToDisplay = selectedItem || itemToDisplay

  return (
    <Button
      disabled={disabled}
      type="button"
      variant="simple"
      sx={{ ...sx, position: 'relative' }}
      tabIndex={-1}
      {...attrs}
    >
      <Flex sx={{ alignItems: 'center', flex: 1 }}>
        {controlledItemToDisplay?.Icon && (
          <Svg
            svg={controlledItemToDisplay.Icon}
            fill={selectedItemIconFill}
            sx={{
              mr: 4,
              ...iconSx,
            }}
          />
        )}

        <Flex sx={{ flexGrow: 1, textAlign: 'left' }}>
          {!hideText && (
            <Text
              data-testid="dropdown-native-text"
              sx={{
                mr: 4,
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {controlledItemToDisplay ? controlledItemToDisplay.text : 'Select an option'}
            </Text>
          )}
        </Flex>

        <Svg
          svg={(arrowUpDownIcon && ArrowUpDownIcon) || customArrowIcon || DropdownIcon}
          fill="white50"
        />

        <Box
          name={name}
          as="select"
          data-testid="dropdown-native-select"
          value={controlledItemToDisplay?.text || ''}
          // @ts-ignore
          onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
            controlledOnSelectedItemChange(items.find(item => item.text === e.target.value))
          }}
          disabled={disabled}
          sx={{
            // needed to prevent iOS from zooming on click
            fontSize: '100%',
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            opacity: 0,
            left: 0,
            cursor: 'pointer',
            zIndex: 'zIndex10',
            '&:focus': {
              '& + .select_focused_div': {
                outline: '-webkit-focus-ring-color auto 1px',
              },
            },
          }}
        >
          {/* this is needed because otherwise selecting the first option will not trigger
         a change event */}
          {addDefaultSelectorOption && (
            <option value="">{useInitialItemTextAsTitle && initialSelectedItem?.text}</option>
          )}
          {items.map((item, i) => (
            <option
              value={item.text}
              key={`${item.text}${i}`}
              disabled={!!disabledOptionPk && disabledOptionPk === item?.pk}
            >
              {item.text}
            </option>
          ))}
        </Box>

        <Box
          className="select_focused_div"
          sx={{
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            left: 0,
          }}
        />
      </Flex>
    </Button>
  )
}
