import { footerHeight } from 'components/SetupGuide/Components/SetupGuidePane'
import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'

export default function Footer({
  previousButton,
  nextButton,
  textBanner,
}: {
  previousButton?: ReactElement
  nextButton?: ReactElement
  textBanner?: ReactElement
}): ReactElement {
  return (
    <Flex
      sx={{
        top: `calc(100% - ${footerHeight})`,
        height: footerHeight,
        left: '0',
        alignItems: 'center',
        backgroundColor: 'white5',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          borderTop: `1px solid`,
          borderColor: 'white15',
          height: '100%',
          px: '1.6rem',
        }}
      >
        {previousButton}
        {textBanner}
        {nextButton}
      </Flex>
    </Flex>
  )
}
