import React, { ReactElement } from 'react'
import { Text, Flex, Alert as ThemeUiAlert } from 'theme-ui'
import { AlertInfo } from 'ui/AlertPresenter/AlertPresenter.interface'
import { Svg, Button } from 'ui'

export default function Alert({
  message,
  icon,
  buttons,
  variant = 'primary',
}: AlertInfo): ReactElement {
  if (variant !== 'primary' && (icon || buttons)) {
    console.warn('`icon` and `buttons` props only supported in `Alert`s with `variant`="primary"')
  }

  return (
    <>
      {variant === 'primary' ? (
        <ThemeUiAlert variant={variant}>
          <Flex
            sx={{
              flexDirection: 'row',
              flex: 'auto',
              alignItems: 'center',
              '& > :not(:last-child)': {
                mr: '1.6rem',
              },
              minHeight: 'initial',
              pt: '1.6rem',
            }}
          >
            {icon && (
              <Svg
                data-testid="alert-icon"
                svg={icon}
                fill="white"
                sx={{
                  width: '24px',
                  height: '24px',
                  flexShrink: 0,
                  my: '-4px',
                }}
              />
            )}
            <Text
              data-testid="alert-message"
              sx={{ m: 0, fontWeight: 'normal', fontFamily: 'body' }}
            >
              {message}
            </Text>
          </Flex>
          <Flex
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              flex: 'auto',
              height: '100%',
              ml: '1.6rem',
              pt: '1.6rem',
              '& > :not(:last-child)': { mr: '1.6rem' },
            }}
          >
            {buttons?.map((button, index) => (
              <Button
                variant="simple"
                data-testid="alert-button"
                sx={{
                  p: 0,
                  border: 'none',
                  fontWeight: 'bold',
                  backgroundColor: 'plum',
                  color: 'white',
                }}
                key={index}
                onClick={button.onClick}
                onKeyDown={button.onKeyDown}
              >
                {button.text}
              </Button>
            ))}
          </Flex>
        </ThemeUiAlert>
      ) : (
        <ThemeUiAlert
          variant={variant}
          sx={{
            p: 4,
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
          }}
        >
          <Text data-testid="alert-message" sx={{ fontWeight: 'normal', fontFamily: 'body' }}>
            {message}
          </Text>
        </ThemeUiAlert>
      )}
    </>
  )
}
