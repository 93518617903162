import React, { Dispatch, ReactElement } from 'react'
import { DDNS_DOMAIN, DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { Box, Flex, Text } from 'theme-ui'
import SettingsItem from './SettingsItem'
import RestrictedResolverIcon from 'images/profileManagement/settings/restricted-resolver.svg'
import LegacyResolverIcon from 'images/profileManagement/settings/legacy.svg'
import AutoAuthIPIcon from 'images/profileManagement/settings/auto-auth-ip.svg'
import ExposeIPIcon from 'images/profileManagement/settings/expose-ip.svg'
import PreventDeactivationIcon from 'images/profileManagement/settings/prevent-deactivation.svg'
import StatusIcon from 'images/profileManagement/settings/status.svg'
import { ExternalLink, Input, Switch, useAlertPresenter } from 'ui'
import { EnabledStatus } from 'store/api/rules'
import DynamicDNS from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/DynamicDNS'
import StatusDropdown from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsDropdowns/StatusDropdown'
import {
  ActionType,
  DeviceActionType,
  InitialStateType,
} from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsState'
import ErrorBox from 'components/LoginSignUpForm/ErrorBox'
import {
  ddnsSubdomainMinLength,
  isDeactivationPinLengthValid,
} from './AddOrEditDevice/AddOrEditDeviceView'
import DividerWithText from 'ui/DividerWithText'
import useGetUserState from 'store/api/user/useGetUserState'
import { numericalMask } from 'components/Organization/Provision/ProvisionTrayOrModalDialog/AddProvisionView'
import ErrorIcon from 'images/erroricon.svg'

const legacyWarningAlert = 'legacy-warning-alert'

const DeviceSettings = ({
  settingsState,
  settingsDispatch,
  isStatusVisible,
  isSingleDevice,
  deviceDeactivationPin,
}: {
  settingsState: InitialStateType
  settingsDispatch: Dispatch<ActionType>
  isStatusVisible: boolean
  isSingleDevice?: boolean
  deviceDeactivationPin?: number
}): ReactElement => {
  const { isOrganization } = useGetUserState()
  const { presentAlert, dismissAlert } = useAlertPresenter()

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Flex sx={{ flexDirection: 'column', gap: '2.4rem' }}>
        {isOrganization && isSingleDevice && (
          <SettingsItem
            testId="restricted-resolver"
            icon={RestrictedResolverIcon}
            title="Restricted Resolver"
            description="Only previously authorized IPs can query against this Endpoint's resolvers."
            descriptionLink={
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/restricted-resolver`}
                sx={{
                  color: 'white50',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                }}
              >
                Learn more
              </ExternalLink>
            }
            actionContent={
              <Switch
                data-testid="restricted-switch"
                checked={!!settingsState.restricted}
                onClick={() => {
                  settingsDispatch({
                    type: DeviceActionType.RESTRICTED_RESOLVER,
                    payload: settingsState.restricted
                      ? EnabledStatus.DISABLED
                      : EnabledStatus.ENABLED,
                  })

                  settingsDispatch({
                    type: DeviceActionType.LEGACY_RESOLVER,
                    payload: EnabledStatus.DISABLED,
                  })

                  settingsDispatch({
                    type: DeviceActionType.AUTO_AUTH_IP,
                    payload: EnabledStatus.DISABLED,
                  })
                }}
              />
            }
          />
        )}
        {isSingleDevice && (
          <SettingsItem
            testId="legacy-resolver"
            icon={LegacyResolverIcon}
            title="Legacy Resolver"
            description="Issue IPv4 and IPv6 legacy DNS resolvers to use on older physical devices."
            descriptionLink={
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/legacy-resolver`}
                sx={{
                  color: 'white50',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                }}
              >
                Learn more
              </ExternalLink>
            }
            actionContent={
              <Switch
                data-testid="legacy-resolver-switch"
                checked={
                  !settingsState.restricted &&
                  (!!settingsState.legacy_ipv4?.status ?? !!EnabledStatus.DISABLED)
                }
                onClick={e => {
                  e.stopPropagation()

                  const dataDispatch = () => {
                    const payload = !!settingsState.legacy_ipv4?.status
                      ? EnabledStatus.DISABLED
                      : EnabledStatus.ENABLED

                    settingsDispatch({
                      type: DeviceActionType.LEGACY_RESOLVER,
                      payload,
                    })

                    settingsDispatch({
                      type: DeviceActionType.RESTRICTED_RESOLVER,
                      payload: EnabledStatus.DISABLED,
                    })
                  }

                  if (!!settingsState.legacy_ipv4?.status) {
                    presentAlert(
                      {
                        message: `Disabling this option will re-claim Legacy DNS IPs you were issued previously.
                       This will prevent Control D from enforcing your rules if you use Legacy DNS protocols.
                        Are you sure you want to do this?`,
                        variant: 'primary',
                        isSticky: true,
                        shouldDismissOnClickOutside: true,
                        icon: ErrorIcon,
                        buttons: [
                          {
                            onClick: () => {
                              dataDispatch()

                              dismissAlert(legacyWarningAlert)
                            },
                            text: 'Yes',
                          },
                          {
                            onClick: () => {
                              dismissAlert(legacyWarningAlert)
                            },
                            text: 'No',
                          },
                        ],
                      },
                      legacyWarningAlert,
                    )
                  } else {
                    dataDispatch()
                  }
                }}
              />
            }
          />
        )}
        <SettingsItem
          testId="auto-auth-ip"
          icon={AutoAuthIPIcon}
          title="Auto Authorize IP"
          description="Learn and log new IPs on an Endpoint automatically."
          descriptionLink={
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/auto-authorize-ip`}
              sx={{
                color: 'white50',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
              }}
            >
              Learn more
            </ExternalLink>
          }
          warningMessage={`${
            !!settingsState.legacy_ipv4?.status
              ? 'Disable "Legacy Resolver" to modify this option'
              : ''
          }`}
          actionContent={
            <Switch
              data-testid="auto-auth-ip-switch"
              isDisabled={!!settingsState.legacy_ipv4?.status || !!settingsState.restricted}
              checked={!!settingsState.learn_ip}
              onClick={() => {
                settingsDispatch({
                  type: DeviceActionType.AUTO_AUTH_IP,
                  payload: settingsState.learn_ip ? EnabledStatus.DISABLED : EnabledStatus.ENABLED,
                })
              }}
            />
          }
          expandedContent={
            !!settingsState.legacy_ipv4?.status ? (
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Text sx={{ fontSize: '1.6rem', color: 'white50', mb: '2.8rem' }}>
                  Make a DNS query using any Secure DNS protocol. Source IP will be automatically
                  authorized to query against Legacy IPv4 resolvers.
                </Text>
                <DividerWithText sx={{ fontSize: '1.6rem', color: 'white50' }} text="OR" />
                <DynamicDNS settingsState={settingsState} settingsDispatch={settingsDispatch} />
              </Flex>
            ) : null
          }
        />
        {isSingleDevice && (
          <SettingsItem
            testId="expose-ip"
            icon={ExposeIPIcon}
            title="Expose IP via DNS"
            description="Advertise source IP of last Secure protocol query via a Dynamic DNS record."
            descriptionLink={
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/expose-ip-via-dns`}
                sx={{
                  color: 'white50',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                }}
              >
                Learn more
              </ExternalLink>
            }
            actionContent={
              <Switch
                data-testid="expose-ip-switch"
                checked={!!settingsState.ddns?.status}
                onClick={() => {
                  const payload = settingsState.ddns?.status
                    ? EnabledStatus.DISABLED
                    : EnabledStatus.ENABLED

                  settingsDispatch({
                    type: DeviceActionType.EXPOSE_IP_STATUS,
                    payload,
                  })
                }}
              />
            }
            expandedContent={
              !!settingsState.ddns?.status ? (
                <Flex sx={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <Flex
                    sx={{
                      width: '100%',
                      borderRadius: '4.5rem',
                      border: '1px solid',
                      borderColor: 'white10',
                      pr: '2.4rem',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Input
                      name="expose-ip-input"
                      data-testid="expose-ip-input"
                      aria-label="expose ip input"
                      activeLabelColor="white"
                      hideLabel
                      value={settingsState.ddns?.subdomain}
                      placeholder="Enter subdomain"
                      sx={{
                        borderRadius: '45px',
                        alignItems: 'center',
                        backgroundColor: 'white10',
                      }}
                      onChange={(event): void => {
                        settingsDispatch({
                          type: DeviceActionType.EXPOSE_IP_SUBDOMAIN,
                          payload: event.target.value,
                        })
                      }}
                    />
                    <Text
                      sx={{
                        fontSize: ['1.4rem', '1.8rem'],
                        color: 'white50',
                        ml: ['0.6rem', '1.2rem'],
                      }}
                    >
                      {`.${DDNS_DOMAIN}`}
                    </Text>
                  </Flex>
                  {(settingsState.ddns?.subdomain?.length || 0) < ddnsSubdomainMinLength && (
                    <ErrorBox
                      sx={{
                        width: 'fit-content',
                        minHeight: 'auto',
                        backgroundColor: 'transparent',
                        mx: 0,
                        my: '0.4rem',
                        fontWeight: 'normal',
                      }}
                      errorMessage="Please enter a subdomain that is 5 characters or more."
                    />
                  )}
                </Flex>
              ) : null
            }
          />
        )}
        {/* TODO: should be uncommented when working on ESH */}
        {/*<SettingsItem*/}
        {/*  icon={ECHIcon}*/}
        {/*  title="ECH Support"*/}
        {/*  description="Enable EncryptedClientHello support which encrypts SNI when using TLS."*/}
        {/*  actionContent={*/}
        {/*    <Switch*/}
        {/*      data-testid="ech-support-switch"*/}
        {/*      checked={!!settingsState.bump_tls}*/}
        {/*      onClick={() => {*/}
        {/*        settingsDispatch({*/}
        {/*          type: DeviceActionType.ECH_SUPPORT,*/}
        {/*          payload: settingsState.bump_tls*/}
        {/*            ? EnabledStatus.DISABLED*/}
        {/*            : EnabledStatus.ENABLED,*/}
        {/*        })*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  }*/}
        {/*  expandedContent={*/}
        {/*    !!settingsState.bump_tls ? (*/}
        {/*      <Flex*/}
        {/*        sx={{*/}
        {/*          justifyContent: 'space-between',*/}
        {/*          alignItems: 'center',*/}
        {/*          flexDirection: 'column',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <Text sx={{ fontSize: '1.6rem', color: 'white50' }}>*/}
        {/*          EncryptedClientHello (ECH) is bleeding edge tech and is only available in a*/}
        {/*          handful of browsers, and almost no website actually supports it. Control D can*/}
        {/*          enable ECH Internet wide, regardless of end destinations actually supporting it.*/}
        {/*          In order for this to work, a root certificate must be installed on your machine*/}
        {/*          which gives Control D the capability to decrypt ECH and re-encrypt traffic using*/}
        {/*          standard TLS.*/}
        {/*        </Text>*/}
        {/*        <Flex*/}
        {/*          sx={{*/}
        {/*            width: '100%',*/}
        {/*            fontSize: '1.8rem',*/}
        {/*            fontWeight: 'bold',*/}
        {/*            color: 'white50',*/}
        {/*            justifyContent: 'space-between',*/}
        {/*            mt: '1.6rem',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          Download Certificate*/}
        {/*          <Svg svg={ExternalLinkIcon} fill="white50" />*/}
        {/*        </Flex>*/}
        {/*        <Divider sx={{ width: '100%', height: '1px', color: 'white10', my: '1.6rem' }} />*/}
        {/*        <Flex*/}
        {/*          sx={{*/}
        {/*            width: '100%',*/}
        {/*            fontSize: '1.8rem',*/}
        {/*            fontWeight: 'bold',*/}
        {/*            color: 'white50',*/}
        {/*            justifyContent: 'space-between',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          How to install*/}
        {/*          <Svg svg={ExternalLinkIcon} fill="white50" />*/}
        {/*        </Flex>*/}
        {/*        <Divider sx={{ width: '100%', height: '1px', color: 'white10', my: '1.6rem' }} />*/}
        {/*        <Flex*/}
        {/*          sx={{*/}
        {/*            width: '100%',*/}
        {/*            fontSize: '1.8rem',*/}
        {/*            fontWeight: 'bold',*/}
        {/*            color: 'white50',*/}
        {/*            justifyContent: 'space-between',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          How it works*/}
        {/*          <Svg svg={ExternalLinkIcon} fill="white50" />*/}
        {/*        </Flex>*/}
        {/*      </Flex>*/}
        {/*    ) : null*/}
        {/*  }*/}
        {/*/>*/}
        {isSingleDevice && (
          <SettingsItem
            testId="prevent-deactivation"
            icon={PreventDeactivationIcon}
            title="Prevent Deactivation"
            description="Set a PIN that is required to disable Control D if apps are used."
            descriptionLink={
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/prevent-deactivation`}
                sx={{
                  color: 'white50',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                }}
              >
                Learn more
              </ExternalLink>
            }
            actionContent={
              <Switch
                data-testid="prevent-deactivation-switch"
                checked={!!settingsState.deactivationStatus}
                onClick={() => {
                  const payload = settingsState.deactivationStatus
                    ? EnabledStatus.DISABLED
                    : EnabledStatus.ENABLED

                  settingsDispatch({
                    type: DeviceActionType.PREVENT_DEACTIVATION_STATUS,
                    payload,
                  })

                  if (deviceDeactivationPin && payload === EnabledStatus.ENABLED) {
                    settingsDispatch({
                      type: DeviceActionType.PREVENT_DEACTIVATION_PIN,
                      payload: deviceDeactivationPin,
                    })
                  }

                  if (payload === EnabledStatus.DISABLED) {
                    settingsDispatch({
                      type: DeviceActionType.PREVENT_DEACTIVATION_PIN,
                      payload: undefined,
                    })
                  }
                }}
              />
            }
            expandedContent={
              !!settingsState.deactivationStatus ? (
                <Flex sx={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <Input
                    name="prevent-deactivation-input"
                    data-testid="prevent-deactivation-input"
                    aria-label="prevent deactivation input"
                    activeLabelColor="white"
                    hideLabel
                    value={settingsState.deactivation_pin || ''}
                    placeholder="Enter a numeric PIN"
                    sx={{
                      borderRadius: '45px',
                      alignItems: 'center',
                      backgroundColor: 'white10',
                    }}
                    onChange={(event): void => {
                      settingsDispatch({
                        type: DeviceActionType.PREVENT_DEACTIVATION_PIN,
                        payload: event.target.value.length
                          ? +numericalMask(event.target.value)
                          : undefined,
                      })
                    }}
                  />
                  {(!settingsState.deactivation_pin ||
                    !isDeactivationPinLengthValid(settingsState.deactivation_pin)) && (
                    <ErrorBox
                      sx={{
                        width: 'fit-content',
                        minHeight: 'auto',
                        backgroundColor: 'transparent',
                        mx: 0,
                        my: '0.4rem',
                        fontWeight: 'normal',
                      }}
                      errorMessage="Please enter a deactivation PIN consisting of 1-10 characters."
                    />
                  )}
                </Flex>
              ) : null
            }
          />
        )}
        {isStatusVisible && (
          <SettingsItem
            icon={StatusIcon}
            title="Status"
            description="Current state of the Endpoint."
            descriptionLink={
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/device-status`}
                sx={{
                  color: 'white50',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                }}
              >
                Learn more
              </ExternalLink>
            }
            actionContent={
              <Box
                data-testid="settings-status-dropdown-container"
                sx={{ position: 'relative', flexShrink: 0 }}
                className="reset-tooltip"
              >
                <StatusDropdown
                  deviceStatus={settingsState.status}
                  settingsDispatch={settingsDispatch}
                  boundaryDataTestId="settings-status-dropdown-container"
                />
              </Box>
            }
          />
        )}
      </Flex>
    </Flex>
  )
}

export default DeviceSettings
