import React, { ReactElement, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import DashboardContainer from 'components/Dashboard/DashboardContainer'
import DashboardHeader from 'components/Dashboard/DashboardHeader'
import ProvisionIcon from 'images/dashboard/navBar/provision.svg'
import WarningIcon from 'images/organization/warning-icon.svg'
import { Flex } from 'theme-ui'
import AddButton from 'ui/AddButton'
import ProvisionContent from './ProvisionContent'
import { useGetProvisionQuery } from 'store/api/provision'
import NoResults from 'components/Dashboard/NoResults'
import { Button } from 'ui'
import ProvisionTrayOrModalDialog from './ProvisionTrayOrModalDialog'
import useQueryString from 'utils/useQueryString'
import EmailProvisioningTrayOrModalDialog from './ProvisionTable/Options/EmailProvisioningTrayOrDialog'
import omit from 'lodash/omit'
import QueriesPlaceholder from 'components/Dashboard/Analytics/ActivityLog/ActivityLogList/Placeholder/QueriesPlaceholder'
import { useUpdateDocumentTitle } from 'utils/useUpdateDocumentTitle'
import { useGetStatEndpointsQuery } from 'store/api/analytics'

export enum ProvisionDialogType {
  ADD = 'add',
  EMAIL = 'email',
  INSTRUCTIONS = 'instructions',
}

export default function Provision({}: RouteComponentProps): ReactElement {
  const { data: provisionData, isLoading } = useGetProvisionQuery('')
  const { nav, qs } = useQueryString()
  useUpdateDocumentTitle('Provision')
  useGetStatEndpointsQuery('')

  useEffect(() => {
    nav({ ...omit(qs, 'provisionDialog') })

    // we only want to trigger this effect when component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DashboardContainer
        header={
          <DashboardHeader
            icon={ProvisionIcon}
            title="Provision"
            description="Centralized deployment for whole fleets of devices."
            rightContent={
              <Flex sx={{ gap: '1.6rem', alignItems: 'center' }}>
                <AddButton
                  dataTestId="add-provision-button"
                  onClick={() => nav({ ...qs, provisionDialog: ProvisionDialogType.ADD })}
                />
              </Flex>
            }
          />
        }
        loadingPlaceholder={isLoading && <QueriesPlaceholder />}
      >
        {provisionData?.provisions.length ? (
          <ProvisionContent />
        ) : (
          <NoResults
            message="You do not have any provisioning codes."
            icon={WarningIcon}
            content={
              <Button
                variant="primary"
                sx={{
                  m: 0,
                  borderRadius: '45px',
                  fontSize: '1.8rem',
                  px: '2.2rem',
                  fontWeight: 'bold',
                }}
                onClick={() => nav({ ...qs, provisionDialog: ProvisionDialogType.ADD })}
              >
                Create Provisioning Code
              </Button>
            }
          />
        )}
        {(qs.provisionDialog === ProvisionDialogType.ADD ||
          qs.provisionDialog === ProvisionDialogType.INSTRUCTIONS) && (
          <ProvisionTrayOrModalDialog />
        )}
        {qs.provisionDialog === ProvisionDialogType.EMAIL && <EmailProvisioningTrayOrModalDialog />}
      </DashboardContainer>
    </>
  )
}
