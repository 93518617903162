import React, { useMemo, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import Popup from 'ui/Popup'
import { TwoFactorStep } from 'store/twofa'
import AccountPasswordStep from 'components/Organization/TwoFa/Steps/AccountPasswordStep'
import EnableTwoFaStep from 'components/Organization/TwoFa/Steps/EnableTwoFa'
import ScanOrEnterCodeStep from 'components/Organization/TwoFa/Steps/ScanOrEnterCodeStep'

export default function TwoFaPopup(): React.ReactElement {
  const [step, setStep] = useState(TwoFactorStep.PASSWORD_CHECK)

  const stepContent = useMemo(() => {
    switch (step) {
      case TwoFactorStep.PASSWORD_CHECK:
        return <AccountPasswordStep setStep={setStep} />
      case TwoFactorStep.SCAN_OR_ENTER_CODE:
        return <ScanOrEnterCodeStep setStep={setStep} />
      case TwoFactorStep.ENABLE_TWOFA:
        return <EnableTwoFaStep setStep={setStep} />
      default:
        return null
    }
  }, [step])

  return (
    <Popup
      header={
        <Flex
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text sx={{ fontSize: ['1.8rem', '2.1rem'], fontWeight: 'bold' }}>Enable 2FA</Text>
        </Flex>
      }
    >
      {stepContent}
    </Popup>
  )
}
