import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Flex } from '@theme-ui/components'
import {
  Accordion,
  SimpleActionMenuItemProps,
  Switch,
  MultiSelectInput,
  Option,
  ExternalLink,
  Input,
} from 'ui'
import { Divider, Text } from 'theme-ui'
import { CONTROLDText } from 'ui/CONTROLD'
import SimpleDropdown from 'ui/Dropdown/SimpleDropdown'
import type { Setter } from 'utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { ROOT_GROUP } from 'store/api/rules'
import FocusLock from 'react-focus-lock'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { useGetProfilesQuery } from 'store/api/profiles'
import { useLazyGetRulesQuery } from 'store/api/rules/rules'
import { useGetGroupsQuery } from 'store/api/groups'
import { useGetDevicesQuery } from 'store/api/devices'
import useGetUserState from 'store/api/user/useGetUserState'
import useQueryString from 'utils/useQueryString'
import type { AdvancedSettingsType } from 'components/SetupGuide/Components/ProfileDownload'
import customUnescape from 'utils/customUnescape'

function clientIdMask(value: string): string {
  return value.replace(/\W/g, '-').toLowerCase()
}

export default function AdvancedSettings({
  advancedSettings,
  setAdvancedSettings,
  excludedDomains,
  setExcludedDomains,
  excludedNetworks,
  setExcludedNetworks,
  isOpen,
  setIsOpen,
}: {
  advancedSettings: AdvancedSettingsType
  setAdvancedSettings: Setter<AdvancedSettingsType>
  excludedDomains?: Option[] | null
  setExcludedDomains?: Setter<Option[] | null>
  excludedNetworks?: Option[] | null
  setExcludedNetworks?: Setter<Option[] | null>
  isOpen: boolean
  setIsOpen: Setter<boolean>
}): ReactElement {
  const { isCommonExclude, shouldTrustRootCa, shouldPreventDisablement, clientId } =
    advancedSettings

  const dispatch = useAppDispatch()
  const hideRef = useRef<() => void>()
  const [selectedFolderPK, setSelectedFolderPK] = useState<number | undefined>(undefined)
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: profilesData } = useGetProfilesQuery('', { skip: !sessionToken })
  const [getRules] = useLazyGetRulesQuery()
  const { qs } = useQueryString()
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  const selectedDevice = devicesData?.devices.find(d => d.PK === qs.deviceId)
  const { isOrganization } = useGetUserState()
  const currentProfile = profilesData?.shared_profiles?.find(
    profile => profile.PK === selectedDevice?.profile.PK,
  )
  const isCurrentProfileShared = currentProfile?.profile?.opt.data.some(opt => opt.PK === 'shared')

  const { data: groupsData } = useGetGroupsQuery(
    {
      profileId: selectedDevice?.profile.PK ?? '',
      ignoreImpersonation: isCurrentProfileShared,
    },
    { skip: !sessionToken || !selectedDevice },
  )

  useEffect(() => {
    if (selectedFolderPK === undefined) {
      return
    }

    const setDomains = async () => {
      const response = await getRules({
        groupPk: selectedFolderPK,
        profileId: selectedDevice?.profile.PK.toString() ?? '',
      })

      setExcludedDomains?.(
        response.data?.rules.map(
          (rule): Option => ({
            label: rule.PK,
            value: rule.PK,
          }),
        ) || [],
      )
    }

    setDomains()
  }, [dispatch, getRules, selectedDevice?.profile.PK, selectedFolderPK, setExcludedDomains])

  const filteredGroupsData = useMemo(
    () =>
      groupsData?.groups?.map(x => ({
        ...x,
        text: customUnescape(x.group),
      })) || [],
    [groupsData?.groups],
  )

  const options: SimpleActionMenuItemProps[] = useMemo(
    () =>
      filteredGroupsData.map(group => {
        return {
          isSelected: group.PK === selectedFolderPK,
          children: <Text>{group?.text ?? ''}</Text>,
          onClick: () => {
            setSelectedFolderPK(group.PK)
            hideRef.current?.()
          },
        }
      }),
    [filteredGroupsData, selectedFolderPK],
  )

  const rootGroupOption: SimpleActionMenuItemProps = {
    isSelected: selectedFolderPK === ROOT_GROUP,
    children: <Text>Root Folder</Text>,
    onClick: () => {
      setSelectedFolderPK(ROOT_GROUP)
      hideRef.current?.()
    },
  }

  return (
    <Accordion
      id="advanced-settings"
      title="Advanced Settings"
      buttonVariant="simple"
      sxTitleContainer={{
        pr: '2.4rem',
      }}
      titleStyle={{
        color: isOpen ? 'white' : 'white50',
        textAlign: 'left',
      }}
      containerStyle={{
        py: '1.6rem',
        alignItems: 'center',
      }}
      buttonStyle={{
        justifyContent: 'space-between',
        pl: 0,
        pr: '0.8rem',
        py: 0,
        mt: ['-4rem', '-6rem'],
        fontSize: '1.8rem',
        fontWeight: 'bold',
      }}
      svgStyle={{
        backgroundColor: 'transparent',
        flexShrink: 0,
        '& svg': {
          flexShrink: 0,
        },
      }}
      isOpenControlled={isOpen}
      setIsOpenControlled={setIsOpen}
    >
      <FocusLock whiteList={() => false}>
        <Flex
          sx={{
            width: ['100%', '54.8rem'],
            backgroundColor: 'white5',
            borderRadius: '1.6rem',
            flexDirection: 'column',
            '& > div': {
              p: '2.4rem',
            },
          }}
        >
          <Flex
            data-testid="client-ids"
            sx={{
              width: '100%',
              flexDirection: 'column',
              borderBottom: '1px solid',
              borderBottomColor: 'darkItemBG',
            }}
          >
            <Text
              sx={{
                fontSize: '1.6rem',
                fontWeight: 'bold',
                color: 'white50',
                mb: '0.8rem',
                ml: '2.4rem',
              }}
            >
              Client ID
            </Text>
            <Input
              type="text"
              value={clientId}
              backgroundColor="white10"
              data-testid="client-ids-input"
              onChange={(e): void => {
                setAdvancedSettings({
                  ...advancedSettings,
                  clientId: clientIdMask(e.target.value),
                })
              }}
              tabIndex={0}
              hideLabel
              placeholder="Optional client id ie. client-01"
              sx={{
                fontSize: '1.6rem',
                color: 'white',
                borderRadius: '1.6rem',
                '&::placeholder': {
                  color: 'white50',
                },
              }}
              autoComplete="off"
              autoFocus={false}
            />
            <Text sx={{ fontSize: '1.2rem', color: 'white50', ml: '2.4rem', mt: '0.8rem' }}>
              Optionally supply a device client identifier.
              <ExternalLink
                to={`${DOCS_CONTROL_D_DOMAIN}/docs/device-clients`}
                sx={{
                  color: 'white50',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                  ml: '0.5rem',
                }}
              >
                Learn more
              </ExternalLink>
            </Text>
          </Flex>
          <Flex
            data-testid="exclude-networks"
            sx={{
              width: '100%',
              flexDirection: 'column',
              borderBottom: '1px solid',
              borderBottomColor: 'darkItemBG',
            }}
          >
            <Text
              sx={{
                fontSize: '1.6rem',
                fontWeight: 'bold',
                color: 'white50',
                mb: '0.8rem',
                ml: '2.4rem',
              }}
            >
              Exclude Wi-Fi Networks
            </Text>
            <MultiSelectInput
              ariaLabel="type SSID names"
              placeholder="Type SSID names here"
              value={excludedNetworks}
              setValue={setExcludedNetworks}
            />
            <Text sx={{ fontSize: '1.2rem', color: 'white50', ml: '2.4rem', mt: '0.8rem' }}>
              Names of networks where {CONTROLDText} shouldn&apos;t be used.
            </Text>
          </Flex>
          <Flex
            data-testid="exclude-domains"
            sx={{
              width: '100%',
              flexDirection: 'column',
            }}
          >
            <Text
              sx={{
                fontSize: '1.6rem',
                fontWeight: 'bold',
                color: 'white50',
                mb: '0.8rem',
                ml: '2.4rem',
              }}
            >
              Exclude Domains
            </Text>
            <MultiSelectInput
              ariaLabel="type domain names"
              placeholder="Type domain names here"
              value={excludedDomains}
              setValue={setExcludedDomains}
            />
            <Text
              sx={{
                fontSize: '1.2rem',
                color: 'white50',
                ml: '2.4rem',
                mt: '0.8rem',
                mb: '2.4rem',
              }}
            >
              Domain names that should not be queried through {CONTROLDText}.
            </Text>
            {sessionToken && (
              <SimpleDropdown
                items={[rootGroupOption, ...options]}
                hideRef={hideRef}
                isOpen={isOpen}
                defaultValue="Or choose folder"
                sx={{ mb: 0 }}
                contentMaxHeight="20rem"
              />
            )}
          </Flex>
          <Divider sx={{ color: 'white10', ml: '1.6rem', m: 0 }} />
          <SwitchBoxItem
            dataTestId="exclude-common-domains"
            checked={isCommonExclude}
            handleClick={checked => {
              setAdvancedSettings({
                ...advancedSettings,
                isCommonExclude: checked,
              })
            }}
          >
            <Text sx={{ fontSize: ['1.6rem', '1.8rem'], color: 'white50', mb: '0.8rem' }}>
              Exclude common domains
            </Text>
            <Text sx={{ fontSize: ['1.4rem', '1.6rem'], color: 'white50' }}>
              Don&apos;t use {CONTROLDText} on common captive portals (plane wifi, etc).
            </Text>
          </SwitchBoxItem>
          {isOrganization && (
            <SwitchBoxItem
              dataTestId="prevent-disablement"
              checked={shouldPreventDisablement}
              handleClick={checked => {
                setAdvancedSettings({
                  ...advancedSettings,
                  shouldPreventDisablement: checked,
                })
              }}
            >
              <Text sx={{ fontSize: ['1.6rem', '1.8rem'], color: 'white50', mb: '0.8rem' }}>
                Prevent Disablement
              </Text>
              <Text sx={{ fontSize: ['1.4rem', '1.6rem'], color: 'white50' }}>
                Prevent the DNS Profile from being removed,{' '}
                <Text sx={{ fontWeight: 600 }}>
                  this only works in
                  <ExternalLink
                    to="https://support.apple.com/en-ca/102291"
                    sx={{
                      color: 'white50',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                      ml: '0.5rem',
                    }}
                  >
                    Supervised mode
                  </ExternalLink>
                  .
                </Text>
              </Text>
            </SwitchBoxItem>
          )}
          <SwitchBoxItem
            dataTestId="trust-root-ca"
            checked={shouldTrustRootCa}
            handleClick={checked => {
              setAdvancedSettings({
                ...advancedSettings,
                shouldTrustRootCa: checked,
              })
            }}
          >
            <Text sx={{ fontSize: ['1.6rem', '1.8rem'], color: 'white50', mb: '0.8rem' }}>
              Trust Control D Root CA
            </Text>
            <Text sx={{ fontSize: ['1.4rem', '1.6rem'], color: 'white50' }}>
              Allows for custom block pages to be used.
              <ExternalLink
                to="https://support.apple.com/en-us/102390"
                sx={{
                  color: 'white50',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  whiteSpace: 'nowrap',
                  ml: '0.5rem',
                }}
              >
                See additional steps.
              </ExternalLink>
            </Text>
          </SwitchBoxItem>
        </Flex>
      </FocusLock>
    </Accordion>
  )
}

function SwitchBoxItem({
  checked,
  handleClick,
  dataTestId,
  children,
}: {
  checked: boolean
  handleClick: (checked: boolean) => void
  dataTestId: string
  children: React.ReactNode
}): ReactElement {
  return (
    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', py: '3rem' }}>
      <Flex sx={{ flexDirection: 'column', mr: '0.8rem' }}>{children}</Flex>
      <Switch
        data-testid={dataTestId}
        checked={checked}
        onClick={(): void => {
          handleClick(!checked)
        }}
      />
    </Flex>
  )
}
