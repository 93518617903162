import React, { ReactElement, ReactNode, useEffect, useRef } from 'react'
import { Flex } from 'theme-ui'
import { warningMessageRef } from 'components/WarningMessage'
import { scrollingContainerRef } from './Dashboard'
import UpcomingFeaturesPlaceholder from './NavBar/UpcomingFeaturesPlaceholder'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { useAppSelector } from 'store/hooks'
import useGetUserState from 'store/api/user/useGetUserState'
import MaintenanceModePane from './MaintenanceModePane/MaintenanceModePane'

export default function DashboardContainer({
  children,
  header,
  subHeader,
  loadingPlaceholder,
}: {
  children?: ReactNode
  header: ReactNode
  subHeader?: ReactNode
  loadingPlaceholder?: ReactNode
}): ReactElement {
  const isTablet = useBreakpointIndex() === 1
  const isDesktop = useBreakpointIndex() === 2
  const headerRef = useRef<HTMLDivElement>(null)
  const { isOrganization } = useGetUserState()
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const isInAnalyticsMaintenanceMode = useAppSelector(s => s.errors.isInAnalyticsMaintenanceMode)
  const shouldShowUpcomingFeatures = isOrganization
    ? isTablet
    : isTablet || (isDesktop && !isNavBarExpanded)

  useEffect(() => {
    scrollingContainerRef.current && (scrollingContainerRef.current.scrollTop = 0)
  }, [])

  return (
    <Flex sx={{ flexDirection: 'column', minHeight: '100vh' }}>
      <Flex
        ref={headerRef}
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: 'zIndex240',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'darkBodyBG',
            pb: ['2rem', '4.8rem'],
            background: 'linear-gradient(180deg, #12131C 86%, rgba(18, 19, 28, 0) 100%)',
          }}
        >
          {header}
          {subHeader && !isInAnalyticsMaintenanceMode && (
            <Flex
              sx={{
                position: 'relative',
                width: '100%',
                pt: ['1.6rem', '3.8rem'],
                pl: ['1.6rem', 0],
                pr: ['1.6rem', '2.4rem'],
              }}
            >
              {subHeader}
            </Flex>
          )}
        </Flex>
      </Flex>
      {isInAnalyticsMaintenanceMode ? (
        <Flex sx={{ width: '100%', mt: '9rem', justifyContent: 'center', alignItems: 'center' }}>
          <MaintenanceModePane isAnalytics />
        </Flex>
      ) : (
        <Flex
          sx={{
            position: 'relative',
            flexDirection: 'column',
            mb: `${warningMessageRef.current?.clientHeight || 0}px`,
          }}
        >
          <Flex
            sx={{
              // should be absolute for proper rendering of the virtualized grid/list
              position: 'absolute',
              top: 0,
              width: '100%',
              pl: ['1.6rem', 0],
              pr: ['1.6rem', subHeader ? 0 : '2.4rem'],
              flexDirection: 'column',
            }}
          >
            {loadingPlaceholder}
          </Flex>
          <Flex
            sx={{
              minHeight: `calc(100vh - ${headerRef.current?.clientHeight}px - 32px)`,
              pl: ['1.6rem', 0],
              pr: ['1.6rem', subHeader ? 0 : ['1.6rem', '2.4rem']],
              opacity: loadingPlaceholder ? 0 : 1,
              flexDirection: 'column',
            }}
          >
            {children}
          </Flex>
          <Flex
            sx={{
              width: 'fit-content',
              position: 'fixed',
              bottom: 0,
            }}
          >
            {shouldShowUpcomingFeatures && (
              <UpcomingFeaturesPlaceholder
                sx={{
                  width: ['100%', 'fit-content'],
                  position: 'relative',
                  bottom: '1.6rem',
                }}
              />
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
