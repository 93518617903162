import { Profile } from 'store/api/profiles/profiles.interface'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { EnabledStatus } from 'store/api/rules'
import { NukeAnalyticsType, ResolverStatus } from 'store/api/user/user.interface'

export interface DeviceInfoResponse {
  devices: DeviceInfo[]
}

export enum DeviceGroup {
  BROWSER = 'browser',
  OS = 'os',
  ROUTER = 'router',
  TV = 'tv',
}

export enum HighlightProtocolInSetupGuide {
  DOH = 'doh',
  DOT = 'dot',
  IPv4 = 'ipv4',
  IPv6 = 'ipv6',
  ResolverID = 'resolverId',
}
export interface DeviceSettings {
  type: string
  name: string
  highlight?: HighlightProtocolInSetupGuide[]
  required?: HighlightProtocolInSetupGuide[]
  settings?: {
    legacy_ipv4_status?: EnabledStatus
    learn_ip?: EnabledStatus
    stats?: StatLevel
  }
}

export interface DeviceTypesResponse {
  types?: {
    [key in DeviceGroup]: {
      icons: Record<string, Omit<DeviceSettings, 'type'>>
      name: string
    }
  }
}

export enum DeviceStatus {
  HARD_DISABLED = 3,
  SOFT_DISABLED = 2,
  ACTIVE = 1,
  PENDING = 0,
}

export enum ResolverTypes {
  DEVICE = 'device',
  DEFAULT = 'default',
}
export interface Resolvers {
  uid: string
  doh: string
  dot: string
  v4?: string[] // only present on default device
  v6: string[]
  type?: 'device' | 'default' // only present in provisioning
}

export interface D3NSDetails {
  /** If details exist, status = 1 */
  status?: EnabledStatus
  subdomain?: string
  hostname?: string
  record?: string
}

export interface D3NSExtDetails {
  status?: EnabledStatus
  host?: string
}

export interface LegacyIPv4Details {
  resolver?: string
  /** If details exist, status = 1 */
  status: EnabledStatus
}

export interface DeviceClient {
  id: string
  ts: number
  host?: string
  mac?: string
  ip?: string
}

export interface CtrldInfo {
  status: EnabledStatus
  last_fetch: number
  version: string
  custom_config: string
  custom_last_update: number
  custom_config_deployed: EnabledStatus
}
// TODO: Split into model and response types. use model for state and response for API calls
export interface DeviceInfo<T = DeviceClient[]> {
  PK: string
  name: string
  device_id: string
  status: ResolverStatus
  resolvers: Resolvers
  profile: Profile
  profile2?: Profile
  icon?: string
  stats?: StatLevel // stat level
  ddns?: D3NSDetails
  ddns_ext?: D3NSExtDetails
  profile_id: string
  profile_id2?: string
  last_activity?: number
  /** Control automatic IP learning and logging, undefined or has value of 1 */
  learn_ip?: EnabledStatus
  /** Legacy IPv4 (and IPv6) DNS details */
  legacy_ipv4?: LegacyIPv4Details
  /** Legacy IPv4 (and IPv6) DNS resolver status, undefined or has value of 1 */
  legacy_ipv4_status?: EnabledStatus
  restricted?: EnabledStatus
  /** Enable or disable experimental ECH support and TLS bumping */
  bump_tls?: EnabledStatus
  /** Add a description or comment to the device */
  desc?: string
  /** Status of public DDNS endpoint. 1 = enabled, 0 = disable */
  ddns_status?: EnabledStatus
  /** DDNS subdomain to expose the IP on */
  ddns_subdomain?: string
  /** DDNS hostname to query to learn new IPs */
  ddns_ext_host?: string
  /** Status of DDNS based IP learning. 0 to disable, 1 to enable. */
  ddns_ext_status?: EnabledStatus
  // clients are stored as an array in state but received as a map from the API
  // https://gitlab.int.windscribe.com/controld/frontend/website/-/issues/2670#note_226250
  clients?: T
  remap_device_id?: string
  remap_client_id?: string
  parent_device?: ParentDeviceDetails
  ctrld?: CtrldInfo
  deactivation_pin?: number
  /** Number of connections for this device. by default user devices will have 1 & routers will have 5 */
  client_count?: number
}

export interface ParentDeviceDetails {
  device_id: string
  client_id: string
}

export interface ModifyDevice {
  profile_id?: string
  profile_id2?: string
  icon?: string
  stats?: number // set -1 to revert to profile stats
  ddns_status?: EnabledStatus
  ddns_subdomain?: string
  [NukeAnalyticsType.USER]?: EnabledStatus
  [NukeAnalyticsType.ORG]?: EnabledStatus
  legacy_ipv4_status?: EnabledStatus
  ddns_ext_host?: string
  ddns_ext_status?: EnabledStatus
  ctrld_custom_config?: string
  remap_device_id?: string
  remap_client_id?: string
  client_count?: number
}

export interface NewDevice {
  name: string
  profile_id: string
  icon?: string
  stats?: number
  ddns_status?: EnabledStatus
  ddns_subdomain?: string
  legacy_ipv4_status?: EnabledStatus
  learn_ip?: EnabledStatus
  restricted?: EnabledStatus
  bump_tls?: EnabledStatus
  desc?: string
  ddns_ext_host?: string
  ddns_ext_status?: EnabledStatus
  client_count?: number
}

export type ApiResponseClient = Map<string, Omit<DeviceClient, 'name'>>

export interface DeviceClientsInfoResponse {
  clients: ApiResponseClient
}
