import { ProxyLocation } from 'store/api/proxies'
import React, { memo, ReactElement, useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import { Button, Svg } from 'ui'
import DownArrow from 'images/profileManagement/context-menu-arrow-down.svg'
import ProxyMenuItem from 'components/ProxyList/ProxyMenuItem'
import CheckIcon from 'images/analytics/check.svg'
import TextWithOverFlowAndTippyPopup from '../TextWithOverFlowAndTippyPopup'
import { scrollingContainerRef } from '../Dashboard/Dashboard'
import ExternalSvgIcon, { IconType } from 'components/Dashboard/ExternalSvgIcon'

interface ProxyCountryProps {
  index: number
  name: string
  locations: ProxyLocation[]
  proxyLevel?: string
  selectedItemColor: string
  currentLocationPK?: string
  handleProxyClick: (location?: string) => void
  allowDeselect?: boolean
  shouldNotShowSingle?: boolean
  isBackgroundHighlighted?: boolean
}

const MemoizedProxyMenuItem = memo(ProxyMenuItem)
export default function ProxyCountry({
  index,
  name,
  locations,
  proxyLevel,
  selectedItemColor,
  currentLocationPK,
  handleProxyClick,
  shouldNotShowSingle = false,
  isBackgroundHighlighted,
  ...attr
}: ProxyCountryProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const isSelected = !!locations.find(l => l.PK === currentLocationPK)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isSingleLocation = locations.length === 1 && locations[0].isSingle && shouldNotShowSingle
  const timeoutId = useRef<NodeJS.Timeout | undefined>()
  const onProxyCountryAction = () => {
    clearTimeout(timeoutId.current)

    if (isSingleLocation) {
      handleProxyClick(locations[0].PK)
    } else {
      setIsOpen(prev => !prev)
    }

    if (!isOpen) {
      timeoutId.current = setTimeout(() => {
        // should not scroll container when show the cities
        scrollingContainerRef.current?.scrollTo({ top: scrollingContainerRef.current.scrollTop })
        buttonRef.current?.scrollIntoView({ block: 'nearest' })
      }, 0)
    }
  }

  useEffect(() => {
    if (!isSingleLocation && !!locations.find(l => l.matchKey || isSelected)) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [isSelected, isSingleLocation, locations])

  return (
    <Button
      ref={buttonRef}
      variant="transparent"
      onClick={onProxyCountryAction}
      ariaLabel={`select ${name}`}
      sx={{
        p: 0,
        flexShrink: 0,
        width: '100%',
        minHeight: '4.8rem',
        color: 'white50',
        ':hover svg:last-child path': {
          fill: 'white',
        },
      }}
      {...attr}
    >
      <Flex sx={{ width: '100%', flexDirection: 'column' }}>
        <Flex
          data-testid={`proxy-country-${name}`}
          sx={{
            width: '100%',
            height: '4.8rem',
            py: '1.2rem',
            px: '2.4rem',
            alignItems: 'center',
            borderTopWidth: !!index ? '1px' : '0',
            borderRightWidth: '0',
            borderLeftWidth: '0',
            borderBottomWidth: isOpen ? '1px' : '0',
            borderStyle: 'solid',
            borderColor: 'white15',
            '& div': {
              color: isSelected ? selectedItemColor : isOpen ? 'white' : 'white50',
            },
            '& svg': {
              fill: selectedItemColor,
            },
            '& > svg': {
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
            },
            ':hover': {
              '& div': {
                color: isSelected ? selectedItemColor : 'white',
              },
              '& svg': {
                fill: isSelected ? selectedItemColor : 'white',
              },
            },
          }}
        >
          <Flex sx={{ width: '100%', alignItems: 'center' }}>
            <ExternalSvgIcon
              icon={locations[0].country}
              type={IconType.LOCATIONS}
              sx={{
                width: '2rem',
                height: '2rem',
                flexShrink: 0,
              }}
            />
            <TextWithOverFlowAndTippyPopup
              sxText={{
                ml: '1.6rem',
                fontSize: '1.8rem',
                fontWeight: 'bold',
              }}
              content={name}
            />
          </Flex>
          {!isSingleLocation && (
            <Svg
              svg={DownArrow}
              fill={isOpen ? 'white' : 'white50'}
              sx={{ flexShrink: 0 }}
              data-testid={isOpen ? 'proxy-country-open' : 'proxy-country-close'}
            />
          )}
          {isSingleLocation && isSelected && (
            <Box className="right-svg">
              <Svg svg={CheckIcon} fill={selectedItemColor} />
            </Box>
          )}
        </Flex>
        {isOpen && (
          <Flex
            sx={{
              width: '100%',
              pl: '3.6rem',
              flexDirection: 'column',
            }}
          >
            {locations.map(location => {
              return (
                <MemoizedProxyMenuItem
                  key={location.PK}
                  sx={{ width: '100%', pl: '2.4rem', pr: '4.4rem', mx: 0 }}
                  isSelected={location.PK === currentLocationPK}
                  data-testid={`${proxyLevel}-location-${location.city}`}
                  location={location}
                  onClick={handleProxyClick}
                  rightSvg={
                    currentLocationPK === location.PK ? (
                      <Svg svg={CheckIcon} fill={selectedItemColor} />
                    ) : undefined
                  }
                  selectedItemColor={selectedItemColor}
                  isBackgroundHighlighted={isBackgroundHighlighted}
                />
              )
            })}
          </Flex>
        )}
      </Flex>
    </Button>
  )
}
