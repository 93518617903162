import React, { forwardRef, ReactElement } from 'react'
import InputProps from './Input.interface'
import Input from './Input.base'
import { Flex } from 'theme-ui'
import SearchIcon from 'images/ui/search-icon-bold.svg'
import { Svg } from 'ui'
import CloseIcon from 'images/menu-close.svg'

export default forwardRef<HTMLInputElement, InputProps>(function InputSearch(
  props: InputProps,
  ref,
): ReactElement {
  const {
    sx = {},
    containerStyle = {},
    svgContainerStyle = {},
    clearInput,
    hasFocus,
    ...attrs
  } = props

  return (
    <Flex
      sx={{
        width: '100%',
        borderBottom: '1px solid',
        borderBottomColor: 'white15',
        position: 'relative',
        ...containerStyle,
      }}
    >
      <Flex
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '2.4rem',
          backgroundColor: 'transparent',
          ...svgContainerStyle,
        }}
      >
        <Svg
          svg={SearchIcon}
          fill={props.value || hasFocus ? 'white' : 'white25'}
          sx={{
            margin: '0 auto',
            alignSelf: 'center',
            flexShrink: 0,
          }}
        />
      </Flex>

      <Input
        ref={ref}
        variant="search"
        backgroundColor="transparent"
        sx={{ pl: '6.4rem', pr: '2.4rem', ...sx }}
        {...attrs}
        hideLabel
      />

      {clearInput && props.value && (
        <Flex
          sx={{
            width: 'fit-content',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '1.6rem',
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          onClick={clearInput}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              clearInput()
            }
          }}
          tabIndex={0}
        >
          <Svg
            data-testid="clear-search"
            svg={CloseIcon}
            fill={props.value ? 'white' : 'white50'}
            sx={{
              width: '2rem',
              height: '2rem',
              margin: '0 auto',
              flexShrink: 0,
            }}
          />
        </Flex>
      )}
    </Flex>
  )
})
