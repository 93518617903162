import { keyframes } from '@emotion/react'

export const foregroundStyle = {
  borderRadius: '1.6rem',
  backgroundColor: 'white15',
  opacity: '0.5',
  animation: `${keyframes({ '50%': { opacity: '1' } })} 3s 1s backwards infinite`,
}

export { default as OptionBox } from './OptionBox'
export { default as TablePlaceholder } from './TablePlaceholder'
export { default as TableOrAccordionPlaceholder } from './TableOrAccordionPlaceholder'
