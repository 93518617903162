import React, { ReactElement } from 'react'
import { Text } from 'theme-ui'

export default function CurrentIPLabel(): ReactElement {
  return (
    <Text
      data-testid="current-ip-label"
      sx={{
        minWidth: '8rem',
        borderRadius: '30px',
        px: '1rem',
        py: '0.4rem',
        fontSize: '1.2rem',
        color: 'greenApple',
        backgroundColor: 'greenApple15',
        textAlign: 'center',
        justifySelf: 'flex-end',
        whiteSpace: 'nowrap',
      }}
    >
      Current IP
    </Text>
  )
}
