import React, { ReactElement } from 'react'
import { Button, Svg } from 'ui'
import useQueryString from 'utils/useQueryString'
import PrevIcon from 'images/helpPaneSteps/prevstepicon.svg'

export const arrowIconButtonStyle = {
  p: 0,
  width: ['4rem', '4.8rem'],
  height: ['4rem', '4.8rem'],
  backgroundColor: 'white10',
  borderRadius: '50%',
  flexShrink: 0,
  ':hover': {
    backgroundColor: 'white25',
  },
  '&:hover > svg path': {
    fill: 'white',
  },
  ':disabled': { backgroundColor: 'transparent' },
}

export default function PreviousStepButton({ onClick }: { onClick: () => void }): ReactElement {
  const { qs } = useQueryString()

  const currentStep = +(qs.step ?? 0)
  return (
    <Button
      data-testid="setup-guide-prev-step-arrow"
      aria-label="Previous step"
      sx={arrowIconButtonStyle}
      variant="simple"
      disabled={!currentStep}
      onClick={onClick}
    >
      {!!currentStep && <Svg svg={PrevIcon} fill="white50" />}
    </Button>
  )
}
