import React, { ReactElement, useRef, useState } from 'react'
import { Accordion, Svg, TrayRenderProps } from 'ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { markAllRead } from 'store/notifications'
import { Flex, Text, Link, Image } from 'theme-ui'
import { Notification } from 'store/api/notifications'
import SmallArrowUpIcon from 'images/profileManagement/context-menu-arrow-up.svg'
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import { CONTROLDText } from 'ui/CONTROLD'
import useQueryString from 'utils/useQueryString'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { useForClosingTray } from 'utils/useForClosingTray'
import TrayWithCloseButton from 'ui/TrayWithCloseButton'
import { useGetNotificationsQuery } from 'store/api/notifications/notifications'
import ExternalLinkIcon from 'images/dashboard/external-link-icon.svg'
import VerticalDivider from 'ui/VerticalDivider'
import useOnClickOutside from 'utils/useOnClickOutside'
import useGetUser from 'components/Dashboard/utils/useGetUser'

const notificationsTrayBottomSpace = '0.8rem'

export default function NotificationsTray({
  dismiss,
  isInMaintenanceMode,
}: TrayRenderProps & { isInMaintenanceMode?: boolean }): ReactElement {
  const dispatch = useAppDispatch()
  const newNotificationKeys = useAppSelector(s => s.notifications.newNotificationKeys)
  const userPk = useAppSelector(s => s.session.userPk)
  const breakpointIndex = useBreakpointIndex()
  const { nav } = useQueryString()
  const { data: userData } = useGetUser()
  const haptics = userData?.haptics
  const { data: notificationsData } = useGetNotificationsQuery('', { skip: !userPk })
  const notificationTrayRef = useRef<HTMLDivElement>(null)

  const closeTray = () => {
    dispatch(
      markAllRead({
        userPk,
        incomingNotificationKeys: notificationsData?.notifications.map(n => n.PK) || [],
      }),
    )

    isInMaintenanceMode ? dismiss() : nav({})
  }

  useOnClickOutside([notificationTrayRef], () => {
    if (notificationTrayRef) {
      closeTray()
    }
  })

  useForClosingTray({
    closeTray,
  })

  return (
    <TrayWithCloseButton
      dataTestId="NotificationsTray"
      trayRef={notificationTrayRef}
      backgroundOverlayWhenOpen={true}
      dismiss={() => {
        closeTray()
        dismiss()
      }}
      shouldVibrateOnPresent={!!haptics}
      zIndexName="zIndex550"
      sx={{
        overflowY: 'hidden',
        height: '100%',
      }}
      trayBodySx={{
        backgroundColor: 'darkBodyBG',
      }}
      headerSx={{
        backgroundColor: 'black5',
        border: 'none',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 0 rgba(255, 255, 255, 0.1)',
      }}
      disableFocusLock={breakpointIndex < 2}
      headerContent="Notifications"
    >
      <Flex
        data-testid="notifications-tray"
        sx={{
          overflowY: 'auto',
          height: [
            `calc(100% - ${notificationsTrayBottomSpace})`,
            `calc(100% - ${notificationsTrayBottomSpace})`,
          ],
        }}
        className="show-scrollbar"
      >
        <Flex
          sx={{
            flexDirection: 'column',
            py: '1.6rem',
            width: '100%',
          }}
        >
          <Flex
            as="ul"
            sx={{
              pl: 0,
              ml: 0,
              scrollbarWidth: 'thin',
              gap: '1.6rem',
              flexDirection: 'column',
            }}
          >
            {notificationsData?.notifications.map(notification => (
              <NotificationItem
                notification={notification}
                shouldBeOpen={!!newNotificationKeys?.includes(notification.PK)}
                key={notification.PK}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </TrayWithCloseButton>
  )
}

interface NotificationItemProps {
  notification: Notification
  shouldBeOpen: boolean
}

function NotificationItem({ notification, shouldBeOpen }: NotificationItemProps): ReactElement {
  const [isOpen, setIsOpen] = useState(shouldBeOpen)
  const linkData = Array.from(notification.links ?? [])
  const notificationMessage = notification.message.replace(/ControlD/g, CONTROLDText)

  return (
    <Flex
      as="li"
      key={notification.PK}
      data-testid={`notification-${notification.PK}`}
      sx={{
        listStyle: 'none',
        m: 0,
        color: 'white50',
        px: '1.6rem',
      }}
    >
      <Accordion
        data-testid="notifications-accordion"
        id={notification.PK}
        title={
          <NotificationItemTitle
            text={notification.title}
            timestamp={notification.date}
            isOpen={isOpen}
          />
        }
        icon={SmallArrowUpIcon}
        titleStyle={{
          minWidth: '100%',
          color: isOpen ? 'white' : 'white50',
          textAlign: 'left',
        }}
        containerStyle={{
          minWidth: '100%',
          py: '1.6rem',
          backgroundColor: 'darkItemBG50',
          borderRadius: '0.8rem',
        }}
        buttonStyle={{
          justifyContent: 'space-between',
          p: 0,
          px: '1.6rem',
          fontSize: '1.6rem',
          fontWeight: 'bold',
          '&:hover > div span': { color: 'white' },
          '&:hover svg > path': { fill: 'white ' },
        }}
        svgStyle={{
          p: 0,
          svg: {
            width: '2.2rem',
            height: '2.2rem',
          },
        }}
        isOpenControlled={isOpen}
        setIsOpenControlled={setIsOpen}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            pt: '1.6rem',
            '& > :not(:last-child)': {
              mb: '1.6rem',
            },
          }}
        >
          {notification.header_img && (
            <Image data-testid="notofication-image" src={notification.header_img} />
          )}
          <Text
            sx={{
              fontSize: '1.4rem',
              display: 'block',
              // to preserve white space characters (handle lists)
              whiteSpace: 'pre-wrap',
              lineHeight: '1.86',
              px: '1.6rem',
            }}
          >
            {notificationMessage}
          </Text>
          <Flex sx={{ gap: '0.8rem', alignItems: 'center', px: '1.6rem' }}>
            {linkData &&
              linkData.map((link, index) => (
                <Flex
                  key={index}
                  sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: '0.8rem' }}
                >
                  {index !== 0 && (
                    <VerticalDivider sx={{ width: '0.1rem', height: '1.6rem', flexShrink: 0 }} />
                  )}
                  <Link
                    data-testid={`notifications-link-${notification.PK}-${index}`}
                    target="_blank"
                    href={link.url}
                    sx={{
                      color: 'green',
                      lineHeight: '1.8rem',
                      fontSize: '1.4rem',
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'underline',
                      },
                      textDecoration: 'none',
                    }}
                  >
                    {link.title}
                  </Link>
                  <Svg
                    svg={ExternalLinkIcon}
                    fill="green"
                    sx={{ flexShrink: 0, width: '1.6rem', height: '1.6rem' }}
                  />
                </Flex>
              ))}
          </Flex>
        </Flex>
      </Accordion>
    </Flex>
  )
}

interface NotificationItemTitleProps {
  text: string
  timestamp: number
  isOpen: boolean
}
function NotificationItemTitle({
  text,
  timestamp,
  isOpen,
}: NotificationItemTitleProps): ReactElement {
  const now = new Date()
  const timeAndDateOfNotification = new Date(timestamp * 1000)
  const distanceFromNotificationTimeToNow = formatDistanceStrict(timeAndDateOfNotification, now)

  return (
    <Flex
      sx={{
        gap: '0.8rem',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        pr: '1.6rem',
        color: 'white50',
        ':hover': {
          '& > span:last-of-type': {
            color: 'white50',
          },
        },
      }}
    >
      <Text sx={{ fontSize: '1.6rem', fontWeight: 'bold', color: isOpen ? 'white' : 'white50' }}>
        {text}
      </Text>
      <Text
        sx={{
          fontSize: '1.4rem',
          fontWeight: 'normal',
        }}
      >
        {distanceFromNotificationTimeToNow} ago
      </Text>
    </Flex>
  )
}
