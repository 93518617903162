import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import VerticalDivider from 'ui/VerticalDivider'
import { foregroundStyle } from 'components/Dashboard/PlaceholderElements'

export function QueriesCardLoaderMobile(): ReactElement {
  return (
    <Flex sx={{ display: ['flex', 'none'], height: '100%', justifyContent: 'space-between' }}>
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            gap: '1.6rem',
            mb: '1.6rem',
          }}
        >
          <Box
            sx={{
              ...foregroundStyle,
              width: '4.9rem',
              height: '2.6rem',
            }}
          />
          <Box
            sx={{
              ...foregroundStyle,
              width: '13.2rem',
              height: '2.4rem',
            }}
          />
        </Flex>
        <Flex
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            gap: '1.6rem',
            mb: '1.6rem',
          }}
        >
          <Box
            sx={{
              ...foregroundStyle,
              width: '10.2rem',
              height: '2.6rem',
            }}
          />
          <Flex>
            <Box
              sx={{
                ...foregroundStyle,
                width: '2.4rem',
                height: '2.4rem',
                borderRadius: '50%',
              }}
            />
            <VerticalDivider sx={{ width: '1px', mx: '1.6rem' }} />
            <Box
              sx={{
                ...foregroundStyle,
                width: '2.4rem',
                height: '2.4rem',
                borderRadius: '50%',
              }}
            />
            <VerticalDivider sx={{ width: '1px', mx: '1.6rem' }} />
            <Box
              sx={{
                ...foregroundStyle,
                width: '2.4rem',
                height: '2.4rem',
                borderRadius: '50%',
              }}
            />
          </Flex>
        </Flex>
        <Box
          sx={{
            ...foregroundStyle,
            width: '100%',
            height: '2.6rem',
          }}
        />

        <Flex sx={{ width: '100%', mt: '1.6rem', flexDirection: 'column' }}>
          <Box
            sx={{
              ...foregroundStyle,
              width: '13.2rem',
              height: '2.6rem',
            }}
          />
          <Flex sx={{ width: '100%', justifyContent: 'space-between', mt: '0.8rem' }}>
            <Box
              sx={{
                ...foregroundStyle,
                width: '11.2rem',
                height: '2.6rem',
              }}
            />
            <Box
              sx={{
                ...foregroundStyle,
                width: '2.6rem',
                height: '2.6rem',
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
