import React, { MutableRefObject, ReactElement, ReactNode, useRef } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import Tippy from '@tippyjs/react'
import { useHideTooltipOnScroll } from 'utils/useHideTooltipOnScroll'
import customUnescape from 'utils/customUnescape'

export default function TextWithOverFlowAndTippyPopup({
  content,
  tippyContent,
  sxText,
  sxTooltipContent,
  maxWidth,
  isEnabled = true,
  testId,
  boundaryElement,
  dropdownScrollingContainerRef,
  textVariant,
  shouldCheckOnOverflow = true,
  theme,
}: {
  content: string | ReactNode
  tippyContent?: string
  sxText?: ThemeUIStyleObject
  sxTooltipContent?: ThemeUIStyleObject
  maxWidth?: string
  isEnabled?: boolean
  testId?: string
  boundaryElement?: Element | 'parent'
  dropdownScrollingContainerRef?: MutableRefObject<HTMLDivElement | null>
  textVariant?: string
  shouldCheckOnOverflow?: boolean
  theme?: string
}): ReactElement {
  const textRef = useRef<HTMLDivElement>(null)
  const hideRef = useRef<() => void>()

  useHideTooltipOnScroll(hideRef, dropdownScrollingContainerRef)

  return (
    <Tippy
      onShow={(): void | false => {
        if (shouldCheckOnOverflow) {
          const clientWidth = textRef?.current?.clientWidth ?? 0
          const scrollWidth = textRef?.current?.scrollWidth ?? 0
          if (clientWidth > scrollWidth || clientWidth === scrollWidth) {
            // returning false here stops the tooltip from showing
            return false
          }
        }
      }}
      onCreate={instance => {
        hideRef.current = () => {
          instance.hide()
        }
      }}
      content={
        <Flex
          sx={{
            overflowWrap: 'break-word',
            flexWrap: 'wrap',
            ...sxTooltipContent,
          }}
        >
          {tippyContent || content}
        </Flex>
      }
      arrow={false}
      theme={theme || 'screen'}
      touch="hold"
      interactive={true}
      appendTo={boundaryElement ?? 'parent'}
      maxWidth={maxWidth ?? window.innerWidth}
      disabled={!isEnabled}
    >
      <Text
        role="button" // it is important for accessibility, role="button" because when hovered it shows a tooltip
        data-testid={testId}
        ref={textRef}
        variant={textVariant ? textVariant : 'none'}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ...sxText,
        }}
        tabIndex={0}
      >
        {typeof content === 'string' ? customUnescape(content) : content}
      </Text>
    </Tippy>
  )
}
