import React, { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Flex } from 'theme-ui'
import { Button, Svg } from 'ui'
import BackIcon from 'images/back-icon-big.svg'
import { navigate } from '@reach/router'
import { useEditOrganizationMutation } from 'store/api/organization'
import OrganizationsAccordion from './OrganizationAccordion'
import NavMenuItem from 'components/Dashboard/NavBar/NavMenuItem'
import MyOrganizationIcon from 'images/organization/my-organization-icon.svg'
import { OrganizationNavRelativePath } from './OrganizationNavBarContent'
import ApiIcon from 'images/dashboard/navBar/api-icon.svg'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { clearStateForImpersonation } from 'store/organization'
import { userApi } from 'store/api/user'
import customUnescape from 'utils/customUnescape'
import { baseApi } from 'store/api'
import { setSelectedDeviceId } from 'store/access'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'

export default function ImpersonationNavMenu(): ReactElement {
  const { isFetching, data } = useGetOrganization()

  const subOrgBeingViewed = data?.organization

  return (
    <Flex
      sx={{
        width: '100%',
        gap: '1.6rem',
        alignItems: 'center',
        flexDirection: 'column',
        mt: [0, '0.8rem'],
      }}
    >
      <ParentOrgBackButton />
      <OrganizationsAccordion
        name={customUnescape(subOrgBeingViewed?.name)}
        isFetching={isFetching}
        isSubOrg
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'column',
            gap: '1.6rem',
          }}
        >
          <NavMenuItem
            activeIcon={MyOrganizationIcon}
            relativePath={OrganizationNavRelativePath.MY_ORGANIZATION}
            label="My Organization"
            sx={{ mt: [0, '1.2rem'] }}
          />
          <NavMenuItem
            activeIcon={ApiIcon}
            relativePath={OrganizationNavRelativePath.API}
            label="API"
            sx={{ mb: '0.8rem' }}
          />
        </Flex>
      </OrganizationsAccordion>
    </Flex>
  )
}

const ParentOrgBackButton = () => {
  const dispatch = useAppDispatch()
  const isTablet = useBreakpointIndex() === 1
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const editOrganizationMutation = useEditOrganizationMutation({
    fixedCacheKey: 'editOrganizationRegion',
  })
  const isDisabled = editOrganizationMutation[1].isLoading

  return (
    <Button
      variant="secondaryWithoutScaling"
      data-testid="impersonation-back-button"
      disabled={isDisabled}
      sx={{
        width: ['100%', '4.8rem', isNavBarExpanded ? '100%' : '4.8rem'],
        height: '4.8rem',
        py: 0,
        px: '2.1rem',
        borderRadius: '45px',
        gap: '1.8rem',
        fontSize: '1.8rem',
        fontWeight: 'bold',
        justifyContent: ['flex-start', 'center'],
      }}
      onClick={() => {
        /**
         * instead of dispatching the actions to clear any state related to the sub org
         * we trigger an action which is subscribed to by all the slices that need to
         * clear their state
         */

        dispatch(clearStateForImpersonation())
        // this will reset all apistate
        dispatch(baseApi.util.resetApiState())
        dispatch(setSelectedDeviceId(''))
        navigate('/dashboard/sub-orgs')
        // need to fetch the actual logged in users org info once impersonation is over
        dispatch(userApi.endpoints.getUser.initiate('', { forceRefetch: true }))
      }}
    >
      <Svg
        svg={BackIcon}
        fill={isDisabled ? 'white50' : 'white'}
        sx={{
          width: '1.6rem',
          height: '1.6rem',
          cursor: 'pointer',
          flexShrink: 0,
        }}
      />
      <Flex
        sx={{
          display: isNavBarExpanded && !isTablet ? 'flex' : 'none',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        Exit Organization
      </Flex>
    </Button>
  )
}
