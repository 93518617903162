import React, { ReactElement } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Checkbox } from 'ui'

interface MultiSelectCheckboxProps {
  isMultiSelectMode: boolean
  isSelected: boolean
  onChange: (wasChecked: boolean) => void
  sx?: ThemeUIStyleObject
}

export default function MultiSelectCheckbox({
  isMultiSelectMode,
  isSelected,
  onChange,
  sx,
}: MultiSelectCheckboxProps): ReactElement {
  return (
    <Flex
      sx={{
        mr: ['0.8rem', '1.6rem'],
        mt: '-0.8rem',
        flexShrink: 0,
        alignItems: 'center',
        pointerEvents: 'none',
        opacity: isMultiSelectMode ? '100' : '0',
        width: isMultiSelectMode ? 'inherit' : 0,
        ml: isMultiSelectMode ? 0 : '-3.2rem',
        transitionProperty: 'margin-left, opacity',
        transitionDuration: '0.4s, 0.4s',
        transitionDelay: isMultiSelectMode ? '0s, 0.2s' : '0.2s, 0s',
        transitionTimingFunction: 'ease-in-out',
        ...sx,
      }}
    >
      <Checkbox
        tabIndex={-1}
        isChecked={isSelected}
        sx={{ width: ['2.4rem', '1.6rem'], height: ['2.4rem', '1.6rem'] }}
        onChange={onChange}
      />
    </Flex>
  )
}
