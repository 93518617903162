import ReceiptContainer from 'components/Dashboard/Preferences/Billing/ReceiptsTable/ReceiptContainer'
import React, { ReactElement } from 'react'
import ProvisionElement from './ProvisionElement'
import { Provision } from 'store/api/provision'

const tableHeaders = [
  'Created On',
  'Endpoint Type',
  'Link to Profile',
  'Status',
  'Expires on',
  'Limit',
]
export default function ProvisionTable({ provisions }: { provisions: Provision[] }): ReactElement {
  return (
    <ReceiptContainer
      headers={tableHeaders}
      sx={{
        '& th': {
          width: 'auto',
        },
        '& th:nth-of-type(4)': {
          width: '8',
        },
      }}
    >
      {provisions?.map(provision => (
        <ProvisionElement
          key={`${provision.PK}`}
          provision={provision}
          tableHeaders={tableHeaders}
        />
      ))}
    </ReceiptContainer>
  )
}
