import { ActionType, DeviceActionType, InitialStateType } from './SettingsState'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import React, { Dispatch, ReactElement, useLayoutEffect, useMemo, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import ProfileSelectionDropDown, {
  ProfileDropDownItem,
} from 'components/Dashboard/Devices/ProfileSelectionDropDown'
import { CircleIconWrapperButton, ExternalLink, Svg } from 'ui'
import MinusIcon from 'images/minus-icon.svg'
import AddIcon from 'images/add-icon.svg'
import Tippy from '@tippyjs/react'
import { EnforcingStatus, useGetSchedulesQuery } from 'store/api/schedules'
import { useGetProfilesQuery } from 'store/api/profiles'

const MultipleProfiles = ({
  settingsState,
  settingsDispatch,
  devicePk,
  globalProfilePk,
}: {
  settingsState: InitialStateType
  settingsDispatch: Dispatch<ActionType>
  devicePk?: string
  globalProfilePk?: string
}): ReactElement => {
  const [isSecondProfileVisible, setIsSecondProfileVisible] = useState(false)
  const { data: profilesData } = useGetProfilesQuery('')
  const { data: schedulesData } = useGetSchedulesQuery('')

  const existingSchedule = useMemo(() => {
    return schedulesData?.schedules?.find(schedule => devicePk && devicePk === schedule?.device?.PK)
  }, [devicePk, schedulesData?.schedules])

  useLayoutEffect(() => {
    setIsSecondProfileVisible(!!settingsState.profile2)
  }, [settingsState.profile2])

  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        gap: '1.6rem',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          gap: '1.6rem',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          flexDirection: [isSecondProfileVisible ? 'column' : 'row', 'row'],
        }}
      >
        <ProfileSelectionDropDown
          label="Enforced Profile"
          disabledProfilePk={settingsState.profile2?.PK.toString()}
          isDropdownDisabled={existingSchedule?.enforcing === EnforcingStatus.TRUE}
          globalProfilePk={globalProfilePk}
          selectedProfile={
            settingsState.profile
              ? { ...settingsState.profile, text: settingsState.profile?.name || '' }
              : undefined
          }
          setSelectedProfile={profile =>
            settingsDispatch({
              type: DeviceActionType.SELECTED_PROFILE,
              payload: profile as ProfileDropDownItem | undefined,
            })
          }
          description=""
          tooltipText={
            existingSchedule?.enforcing === EnforcingStatus.TRUE
              ? "Please disable or delete the Schedule before changing this Endpoint's Enforced Profile."
              : 'The Profile whose rules you want to enforce on this Endpoint'
          }
          sx={{
            width: isSecondProfileVisible ? ['100%', '24.2rem'] : '100%',
            flexShrink: isSecondProfileVisible && 0,
            p: 0,
          }}
        />
        <Flex
          sx={{
            width: isSecondProfileVisible ? '100%' : 'fit-content',
            gap: '1.6rem',
            alignItems: 'flex-end',
            flexShrink: !isSecondProfileVisible && 0,
          }}
        >
          {isSecondProfileVisible && (
            <ProfileSelectionDropDown
              disabledProfilePk={settingsState.profile?.PK.toString()}
              globalProfilePk={globalProfilePk}
              label="Enforced Profile 2"
              testId="2nd-select-profile-menu"
              selectedProfile={
                settingsState.profile2
                  ? { ...settingsState.profile2, text: settingsState.profile2?.name || '' }
                  : undefined
              }
              setSelectedProfile={profile2 => {
                settingsDispatch({
                  type: DeviceActionType.SELECTED_PROFILE_2,
                  payload: profile2 as ProfileDropDownItem | undefined,
                })
              }}
              description=""
              tooltipText="The Profile whose rules you want to enforce on this Endpoint"
              sx={{
                width: isSecondProfileVisible ? ['100%', '24.2rem'] : '100%',
                flexShrink: isSecondProfileVisible && 0,
                p: 0,
                flex: 1,
              }}
            />
          )}
          <Tippy
            disabled={!(devicePk && existingSchedule)}
            theme="screen"
            arrow={false}
            content={
              <Box sx={{ fontSize: '1.2rem', textAlign: 'center' }}>
                Second profile not allowed as a schedule exists on this endpoint.
              </Box>
            }
            maxWidth={200}
          >
            <Box>
              <CircleIconWrapperButton
                data-testid="add-remove-second-profile"
                disabled={
                  (profilesData?.profiles?.length || 0) +
                    (profilesData?.shared_profiles?.length || 0) <
                    2 || !!(devicePk && existingSchedule)
                }
                sx={{ width: '5.4rem', height: '5.4rem', p: 0 }}
                hasBackgroundOnHover
                onClick={() => {
                  setIsSecondProfileVisible(!isSecondProfileVisible)
                  settingsDispatch({
                    type: DeviceActionType.SELECTED_PROFILE_2,
                    payload: undefined,
                  })
                }}
              >
                <Svg
                  svg={isSecondProfileVisible ? MinusIcon : AddIcon}
                  fill="white50"
                  sx={{
                    width: '2.4rem',
                    height: '2.4rem',
                  }}
                />
              </CircleIconWrapperButton>
            </Box>
          </Tippy>
        </Flex>
      </Flex>
      {isSecondProfileVisible && (
        <Flex
          sx={{
            backgroundColor: 'black25',
            fontSize: '1.2rem',
            color: 'white50',
            justifyContent: 'center',
            p: '0.8rem',
            borderRadius: '0.8rem',
            textAlign: ['center', 'center', 'left'],
          }}
        >
          <Text data-testid="multiple-linked-profile-message" sx={{ mr: '0.4rem' }}>
            Multiple linked profiles allow you to enforce rules from two profiles simultaneously
            when using this Endpoint.{' '}
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/multiple-enforced-profiles`}
              sx={{
                color: 'white50',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
              }}
            >
              Learn more
            </ExternalLink>
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default MultipleProfiles
