import React, { ReactElement, ReactNode } from 'react'
import { Box, Flex } from 'theme-ui'
import { foregroundStyle } from 'components/Dashboard/PlaceholderElements'

export default function ColumnPlaceholder({ children }: { children?: ReactNode }): ReactElement {
  return (
    <Flex sx={{ flex: 1, height: '100%', flexDirection: 'column', gap: '2.4rem' }}>
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            ...foregroundStyle,
            width: '60%',
            height: '2.4rem',
          }}
        />
      </Flex>
      {children}
    </Flex>
  )
}
