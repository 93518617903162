import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Button, IconButtonContent, IconMenu, Input, SimpleActionMenuItemProps, Svg } from 'ui'
import { setQueryFilters } from 'store/activityLog'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import Fuse from 'fuse.js'
import { defaultFuseOptions } from 'utils'
import CheckIcon from 'images/analytics/check.svg'
import CloseIcon from 'images/bold-close-icon.svg'
import SearchIcon from 'images/search-icon-bold.svg'
import { Filter } from 'store/api/filters/filters.interface'
import { ServiceData } from 'store/api/services'
import { ProxyLocation } from 'store/api/proxies'
import { QueriesFilterMenuType } from '../QueryFilterMobileTray'
import { verdictTypeFilters, verdictTypesToDropdownItemData } from './ActionsDropdown'
import { protocolToDropdownItemData, protocolTypeFilters } from './ProtocolsDropdown'
import actionIcons from 'components/Dashboard/IconMap/ActionIcons'
import protocolIcons from 'components/Dashboard/IconMap/ProtocolIcons'
import { hideQueryFilterDropdown } from './QueryFilterDropdown'
import ProxyList from 'components/ProxyList/ProxyList'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { scrollingContainerRef } from 'components/Dashboard/Dashboard'
import { trayHeaderHeight } from 'components/TrayOrModalDialog/Tray.mobile'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { IconType } from 'components/Dashboard/ExternalSvgIcon'

interface QueryFilterDropdownContentProps {
  type: QueriesFilterMenuType
  filters?: Filter[] | null
  services?: ServiceData[] | null
  locations?: ProxyLocation[] | null
  actions?: ProxyLocation[] | null
  headerRef?: MutableRefObject<HTMLDivElement | null>
  dismiss?: () => void
}

interface QueriesFilterSubmenuItemMap {
  title: string
  items: {
    name: string
    uid: string
    country?: string
    /**
     * this is the name of the icon to use for the filter level. For filter levels
     * the PK is the name of the level e.g. "ads_small" but the iconName is "ads"
     */
    iconName?: string
  }[]
}

export default function QueryFilterDropdownContent({
  type,
  filters = [],
  services = [],
  locations = [],
  dismiss,
}: QueryFilterDropdownContentProps): ReactElement {
  const dispatch = useAppDispatch()
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const [searchText, setSearchText] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null)
  const visibleProxyLocations = locations?.filter(x => !x.hidden) ?? []
  const isMobile = useBreakpointIndex() === 0

  useEffect(() => {
    if (isMobile) {
      scrollingContainerRef.current && (scrollingContainerRef.current.style.overflowY = 'hidden')
    }

    return () => {
      scrollingContainerRef.current && (scrollingContainerRef.current.style.overflowY = 'scroll')
    }
  }, [isMobile])

  const filterTypeMap: QueriesFilterSubmenuItemMap = {
    [QueriesFilterMenuType.FILTERS]: {
      title: 'Filters',
      items:
        filters?.map(filter => {
          return { name: filter.name, uid: filter.PK, iconName: filter.iconName }
        }) || [],
    },
    [QueriesFilterMenuType.SERVICES]: {
      title: 'Services',
      items:
        services?.map(service => {
          return { name: service.name, uid: service.PK }
        }) || [],
    },
    [QueriesFilterMenuType.ACTION]: {
      title: 'Action',
      items:
        verdictTypeFilters?.map(type => {
          return {
            name: verdictTypesToDropdownItemData[type].label,
            uid: `${type}`,
          }
        }) || [],
    },
    [QueriesFilterMenuType.PROTOCOL]: {
      title: 'Protocol',
      items:
        protocolTypeFilters?.map(type => {
          return {
            name: protocolToDropdownItemData[type].label,
            uid: `${type}`,
          }
        }) || [],
    },
  }[type]

  const searchQueryFilterParameters = useCallback(() => {
    if (!filterTypeMap?.items) {
      return []
    }

    const fuzzySearchList = new Fuse(filterTypeMap.items, {
      ...defaultFuseOptions,
      keys: ['name'],
    })
    if (!searchText) {
      return filterTypeMap.items
    }
    return fuzzySearchList.search(searchText).map(matches => matches.item)
  }, [filterTypeMap, searchText])

  const submenuOptions: SimpleActionMenuItemProps[] = useMemo(() => {
    const sortedOptions = [...searchQueryFilterParameters()]

    const options: SimpleActionMenuItemProps[] =
      sortedOptions.map(item => {
        const icon =
          type === QueriesFilterMenuType.FILTERS || type === QueriesFilterMenuType.SERVICES
            ? undefined
            : type === QueriesFilterMenuType.ACTION
            ? actionIcons[item.uid || '']
            : protocolIcons[item.uid || '']

        const externalIcon =
          type === QueriesFilterMenuType.FILTERS
            ? { icon: item.iconName ?? item.uid, type: IconType.FILTERS }
            : type === QueriesFilterMenuType.SERVICES
            ? { icon: item.uid, type: IconType.SERVICES }
            : undefined

        const isSelected = filterParameters[type] === item.uid

        return {
          dataTestId: `activity-log-filter-item-${item.uid}`,
          isSelected,
          tabIndex: 0,
          isBackgroundHighlighted: true,
          children: (
            <IconButtonContent
              startIcon={icon}
              startExternalIcon={externalIcon}
              endIcon={isSelected ? CheckIcon : undefined}
            >
              <TextWithOverFlowAndTippyPopup
                sxText={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '26.4rem' }}
                content={item.name}
              />
            </IconButtonContent>
          ),
          onClick: () => {
            const isActionOrProtocolType = [
              QueriesFilterMenuType.ACTION,
              QueriesFilterMenuType.PROTOCOL,
              QueriesFilterMenuType.LOCATIONS,
            ].includes(type)

            const queryFilters = {
              [type]:
                filterParameters[type] === item.uid && !isActionOrProtocolType ? '' : item.uid,
            }

            if (type === QueriesFilterMenuType.FILTERS) {
              queryFilters[QueriesFilterMenuType.SERVICES] = ''
            }

            if (type === QueriesFilterMenuType.SERVICES) {
              queryFilters[QueriesFilterMenuType.FILTERS] = ''
            }

            dispatch(setQueryFilters(queryFilters))
            hideQueryFilterDropdown?.[type]?.()
            dismiss?.()
          },
        }
      }) ?? []

    return options
  }, [searchQueryFilterParameters, type, filterParameters, dispatch, dismiss])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: ['100%', '26.8rem'],
        mb: '0.8rem',
        overflow: 'hidden',
        height: [`calc(100% - ${trayHeaderHeight})`, '100%'],
        maxHeight: ['100%', '30rem'],
      }}
    >
      {type === QueriesFilterMenuType.LOCATIONS ? (
        <ProxyList
          currentLocationPK={filterParameters[QueriesFilterMenuType.LOCATIONS]}
          proxyLocations={visibleProxyLocations}
          handleProxyClick={locationPK => {
            const queryFilters = {
              [QueriesFilterMenuType.LOCATIONS]:
                filterParameters[QueriesFilterMenuType.LOCATIONS] === locationPK ? '' : locationPK,
            }

            dispatch(setQueryFilters(queryFilters))
            hideQueryFilterDropdown?.[QueriesFilterMenuType.LOCATIONS]?.()
            dismiss?.()
          }}
          isOpen={true}
          isVisible={true}
          placeholder="Search"
          isBackgroundHighlighted
          sxSearch={{
            pl: '4.8rem',
            '::placeholder': {
              fontSize: '1.6rem',
              color: 'white25',
            },
          }}
          sxMenu={{
            '& div[data-testid^=proxy-country]': {
              border: 'none',
              pr: '3.2rem',
              pl: '1.8rem',
              '& div span': {
                fontSize: '1.6rem',
                ml: '1.4rem',
              },
            },
            '& [data-testid^=default-redirect-country]': {
              border: 'none',
              '& > div > div:nth-of-type(2)': {
                pl: '1.8rem',
                pr: '1.6rem',
              },
            },
            '& [data-testid^=default-redirect-location]': {
              borderRadius: '0.8rem',
              py: '0.8rem',
              pr: '1.6rem',
              pl: '3.2rem',
              height: '4rem',
              '&:not(:last-child)': {
                border: 'none',
              },
              '& div span': {
                fontWeight: 'normal',
              },
            },
          }}
          sxList={{ maxHeight: ['100%', '24.4rem'] }}
        />
      ) : (
        <>
          {type !== QueriesFilterMenuType.ACTION && type !== QueriesFilterMenuType.PROTOCOL && (
            <Flex
              sx={{
                alignItems: 'center',
              }}
            >
              <Flex
                sx={{
                  width: '100%',
                  right: 0,
                  zIndex: 'zIndex240',
                  alignItems: 'center',
                  borderBottom: '1px solid',
                  borderBottomColor: 'white10',
                  p: '1.6rem',
                }}
              >
                <Svg
                  svg={SearchIcon}
                  fill="white50"
                  sx={{
                    cursor: 'pointer',
                    width: '2.4rem',
                    height: '2.4rem',
                    flexShrink: 0,
                  }}
                />

                <Box sx={{ width: '100%' }}>
                  <Input
                    type="text"
                    ref={searchInputRef}
                    value={searchText}
                    variant="search"
                    backgroundColor="transparent"
                    data-testid="search-activity-log-filters"
                    onChange={(e): void => {
                      setSearchText(e.target.value)
                    }}
                    tabIndex={0}
                    hideLabel
                    placeholder="Search"
                    sx={{ pl: '0.8rem', pr: 0, fontSize: '1.8rem', height: '2.8rem' }}
                    autoComplete="off"
                    autoFocus={false}
                  />
                </Box>

                {searchText && (
                  <Button
                    variant="simple"
                    sx={{ p: 0, ml: '0.6rem' }}
                    onClick={(): void => setSearchText('')}
                  >
                    <Svg
                      svg={CloseIcon}
                      fill="white"
                      sx={{
                        cursor: 'pointer',
                        width: '2.4rem',
                        height: '2.4rem',
                        flexShrink: 0,
                      }}
                    />
                  </Button>
                )}
              </Flex>
            </Flex>
          )}
          <Flex
            data-testid="queries-filter-submenu-content"
            sx={{
              width: '100%',
              height: '100%',
              alignItems: 'center',
              flexDirection: 'column',
              fontSize: '1.6rem',
              overflow: 'auto',
            }}
            className="show-scrollbar"
          >
            {submenuOptions.length ? (
              <IconMenu
                hideRef={{ current: hideQueryFilterDropdown?.[type] }}
                isOpen={!!type}
                items={submenuOptions}
                sxButton={{
                  maxHeight: '4rem',
                  ':hover': {
                    fill: 'none',
                    opacity: 1,
                  },
                }}
                sxContainer={{
                  border: 0,
                  width: '100%',
                  pt: 0,
                }}
              />
            ) : (
              <Text sx={{ py: '1.6rem' }}>{`No such ${
                type === QueriesFilterMenuType.FILTERS ? 'filter' : 'service'
              }...`}</Text>
            )}
          </Flex>
        </>
      )}
    </Flex>
  )
}
