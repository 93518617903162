import React, { ReactElement } from 'react'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { Text } from 'theme-ui'
import { ExternalLink, Svg } from 'ui'
import ExternalLinkIcon from 'images/profileManagement/external-link-icon.svg'

export function LearnMoreLink({ url }: { url?: string }): ReactElement {
  return (
    <ExternalLink
      to={url || `${DOCS_CONTROL_D_DOMAIN}/docs/update-ip-via-heartbeat-device`}
      sx={{
        color: 'white50',
        fontSize: ['1.6rem', '1.4rem', '1.6rem'],
        alignItems: 'center',
        display: 'flex',
        ':hover': {
          color: 'white',
        },
        '&:hover > svg path': {
          fill: 'white',
        },
      }}
    >
      <Text sx={{ pr: '0.8rem', whiteSpace: 'nowrap' }}> Learn more</Text>{' '}
      <Svg fill="white50" svg={ExternalLinkIcon} />
    </ExternalLink>
  )
}
