import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NavBarAccordionType } from 'components/Dashboard/NavBar/NavBarAccordion'

type DashboardState = {
  breakpointIndex: number
  isMobileNavMenuOpen: boolean
  isNavBarExpanded: boolean
  isSearchExpanded: boolean
  accordionType?: NavBarAccordionType
  isModalDialogVisible?: boolean
  isMobileTrayVisible?: boolean
  isSearchDropdownVisible: boolean
}
const initialState: DashboardState = {
  breakpointIndex: -1,
  isMobileNavMenuOpen: false,
  isNavBarExpanded: true,
  isSearchExpanded: false,
  isModalDialogVisible: false,
  isMobileTrayVisible: false,
  isSearchDropdownVisible: false,
}
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    ...initialState,
  },
  reducers: {
    setBreakpointIndex: (state, action: PayloadAction<number>) => {
      state.breakpointIndex = action.payload
    },
    setIsMobileNavMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isMobileNavMenuOpen = action.payload
    },
    setIsNavBarExpanded: (state, action: PayloadAction<boolean>) => {
      state.isNavBarExpanded = action.payload
    },
    setIsSearchExpanded: (state, action: PayloadAction<boolean>) => {
      state.isSearchExpanded = action.payload
    },
    setAccordionType: (state, action: PayloadAction<NavBarAccordionType>) => {
      state.accordionType = action.payload
    },
    setIsModalDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.isModalDialogVisible = action.payload
    },
    setIsMobileTrayVisible: (state, action: PayloadAction<boolean>) => {
      state.isMobileTrayVisible = action.payload
    },
    setIsSearchDropdownVisible: (state, action: PayloadAction<boolean>) => {
      state.isSearchDropdownVisible = action.payload
    },
  },
})

export const {
  setBreakpointIndex,
  setIsMobileNavMenuOpen,
  setIsNavBarExpanded,
  setIsSearchExpanded,
  setAccordionType,
  setIsModalDialogVisible,
  setIsMobileTrayVisible,
  setIsSearchDropdownVisible,
} = dashboardSlice.actions
