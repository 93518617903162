import React, { ReactNode, ReactElement } from 'react'
import { Flex, Button, Text } from 'theme-ui'
import { Svg } from 'ui'
import ButtonArrowIcon from 'images/button-arrow.svg'

interface PlanProps {
  name: ReactNode
  priceElement: ReactNode
  color: string
  features: ReactNode
  icon: React.ElementType
  onSelect: () => void
  isCurrentPlan: boolean
}
function Plan({
  name,
  priceElement,
  color,
  features,
  icon,
  onSelect,
  isCurrentPlan,
}: PlanProps): ReactElement {
  return (
    <Flex
      data-testid={name}
      sx={{
        flexDirection: 'column',
        p: '0.8rem',
        height: '100%',
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'darkItemBG',
          borderRadius: '16px',
          p: '2.4rem',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Flex>
            <Svg svg={icon} />
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              textAlign: 'right',
              fontWeight: 'bold',
              ml: 'auto',
              fontSize: '2.4rem',
              color,
            }}
          >
            {isCurrentPlan && (
              <Text
                sx={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'greenApple',
                  textTransform: 'uppercase',
                }}
              >
                Current plan
              </Text>
            )}
            <Text>{name}</Text>
          </Flex>
        </Flex>
        <Flex
          sx={{
            fontWeight: 'bold',
            fontSize: '2.8rem',
            mt: '2.4rem',
            height: 'fit-content',
          }}
        >
          {priceElement}
        </Flex>
        <Flex
          sx={{
            justifyContent: 'center',
          }}
        >
          <Button
            data-testid="select-plan-button"
            sx={{
              width: '100%',
              backgroundColor: 'greenApple',
              borderColor: 'greenApple',
              fontWeight: 'bold',
              fontSize: ['1.8rem', '2.1rem'],
              borderRadius: '45px',
              py: ['1.1rem', '1.6rem'],
              mt: '2.4rem',
            }}
            aria-label={`Select ${name}`}
            onClick={onSelect}
          >
            <Flex sx={{ alignItems: 'center', mr: '-2.4rem' }}>
              Select <Svg sx={{ ml: '0.8rem' }} svg={ButtonArrowIcon} />
            </Flex>
          </Button>
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            mt: '2.4rem',
          }}
        >
          {features}
        </Flex>
      </Flex>
    </Flex>
  )
}
export default Plan
