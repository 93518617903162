import React, { ReactElement } from 'react'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { TablePlaceholder } from './index'
import AccordionPlaceholder from './AccordionPlaceholder'

export default function TableOrAccordionPlaceholder({
  columnsCount = 4,
  rowsCount = 4,
}: {
  columnsCount?: number
  rowsCount?: number
}): ReactElement {
  const isDesktop = useBreakpointIndex() === 2

  return isDesktop ? (
    <TablePlaceholder columnsCount={columnsCount} rowsCount={rowsCount} />
  ) : (
    <AccordionPlaceholder rowsCount={rowsCount} />
  )
}
