import React, { ReactNode, ElementType, ReactElement, useEffect } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import HeaderNavMenuButtonMobile from 'components/Dashboard/NavBar/HeaderNavMenuButton.mobile'
import { Button, Svg } from 'ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { useParams } from '@reach/router'
import { EditProfileUrlParams } from './Profiles'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setCurrentProfileId } from 'store/persist'
import InfoIcon from 'images/dashboard/info-icon.svg'
import useTutorialSlides from 'components/Dashboard/utils/useTutorialSlides'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import { TutorialType } from 'store/tutorial/tutorial'

export interface DashboardHeaderProps {
  children?: ReactNode
  icon?: ElementType
  rightContent?: ReactNode
  title: string
  description?: string | ReactNode
  sx?: ThemeUIStyleObject
  tutorialSlide?: number
  customTutorial?: TutorialType
}

export default function DashboardHeader({
  children,
  icon,
  title,
  description,
  rightContent,
  sx,
  tutorialSlide,
  customTutorial,
}: DashboardHeaderProps): ReactElement {
  const dispatch = useAppDispatch()
  const isMobile = useBreakpointIndex() === 0
  const { profileId } = useParams<EditProfileUrlParams>()
  const { data: userData } = useGetUser()
  const userPk = userData?.PK || ''
  const sessionTutorials = userData?.tutorials
  const shouldShowDescription = description && !!userPk && !sessionTutorials
  const isInAnalyticsMaintenanceMode = useAppSelector(s => s.errors.isInAnalyticsMaintenanceMode)

  useEffect(() => {
    if (!profileId) {
      dispatch(setCurrentProfileId(''))
    }
  }, [dispatch, profileId])

  return (
    <Flex
      data-testid="dashboard-header"
      sx={{
        position: 'relative',
        width: '100%',
        borderBottom: ['1px solid', 'none'],
        borderBottomColor: 'white10',
        height: [description && children ? '8.2rem' : '6.4rem', '7.2rem'],
        pl: ['1.6rem', 0],
        pr: ['1.6rem', '2.4rem'],
        pt: ['0.8rem', '2.4rem'],
        pb: ['0.8rem', 0],
        ...sx,
      }}
      className={isMobile ? 'reset-tippy-width centered-header-tippy mobile' : ''}
    >
      <Flex
        sx={{
          flexGrow: 1,
          alignItems: 'center',
          gap: '1.6rem',
        }}
      >
        <HeaderNavMenuButtonMobile />
        {icon && <Svg svg={icon} sx={{ height: '2.4rem', width: '2.4rem', flexShrink: 0 }} />}
        <Flex
          sx={{
            mr: '1.6rem',
            alignItems: ['baseline', 'center', 'center'],
            flexDirection: ['column', 'row'],
            flexGrow: 1,
          }}
        >
          <Flex
            sx={{
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Flex
              sx={{ width: '100%', justifyContent: 'flex-start', flexDirection: ['column', 'row'] }}
            >
              <Flex sx={{ alignItems: 'center', gap: ['0.4rem', 0] }}>
                <TextWithOverFlowAndTippyPopup
                  testId="header-title"
                  content={title}
                  sxText={{
                    fontSize:
                      description && !sessionTutorials ? '1.8rem' : ['1.8rem', '2.1rem', '2.6rem'],
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    minWidth: '1px',
                  }}
                />
                {shouldShowDescription && isMobile && (
                  <AnimatedInfoButton
                    description={description}
                    tutorialSlide={tutorialSlide}
                    customTutorial={customTutorial}
                  />
                )}
              </Flex>
              {children && !isInAnalyticsMaintenanceMode && (
                <Flex
                  sx={{
                    ml: [0, '1.6rem'],
                  }}
                >
                  {children}
                </Flex>
              )}
            </Flex>
            {shouldShowDescription && !isMobile && (
              <AnimatedInfoButton
                description={description}
                tutorialSlide={tutorialSlide}
                customTutorial={customTutorial}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      {rightContent && !isInAnalyticsMaintenanceMode && (
        <Flex sx={{ mr: 0, alignItems: 'center', flexShrink: 0 }}>{rightContent}</Flex>
      )}
    </Flex>
  )
}

function AnimatedInfoButton({
  description,
  tutorialSlide = 0,
  customTutorial,
}: {
  description?: string | ReactNode
  tutorialSlide?: number
  customTutorial?: TutorialType
}): ReactElement {
  const { onInfoIconClick } = useTutorialSlides()
  const { wasTutorialViewed } = useTutorialSlides()

  return (
    <Button
      variant="transparent"
      data-testid="header-info-button"
      ariaLabel="view tutorial"
      sx={{
        width: 'fit-content',
        p: 0,
        color: wasTutorialViewed ? 'white50' : 'yellow',
        gap: wasTutorialViewed ? ['0.2rem', '0.8rem'] : '0.8rem',
        height: '2.4rem',
        justifyContent: 'flex-start',
        maxWidth: '100%',
        '&:hover': {
          color: wasTutorialViewed ? 'white' : 'yellow80',
          '& > .animation-block': {
            backgroundColor: wasTutorialViewed ? 'transparent' : 'yellow80',
            'svg path': {
              fill: wasTutorialViewed ? 'white' : 'black',
            },
          },
        },
      }}
      onClick={() => onInfoIconClick?.(tutorialSlide, customTutorial)}
    >
      <TextWithOverFlowAndTippyPopup
        testId="header-description"
        content={description}
        sxText={{
          display: ['none', 'inline-block'],
          fontSize: '1.2rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      />
      <Flex
        className="animation-block"
        sx={{
          flexShrink: 0,
          p: wasTutorialViewed ? 0 : ['0.2rem', '0.4rem'],
          borderRadius: '100%',
          backgroundColor: wasTutorialViewed ? 'transparent' : 'yellow',
          '@-webkit-keyframes rotation': {
            '0%': { padding: ['0.2rem', '0.4rem'] },
            '50%': { padding: ['0.4rem', '0.8rem'] },
            '100%': { padding: ['0.2rem', '0.4rem'] },
          },
          '-webkit-animation': 'rotation 6s infinite linear',
          animation: wasTutorialViewed ? 'none' : 'rotation 1.5s infinite linear',
        }}
      >
        <Svg
          svg={InfoIcon}
          fill={wasTutorialViewed ? 'white50' : 'black'}
          sx={{ width: ['1.8rem', '1.4rem'], height: ['1.8rem', '1.4rem'], flexShrink: 0 }}
        />
      </Flex>
    </Button>
  )
}
