import React, { ReactElement } from 'react'
import { Text, ThemeUIStyleObject } from 'theme-ui'

export default function PreferencesSectionTitle({
  title,
  sx,
}: {
  title: string
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Text sx={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'white50', mb: '1.6rem', ...sx }}>
      {title}
    </Text>
  )
}
