import React, { MutableRefObject, ReactElement, useCallback, useRef, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { Flex } from 'theme-ui'
import DeleteIcon from 'images/delete-icon.svg'
import FloatingActionButton from 'ui/FloatingActionButton'
import useOnClickOutside from 'utils/useOnClickOutside'
import SelectButton from 'components/Dashboard/IPs/SelectButton'
import { Setter } from 'utils'
import { useGetAccessQuery } from 'store/api/access'
import useGetUser from 'components/Dashboard/utils/useGetUser'

const buttonSpace = '1.6rem'
const buttonHeight = '4.8rem'
const buttonSpaceAndHeight = `calc(${buttonSpace} + ${buttonHeight})`

export default function IPsFixedButtons({
  ipsToDelete,
  setIpsToDelete,
  isMultiSelectMode,
  setIsMultiSelectMode,
  nonCurrentIPsCardRef,
}: {
  ipsToDelete: string[]
  setIpsToDelete: Setter<string[]>
  isMultiSelectMode: boolean
  setIsMultiSelectMode: Setter<boolean>
  nonCurrentIPsCardRef: MutableRefObject<HTMLDivElement | null>
}): ReactElement {
  const { data: userData } = useGetUser()
  const sourceIp = userData?.debug?.source_ip
  const accessSelectedDeviceId = useAppSelector(s => s.access.selectedDeviceId || '')
  const [shouldShowDeleteMultipleRulesConfirmation, setShouldShowDeleteMultipleRulesConfirmation] =
    useState(false)
  const selectButtonRef = useRef<HTMLButtonElement>(null)
  const deleteButtonRef = useRef<HTMLButtonElement>(null)
  const addIpButton = useRef<HTMLButtonElement>(null)
  const { data } = useGetAccessQuery(
    { deviceId: accessSelectedDeviceId },
    { skip: !accessSelectedDeviceId },
  )

  const deselectAllIPs = useCallback(() => {
    setIpsToDelete([])
  }, [setIpsToDelete])

  const resetSelection = useCallback(() => {
    setIsMultiSelectMode(false)
    deselectAllIPs()
    setShouldShowDeleteMultipleRulesConfirmation(false)
  }, [deselectAllIPs, setIsMultiSelectMode])

  useOnClickOutside([nonCurrentIPsCardRef, selectButtonRef, deleteButtonRef, addIpButton], () => {
    resetSelection()
  })

  const selectAllIPs = () => {
    data?.ips?.forEach(ipData => {
      if (!ipsToDelete.includes(ipData.ip) && ipData.ip !== sourceIp) {
        setIpsToDelete(ipsToDelete => [...ipsToDelete, ipData.ip])
      }
    })
  }

  return (
    <Flex
      sx={{
        position: 'sticky',
        bottom: 0,
        width: ['100%', '4.8rem'],
        height: '4.8rem',
        ml: 'auto',
        mt: 'auto',
        zIndex: 'zIndex50',
      }}
    >
      <FloatingActionButton
        dataTestId="delete-ips-button"
        ref={deleteButtonRef}
        fill="darkBodyBG"
        icon={DeleteIcon}
        sx={{
          width: '4.8rem',
          height: '4.8rem',
          opacity: isMultiSelectMode && ipsToDelete.length > 0 ? 1 : 0,
          right: 0,
          bottom: isMultiSelectMode && ipsToDelete.length > 0 ? buttonSpaceAndHeight : '2rem',
          transitionProperty: 'all',
          transitionDuration: '0.5s',
          transitionTimingFunction: 'ease-in-out',
        }}
        onClick={() =>
          setShouldShowDeleteMultipleRulesConfirmation(shouldShowConf => !shouldShowConf)
        }
      />
      <FloatingActionButton
        variant="primaryWithoutScaling"
        fill="darkBodyBG"
        ref={addIpButton}
        sx={{
          width: '4.8rem',
          height: '4.8rem',
          transform: 'rotate(45deg)',
          opacity: isMultiSelectMode && ipsToDelete.length > 0 ? 1 : 0,
          bottom: isMultiSelectMode && ipsToDelete.length > 0 ? 0 : '-2.4rem',
          right: 0,
          transitionProperty: 'bottom, opacity',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-in-out',
        }}
        onClick={() => {
          resetSelection()
        }}
      />
      <SelectButton
        isDisabled={!data?.ips.length}
        selectButtonRef={selectButtonRef}
        ipsToDelete={ipsToDelete}
        areAllIPsSelected={
          !!data?.ips.filter(ip => ip.ip !== sourceIp).every(ip => ipsToDelete.includes(ip.ip))
        }
        shouldShowDeleteConfirmation={shouldShowDeleteMultipleRulesConfirmation}
        setShouldShowDeleteConfirmation={setShouldShowDeleteMultipleRulesConfirmation}
        isMultiSelectMode={isMultiSelectMode}
        setIsMultiSelectMode={setIsMultiSelectMode}
        selectAllIPs={selectAllIPs}
        deselectAllIPs={deselectAllIPs}
        sx={{
          opacity: isMultiSelectMode && ipsToDelete.length > 0 ? 1 : 0,
          bottom: isMultiSelectMode && ipsToDelete.length > 0 ? 0 : '-2.4rem',
          transitionProperty: 'bottom, opacity',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-in-out',
        }}
      />
    </Flex>
  )
}
