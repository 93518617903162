import React, { ReactElement, useEffect, useMemo } from 'react'
import { Flex, Text } from 'theme-ui'
import { Button, ExternalLink, Svg, TrayRenderProps, useTrayPresenter } from 'ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { RouteComponentProps } from '@reach/router'
import useQueryString from 'utils/useQueryString'
import { navigate } from 'gatsby'
import useRequireAuthentication from 'utils/useRequireAuthentication'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import UfoIcon from 'images/ufo.svg'
import LogoNotFoundIcon from 'images/logos/not-found.svg'
import DiscordIcon from 'images/organization/homepage/footer/discord.svg'
import RedditIcon from 'images/organization/homepage/footer/reddit.svg'
import XIcon from 'images/organization/homepage/footer/x.svg'
import NotificationsTray from '../NavBar/NotificationTray'

const analyticsMaintenanceMessage =
  'Oops! Our Analytics servers have been abducted for maintenance. Please check the Notifications for more information on when they would be back.'

const MaintenanceModePane = ({
  isAnalytics,
}: { isAnalytics?: boolean } & RouteComponentProps): ReactElement => {
  const { isInMaintenanceMode, maintenanceMessage } = useAppSelector(s => s.errors)
  const { isLoading } = useGetUser()
  const dispatch = useAppDispatch()
  const { qs } = useQueryString()

  useRequireAuthentication()

  useEffect(() => {
    if (!isInMaintenanceMode && qs.redirectTo && !isLoading && !isAnalytics) {
      navigate(qs.redirectTo.includes('http') ? '' : qs.redirectTo)
    }
  }, [dispatch, isInMaintenanceMode, qs.redirectTo, isLoading, isAnalytics])

  return (
    <Flex
      sx={{
        width: '100%',
        position: isAnalytics ? 'static' : 'fixed',
        height: '100%',
        top: 0,
        left: 0,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: 'darkBodyBG',
        gap: '3.3rem',
      }}
      data-testid="not-found-container"
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          gap: '4.8rem',
        }}
      >
        <Flex
          sx={{
            gap: '0.8rem',
            alignItems: 'center',
          }}
        >
          <Svg data-testid="not-found-logo" svg={LogoNotFoundIcon} />
          <Text color="title" variant="size18Weight800Line140">
            CONTROL D
          </Text>
        </Flex>
        <Svg svg={UfoIcon} data-testid="not-found-icon" />
      </Flex>
      <Text
        color="title"
        sx={{
          maxWidth: '37.6rem',
          fontSize: '1.6rem',
          letterSpacing: '0.008rem',
          lineHeight: '140%',
          px: ['1rem', 0],
        }}
        data-testid="not-found-text"
      >
        {isAnalytics ? analyticsMaintenanceMessage : maintenanceMessage}
      </Text>
      <Flex
        sx={{
          gap: '1.6rem',
          alignItems: 'center',
        }}
      >
        {isAnalytics ? (
          <NotificationButton />
        ) : (
          <Button
            aria-label="Support"
            name="Support"
            data-testid="support-button"
            variant="roundedSecondary"
            onClick={() => navigate('/contact')}
          >
            <Text color="title">Support</Text>
          </Button>
        )}
        <Button
          aria-label="Reload"
          name="Reload"
          data-testid="reload-button"
          variant="roundedSecondary"
          onClick={() => location.reload()}
        >
          <Text color="title">Reload</Text>
        </Button>
      </Flex>
      {!isAnalytics && (
        <Flex sx={{ gap: '1.6rem', alignItems: 'center' }}>
          <SocialMediaButton to="https://twitter.com/controldns" icon={XIcon} />
          <SocialMediaButton to="https://www.reddit.com/r/ControlD/" icon={RedditIcon} />
          <SocialMediaButton to="https://discord.gg/dns" icon={DiscordIcon} />
        </Flex>
      )}
    </Flex>
  )
}

export default MaintenanceModePane

interface SocialMediaButtonProps {
  to: string
  icon: React.ElementType
}

function SocialMediaButton({ to, icon }: SocialMediaButtonProps): ReactElement {
  return (
    <ExternalLink
      aria-label={to}
      to={to}
      sx={{
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          '& svg path': {
            fill: 'title',
          },
        },
      }}
    >
      <Svg svg={icon} fill="body" />
    </ExternalLink>
  )
}

function NotificationButton() {
  const notificationTray = useMemo(
    () => ({
      id: `NotificationTrayMaintenanceMode`,
      renderTray: (props: TrayRenderProps) => <NotificationsTray {...props} isInMaintenanceMode />,
      zIndexName: 'zIndex550',
    }),
    [],
  )

  const { showTray } = useTrayPresenter(notificationTray)

  return (
    <Button
      aria-label="Notifications"
      name="Notifications"
      data-testid="notifications-button"
      variant="roundedSecondary"
      onClick={() => showTray()}
    >
      <Text color="title">Notifications</Text>
    </Button>
  )
}
