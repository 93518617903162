const sharedStyles = {
  cursor: 'pointer',
  fontSize: '2.1rem',
  borderRadius: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'body',
  ':disabled': {
    cursor: 'auto',
    backgroundColor: 'white10',
    color: 'white50',
    border: '1px solid rgba(229,229,229,0)',
    '& svg': {
      fill: 'white50',
    },
  },
}

const roundedSharedStyles = {
  cursor: 'pointer',
  outline: 'none',
  py: '0.9rem',
  px: '2rem',
  borderRadius: '10rem',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: '1.7rem',
  color: 'white',
  fontFamily: 'body',
}

export default {
  primary: {
    ...sharedStyles,
    color: 'black',
    ':hover': {
      ':not([disabled])': {
        color: 'white',
        span: {
          color: 'white',
        },
        backgroundColor: 'primaryHoverBG',
        'svg path': {
          fill: 'white',
        },
        transform: 'scale(1.025)',
      },
    },
  },
  primaryWithoutScaling: {
    ...sharedStyles,
    color: 'black',
    ':hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'primaryHoverBG',
        'svg path': {
          fill: 'white',
        },
      },
    },
  },
  secondary: {
    ...sharedStyles,
    backgroundColor: 'plum',
    color: 'white',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'secondaryHoverBG',
        '& svg path': {
          fill: 'white',
        },
        transform: 'scale(1.025)',
      },
    },
  },
  secondaryWithoutScaling: {
    ...sharedStyles,
    backgroundColor: 'plum',
    color: 'white',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'secondaryHoverBG',
        '& svg path': {
          fill: 'white',
        },
      },
    },
  },
  tertiary: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    borderRadius: '59px',
    border: '2px solid',
    borderColor: 'plum50',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'plum25',
        '& svg path': {
          fill: 'white',
        },
      },
    },
  },
  bordered: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    borderRadius: '59px',
    border: '1px solid',
    borderColor: 'plum',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'secondaryHoverBG',
        '& svg path': {
          fill: 'white',
        },
      },
    },
  },
  borderedGreen: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    borderRadius: '59px',
    border: '1px solid',
    borderColor: 'greenApple15',
    '&:hover': {
      ':not([disabled])': {
        color: 'white',
        backgroundColor: 'greenApple25',
        '& svg path': {
          fill: 'white',
        },
      },
    },
  },
  simple: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    outline: 'none',
    // hover, but not a tap on touch screen
    '@media (hover: hover)': {
      '&:hover': {
        ':not([disabled])': {
          color: 'white',
          '& > div span': {
            color: 'white',
          },
          '& svg path': {
            fill: 'white',
          },
        },
      },
    },
  },
  transparent: {
    ...sharedStyles,
    backgroundColor: 'transparent',
    outline: 'none',
  },
  icon: {
    ...sharedStyles,
    margin: 0,
    padding: 0,
  },
  switch: {
    cursor: 'pointer',
    position: 'relative',
    m: 0,
    p: 0,
    height: '2.4rem',
    borderRadius: 1,
    width: '4.8rem',
    minWidth: '4.8rem',
    '&:disabled': {
      backgroundColor: '#2F3241',
      border: '1px solid',
      borderColor: 'white10',
      '.switch-toggle': {
        backgroundColor: 'transparent',
        border: '2px solid',
        borderColor: 'white10',
        boxShadow: '0 2px 0 0 rgba(0, 0, 0, 0.25)',
        '& svg': {
          fill: 'white50',
        },
      },
    },
    '& .switch-toggle': {
      boxShadow: '0 0.2rem 0.2rem 0 rgba(0,0,0,0.40)',
      m: 0,
      ml: '0.1rem',
      width: '2.0rem',
      height: '2.0rem',
      borderRadius: 3,
      transitionProperty: 'transform',
      transitionTimingFunction: 'ease-out',
      transitionDuration: '0.1s',
    },
  },
  multiSwitchSVG: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: 0,
    marginRight: '1.6rem',
    '& > span > svg': {
      width: '1.6rem',
      height: '2.4rem',
    },
  },
  roundedSimple: {
    ...roundedSharedStyles,
    color: 'body',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'borderFaint',
    },
  },
  roundedSecondary: {
    ...roundedSharedStyles,
    border: '1px solid',
    borderColor: 'cyan60',
    backgroundColor: 'roundedTertiary10',
    lineHeight: '138%',
    '&:hover': {
      backgroundColor: 'roundedTertiary15',
    },
    '&:active': {
      backgroundColor: 'roundedTertiary05',
    },
  },
  roundedTertiary: {
    ...roundedSharedStyles,
    lineHeight: '138%',
    height: '3.8rem',
    backgroundColor: 'roundedTertiary10',
    '&:hover': {
      backgroundColor: 'roundedTertiary20',
    },
    '&:active': {
      backgroundColor: 'roundedTertiary05',
    },
  },
  gradientButton: {
    fontFamily: 'body',
    height: '4.7rem',
    width: ['100%', 'fit-content'],
    alignItems: 'center',
    py: '1.2rem',
    px: '2rem',
    gap: '0.5rem',
    fontSize: '1.7rem',
    borderRadius: '50px',
    background: 'linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
    boxShadow:
      '0px 0px 20px 0px rgba(97, 63, 219, 0.6), 0px 1px 0.2px 0px rgba(255, 255, 255, 0.4) inset',
    '&:hover': {
      ':not([disabled])': {
        cursor: 'pointer',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
      },
    },
    '&:active': {
      ':not([disabled])': {
        background:
          'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, #7252E5 0%, #4A20E5 100%)',
      },
    },
    ':disabled': {
      cursor: 'auto',
      backgroundColor: 'transparent',
      color: 'white25',
      border: '1px solid',
      borderColor: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '10rem',
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 100%)',
      backgroundClip: 'padding-box',
      boxShadow: 'none',
      '& > div span': {
        color: 'white25',
      },
      '& svg path': {
        fill: 'white25',
      },
    },
  },
}
