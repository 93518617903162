import React, { ReactElement, ReactNode } from 'react'
import { Currency } from 'store/api/billing'
import { Flex, Text } from 'theme-ui'
import getFormattedPrice from 'utils/getFormattedPrice'
import getPriceDetailsForCurrency from 'utils/getPriceDetailsForCurrency'
import { CommonPriceInterval, PricePoint } from 'store/api/billing/payments.interface'

interface StrikeThroughPriceProps {
  children: ReactNode
}
function StrikeThroughPrice({ children }: StrikeThroughPriceProps): ReactElement {
  return (
    <Text
      sx={{
        textDecoration: 'line-through',
        opacity: 0.5,
        mr: '0.8rem',
        fontSize: '3.6rem',
        fontWeight: 'bold',
      }}
    >
      {children}
    </Text>
  )
}

interface PriceElementProps {
  pricePoints: PricePoint[]
  currency?: Currency
}

function PriceElement({ pricePoints, currency }: PriceElementProps): ReactElement {
  const monthlyPricePoint = pricePoints.find(x => x.duration === CommonPriceInterval.MONTHLY)
  const monthlyPrice =
    monthlyPricePoint && getPriceDetailsForCurrency(monthlyPricePoint, currency?.code)
  const yearlyPricePoint = pricePoints.find(
    x => x.duration > CommonPriceInterval.MONTHLY && x.duration % CommonPriceInterval.YEARLY === 0,
  )
  const yearlyPrice =
    yearlyPricePoint && getPriceDetailsForCurrency(yearlyPricePoint, currency?.code)
  const monthsPricePoint = pricePoints.find(
    x => x.duration > CommonPriceInterval.MONTHLY && x.duration % CommonPriceInterval.YEARLY !== 0,
  )
  const monthsPrice =
    monthsPricePoint && getPriceDetailsForCurrency(monthsPricePoint, currency?.code)
  const doesMonthAndYearPriceExist = monthlyPrice && yearlyPrice
  const isAlreadyBilled = pricePoints[0].already_billed === 1
  const yearsNumber = yearlyPrice ? yearlyPrice.duration / CommonPriceInterval.YEARLY : 0

  return (
    <Flex
      sx={{
        flexDirection: 'column',
      }}
    >
      {monthlyPrice && (
        <>
          <Flex
            data-testid="monthly-price"
            sx={{
              fontWeight: 'normal',
              alignItems: 'center',
            }}
          >
            {monthlyPrice.original_price && (
              <StrikeThroughPrice>
                {getFormattedPrice(currency, monthlyPrice.original_price)}
              </StrikeThroughPrice>
            )}
            <Text
              sx={{
                fontSize: monthlyPrice?.original_price ? '3.8rem' : '2.8rem',
                fontWeight: 'bold',
              }}
            >
              {getFormattedPrice(currency, isAlreadyBilled ? 0 : monthlyPrice.price)}
              {doesMonthAndYearPriceExist && (
                <Text sx={{ fontWeight: 'normal', pl: '0.8rem' }}>per month or </Text>
              )}
            </Text>
          </Flex>
          {!doesMonthAndYearPriceExist && (
            <Text sx={{ fontWeight: 'normal' }}>{isAlreadyBilled ? 'for 1' : 'per'} month.</Text>
          )}
        </>
      )}
      {yearlyPrice && (
        <>
          <Flex
            data-testid="yearly-price"
            sx={{
              fontWeight: 'normal',
              alignItems: 'center',
            }}
          >
            {yearlyPrice.original_price && (
              <StrikeThroughPrice>
                {getFormattedPrice(currency, yearlyPrice.original_price)}
              </StrikeThroughPrice>
            )}
            <Text
              sx={{
                fontSize: yearlyPrice?.original_price ? '3.8rem' : '2.8rem',
                fontWeight: 'bold',
                pr: '0.8rem',
              }}
            >
              {getFormattedPrice(currency, isAlreadyBilled ? 0 : yearlyPrice.price)}
            </Text>
            {doesMonthAndYearPriceExist && 'per year.'}
          </Flex>
          {!doesMonthAndYearPriceExist && (
            <Text sx={{ fontWeight: 'normal' }}>{`${isAlreadyBilled ? 'for' : 'per'} ${
              yearsNumber === CommonPriceInterval.MONTHLY && yearlyPrice.price
                ? isAlreadyBilled
                  ? '1'
                  : ''
                : yearsNumber
            } year${yearsNumber > CommonPriceInterval.MONTHLY ? 's' : ''}.`}</Text>
          )}
        </>
      )}
      {monthsPrice && (
        <>
          <Flex data-testid="half-yearly-price">
            <Text
              sx={{
                fontWeight: 'normal',
                alignItems: 'center',
                fontSize: '2.8rem',
                pr: '0.4rem',
              }}
            >
              {monthsPrice.original_price && (
                <StrikeThroughPrice>
                  {getFormattedPrice(currency, monthsPrice.original_price)}
                </StrikeThroughPrice>
              )}
              <Text
                sx={{
                  fontSize: '3.6rem',
                  fontWeight: 'bold',
                  pr: '0.8rem',
                }}
              >
                {getFormattedPrice(currency, isAlreadyBilled ? 0 : monthsPrice.price)}
              </Text>
              {monthsPrice.original_price && `for ${monthsPrice.duration} months.`}
            </Text>
          </Flex>
          {!monthsPrice.original_price && (
            <Text sx={{ fontWeight: 'normal' }}>{`for ${monthsPrice.duration} months.`}</Text>
          )}
        </>
      )}
    </Flex>
  )
}
export default PriceElement
