import { Setter } from 'utils'
import { addCode, TwoFactorStep } from 'store/twofa'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Flex, Spinner, Text } from 'theme-ui'
import { Button, Input } from 'ui'
import { LoadingState } from 'store/fetchingLogic'
import { userApi } from 'store/api/user'

export default function EnableTwoFaStep({
  setStep,
}: {
  setStep: Setter<TwoFactorStep>
}): React.ReactElement {
  const dispatch = useAppDispatch()
  const twofa = useAppSelector(s => s.twofa)
  const [code, setCode] = useState('')

  return (
    <Flex
      as="form"
      onSubmit={async (e): Promise<void> => {
        e.preventDefault()
        await dispatch(
          addCode({
            code,
            setTwofaStep: setStep,
            closeEditing: () => {},
          }),
        )
        await dispatch(userApi.endpoints.getUser.initiate(''))
      }}
      sx={{
        width: '100%',
        flexDirection: 'column',
        gap: ['2.4rem', '4.8rem'],
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
      }}
    >
      <Text sx={{ maxWidth: '43.8rem', fontSize: '1.6rem', color: 'white50' }}>
        Now enter the code generated by the app.
      </Text>
      <Flex
        sx={{
          width: '100%',
          gap: '2.4rem',
          alignItems: 'flex-end',
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <Input
          data-testid="enable-two-factor-code"
          placeholder="Code"
          aria-label="current password"
          autoComplete="current-password"
          type="password"
          variant="primary"
          id="confirm-two-factor-password"
          name="password"
          value={code}
          activeLabelColor="white"
          hideLabel
          onChange={e => setCode(e.target.value)}
          sx={{
            border: 'none',
            borderRadius: '59px',
            height: '5.4rem',
            flexShrink: 0,
          }}
          containerStyle={{
            width: ['100%', '100%', 'auto'],
          }}
        />
        <Button
          type="submit"
          sx={{
            width: ['100%', '100%', '17.4rem'],
            minWidth: '15.2rem',
            height: ['4.8rem', '5.4rem'],
            fontSize: ['1.8rem', '2.1rem'],
            fontWeight: 'bold',
            borderRadius: '59px',
            border: 'none',
            backgroundColor: 'plum',
            color: 'white',
          }}
          variant="secondary"
          data-testid="enable-twofa"
        >
          {twofa.loading !== LoadingState.PENDING && 'Enable'}
          {twofa.loading === LoadingState.PENDING && (
            <Spinner
              color="white"
              sx={{
                height: '2.1rem',
              }}
            />
          )}
        </Button>
      </Flex>
    </Flex>
  )
}
