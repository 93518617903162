import React, { ReactElement } from 'react'
import { Flex, Link, Spinner } from 'theme-ui'
import useQueryString from 'utils/useQueryString'
import { DeviceDialogType } from 'components/Dashboard/Devices/DeviceTrayOrModalDialog'
import GroupItem from './Groups/GroupItem'
import { trayHeaderHeight } from 'components/TrayOrModalDialog/Tray.mobile'
import { useGetDevicesTypesQuery } from 'store/api/devices/devices'

// This screen is used in Setup Guide flow as well
const PlatformView = ({
  shouldShowOtherDevice = true,
}: {
  shouldShowOtherDevice?: boolean
}): ReactElement => {
  const { qs, nav } = useQueryString()
  const { data: typesData, isLoading } = useGetDevicesTypesQuery('')
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        gap: '4.8rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflowY: 'auto',
        maxHeight: [
          shouldShowOtherDevice ? `calc(100% - ${trayHeaderHeight})` : '100%',
          '100%',
          '100%',
        ],
        py: '4.8rem',
        px: ['1.6rem', '3.6rem'],
      }}
      className="show-scrollbar"
    >
      {isLoading ? (
        <Spinner size={24} color={'greenApple'} />
      ) : (
        <>
          {Object.values(typesData?.types || []).map(({ name, icons }) => (
            <GroupItem key={name} title={name} icons={icons} />
          ))}
          {shouldShowOtherDevice && (
            <Link
              sx={{
                fontSize: '1.8rem',
                fontWeight: 'bold',
                color: 'white50',
                textDecoration: 'underline',
                mt: '4.8rem',
                cursor: 'pointer',
                ':hover': {
                  color: 'white',
                },
              }}
              onClick={() => {
                nav({ ...qs, deviceDialog: DeviceDialogType.ADD })
              }}
            >
              Other Endpoint
            </Link>
          )}
        </>
      )}
    </Flex>
  )
}

export default PlatformView
