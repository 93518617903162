import React, { ReactElement } from 'react'
import { useAppSelector } from 'store/hooks'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import QueryFilterDropdown from './QueryFilterDropdown'
import { QueriesFilterMenuType } from '../QueryFilterMobileTray'
import { EnabledStatus } from 'store/api/rules'
import { useGetAllFiltersQuery } from 'store/api/filters'
import { Filter } from 'store/api/filters/filters.interface'
import { ExternalIcon, IconType } from 'components/Dashboard/ExternalSvgIcon'

export const aiMalware = {
  name: 'AI Malware',
  PK: 'ai_malware',
  description: '',
  sources: [],
  status: EnabledStatus.ENABLED,
} as Filter

export const getFilterTextForFiltersWithLevels = (filters: Filter[]): Filter[] => {
  const filtersWithLevels = filters.filter(f => f.levels?.length && f.PK !== 'malware') || []

  return filtersWithLevels.reduce((allFilters: Filter[], filter) => {
    const filterLevels =
      filter.levels?.map(
        level =>
          ({
            ...level,
            name: `${filter.name} - ${level.title}`,
            PK: `${level.name}`,
            description: '',
            sources: [],
            status: level.status,
            iconName: filter.PK,
          } as Filter),
      ) || []
    allFilters.push(...filterLevels)

    return allFilters
  }, [])
}

export default function FiltersDropdown(): ReactElement {
  const { data: allFilters } = useGetAllFiltersQuery('')

  const filtersWithLevels = getFilterTextForFiltersWithLevels(allFilters?.filters || [])
  const filters = [
    aiMalware,
    ...filtersWithLevels,
    ...(allFilters?.filters.filter(f => f.PK === 'malware' || !f.levels?.length) || []),
    ...(allFilters?.external_filters || []),
  ]
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const selectedFilter = filters.find(
    filter => filterParameters[QueriesFilterMenuType.FILTERS] === filter.PK,
  )
  const selectedFilterName = selectedFilter?.name || 'Filters'

  const icon: ExternalIcon = {
    icon: selectedFilter?.iconName ?? selectedFilter?.PK ?? '',
    type: IconType.FILTERS,
  }

  return (
    <QueryFilterDropdown
      selectedValue={selectedFilterName}
      externalIcon={icon}
      type={QueriesFilterMenuType.FILTERS}
    >
      <QueryFilterDropdownContent type={QueriesFilterMenuType.FILTERS} filters={filters} />
    </QueryFilterDropdown>
  )
}
