import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import { foregroundStyle } from 'components/Dashboard/PlaceholderElements'

export default function OptionBox(): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        height: ['10.7rem', '6.4rem'],
        backgroundColor: 'darkItemBG',
        flexDirection: 'column',
        borderRadius: '16px',
        p: '1.6rem',
      }}
    >
      <Flex sx={{ flexDirection: ['column', 'row'], justifyContent: 'space-between' }}>
        <Flex
          sx={{
            height: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '4.8rem',
          }}
        >
          <Box
            sx={{
              ...foregroundStyle,
              width: '17.5rem',
              height: '2.8rem',
            }}
          />
          <Box
            sx={{
              display: ['none', 'block'],
              ...foregroundStyle,
              width: '15rem',
              height: '2.8rem',
            }}
          />
        </Flex>

        <Flex sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: ['block', 'none'],
              ...foregroundStyle,
              width: '20rem',
              height: '2.4rem',
              mr: '1.6rem',
              mt: '1.6rem',
            }}
          />
          <Box
            sx={{
              ...foregroundStyle,
              width: ['2.4rem', '3.2rem'],
              height: ['2.4rem', '3.2rem'],
              flexShrink: 0,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
