import React, { ReactElement, ReactNode } from 'react'
import { Flex } from 'theme-ui'
import CrownIcon from 'images/crown.svg'
import { LoadingSpinner, Svg } from 'ui'
import { useAppSelector } from 'store/hooks'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { OrganizationNavRelativePath } from './OrganizationNavBarContent'
import NavBarAccordion, { NavBarAccordionType } from 'components/Dashboard/NavBar/NavBarAccordion'
import customUnescape from 'utils/customUnescape'

const isLowerCaseLetter = string => /^[a-z]*$/.test(string)

const getLineHeightAccordingToChar = char => {
  if ('jpqyg'.includes(char)) {
    return '2.8rem'
  }
  if ('bdfiklt'.includes(char)) {
    return '3.4rem'
  }

  return isLowerCaseLetter(char) ? '3.1rem' : '3.4rem'
}

export default function OrganizationsAccordion({
  children,
  name,
  isFetching,
  isSubOrg,
}: {
  children: ReactNode
  name?: string | number
  isFetching?: boolean
  isSubOrg?: boolean
}): ReactElement {
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const firstCharacter = `${name}`?.charAt(0)

  return (
    <NavBarAccordion
      type={NavBarAccordionType.ORGANIZATION}
      title={
        <Flex sx={{ width: '100%', flexDirection: 'column' }}>
          {isFetching ? (
            <LoadingSpinner sx={{ height: '2.4rem' }} />
          ) : (
            <TextWithOverFlowAndTippyPopup
              sxText={{
                display: ['inline', 'none', isNavBarExpanded ? 'inline' : 'none'],
                fontSize: '1.6rem',
                fontWeight: 'bold',
                maxWidth: '13rem',
                flexShrink: 0,
              }}
              content={customUnescape(name)}
            />
          )}
        </Flex>
      }
      titleIcon={
        !isFetching ? (
          <Flex
            sx={{
              borderRadius: '100%',
              backgroundColor: 'white15',
              width: '3.2rem',
              height: '3.2rem',
              flexShrink: 0,
              color: 'white',
              justifyContent: 'center',
              position: 'relative',
              fontWeight: 'normal',
              lineHeight: getLineHeightAccordingToChar(firstCharacter),
            }}
          >
            {firstCharacter}
            {!isFetching && !isSubOrg && (
              <Svg
                className="crown-icon"
                data-testid="crown-icon"
                svg={CrownIcon}
                sx={{
                  position: 'absolute',
                  width: '0.8rem',
                  height: '0.8rem',
                  bottom: 0,
                  right: 0,
                }}
              />
            )}
          </Flex>
        ) : (
          <></>
        )
      }
      defaultPath={OrganizationNavRelativePath.MY_ORGANIZATION}
      relativePath={OrganizationNavRelativePath}
    >
      {children}
    </NavBarAccordion>
  )
}
