import React, { ReactElement } from 'react'
import { Box, Text } from 'theme-ui'
import SubOrgImage from 'images/tutorialFlows/SubOrg/sub-org-slide.svg'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'

export function SubOrgSlide(): ReactElement {
  return (
    <TutorialBaseSlide
      Illustration={SubOrgImage}
      description={
        <Box>
          <Text>
            <Highlight svg={tutorialSlideIcons.subOrg}>Sub-Organizations</Highlight> can be used to
            represent customers, departments, physical sites or any other logical grouping of{' '}
            <Highlight svg={tutorialSlideIcons.profiles}>Profiles</Highlight> and{' '}
            <Highlight svg={tutorialSlideIcons.endpoints}>Endpoints</Highlight>. <br /> <br /> A{' '}
            <Highlight svg={tutorialSlideIcons.subOrg}>Sub-Organization</Highlight> can have its own
            Admins, Owners and Viewers, who will not gain access to the parent Organization. <br />{' '}
            <br /> Manage your{' '}
            <Highlight svg={tutorialSlideIcons.subOrg}>Sub-Organization</Highlight> by impersonating{' '}
            it, or by inviting an Admin or Owner.
          </Text>
        </Box>
      }
    />
  )
}
