import React, { CSSProperties, ReactElement } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Svg } from 'ui'
import LogoIcon from 'images/logos/org-homepage.svg'
import { CONTROLDText } from 'ui/CONTROLD'
import { noRedirectHomePagePath, noRedirectParam } from 'layouts'
import { NoRedirectLink } from 'components/NoRedirectLink'
import useDetermineTypeOfHeader from 'utils/useDetermineTypeOfHeader'

export function CompanyLogo({
  sx,
  sxLink,
}: {
  sx?: ThemeUIStyleObject
  sxLink?: CSSProperties
}): ReactElement {
  const { isPersonalHeader } = useDetermineTypeOfHeader()

  return (
    <NoRedirectLink
      testId="organization-homepage-logo"
      aria-label="Go to homepage"
      to={isPersonalHeader ? `/personal${noRedirectParam}` : noRedirectHomePagePath}
      style={{ display: 'flex', flexShrink: 0, textDecoration: 'none', ...sxLink }}
    >
      <Svg svg={LogoIcon} />
      <Flex
        sx={{
          display: ['none', 'none', 'flex'],
          ml: '0.8rem',
          alignItems: 'center',
          color: 'logo',
          textTransform: 'uppercase',
          fontWeight: 800,
          fontSize: '1.8rem',
          ...sx,
        }}
      >
        {CONTROLDText}
      </Flex>
    </NoRedirectLink>
  )
}
