const inputSharedStyles = {
  border: 'hidden',
  p: 0,
  height: '4.4rem',
  borderRadius: 0,
  color: 'text',
}

const inputSecondaryStyles = {
  ...inputSharedStyles,
  bg: 'white15',
  border: 'none',
  height: '4.4rem',
  borderRadius: 0,
}

export default {
  input: {
    primary: {
      ...inputSharedStyles,
      bg: 'transparent',
      borderBottom: '1px solid',
      borderBottomColor: 'white25',
      ':focus': {
        borderBottomColor: 'white',
        '& + label': {
          color: 'white50',
        },
      },
      ':disabled': {
        borderBottomColor: 'white',
      },
      '@keyframes autofill': {
        to: {
          color: ({ colors }): string => colors.text,
          background: 'transparent',
        },
      },
      '&:-webkit-autofill': {
        transitionDelay: '99999s',
        animationName: 'autofill',
        animationFillMode: 'both',
      },
    },
    secondary: {
      ...inputSecondaryStyles,
      p: '1.2rem',
      '& + label': {
        fontWeight: 1,
        mb: 1,
      },
      ':focus': {
        '& + label': {
          color: 'white',
        },
      },
      '&:-webkit-autofill': {
        boxShadow: '0 0 0 3rem #474747 inset !important',
        WebkitTextFillColor: ({ colors }): string => `${colors.white} !important`,
        caretColor: 'white',
      },
    },
    new: {
      ...inputSecondaryStyles,
      p: '1.2rem',
      '& + label': {
        fontSize: ['1.6rem', '1.8rem', '1.8rem'],
        fontWeight: 'normal',
        ml: '1.6rem',
        mb: '0.8rem',
        color: 'white50',
      },
      ':focus': {
        '& + label': {
          color: 'white',
        },
      },
      '&:-webkit-autofill': {
        boxShadow: '0 0 0 10rem #2A2B32 inset !important',
        WebkitTextFillColor: ({ colors }): string => `${colors.white} !important`,
        caretColor: 'white',
      },
    },
    search: {
      ...inputSecondaryStyles,
      p: 0,
      background: 'transparent',
      borderBottom: ({ colors }): string => `1px solid ${colors.accent}`,
      '&:-webkit-autofill': {
        WebkitTextFillColor: 'white',
        transition: 'background-color 2147483647s',
      },
    },
  },
  textarea: {
    primary: {
      font: 'inherit',
      borderRadius: '1.6rem',
      border: '1px solid',
      borderColor: 'white25',
      bg: 'white10',
      p: '1.6rem 2.4rem',
      '::placeholder': {
        color: 'white50',
      },
      ':focus': {
        borderColor: 'white',
        '& + label': {
          color: 'white50',
        },
      },
      ':disabled': {
        borderColor: 'white',
      },
      '@keyframes autofill': {
        to: {
          color: ({ colors }): string => colors.text,
          background: 'white10',
        },
      },
      '&:-webkit-autofill': {
        transitionDelay: '99999s',
        animationName: 'autofill',
        animationFillMode: 'both',
      },
    },
  },
  label: {
    color: 'text',
    fontFamily: 'body',
  },
}
