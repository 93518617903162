import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import { SimpleActionMenuItemProps, Svg, useAlertPresenter } from 'ui'
import Check from 'images/analytics/check.svg'
import SettingsDropdown from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsDropdowns/SettingsDropdown'
import { RegionSettings } from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsState'
import ErrorIcon from 'images/erroricon.svg'
import { useGetStatEndpointsQuery } from 'store/api/analytics'
import useGetUserState from 'store/api/user/useGetUserState'

const alertId = 'disabling-analytics'

interface RegionDropdownProps {
  region?: string
  setRegionSettings: (payload: RegionSettings) => void
  boundaryDataTestId?: string
}
export default function RegionDropdown({
  region,
  setRegionSettings,
  boundaryDataTestId,
}: RegionDropdownProps): ReactElement {
  const { presentAlert, dismissAlert } = useAlertPresenter()
  const hideRef = useRef<() => void>()
  const { data: statsEndpointsData } = useGetStatEndpointsQuery('')
  const { nuke_analytics: nukeAnalytics, region: storageRegion } = useGetUserState()

  useEffect(() => {
    if (storageRegion) {
      setRegionSettings({
        stats_endpoint: storageRegion,
      })
    }
  }, [setRegionSettings, storageRegion])

  useEffect(() => {
    return () => {
      dismissAlert(alertId)
    }
  }, [dismissAlert])

  const editDeviceAnalytics = useCallback(
    async (endpointPK: string, shouldDeleteData?: boolean) => {
      const settings = {
        stats_endpoint: endpointPK,
      }

      if (shouldDeleteData && nukeAnalytics) {
        settings[nukeAnalytics] = 1
      }

      setRegionSettings(settings)
    },
    [nukeAnalytics, setRegionSettings],
  )

  const statOptions: SimpleActionMenuItemProps[] = (statsEndpointsData?.endpoints || []).map(
    endpoint => ({
      isSelected: endpoint.PK === region,
      isBackgroundHighlighted: true,
      buttonContent: <Text>{endpoint.title}</Text>,
      children: (
        <Flex
          sx={{
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Text>{endpoint.title}</Text>
          <Flex>{region === endpoint.PK && <Svg svg={Check}></Svg>}</Flex>
        </Flex>
      ),
      onClick: () => {
        hideRef.current?.()
        if (!storageRegion) {
          editDeviceAnalytics(endpoint.PK)
          return
        }

        presentAlert(
          {
            message: 'You can choose to either keep or delete your analytics data.',
            variant: 'primary',
            isSticky: true,
            icon: ErrorIcon,
            buttons: [
              {
                onClick: () => {
                  editDeviceAnalytics(endpoint.PK)
                  dismissAlert(alertId)
                },
                text: 'Keep Data',
              },
              {
                onClick: () => {
                  editDeviceAnalytics(endpoint.PK, true)
                  dismissAlert(alertId)
                },
                text: 'Delete Permanently',
              },
            ],
          },
          alertId,
        )
      },
    }),
  )

  return (
    <SettingsDropdown
      testId="settings-region-dropdown"
      items={statOptions}
      hideRef={hideRef}
      defaultValue={'Select Region'}
      boundaryDataTestId={boundaryDataTestId}
    />
  )
}
