import React, { KeyboardEventHandler, ReactElement, useCallback, useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { useThemeUI } from 'theme-ui'
import { Setter } from 'utils'
import { isValidEmail } from 'utils/isValidEmail'

const components = {
  DropdownIndicator: null,
}

export interface Option {
  readonly label: string
  readonly value: string
}

const createOption = (label: string) => ({
  label,
  value: label,
})

const getValuesFromClipboardAndCreateOptions = (clipboardData, shouldValidateEmail: boolean) => {
  return (clipboardData?.getData('text') || '')
    .split(/[\n,]+/)
    .map((value: string) => {
      if (shouldValidateEmail && !isValidEmail(value)) {
        return
      }
      return createOption(value)
    })
    .filter(Boolean)
}

export default function MultiSelectInput({
  placeholder,
  value,
  setValue,
  testId,
  ariaLabel,
  options,
  menuIsOpen,
  shouldValidateEmail = false,
}: {
  placeholder?: string
  value?: Option[] | null
  setValue?: Setter<Option[] | null>
  testId?: string
  ariaLabel?: string
  options?: Option[]
  menuIsOpen?: boolean
  shouldValidateEmail?: boolean
}): ReactElement {
  const [inputValue, setInputValue] = useState('')
  const { theme } = useThemeUI()

  const handleOptionCreation = useCallback(
    event => {
      event.preventDefault()

      if (event.type === 'paste') {
        if (event.target.ariaLabel !== ariaLabel) {
          return
        }

        const options = getValuesFromClipboardAndCreateOptions(
          event.clipboardData,
          shouldValidateEmail,
        )

        return setValue?.(prev => {
          if (prev) {
            return [...prev, ...options]
          }

          return [...options]
        })
      }

      setValue?.(prev => {
        if (shouldValidateEmail && !isValidEmail(inputValue)) {
          return prev
        }

        if (prev) {
          return [...prev, createOption(inputValue)]
        }

        return [createOption(inputValue)]
      })

      setInputValue('')
    },
    [ariaLabel, inputValue, setValue, shouldValidateEmail],
  )

  const handleKeyDown: KeyboardEventHandler = event => {
    if (!inputValue) {
      return
    }

    if (event.type === 'blur') {
      handleOptionCreation(event)
    }

    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
        handleOptionCreation(event)
    }
  }

  // onPaste prop doesn't work in the CreatableSelect
  useEffect(() => {
    addEventListener('paste', handleOptionCreation)

    return () => {
      removeEventListener('paste', handleOptionCreation)
    }
  }, [handleOptionCreation])

  const customStyles = {
    control: () => ({
      backgroundColor: theme.colors?.white10,
      color: 'white',
      borderRadius: shouldValidateEmail ? '4.5rem' : '1.6rem',
      padding: shouldValidateEmail ? '1rem 1.6rem' : '1.6rem',
      paddingRight: '0.4rem',
      border: 'none',
    }),
    indicatorsContainer: styles => ({
      ...styles,
      display: 'none',
    }),
    valueContainer: styles => ({
      ...styles,
      padding: 0,
      paddingRight: '0.8rem',
      maxHeight: '10rem',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        position: 'absolute',
        width: '5px',
        backgroundColor: '#222',
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: '3.5px',
        backgroundColor: '#444',
      },
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: theme.colors?.white10,
      padding: shouldValidateEmail ? '0.6rem 0.8rem' : '0.8rem',
      borderRadius: '45px',
    }),
    multiValueLabel: styles => ({
      ...styles,
      fontSize: '1.2rem',
      padding: 0,
      paddingRight: '0.8rem',
      color: 'white',
    }),
    multiValueRemove: styles => ({
      ...styles,
      padding: '0.1rem',
      backgroundColor: theme.colors?.white50,
      borderRadius: '50%',
      color: theme.colors?.darkItemBG,
      ':hover': {
        color: 'white',
        cursor: 'pointer',
      },
      '& > svg': {
        width: '1.6rem',
        height: '1.6rem',
      },
    }),
    placeholder: styles => ({
      ...styles,
      padding: '0.2rem',
      color: theme.colors?.white50,
    }),
    input: styles => ({
      ...styles,
      padding: '0.2rem',
      color: 'white',
    }),
    menu: styles => ({
      ...styles,
      backgroundColor: theme.colors?.darkItemBG,
      borderRadius: '1.6rem',
      border: `1px solid ${theme.colors?.white15}`,
      boxShadow: '0 8px 16px 8px rgba(18, 19, 28, 0.8)',
      padding: '1rem 0.8rem',
    }),
    menuList: styles => ({
      ...styles,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '0.8rem',
      paddingBottom: '0.8rem',
      gap: '0.8rem',
    }),
    option: styles => ({
      ...styles,
      ...theme.text?.size16Weight400Line140,
      cursor: 'pointer',
      borderRadius: '0.8rem',
      border: `1px solid ${theme.colors?.transparent}`,
      padding: '0.8rem 1.6rem',
      color: theme.colors?.white50,
      backgroundColor: theme.colors?.transparent,
      '&:hover': {
        backgroundColor: theme.colors?.white10,
        color: theme.colors?.white,
      },
    }),
  }

  return (
    <CreatableSelect
      aria-label={ariaLabel}
      data-testid={testId}
      styles={customStyles}
      components={components}
      inputValue={inputValue || ''}
      isClearable
      isMulti
      focus={true}
      menuIsOpen={menuIsOpen ? undefined : false}
      onChange={newValue => setValue?.(newValue)}
      onInputChange={newValue => setInputValue(newValue)}
      onBlur={handleKeyDown}
      onKeyDown={handleKeyDown}
      placeholder={placeholder || 'Type something and press enter...'}
      value={value || ''}
      options={options}
      noOptionsMessage={() => null}
      isValidNewOption={(inputValue: string) => {
        if (!shouldValidateEmail) {
          return true
        }

        return isValidEmail(inputValue)
      }}
    />
  )
}
