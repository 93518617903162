import React, { ReactElement, useEffect } from 'react'
import { Button, Flex } from 'theme-ui'
import { Svg } from 'ui'
import CollapseIcon from 'images/dashboard/collapse-icon.svg'
import NavBarContent from 'components/Dashboard/NavBar/NavBarContent'
import NavBarHeader from 'components/Dashboard/NavBar/NavBarHeader'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setIsNavBarExpanded } from 'store/dashboard/dashboard'

export const navBarTabletWidth = '12rem'
const navBarDesktopCollapsedWidth = '14rem'

export default function NavBar(): ReactElement {
  const dispatch = useAppDispatch()
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)

  useEffect(() => {
    return () => {
      dispatch(setIsNavBarExpanded?.(true))
    }
  }, [dispatch])

  return (
    <Flex
      as="nav"
      data-testid="nav-bar"
      sx={{
        position: 'relative',
        zIndex: 'zIndex300',
        width: [0, navBarTabletWidth, isNavBarExpanded ? '33rem' : navBarDesktopCollapsedWidth],
        transition: 'width 0.3s',
        flexShrink: 0,
      }}
    >
      <Flex
        sx={{
          position: 'fixed',
          width: 'fit-content',
          height: '100%',
          flexShrink: 0,
          pl: ['1.6rem', '1.6rem', '2.4rem'],
          zIndex: 'zIndex240',
        }}
      >
        <Flex
          data-testid="nav-menu-left"
          sx={{
            width: ['10rem', '4.8rem', isNavBarExpanded ? '25.6rem' : '4.8rem'],
            pt: '2.4rem',
            flexDirection: 'column',
            transition: 'width 0.3s',
          }}
        >
          <NavBarHeader />
          <NavBarContent />
        </Flex>
        <Flex
          sx={{
            position: 'relative',
            mx: ['2.4rem', '2.4rem', '3.2rem'],
            width: '1px',
            height: '100%',
            backgroundColor: 'white10',
            ':hover': {
              width: '2px',
              transitionDelay: '0.25s',
              '.divider-button': {
                transitionDelay: '0.25s',
                opacity: 1,
              },
            },
            transition: 'all 100ms ease-in-out',
          }}
        >
          <Flex
            sx={{
              height: '100%',
              minWidth: '3.2rem',
              right: '-1.6rem',
              position: 'absolute',
              display: ['none', 'none', 'flex'],
              cursor: 'pointer',
            }}
            onClick={() => {
              dispatch(setIsNavBarExpanded?.(!isNavBarExpanded))
            }}
          >
            <Button
              variant="simple"
              data-testid="divider-button"
              aria-label={isNavBarExpanded ? 'collapse nav bar' : 'expand nav bar'}
              className="divider-button"
              sx={{
                opacity: 0,
                position: 'absolute',
                top: '50%',
                right: 0,
                width: '3.2rem',
                height: '3.2rem',
                p: 0,
                borderRadius: '50%',
                transform: `rotate(${isNavBarExpanded ? 0 : '180deg'})`,
                backgroundColor: 'darkBodyBG',
              }}
            >
              <Svg svg={CollapseIcon} fill="white" sx={{ flexShrink: 0 }} />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
