import React, { PropsWithChildren, ReactElement, ReactNode } from 'react'
import { Button, Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { WarningMessage } from 'components/WarningMessage'
import { MobilesHeader } from 'components/HomePage/Header/MobilesHeader'
import { DesktopHeader } from 'components/HomePage/Header/DesktopHeader'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { navigate } from 'gatsby'
import { noRedirectHomePagePath } from 'layouts'
import useDetermineTypeOfHeader from 'utils/useDetermineTypeOfHeader'
import { useAppSelector } from 'store/hooks'
import { CompanyLogo } from 'components/HomePage/Header/CompanyLogo'
import { GetStartedDropdown } from 'components/HomePage/Header'
import { getStarted } from 'components/HomePage/Header/menus'
import VerticalDivider from 'ui/VerticalDivider'
import { NoRedirectLink } from '../NoRedirectLink'
import { Svg } from 'ui'
import ButtonArrow from 'images/button-arrow-right.svg'

export const orgStaticPageHeaderHeight = ['7.2rem', '6.9rem', '6.9rem']

type OrgStaticPageHeaderProps = {
  setIsMobileNavMenuOpen?: (isOpen: boolean) => void
  shouldUseMainHeader?: boolean
  children?: ReactNode
  testId?: string
}

export function StaticPageHeader({
  setIsMobileNavMenuOpen,
  shouldUseMainHeader,
  children,
  testId,
}: PropsWithChildren<OrgStaticPageHeaderProps>): ReactElement {
  return (
    <Flex
      data-testid={testId || 'static-page-header'}
      sx={{
        position: 'sticky',
        top: 0,
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        minHeight: orgStaticPageHeaderHeight,
        left: 0,
        zIndex: 'zIndex240',
        backgroundColor: 'blue800',
        borderBottom: '1px solid',
        borderBottomColor: 'borderFaint',
      }}
    >
      <WarningMessage />
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          px: '1.6rem',
          alignItems: 'center',
        }}
      >
        {shouldUseMainHeader ? (
          <MainHeader setIsMobileNavMenuOpen={setIsMobileNavMenuOpen} />
        ) : (
          <Flex sx={{ width: '100%', alignItems: 'center' }}>
            <Flex sx={{ flex: 1 }}>
              <CompanyLogo />
            </Flex>
            <Flex
              sx={{
                flex: 2,
                justifyContent: 'flex-end',
              }}
            >
              {children || <GetStartedHeader />}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

function GetStartedHeader(): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: ['0.8rem', '1.6rem'],
      }}
    >
      <GetStartedDropdown dropdownItems={getStarted}>Get Started</GetStartedDropdown>
    </Flex>
  )
}

function MainHeader({
  setIsMobileNavMenuOpen,
}: {
  setIsMobileNavMenuOpen?: (isOpen: boolean) => void
}): ReactElement {
  const isMobileOrTablet = useBreakpointIndex() < 2

  return isMobileOrTablet ? (
    <Flex
      data-testid="org-mobile-header"
      sx={{
        width: '100%',
        display: ['flex', 'flex', 'none'],
        height: '7.2rem',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <MobilesHeader setIsMobileNavMenuOpen={setIsMobileNavMenuOpen} />
    </Flex>
  ) : (
    <Flex
      data-testid="org-desktop-header"
      sx={{
        width: '100%',
        display: ['none', 'none', 'flex'],
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '1.6rem',
      }}
    >
      <DesktopHeader />
    </Flex>
  )
}

export function OrgAndPersonalHeaderButtons(): ReactElement {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { isPersonalHeader } = useDetermineTypeOfHeader()

  return (
    <Flex sx={{ gap: '0.8rem' }}>
      <Button
        name="button"
        aria-label="go to organization page"
        data-testid="go-to-org-button"
        variant="roundedSimple"
        sx={{
          border: '1px solid',
          borderColor: !isPersonalHeader ? 'lightSteelBlue' : 'transparent',
          flexShrink: 0,
          px: '1.2rem',
          py: '0.8rem',
        }}
        onClick={() => {
          navigate(sessionToken ? `${noRedirectHomePagePath}` : '/')
        }}
      >
        Organization
      </Button>
      <Button
        name="button"
        aria-label="go to personal page"
        data-testid="go-to-personal-button"
        variant="roundedSimple"
        sx={{
          border: '1px solid',
          borderColor: isPersonalHeader ? 'lightSteelBlue' : 'transparent',
          flexShrink: 0,
          px: '1.2rem',
          py: '0.8rem',
        }}
        onClick={() => {
          navigate(sessionToken ? `/personal${noRedirectHomePagePath}` : `/personal`)
        }}
      >
        Personal
      </Button>
    </Flex>
  )
}

export function PersonalLink({
  sx,
  text,
  textColor,
  directTo,
  isPersonalLinkVisible,
}: {
  sx?: ThemeUIStyleObject
  text?: string
  textColor?: string
  directTo?: string
  isPersonalLinkVisible?: boolean
}): ReactElement | null {
  return (
    <Flex sx={sx}>
      {isPersonalLinkVisible && (
        <VerticalDivider
          sx={{
            width: '1px',
            height: '100%',
            color: 'white10',
            backgroundColor: 'white10',
            flexShrink: 0,
          }}
        />
      )}
      <NoRedirectLink
        testId="business-use-link"
        key="business-use"
        to={directTo || '/?noRedirect=true'}
        style={{
          fontSize: '1.8rem',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}
      >
        <Text
          sx={{
            color: textColor,
            fontSize: '1.7rem',
            fontStyle: 'normal',
            fontWeight: 500,
            ':hover': {
              color: 'white',
              'svg path': {
                fill: 'white',
              },
            },
          }}
        >
          {text}
          <Svg
            fill={textColor || 'white50'}
            svg={ButtonArrow}
            sx={{
              ml: '0.6rem',
            }}
          />
        </Text>
      </NoRedirectLink>
    </Flex>
  )
}
