import React, { ReactNode } from 'react'
import Overlay from './Overlay'
import { Flex } from 'theme-ui'

export default function Popup({
  header,
  children,
}: {
  header?: ReactNode
  children?: ReactNode
}): React.ReactElement {
  return (
    <>
      <Overlay />
      <Flex
        sx={{
          position: 'fixed',
          px: '1.6rem',
          zIndex: 'zIndex601',
        }}
      >
        <Flex
          sx={{
            width: ['100%', '67.2rem'],
            border: '1px solid',
            borderColor: 'white10',
            borderRadius: '32px',
            backgroundColor: 'darkItemBG',
            flexDirection: 'column',
          }}
        >
          <Flex
            sx={{
              width: '100%',
              height: '8rem',
              backgroundColor: 'white5',
              boxShadow:
                '0 4px 4px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 0 rgba(255, 255, 255, 0.1)',
              borderTopRightRadius: '32px',
              borderTopLeftRadius: '32px',
            }}
          >
            {header}
          </Flex>
          <Flex
            sx={{
              flex: 1,
              borderBottomRightRadius: '32px',
              borderBottomLeftRadius: '32px',
              py: '4.8rem',
              px: '5.4rem',
            }}
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
