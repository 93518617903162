import React, { ReactElement, useEffect, RefObject, useState, useLayoutEffect } from 'react'
import { AlertPresenter, ExternalLink, useAlertPresenter } from 'ui'
import CautionIcon from 'images/error-msg-icon.svg'
import { useLocation } from '@reach/router'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { clearErrors, handleError } from 'store/errors'
import { ControlDError } from 'store/api/http'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { Text } from 'theme-ui'
import useQueryString from 'utils/useQueryString'
import { ERROR_DUPLICATE_RESOURCE } from 'store/api/errorCodes'
import customUnescape from 'utils/customUnescape'

export const confirmLogoutAlertId = 'confirmLogout'
export const confirmDeleteAnalyticsId = 'clearAnalyticsData'

const errorMessageWithLearnMoreLink = 'Start time cannot be after end time.'
const scheduleLearnMoreLink = `${DOCS_CONTROL_D_DOMAIN}/docs/block-internet-on-a-schedule#overnight-schedules`

interface DashboardAlertsProps {
  resizeableAlertContainerRef: RefObject<HTMLElement>
  hideTray?: () => void
}
export default function DashboardAlerts({
  resizeableAlertContainerRef,
  hideTray,
}: DashboardAlertsProps): ReactElement | null {
  const { presentAlert, dismissAlert, dismissAllAlerts } = useAlertPresenter()
  const errors = useAppSelector(s => s.errors.errors)
  const [isVisibleAlert, setIsAlertVisible] = useState(true)
  const [lastError] = errors.slice(-1) as ControlDError[]
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { relocate } = useQueryString()

  useLayoutEffect(() => {
    // should prevent alert from being displayed until the errors are cleared
    setIsAlertVisible(false)
  }, [])

  // dismiss logout alert on back click
  useEffect(() => {
    dismissAlert(confirmLogoutAlertId)
  }, [dismissAlert, location])

  useEffect(() => {
    dispatch(clearErrors())
  }, [dispatch])

  useLayoutEffect(() => {
    if (!errors.length) {
      dismissAllAlerts()
      setIsAlertVisible(true)
    }
  }, [dismissAllAlerts, errors])

  useEffect(() => {
    if (lastError && isVisibleAlert) {
      let parsed
      try {
        parsed = lastError.actions ? lastError : JSON.parse(lastError?.stack ?? '')
      } catch (err) {
        parsed = {
          actions: [],
          alertDuration: 10000,
        }
      }

      presentAlert(
        {
          message: (
            <Text>
              {customUnescape(lastError.message)}
              {lastError?.message?.includes(errorMessageWithLearnMoreLink) &&
                lastError.code === ERROR_DUPLICATE_RESOURCE && (
                  <ExternalLink
                    to={scheduleLearnMoreLink}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      ml: '0.4rem',
                    }}
                  >
                    Learn more
                  </ExternalLink>
                )}
            </Text>
          ),
          ...(parsed.alertDuration ? { timeout: parsed.alertDuration } : { isSticky: true }),
          icon: CautionIcon,
          buttons: [
            ...parsed.actions.map(x => ({
              onClick: async (): Promise<void> => {
                await relocate(x.path)
                location.pathname.includes('custom-rules') && hideTray?.()

                lastError.message && dismissAlert(lastError.message)
                dispatch(handleError(lastError))
              },
              text: x.name,
            })),
            {
              onClick: (): void => {
                lastError.message && dismissAlert(lastError.message)
                dispatch(handleError(lastError))
              },
              text: 'Okay',
            },
          ],
        },
        lastError.message,
      )
      // since the alert for the error has been shown , we should remove it from the global errors
      if (parsed.alertDuration) {
        setTimeout(() => {
          dispatch(handleError(lastError))
        }, parsed.alertDuration)
      }
    }
  }, [
    dismissAlert,
    presentAlert,
    hideTray,
    location.pathname,
    dispatch,
    lastError,
    isVisibleAlert,
    relocate,
  ])

  return isVisibleAlert ? <AlertPresenter containerRef={resizeableAlertContainerRef} /> : null
}
