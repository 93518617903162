import React, { useState, ReactElement, useEffect } from 'react'
import { Svg } from 'ui'
import { Flex, Button, ThemeUIStyleObject, Text, Spinner } from 'theme-ui'
import CheckmarkIcon from 'images/checkmark.svg'
import { PromoMode } from 'store/api/billing'
import { clearError, setPromoCode, setPromoMode } from 'store/billing'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { resetTrackingData } from 'store/tracking'
import CloseButton from 'components/Plans/SelectPlan/PromoCode/CloseButton'
import PromoCodeForm from 'components/Plans/SelectPlan/PromoCode/PromoCodeForm'
import { LoadingState } from 'store/fetchingLogic'
import { useGetProductsQuery, useLazyGetProductsQuery } from 'store/api/billing/products'

export interface PromoCodeContainerProps {
  tabsContainerSx?: ThemeUIStyleObject
  inputSx?: ThemeUIStyleObject
  isMobileView?: boolean
}

const ApplyPromoCodeButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="simple"
    data-testid="open-promo-code-form"
    sx={{
      border: 'none',
      p: 0,
      color: 'greenApple',
      fontSize: '1.8rem',
      fontWeight: 'bold',
      '&:hover': {
        color: 'white',
        '& svg path': { fill: 'white' },
      },
    }}
    onClick={onClick}
  >
    Apply Promo Code
  </Button>
)

const PromoCodeApplied = ({
  promoCode,
  resetPromo,
}: {
  promoCode: string
  resetPromo: () => Promise<void>
}) => (
  <Flex
    sx={{
      alignItems: 'center',
      justifyContent: 'space-around',
      p: '0.8rem',
      border: '2px solid',
      borderColor: 'white15',
      borderRadius: '30px',
      gap: '0.8rem',
      flex: '1 0 auto',
      flexFlow: 'row wrap',
    }}
  >
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', gap: '0.4rem' }}>
      <Svg svg={CheckmarkIcon} fill="greenApple" width="2.4rem" height="2.4rem" />
      <Text
        sx={{
          whiteSpace: 'nowrap',
          color: 'greenApple',
          fontSize: '1.8rem',
        }}
      >
        Code Applied:
      </Text>
    </Flex>
    <Text
      sx={{
        fontWeight: 'bold',
        color: 'greenApple',
        fontSize: '1.8rem',
      }}
    >
      {promoCode}
    </Text>
    <CloseButton data-testid="promo-code-close-button" onClick={resetPromo} />
  </Flex>
)
export default function PromoCodeContainer({
  tabsContainerSx,
  inputSx,
  isMobileView = false,
}: PromoCodeContainerProps): ReactElement {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const {
    promoMode,
    promoCode,
    error: promoCodeError,
    data: productData,
  } = useAppSelector(s => s.products)

  const { loading } = useAppSelector(s => s.products)
  const trackingPromoCode = useAppSelector(s => s.tracking.trackingPromoCode)
  const [getProducts] = useLazyGetProductsQuery()
  useGetProductsQuery({ promoMode, promoCode: trackingPromoCode }, { skip: !trackingPromoCode })

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    if (trackingPromoCode) {
      dispatch(setPromoCode(trackingPromoCode))
      dispatch(resetTrackingData())
    }
  }, [trackingPromoCode, dispatch, promoMode])

  if (loading === LoadingState.PENDING) {
    return <Spinner color="greenApple" />
  }

  const isPromoCodeApplied =
    productData?.[0].price_points?.[0].type === 'promo' && promoCode && !promoCodeError

  return (
    <Flex sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {!isOpen && !isPromoCodeApplied && (
        <ApplyPromoCodeButton
          onClick={() => {
            setIsOpen(true)
            dispatch(setPromoMode(PromoMode.PROMO))
          }}
        />
      )}
      {isPromoCodeApplied && (
        <PromoCodeApplied
          promoCode={promoCode}
          resetPromo={async () => {
            dispatch(setPromoCode(''))
            dispatch(setPromoMode(PromoMode.PROMO))
            setIsOpen(false)
            await getProducts({})
          }}
        />
      )}
      {(isOpen || promoCodeError) && !isPromoCodeApplied && (
        <PromoCodeForm
          tabsContainerSx={tabsContainerSx}
          isMobileView={isMobileView}
          promoMode={promoMode}
          inputSx={inputSx}
          promoCodeError={promoCodeError}
          closeForm={() => {
            setIsOpen(false)
          }}
        />
      )}
    </Flex>
  )
}
