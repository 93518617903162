import React, { ReactElement, useEffect } from 'react'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import './date-time-picker.scss'
import { DatePicker as DP, DatePickerShortcut } from '@blueprintjs/datetime'

export interface DateTimePickerProps {
  date: Date | null
  onDateChange?: (date: Date) => void
  minDate?: Date
  maxDate?: Date
  shortcuts?: boolean | DatePickerShortcut[]
  onShortcutChange?: (shortcut: DatePickerShortcut, index: number) => void
  selectedShortcutIndex?: number
}
export default function DateTimePicker({
  onDateChange,
  date,
  minDate,
  maxDate,
  shortcuts = false,
  onShortcutChange,
  selectedShortcutIndex,
}: DateTimePickerProps): ReactElement {
  useEffect(() => {
    // getting rid of useless tab points
    // type Element doesn't have tabIndex
    const dayPickerWrapper = document.querySelector('.DayPicker-wrapper')
    if (dayPickerWrapper) {
      dayPickerWrapper['tabIndex'] = -1
    }
    const shortcutsWrapper = document.querySelector('.bp3-daterangepicker-shortcuts')
    if (shortcutsWrapper) {
      shortcutsWrapper['tabIndex'] = -1
    }

    // injecting for Enter key accessibility
    const eventsCallbacks: EventListener[] = []
    shortcutsWrapper?.childNodes.forEach(shortcut => {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.code == 'Enter') {
          const target = e.target as HTMLElement
          target.click()
        }
      }
      eventsCallbacks.push(onKeyDown as EventListener)
      shortcut.childNodes[0].addEventListener('keydown', onKeyDown as EventListener)
    })

    return () => {
      eventsCallbacks.forEach((eventCallback, i) => {
        shortcutsWrapper?.childNodes[i].childNodes[0].removeEventListener('keydown', eventCallback)
      })
    }
  }, [])

  return (
    <DP
      className="datepicker"
      onChange={onDateChange}
      value={date}
      highlightCurrentDay={false}
      canClearSelection={false}
      timePickerProps={{
        precision: 'minute',
        value: date,
        className: 'timepicker',
      }}
      minDate={minDate}
      maxDate={maxDate}
      shortcuts={shortcuts}
      onShortcutChange={onShortcutChange}
      selectedShortcutIndex={selectedShortcutIndex}
    />
  )
}
