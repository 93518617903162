import React from 'react'
import { Box } from 'theme-ui'
import { TableRowProps } from '../index'
import omit from 'lodash/omit'

export default function TableRow(props: TableRowProps): React.ReactElement {
  const { children, sx = {}, ariaLive, ...attrs } = props

  return (
    <Box
      aria-live={ariaLive}
      variant="tables.row"
      as="tr"
      sx={{ ...sx, pl: '2.4rem' }}
      {...omit(attrs, 'infoText')}
    >
      {children}
    </Box>
  )
}
