import React, { ElementType, ReactElement, ReactNode } from 'react'
import { Box, Flex, Text, Spinner, ThemeUIStyleObject } from 'theme-ui'
import { Button, Svg } from 'ui'
import { vibrate } from 'utils'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import {
  TrackingEventAction,
  TrackingEventCategory,
  useTrackingHelper,
} from 'utils/useTrackingHelper'
import { useLocation } from '@reach/router'
import { setIsMobileNavMenuOpen } from 'store/dashboard/dashboard'
import useQueryString from 'utils/useQueryString'
import isNumber from 'lodash/isNumber'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export interface NavMenuItemProps {
  label: string
  activeIcon: ElementType
  inactiveIcon?: ElementType
  relativePath?: string
  onClick?: () => void
  as?: string | ReactNode
  isNavBarExpanded?: boolean
  sx?: ThemeUIStyleObject
  count?: number
  isLoading?: boolean
  specificItemAction?: () => void
}

const NavMenuItem = (props: NavMenuItemProps): ReactElement => {
  const {
    label,
    activeIcon,
    inactiveIcon,
    relativePath,
    sx,
    count,
    onClick = (): void => {},
    isLoading,
    specificItemAction,
  } = props

  const isMobile = useBreakpointIndex() === 0
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { trackEvent } = useTrackingHelper()
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  /**
   * using relocate to preserve the query string when navigating to a new page
   * this is needed because the orgId is stored in the query string
   */
  const { relocate } = useQueryString()
  const isActive =
    relativePath &&
    [relativePath, `${relativePath}/`].some(path => location.pathname.endsWith(path))
  const isCountVisible = isNumber(count)
  const { data: userData } = useGetUser()

  return (
    <Box sx={{ ...sx, px: 0 }}>
      <Button
        variant="transparent"
        data-testid={`side-nav-link-${relativePath}`}
        tabIndex={!isMobile ? 0 : -1}
        onClick={(event): void => {
          specificItemAction?.()

          if (userData?.haptics) {
            vibrate(20)
          }

          if (relativePath) {
            trackEvent({
              category: TrackingEventCategory.Nav,
              action: relativePath.split('-')[0] as TrackingEventAction,
            })
            if (event.ctrlKey || event.metaKey) {
              const href = location.href.slice(0, location.href.lastIndexOf('/'))
              window.open(
                `${href}/${relativePath}${location.search}`,
                '_blank',
                'noopener, noreferrer',
              )
            } else {
              relocate(`/dashboard/${relativePath}`)
            }
            dispatch(setIsMobileNavMenuOpen?.(false))
          } else {
            event.preventDefault()
            onClick()
          }
        }}
        sx={{
          width: '100%',
          flexDirection: ['row', 'column', isNavBarExpanded ? 'row' : 'column'],
          textDecoration: 'none',
          cursor: 'pointer',
          fontWeight: 'bold',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: isActive ? 'greenApple' : 'white50',
          pl: ['1.6rem', 0, isNavBarExpanded ? '1.6rem' : 0],
          pr: ['2.4rem', 0, isNavBarExpanded ? '2.4rem' : 0],
          pt: '1.2rem',
          pb: [
            '1.2rem',
            isCountVisible || isLoading ? '0.4rem' : '1.2rem',
            isNavBarExpanded ? '1.2rem' : isCountVisible || isLoading ? '0.4rem' : '1.2rem',
          ],
          ml: 0,
          boxShadow: isActive
            ? ({ colors }): string => `inset -3px 0 0 0 ${colors?.greenApple}`
            : 'none',
          borderRadius: '45px',
          backgroundColor: isActive ? 'greenApple15' : 'none',
          ...(!isActive && {
            ':hover path': {
              fill: 'white',
              opacity: 1,
            },
            ':hover': {
              color: 'white',
              '.nav-item-label': {
                color: 'white',
                opacity: 1,
              },
            },
          }),
        }}
      >
        <Flex
          sx={{
            justifyContent: ['left', 'center', isNavBarExpanded ? 'left' : 'center'],
          }}
        >
          {activeIcon && (
            <Svg
              svg={isActive ? activeIcon : inactiveIcon || activeIcon}
              data-testid="item"
              fill={isActive ? 'greenApple' : 'white50'}
              sx={{
                width: '2.4rem',
                height: '2.4rem',
                flexShrink: 0,
              }}
            />
          )}
          <Text
            className="nav-item-label"
            sx={{
              display: ['inherit', 'none', isNavBarExpanded ? 'inherit' : 'none'],
              whiteSpace: 'nowrap',
              color: isActive ? 'greenApple' : 'white50',
              fontSize: '1.8rem',
              fontWeight: 'bold',
              ml: ['1.6rem', 0, isNavBarExpanded ? '1.6rem' : 0],
            }}
          >
            {label}
          </Text>
        </Flex>
        <Flex
          sx={{
            alignItems: 'center',
            height: isLoading || isCountVisible ? '2.8rem' : 0,
            overflow: 'hidden',
          }}
        >
          {isLoading ? (
            <Spinner
              data-testid="side-nav-spinner"
              color="greenApple"
              sx={{
                width: '1.8rem',
                height: '1.8rem',
              }}
            />
          ) : (
            <Text
              sx={{
                fontSize: '1.8rem',
                fontWeight: 'normal',
              }}
            >
              {count}
            </Text>
          )}
        </Flex>
      </Button>
    </Box>
  )
}

export default NavMenuItem
