import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import { ApiResponse } from 'store/api/http'
import {
  detectConfiguredDevice,
  setConfigureDevice,
  setIsDeviceStatusVerified,
} from 'store/multiprofile'
import { V4_CHECK_URL, VERIFY_DOMAIN } from 'gatsby-env-variables'
import {
  ApiResponseClient,
  DeviceClient,
  DeviceClientsInfoResponse,
  DeviceGroup,
  DeviceInfo,
  DeviceInfoResponse,
  DeviceSettings,
  DeviceTypesResponse,
  ModifyDevice,
  NewDevice,
} from './devices.interface'
import { DetectionInfo } from '../configurationStatus'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import { current } from '@reduxjs/toolkit'
import { setIsOnboardingCompleted } from 'store/session'
import { ResolverStatus } from 'store/api/user/user.interface'

const scrubProfilesInDevice = (device: DeviceInfo): DeviceInfo => {
  const scrubbedDevice = {
    ...device,
    profile_id: device.profile_id?.toString(),
    profile_id2: device.profile_id2?.toString(),
  }
  if (scrubbedDevice.profile?.PK) {
    scrubbedDevice.profile = { ...scrubbedDevice.profile, PK: scrubbedDevice.profile.PK.toString() }
  }
  if (scrubbedDevice.profile2?.PK) {
    scrubbedDevice.profile2 = {
      ...scrubbedDevice.profile2,
      PK: scrubbedDevice.profile2.PK.toString(),
    }
  }
  return scrubbedDevice
}

const getClientsMapToArray = (
  clients?: ApiResponseClient | undefined,
): DeviceClient[] | undefined => {
  return clients
    ? Object.entries(clients).map(([id, value]) => ({ id, ...value } as DeviceClient))
    : clients
}

const getScrubbedDevice = device => {
  return {
    ...scrubProfilesInDevice(omit(device, ['clients']) as DeviceInfo),
    clients: getClientsMapToArray(device.clients) as DeviceClient[],
  }
}

export const devicesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getDevices: builder.query({
      query: () => getQueryArgs('/devices'),
      transformResponse: (response: ApiResponse<{ devices: DeviceInfo<DeviceClient[]>[] }>) => {
        response.body.devices = response.body?.devices.map(device => {
          return getScrubbedDevice(device)
        })

        return response.body
      },
      transformErrorResponse,
      providesTags: () => ['Devices'],
    }),
    getDevice: builder.query({
      query: (deviceId?: string) => {
        return getQueryArgs(`/devices/${deviceId}`)
      },
      transformResponse: (response: ApiResponse<DeviceInfoResponse>) => response.body,
      transformErrorResponse,
      async onQueryStarted(type, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              const device = data.devices[0]
              const scrubbedDevice = getScrubbedDevice(device)

              const index = draft.devices.findIndex(d => d.PK === device.PK)

              if (
                draft.devices[index].status === ResolverStatus.UNVERIFIED &&
                scrubbedDevice.status === ResolverStatus.VERIFIED
              ) {
                dispatch(setIsDeviceStatusVerified(true))
              }

              draft.devices.splice(index, 1, scrubbedDevice)
            }),
          )
        } catch {}
      },
    }),
    getDeviceClients: builder.query({
      query: (deviceId?: string) => {
        return getQueryArgs(`/devices/${deviceId}/clients`)
      },
      transformResponse: (response: ApiResponse<DeviceClientsInfoResponse>) => response.body,
      transformErrorResponse,
      async onQueryStarted(deviceId, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              const clientsToSet = getClientsMapToArray(data?.clients) ?? []

              const index = draft.devices.findIndex(d => d.device_id === deviceId)

              draft.devices[index] =
                clientsToSet.length > 0
                  ? { ...draft.devices[index], clients: clientsToSet }
                  : omit(draft.devices[index], ['clients'])
            }),
          )
        } catch {}
      },
    }),
    putDevice: builder.mutation({
      query: ({ PK, device }: { PK: string; device: ModifyDevice }) =>
        getQueryArgs(
          `${device.legacy_ipv4_status ? V4_CHECK_URL : ''}/devices/${PK}`,
          'PUT',
          device,
        ),
      transformResponse: (response: ApiResponse<DeviceInfo<ApiResponseClient>>) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      async onQueryStarted(deviceId, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              const modifiedDevice = getScrubbedDevice(data)

              const index = draft.devices.findIndex(d => d.PK === modifiedDevice.PK)
              draft.devices[index] = {
                ...modifiedDevice,
                last_activity: draft.devices[index]?.last_activity,
              }
            }),
          )
        } catch {}
      },
    }),
    postDevice: builder.mutation({
      query: ({ newDevice }: { newDevice: NewDevice }) =>
        getQueryArgs(
          `${newDevice.legacy_ipv4_status ? V4_CHECK_URL : ''}/devices`,
          'POST',
          newDevice,
        ),
      transformResponse: (response: ApiResponse<DeviceInfo>) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      async onQueryStarted({}, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              draft.devices = sortBy(
                [...draft.devices, scrubProfilesInDevice(data)],
                device => `${device.name}`,
              )
            }),
          )
        } catch {}
      },
    }),
    deleteDevice: builder.mutation({
      query: ({ pk }: { pk: string }) => getQueryArgs(`/devices/${pk}`, 'DELETE'),
      transformResponse: (response: ApiResponse<[]>) => response.body,
      transformErrorResponse,
      async onQueryStarted({ pk }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              draft.devices = draft.devices.filter(d => d.PK !== pk)
            }),
          )
        } catch {}
      },
    }),
    detectDevice: builder.query({
      query: () => {
        const randomSubDomain = Math.random().toString(36).substr(2, 12)

        return getQueryArgs(`https://${randomSubDomain}.${VERIFY_DOMAIN}/detect`, 'GET')
      },
      transformResponse: (response: ApiResponse<DetectionInfo>) => {
        return response.body
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              const configuredDevice = draft.devices.find(d => d.PK === data.uid)
              if (configuredDevice) {
                configuredDevice.status = ResolverStatus.VERIFIED
                dispatch(setConfigureDevice(current(configuredDevice)))
              }

              dispatch(setIsOnboardingCompleted(true))
              dispatch(detectConfiguredDevice(false))
            }),
          )
        } catch {
          dispatch(setConfigureDevice(undefined))
        }
      },
    }),
    getDevicesTypes: builder.query({
      query: () => getQueryArgs('/devices/types/new', 'GET'),
      transformResponse: (
        response: ApiResponse<{
          types: {
            group: DeviceGroup
            icons: DeviceSettings[]
            name: string
          }[]
        }>,
      ) => {
        response.body.types = Object.entries(
          (response.body.types ?? {}) as DeviceTypesResponse,
        ).map(([id, value]) => ({
          group: id as DeviceGroup,
          ...value,
          //@ts-ignore
          icons: Object.entries(value.icons).map(([id, v]) => ({ type: id, ...v })),
        }))

        return response.body
      },
      transformErrorResponse,
    }),
    deleteDeviceClient: builder.mutation({
      query: ({ deviceId, clientId }: { deviceId: string; clientId: string }) => {
        return getQueryArgs(`/devices/${deviceId}/${clientId}`, 'DELETE')
      },
      transformResponse: (response: ApiResponse<[]>) => response.body,
      transformErrorResponse,
      async onQueryStarted({ deviceId, clientId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            devicesApi.util.updateQueryData('getDevices', '', draft => {
              const device = draft?.devices.find(d => d.PK === deviceId)

              if (device) {
                device.clients = device?.clients?.filter(c => c.id !== clientId)
              }
            }),
          )
        } catch {}
      },
    }),
  }),
})

export const {
  endpoints,
  useGetDevicesQuery,
  usePutDeviceMutation,
  usePostDeviceMutation,
  useDeleteDeviceMutation,
  useDeleteDeviceClientMutation,
  useGetDevicesTypesQuery,
  useGetDeviceClientsQuery,
  useDetectDeviceQuery,
} = devicesApi
