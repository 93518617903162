import React, { ReactElement } from 'react'
import TrayHeader from 'components/TrayHeader'
import { Text } from 'theme-ui'

export default function Header({
  title,
  leftButton,
}: {
  title: string
  leftButton: ReactElement
}): ReactElement {
  return (
    <TrayHeader
      sx={{
        width: '100%',
        p: 0,
        height: '7.8rem',
        alignItems: 'center',
      }}
      leftComponent={leftButton}
    >
      <Text sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{title}</Text>
    </TrayHeader>
  )
}
