import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import useQueryString from 'utils/useQueryString'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useTutorialTray, { TutorialSlideData } from './useTutorialTray'
import { useTrayPresenter } from 'ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import TutorialModalDialog from 'components/Dashboard/Onboarding/TutorialModalDialog'
import { setHasTutorialBeenViewed, TutorialType } from 'store/tutorial/tutorial'
import {
  activityLogTutorialSlides,
  adminLogTutorialSlides,
  customRulesTutorialSlides,
  endpointsTutorialSlides,
  filtersTutorialSlides,
  myOrgTutorialSlides,
  orgBillingTutorialSlides,
  profileOptionsTutorialSlides,
  profilesTutorialSlides,
  provisionTutorialSlides,
  reportsTutorialSlides,
  servicesTutorialSlides,
  statisticsTutorialSlides,
  subOrgTutorialSlides,
} from './TutorialSlides'
import omit from 'lodash/omit'
import { LearnMoreLink } from '../IPs/AutomaticIPUpdateSection'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function DashboardTutorial({
  tutorialType = '',
}: {
  tutorialType?: TutorialType | ''
}): ReactElement | null {
  const { qs, nav } = useQueryString()
  const currentSlideIndex = +(qs.slide ?? 0) || 0
  const dispatch = useAppDispatch()
  const isMobile = useBreakpointIndex() === 0
  const { data: userData } = useGetUser()
  const userPk = userData?.PK || ''

  const wasTutorialDenied = useAppSelector(s => {
    return s.tutorial.viewedStateByUserPk[userPk ?? '']?.wasTutorialDenied
  })
  const tutorialSlides: TutorialSlideData[] = useMemo(() => {
    switch (tutorialType) {
      case TutorialType.FILTERS:
        return filtersTutorialSlides
      case TutorialType.SERVICES:
        return servicesTutorialSlides
      case TutorialType.CUSTOM_RULES:
        return customRulesTutorialSlides
      case TutorialType.PROFILES:
        return profilesTutorialSlides
      case TutorialType.ENDPOINTS:
        return endpointsTutorialSlides
      case TutorialType.PROVISION:
        return provisionTutorialSlides
      case TutorialType.SUB_ORGS:
        return subOrgTutorialSlides
      case TutorialType.MY_ORG:
        return myOrgTutorialSlides
      case TutorialType.ORG_BILLING:
        return orgBillingTutorialSlides
      case TutorialType.PROFILE_OPTIONS:
        return profileOptionsTutorialSlides
      case TutorialType.STATISTICS:
        return statisticsTutorialSlides
      case TutorialType.ACTIVITY_LOG:
        return activityLogTutorialSlides
      case TutorialType.ADMIN_LOGS:
        return adminLogTutorialSlides
      case TutorialType.REPORTS:
        return reportsTutorialSlides
      default:
        return filtersTutorialSlides
    }
  }, [tutorialType])

  const title = tutorialSlides?.[currentSlideIndex]?.title || ''

  const handleFinish = useCallback(async () => {
    dispatch(
      setHasTutorialBeenViewed({
        tutorialType,
        hasBeenViewed: true,
        userPk: userPk || '',
      }),
    )
    nav({ ...omit({ ...qs }, ['slide', 'tutorial']) })
  }, [dispatch, nav, qs, tutorialType, userPk])

  const footerContent = useMemo(() => {
    return <LearnMoreLink url={tutorialSlides[0]?.url} />
  }, [tutorialSlides])

  const tutorialTrayInfo = useTutorialTray({
    tutorialType,
    tutorialSlides: tutorialSlides.length,
    content: tutorialSlides[currentSlideIndex]?.content,
    title,
    footerContent,
    handleFinish,
  })

  const { showTray: showTutorialTray } = useTrayPresenter(tutorialTrayInfo)
  const shouldShowTray = useMemo(
    () =>
      qs.tutorial && // tutorial on query string
      isMobile && // is mobile
      currentSlideIndex < tutorialSlides.length && // currentSlide is valid
      currentSlideIndex >= 0 && // current slide not negative
      !wasTutorialDenied, // tutorial not denied
    [currentSlideIndex, isMobile, qs.tutorial, tutorialSlides.length, wasTutorialDenied],
  )

  useEffect(() => {
    if (shouldShowTray) {
      showTutorialTray()
    }
  }, [shouldShowTray, showTutorialTray])

  return qs.tutorial &&
    !isMobile &&
    tutorialType &&
    currentSlideIndex < tutorialSlides.length &&
    currentSlideIndex >= 0 ? (
    <TutorialModalDialog
      title={title}
      tutorialType={tutorialType}
      handleFinish={handleFinish}
      totalSlides={tutorialSlides.length}
      handleEarlyExit={handleFinish}
      footerContent={<LearnMoreLink url={tutorialSlides[0]?.url} />}
      dismiss={handleFinish}
    >
      {tutorialSlides[currentSlideIndex]?.content}
    </TutorialModalDialog>
  ) : null
}
