import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import range from 'lodash/range'
import ColumnPlaceholder from './ColumnPlaceholder'
import RowPlaceholder from './RowPlaceholder'

export default function TablePlaceholder({
  columnsCount = 4,
  rowsCount = 4,
}: {
  columnsCount?: number
  rowsCount?: number
}): ReactElement {
  const rows = [...range(rowsCount).map(i => <RowPlaceholder key={i} />)]
  const columns = [
    ...range(columnsCount).map(i => <ColumnPlaceholder key={i}>{rows}</ColumnPlaceholder>),
  ]

  return (
    <Flex sx={{ width: '100%', gap: '3.2rem', flexDirection: 'column' }}>
      <Flex
        sx={{
          width: '100%',
          backgroundColor: 'darkItemBG',
          flexDirection: 'column',
          borderRadius: '16px',
          p: '1.6rem',
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            gap: '0.8rem',
          }}
        >
          {columns}
        </Flex>
      </Flex>
    </Flex>
  )
}
