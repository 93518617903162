import { useCallback } from 'react'
import uniqueId from 'lodash/uniqueId'
import { AlertId, useAlertPresenter } from 'ui'
import CautionIcon from 'images/error-msg-icon.svg'

export type PresentCautionOkAlert = (message: string, id?: AlertId) => AlertId

// expandable hook for custom alerts, add here reusable alerts
export const useCustomAlerts = (): {
  presentCautionOkAlert: PresentCautionOkAlert
} => {
  const { presentAlert, dismissAlert } = useAlertPresenter()
  const presentCautionOkAlert = useCallback(
    (message: string, alertId: AlertId = uniqueId('alert_')) =>
      presentAlert(
        {
          message,
          icon: CautionIcon,
          buttons: [
            {
              onClick: (): void => {
                dismissAlert(alertId)
              },
              text: 'Okay',
            },
          ],
        },
        alertId,
      ),
    [dismissAlert, presentAlert],
  )

  return {
    presentCautionOkAlert,
  }
}
