import { Button, Svg, TrayRenderProps } from 'ui'
import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import TrayHeader from 'components/TrayHeader'
import CloseIcon from 'images/profileManagement/close-icon.svg'
import { useGetUncensoredDomainsQuery } from 'store/api/freeDns'

export default function UncensoredDomainsTrayContent({ dismiss }: TrayRenderProps): ReactElement {
  const { data: domainsData } = useGetUncensoredDomainsQuery('')

  return (
    <Flex
      data-testid="uncensored-domains-tray"
      sx={{ flexDirection: 'column', height: '100%', overflow: 'hidden' }}
    >
      <TrayHeader
        sx={{
          pb: '1.6rem',
          px: '1.6rem',
          paddingTop: '1.6rem',
        }}
        leftComponent={
          <Button
            data-testid="close-notifications-tray"
            ariaLabel="close tray"
            variant="simple"
            sx={{ p: 0 }}
            onClick={dismiss}
          >
            <Svg svg={CloseIcon} fill="white50" />
          </Button>
        }
      >
        <Text variant="cardLabel" sx={{ color: 'labelColor' }}>
          All Domains
        </Text>
      </TrayHeader>

      <Flex
        sx={{
          flexDirection: 'column',
          px: '1.6rem',
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            pl: 0,
            ml: 0,
            width: '100%',
            overflowY: 'auto',
            scrollbarWidth: 'none',
          }}
          className="hide-scrollbar"
        >
          {domainsData?.domains?.map(item => (
            <Text
              variant="trayText"
              key={item}
              sx={{
                py: '1.6rem',
                color: 'textColor',
                fontWeight: 'bold',
                borderBottom: '1px solid',
                borderBottomColor: 'white12',
                '&:last-child': {
                  mb: '2.8rem',
                },
              }}
            >
              {item}
            </Text>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}
