import { Button, Input, Svg } from 'ui'
import InputProps from 'ui/Inputs/Input.interface'
import React, { ReactElement, forwardRef } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Setter } from 'utils'
import HiddenEyeIcon from 'images/ui/hidden-eye.svg'
import ShowEyeIcon from 'images/ui/show-eye.svg'
import omit from 'lodash/omit'

interface PasswordInputProps extends InputProps {
  shouldShowShowPasswordButton?: boolean
  setShowPassword?: Setter<boolean>
  showPassword?: boolean
  sxShowPasswordStyle?: ThemeUIStyleObject
}

export default forwardRef<HTMLInputElement, PasswordInputProps>(function PasswordInput(
  props: PasswordInputProps,
  ref,
): ReactElement {
  const {
    shouldShowShowPasswordButton = false,
    setShowPassword = () => {},
    showPassword = false,
    sxShowPasswordStyle,
  } = props
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          flexWrap: 'nowrap',
          flex: '1 1 auto',
        }}
      >
        <Input
          ref={ref}
          {...omit(
            props,
            'showPassword',
            'sxShowPasswordStyle',
            'shouldShowShowPasswordButton',
            'setShowPassword',
          )}
        />
      </Flex>
      {shouldShowShowPasswordButton && (
        <Button
          variant="simple"
          aria-label="show password"
          data-testid="create-account-show-password"
          onClick={(): void => setShowPassword(!showPassword)}
          sx={{
            p: 0,
            flexShrink: 0,
            position: 'absolute',
            right: ['1.6rem', '2.4rem'],
            alignItems: 'center',
            ...sxShowPasswordStyle,
          }}
        >
          <Svg svg={showPassword ? ShowEyeIcon : HiddenEyeIcon} width="2.4rem" height="2.4rem" />
        </Button>
      )}
    </Flex>
  )
})
