import React, { ReactElement, useMemo, useState, useEffect } from 'react'
import { FREE_DNS_DOMAIN } from 'gatsby-env-variables'
import { Button, Svg } from 'ui'
import { Flex, Text } from 'theme-ui'
import FreeDNSTrayHeader from 'components/FreeDNSPage/FreeDNSTray/FreeDNSTrayHeader'
import { useAppSelector } from 'store/hooks'
import {
  DnsProtocol,
  dnsServersByProtocol,
  DnsServers,
  DnsProtocolCollection,
} from 'constants/dnsServers'
import useQueryString, { Nav } from 'utils/useQueryString'
import { staticPageDesktopMaxWidth } from 'components/StaticPage/StaticPage'
import ArrowRightIcon from 'images/organization/homepage/sections/industries/arrow-right.svg'
import { SetupGuideIntermediateStates } from 'components/SetupGuide/SetupGuide.interface'
import { ResolverKey } from 'components/FreeDNSPage/constants/freeDNS'
import SingleResolverTypeContent, {
  MultipleResolverTypeContent,
} from 'components/FreeDNSPage/FreeDNSTray/FreeDNSResolverContent'
import { Setter } from 'utils'
import { useGetFreeDNSFiltersQuery } from 'store/api/freeDns'

export type FreeResolverType = {
  key: ResolverKey
  value: string
  secondaryValue: string
  onConfigureButtonClick: () => void
}

export type FilterResolver = {
  uri: string
  onConfigureButtonClick: () => void
}

interface GetResolversArgs {
  dnsServers: DnsServers
  dnsServersSecondary: DnsServers
  nav: Nav
}

const openSetupGuide = (nav, freeResolverType) => () =>
  nav(
    {
      helpPane: SetupGuideIntermediateStates.DNS,
      freeResolverType,
    },
    { shouldReplaceHistoryEntry: true },
  )
export const getResolvers = ({
  dnsServers,
  dnsServersSecondary,
  nav,
}: GetResolversArgs): FreeResolverType[] => [
  {
    key: ResolverKey.UNFILTERED,
    value: dnsServers.unfiltered,
    secondaryValue: dnsServersSecondary?.unfiltered,
    onConfigureButtonClick: openSetupGuide(nav, ResolverKey.UNFILTERED),
  },
  {
    key: ResolverKey.MALWARE,
    value: dnsServers.blockMalware,
    secondaryValue: dnsServersSecondary?.blockMalware,
    onConfigureButtonClick: openSetupGuide(nav, ResolverKey.MALWARE),
  },
  {
    key: ResolverKey.ADS_TRACKING,
    value: dnsServers.blockMalwareAndAds,
    secondaryValue: dnsServersSecondary?.blockMalwareAndAds,
    onConfigureButtonClick: openSetupGuide(nav, ResolverKey.ADS_TRACKING),
  },
  {
    key: ResolverKey.SOCIAL,
    value: dnsServers.blockMalwareAdsAndSocial,
    secondaryValue: dnsServersSecondary?.blockMalwareAdsAndSocial,
    onConfigureButtonClick: openSetupGuide(nav, ResolverKey.SOCIAL),
  },
  {
    key: ResolverKey.FAMILY_FRIENDLY,
    value: dnsServers.family,
    secondaryValue: dnsServersSecondary?.family,
    onConfigureButtonClick: openSetupGuide(nav, ResolverKey.FAMILY_FRIENDLY),
  },
  {
    key: ResolverKey.UNCENSORED,
    value: dnsServers.uncensored,
    secondaryValue: dnsServersSecondary?.uncensored,
    onConfigureButtonClick: openSetupGuide(nav, ResolverKey.UNCENSORED),
  },
]

const getResolver = ({
  selectedDnsProtocol,
  selectedDNSKey,
  nav,
}): FreeResolverType | undefined => {
  const dnsServers = dnsServersByProtocol[selectedDnsProtocol]
  const dnsServersSecondary =
    dnsServersByProtocol[DnsProtocol[`${DnsProtocol[selectedDnsProtocol]}_ALT`]]

  const resolversData = getResolvers({
    dnsServers,
    dnsServersSecondary,
    nav,
  })

  return resolversData.find(item => item.key === selectedDNSKey)
}

const getFilterUri = (selectedDnsProtocol, filterPK, isExternalFilter = false): string => {
  return selectedDnsProtocol === DnsProtocol.DNS_OVER_HTTPS
    ? `https://${FREE_DNS_DOMAIN}/${isExternalFilter ? '' : 'no-'}${filterPK}`
    : `${isExternalFilter ? '' : 'no-'}${filterPK}.${FREE_DNS_DOMAIN}`
}

type MultipleResolverSection = {
  title: string
  resolvers: string[]
}

const FreeDNSTrayContent = ({
  setIsOpenTray,
}: {
  setIsOpenTray: Setter<boolean>
}): ReactElement => {
  const { nav } = useQueryString()
  const selectedDNSKey = useAppSelector(s => s.freeDNS.selectedDNSKey)
  const selectedFiltersPK = useAppSelector(s => s.freeDNS.selectedFiltersPKs)
  const selected3rdPartyFilterPK = useAppSelector(s => s.freeDNS.selected3rdPartyFilterPK)
  const isCustom = selectedFiltersPK && selectedFiltersPK?.length > 0
  const { data: freeDnsData } = useGetFreeDNSFiltersQuery('')

  const [selectedDnsProtocol, setSelectedDnsProtocol] = useState<
    DnsProtocol | DnsProtocolCollection
  >(isCustom ? DnsProtocol.DNS_OVER_HTTPS : DnsProtocolCollection.ALL_SECURE)

  useEffect(() => {
    setSelectedDnsProtocol(isCustom ? DnsProtocol.DNS_OVER_HTTPS : DnsProtocolCollection.ALL_SECURE)
  }, [isCustom])

  const resolver = useMemo(
    () => getResolver({ selectedDnsProtocol, selectedDNSKey, nav }),
    [selectedDNSKey, selectedDnsProtocol, nav],
  )

  const filterResolver: FilterResolver = useMemo(() => {
    const selectedFiltersPKDub = selectedFiltersPK ?? []
    const filterPK = selectedFiltersPKDub.length
      ? selectedFiltersPKDub.join('-')
      : selected3rdPartyFilterPK

    return {
      uri: getFilterUri(selectedDnsProtocol, filterPK),
      onConfigureButtonClick: () =>
        nav(
          {
            helpPane: SetupGuideIntermediateStates.DNS,
            freeResolverType: selected3rdPartyFilterPK || ResolverKey.CUSTOM,
          },
          { shouldReplaceHistoryEntry: true },
        ),
    }
  }, [selectedFiltersPK, selected3rdPartyFilterPK, selectedDnsProtocol, nav])

  const getMultipleResolverSection = (
    title: string,
    protocol: DnsProtocol,
  ): MultipleResolverSection => {
    let resolvers: string[]

    if (selectedDNSKey) {
      resolvers = [
        getResolver({ selectedDnsProtocol: protocol, selectedDNSKey, nav })?.value || '',
        getResolver({ selectedDnsProtocol: protocol, selectedDNSKey, nav })?.secondaryValue || '',
      ]
    } else {
      const filterResolvers = freeDnsData?.external_filters.find(
        filter => filter.PK === selected3rdPartyFilterPK,
      )?.resolvers
      const ipVersion = title.includes('v4') ? 'v4' : 'v6'

      resolvers = title.includes('IPv')
        ? filterResolvers?.[ipVersion] || ['']
        : [getFilterUri(protocol, selected3rdPartyFilterPK, true)]
    }

    return {
      title,
      resolvers: resolvers.filter(e => e), // strip empty strings for where there is no secondary value
    }
  }

  const multipleResolverSections =
    selectedDnsProtocol === DnsProtocolCollection.ALL_LEGACY
      ? [
          getMultipleResolverSection('IPv4', DnsProtocol.LEGACY_DNS_V4),
          getMultipleResolverSection('IPv6', DnsProtocol.LEGACY_DNS_V6),
        ]
      : [
          getMultipleResolverSection('DNS-over-HTTPS/3', DnsProtocol.DNS_OVER_HTTPS),
          getMultipleResolverSection('DNS-over-TLS/DoQ', DnsProtocol.DNS_OVER_TLS),
        ]

  return (
    <Flex
      data-testid="configuration-tray-content"
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'color16',
        borderTopLeftRadius: '1.2rem',
        borderTopRightRadius: '1.2rem',
        borderLeft: '1px solid',
        borderRight: '1px solid',
        borderColor: 'color4',
      }}
    >
      <FreeDNSTrayHeader
        selectedDnsProtocol={selectedDnsProtocol}
        setSelectedDnsProtocol={setSelectedDnsProtocol}
        setIsOpenTray={setIsOpenTray}
      />

      <Flex
        sx={{
          width: ['100%', '100%', staticPageDesktopMaxWidth],
          '@media screen and (min-width: 1120px) and (max-width: 1170px)': {
            width: '104rem',
          },
        }}
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: ['column', 'row'],
            justifyContent: ['center', 'space-between'],
            px: '1.6rem',
            minHeight: ['8rem', '15rem', '13rem'],
          }}
        >
          {isCustom ? (
            <SingleResolverTypeContent resolver={resolver} filterResolver={filterResolver} />
          ) : (
            <MultipleResolverTypeContent
              sections={multipleResolverSections}
              shouldDisplayAsColumn={selectedDnsProtocol === DnsProtocolCollection.ALL_SECURE}
            />
          )}
          <Flex sx={{ alignItems: ['flex-end', 'center'], flexShrink: 0 }}>
            <Button
              variant="gradientButton"
              aria-label="open setup guide"
              sx={{
                display: ['none', 'flex'],
                width: 'fit-content',
                borderRadius: '5.2rem',
                border: 'none',
                py: '1.6rem',
                px: '2.4rem',
              }}
              onClick={resolver?.onConfigureButtonClick || filterResolver.onConfigureButtonClick}
            >
              <Text variant="buttonText" sx={{ mr: '0.8rem' }}>
                Setup Guide
              </Text>
              <Svg svg={ArrowRightIcon} fill="white" />
            </Button>
            <Button
              variant="gradientButton"
              aria-label="open setup guide"
              sx={{
                display: ['flex', 'none'],
                width: '100%',
                borderRadius: '5.2rem',
                border: 'none',
                py: '1.6rem',
                px: '2.4rem',
                justifyContent: 'center',
              }}
              onClick={resolver?.onConfigureButtonClick || filterResolver.onConfigureButtonClick}
            >
              <Text variant="buttonText" sx={{ mr: '0.8rem' }}>
                Configure
              </Text>
              <Svg svg={ArrowRightIcon} fill="white" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FreeDNSTrayContent
