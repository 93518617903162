import React, { ElementType, ReactElement, useRef, useState } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Dropdown, SimpleActionMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import ArrowUpDownIcon from 'images/ui/arrow-up-down.svg'
import { Label } from '@theme-ui/components'
import InfoIcon from 'images/infoicon.svg'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import useBoundaryElement from 'utils/useBoundaryElement'

export default function SimpleDropdown({
  disabled = false,
  label,
  items,
  hideRef,
  isOpen,
  sx,
  sxDropdown,
  sxDropdownButton,
  sxDropdownContent,
  sxArrowIcon,
  testId,
  defaultValue,
  tooltipText,
  contentMaxHeight,
  mobileContentWidth,
  arrowIcon,
  arrowFill,
  shouldRotateArrow,
  theme,
  ariaLabel,
  boundaryElementTestId,
}: {
  disabled?: boolean
  label?: string
  items: SimpleActionMenuItemProps[]
  sx?: ThemeUIStyleObject
  sxDropdown?: ThemeUIStyleObject
  sxDropdownButton?: ThemeUIStyleObject
  sxDropdownContent?: ThemeUIStyleObject
  sxArrowIcon?: ThemeUIStyleObject
  hideRef: React.MutableRefObject<(() => void) | undefined>
  isOpen?: boolean
  testId?: string
  defaultValue?: string | ReactElement
  tooltipText?: string
  contentMaxHeight?: string
  mobileContentWidth?: string
  arrowIcon?: ElementType
  arrowFill?: string
  shouldRotateArrow?: boolean
  theme?: string
  ariaLabel?: string
  boundaryElementTestId?: string
}): ReactElement {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const buttonContentRef = useRef<HTMLButtonElement>(null)
  const selectedItem = items.find(item => item.isSelected)
  const boundaryElement = useBoundaryElement(boundaryElementTestId || 'parent')

  return (
    <Flex
      sx={{
        alignItems: 'center',
        flexDirection: 'column',
        mb: '2.4rem',
        '& > button': {
          px: '2.4rem',
        },
        ...sx,
      }}
    >
      {(label || tooltipText) && (
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            px: '2.4rem',
            mb: '0.8rem',
          }}
        >
          {label && (
            <Label
              sx={{
                fontWeight: 'bold',
                color: disabled ? 'white25' : isDropdownOpened ? 'white' : 'white50',
              }}
            >
              {label ?? 'Linked Profile'}
            </Label>
          )}
          {tooltipText && (
            <IconWithTippyPopup
              placement="top"
              content={
                <Flex
                  sx={{
                    alignSelf: 'center',
                    width: '15.6rem',
                    textAlign: 'center',
                    fontSize: '1.2rem',
                  }}
                >
                  {tooltipText}
                </Flex>
              }
              svg={InfoIcon}
              fill={disabled ? 'white25' : 'white50'}
              sx={{
                width: '1.6rem',
                height: '1.6rem',
              }}
            />
          )}
        </Flex>
      )}
      <Dropdown
        aria-label={ariaLabel}
        disabled={disabled}
        buttonRef={buttonContentRef}
        data-testid={testId}
        variant="simple"
        onKeyDown={stopPropagationOnKeyPress}
        sx={{
          width: '100%',
          backgroundColor: 'white10',
          height: '5.4rem',
          borderRadius: '45px',
          ':disabled': {
            cursor: 'auto',
            backgroundColor: 'transparent',
            border: '2px solid',
            borderColor: 'white10',
            color: 'white25',
            '& > div > div': {
              color: 'white25',
            },
            '& > div svg': {
              fill: 'white25',
            },
          },
          ...sxDropdown,
        }}
        tippyprops={{
          appendTo: boundaryElement,
          placement: 'bottom-start',
          animation: 'fade',
          onCreate: instance => {
            hideRef.current = () => {
              instance.hide()
            }
          },
          onHide() {
            setIsDropdownOpened(false)
          },
          onTrigger() {
            setIsDropdownOpened(true)
          },
          theme: theme || 'screen',
          maxWidth: 'fit-content',
        }}
        dropdowncontent={
          <Flex
            className="show-scrollbar"
            data-testid={`${testId}-content`}
            sx={{
              flexDirection: 'column',
              width: [
                mobileContentWidth || buttonContentRef.current?.clientWidth,
                buttonContentRef.current?.clientWidth,
              ],
              maxHeight: contentMaxHeight || 'calc(50vh)',
              px: '0.8rem',
              my: '1rem',
              backgroundColor: 'darkItemBG',
              borderRadius: '16px',
              overflowY: 'auto',
              overflowX: 'hidden',
              ...sxDropdownContent,
            }}
          >
            <SimpleActionMenu
              hideRef={hideRef}
              isOpen={isOpen || isDropdownOpened}
              items={items}
              sxContainer={{
                px: 0,
                py: '0.8rem',
                position: 'relative',
                gap: '0.8rem',
              }}
              sxButton={{
                maxHeight: 'auto',
                mx: 0,
                py: '0.8rem',
                px: '1.6rem',
                fontSize: '1.6rem',
                fontWeight: 'normal',
                '& > div': {
                  alignItems: 'center',
                },
              }}
            />
          </Flex>
        }
      >
        <Flex sx={{ width: '100%' }}>
          <Flex
            sx={{
              flex: 1,
              alignItems: 'center',
              fontSize: '1.8rem',
              fontWeight: 'bold',
              color: isDropdownOpened ? 'white' : 'white50',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              ...sxDropdownButton,
            }}
          >
            {selectedItem?.buttonContent || selectedItem?.children || defaultValue}
          </Flex>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              svg: shouldRotateArrow
                ? {
                    transform: isDropdownOpened ? 'rotate(0)' : 'rotate(180deg)',
                    transition: 'transform 0.1s ease',
                  }
                : {},
            }}
          >
            <Svg
              svg={arrowIcon || ArrowUpDownIcon}
              fill={disabled ? 'white25' : arrowFill || isDropdownOpened ? 'white' : 'white50'}
              sx={{ ...sxArrowIcon }}
            />
          </Flex>
        </Flex>
      </Dropdown>
    </Flex>
  )
}
