import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import range from 'lodash/range'
import RowPlaceholder from './RowPlaceholder'

export default function AccordionPlaceholder({
  rowsCount = 4,
}: {
  rowsCount?: number
}): ReactElement {
  const rows = [...range(rowsCount).map(i => <RowPlaceholder key={i} />)]

  return (
    <Flex sx={{ width: '100%', gap: '3.2rem', flexDirection: 'column' }}>
      <Flex
        sx={{
          width: '100%',
          backgroundColor: 'darkItemBG',
          flexDirection: 'column',
          borderRadius: '16px',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& > :not(:last-of-type)': {
              borderBottom: '1px solid',
              borderColor: 'white5',
            },
          }}
        >
          {rows}
        </Flex>
      </Flex>
    </Flex>
  )
}
