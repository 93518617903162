import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import ProvisionOptionsMenu from './ProvisionOptionsMenu'

export default function Options({ provisionPk }: { provisionPk: string }): ReactElement {
  return (
    <Flex
      sx={{ gap: '1.6rem', alignItems: 'center', justifyContent: 'flex-end', mr: [0, 0, '1.6rem'] }}
    >
      <ProvisionOptionsMenu provisionPk={provisionPk} />
    </Flex>
  )
}
