import React, { ReactElement } from 'react'
import range from 'lodash/range'
import { QueriesCardBox } from './QueriesCardBox'
import { Flex } from 'theme-ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export default function QueriesPlaceholder(): ReactElement {
  const isMobile = useBreakpointIndex() === 0
  const queriesPlaceholder = [...range(isMobile ? 3 : 7).map(i => <QueriesCardBox key={i} />)]

  return (
    <Flex
      sx={{
        pr: [0, '1.6rem'],
        flexDirection: 'column',
        '& > div:first-child': {
          borderTopRightRadius: '1.6rem',
          borderTopLeftRadius: '1.6rem',
        },
        '& > div:last-child': {
          borderBottomLeftRadius: '1.6rem',
          borderBottomRightRadius: '1.6rem',
        },
        '& > div:nth-of-type(even)': {
          backgroundColor: 'darkItemBG50',
        },
        '& > div:nth-of-type(odd)': {
          backgroundColor: 'darkItemBG',
        },
      }}
    >
      {queriesPlaceholder}
    </Flex>
  )
}
