import React from 'react'
import { Setter } from 'utils'
import { TwoFactorStep } from 'store/twofa'
import { useAppSelector } from 'store/hooks'
import { Flex, Image, Spinner, Text } from 'theme-ui'
import { Button } from 'ui'
import { LoadingState } from 'store/fetchingLogic'

export default function ScanOrEnterCodeStep({
  setStep,
}: {
  setStep: Setter<TwoFactorStep>
}): React.ReactElement {
  const twofaData = useAppSelector(s => s.twofa)
  const twofa = useAppSelector(s => s.twofa.data?.twofa)

  return (
    <Flex
      as="form"
      onSubmit={(e): void => {
        e.preventDefault()
        setStep(TwoFactorStep.ENABLE_TWOFA)
      }}
      sx={{
        width: '100%',
        gap: '2.4rem',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: ['column', 'column', 'row'],
      }}
    >
      <Image
        sx={{
          width: ['15.4rem', '20.6rem'],
          height: ['15.4rem', '20.6rem'],
          mb: ['2.4rem', 0, 0],
          flexShrink: 0,
        }}
        src={`data:image/jpeg;base64,${twofa?.qr}`}
      />
      <Flex
        sx={{
          gap: '2.4rem',
          flexDirection: 'column',
          textAlign: 'left',
          color: 'white50',
          fontSize: ['1.4rem', '1.6rem'],
        }}
      >
        <Flex sx={{ gap: '0.8rem', flexDirection: 'column' }}>
          <Text sx={{ fontWeight: 'bold' }}>Scan The QR Code</Text>
          <Text>
            Use an authentication app (Google Authenticator or Authy) to scan the QR code using your
            phone’s camera.
          </Text>
        </Flex>
        <Flex sx={{ gap: '0.8rem', flexDirection: 'column' }}>
          <Text sx={{ fontWeight: 'bold' }}>Backup Code</Text>
          <Text>{twofa?.secret}</Text>
        </Flex>
        <Button
          type="submit"
          sx={{
            width: ['100%', '100%', '17.4rem'],
            minWidth: '15.2rem',
            height: ['4.8rem', '5.4rem'],
            fontSize: ['1.8rem', '2.1rem'],
            fontWeight: 'bold',
            borderRadius: '59px',
            border: 'none',
            backgroundColor: 'plum',
            color: 'white',
            alignSelf: 'flex-end',
          }}
          variant="secondary"
          data-testid="scan-or-enter-button"
        >
          {twofaData.loading !== LoadingState.PENDING && 'Continue'}
          {twofaData.loading === LoadingState.PENDING && (
            <Spinner
              color="white"
              sx={{
                height: '2.1rem',
              }}
            />
          )}
        </Button>
      </Flex>
    </Flex>
  )
}
