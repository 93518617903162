import React, { ReactElement } from 'react'
import sortBy from 'lodash/sortBy'
import ReceiptContainer from 'components/Dashboard/Preferences/Billing/ReceiptsTable/ReceiptContainer'
import { Currency } from 'store/api/billing'
import OrganizationInvoiceElement from 'components/Organization/OrganizationBilling/OrganizationInvoiceElement'
import { OrganizationInvoice } from 'store/api/billing/payments.interface'

const tableHeaders = [
  'Date',
  'Trans ID',
  'Payment Method',
  'Amount',
  'Status',
  '', // header for open receipt button icon column
]

interface OrganizationInvoiceTableProps {
  paymentDataArray: Array<OrganizationInvoice> | undefined
  currencies: Array<Currency>
}

export default function OrganizationInvoicesTable({
  paymentDataArray,
  currencies,
}: OrganizationInvoiceTableProps): ReactElement {
  const sortedData = sortBy(paymentDataArray, ['ts']).reverse()

  return (
    <ReceiptContainer headers={tableHeaders}>
      {sortedData?.map(invoice => (
        <OrganizationInvoiceElement
          key={`${invoice.tx_id}-desktop`}
          invoice={invoice}
          currencies={currencies}
          tableHeaders={tableHeaders}
        />
      ))}
    </ReceiptContainer>
  )
}
