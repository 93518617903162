import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { ActionableRule } from 'utils/uniteRules'

export interface ProxyTrayState {
  rule?: ActionableRule
}

export const proxyTraySlice = createSlice<
  ProxyTrayState,
  SliceCaseReducers<ProxyTrayState>,
  string
>({
  name: 'proxyTray',
  initialState: {},
  reducers: {
    openProxyTray(state, action): void {
      return action.payload
    },
  },
})

export const { openProxyTray } = proxyTraySlice.actions
