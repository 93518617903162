import React, { ReactElement, useMemo, useRef } from 'react'
import { SimpleActionMenuItemProps, Svg } from 'ui'
import { Flex } from 'theme-ui'
import SimpleDropdown from 'ui/Dropdown/SimpleDropdown'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import CheckIcon from 'images/analytics/check.svg'
import { SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import { useGetDevicesTypesQuery } from 'store/api/devices/devices'
import { DeviceGroup } from 'store/api/devices/devices.interface'

const DeviceTypeDropdown = ({
  selectedType,
  setSelectedType,
}: {
  selectedType?: string
  setSelectedType: (type: string) => void
}): ReactElement => {
  const hideRef = useRef<() => void>()
  const { data: typesData } = useGetDevicesTypesQuery('')
  useGetDevicesTypesQuery('')

  const options: SimpleActionMenuItemProps[] = useMemo(() => {
    const icons = typesData?.types?.find(type => type.group === DeviceGroup.OS)?.icons || []

    return (
      icons
        // should be only windows, macOS and linux
        .filter(icon => icon.type !== SetupOs.IOS && icon.type !== SetupOs.ANDROID)
        .map(icon => ({
          isSelected: icon.type === selectedType,
          isBackgroundHighlighted: true,
          buttonContent: (
            <TextWithOverFlowAndTippyPopup
              content={icon.name ?? ''}
              sxText={{
                maxWidth: '44rem',
              }}
            />
          ),
          children: (
            <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextWithOverFlowAndTippyPopup
                content={icon.name ?? ''}
                sxText={{
                  maxWidth: '32rem',
                }}
              />
              {icon.type === selectedType && (
                <Svg svg={CheckIcon} sx={{ flexShrink: 0, fill: 'white', opacity: 0.5 }} />
              )}
            </Flex>
          ),
          onClick: () => {
            setSelectedType(icon.type)
            hideRef.current?.()
          },
        }))
    )
  }, [typesData?.types, setSelectedType, selectedType])

  return (
    <SimpleDropdown
      testId="device-type-dropdown"
      disabled={!typesData?.types.length}
      items={options}
      hideRef={hideRef}
      label="Endpoint Type"
      defaultValue="Select device type"
      sx={{ width: '100%', flex: 1, mb: 0 }}
      contentMaxHeight="33rem"
    />
  )
}

export default DeviceTypeDropdown
