import { createSlice, SliceCaseReducers, PayloadAction } from '@reduxjs/toolkit'
import { AccessData } from 'store/api/access'
import { initialResponseState, ResponseState } from '../fetchingLogic'

export interface AccessState {
  selectedDeviceId?: string
}

export const accessSlice = createSlice<
  AccessState & ResponseState<AccessData>,
  SliceCaseReducers<AccessState & ResponseState<AccessData>>
>({
  name: 'access',
  initialState: {
    ...initialResponseState,
    selectedDeviceId: undefined,
  },
  reducers: {
    setSelectedDeviceId(state, { payload }: PayloadAction<string | undefined>): void {
      state.selectedDeviceId = payload
    },
    clearError(state): void {
      state.error = undefined
    },
  },
})

export const { setSelectedDeviceId } = accessSlice.actions
