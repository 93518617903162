import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'

export default function ErrorMessage({
  promoCodeError,
  isMobileView = false,
}: {
  promoCodeError: string
  isMobileView?: boolean
}): ReactElement {
  return (
    <Flex
      data-testid="promo-input-error"
      sx={{
        maxWidth: isMobileView ? '100%' : '50.7rem',
        color: 'error',
        fontSize: '1.4rem',
        mt: isMobileView ? 0 : '1.4rem',
        mb: isMobileView ? '1.2rem' : 0,
        px: isMobileView ? '2.4rem' : 0,
      }}
    >
      {promoCodeError}
    </Flex>
  )
}
