import React, { ReactElement, ReactNode } from 'react'
import { ThemeUIStyleObject, useThemeUI } from 'theme-ui'
import { Button } from 'ui'

interface TabButtonProps {
  sx?: ThemeUIStyleObject
  isSelected?: boolean
  children: ReactNode
  onClick: () => void
}
export default function TabButton({ sx = {}, isSelected, ...props }: TabButtonProps): ReactElement {
  const { theme } = useThemeUI()
  return (
    <Button
      sx={{
        padding: '0.1rem 0.8rem 0.1rem 0.8rem',
        borderRadius: '20px',
        fontSize: ['1.4rem', '1.6rem'],
        width: 'fit-content',
        whiteSpace: 'nowrap',
        fontWeight: isSelected && 'bold',
        color: isSelected ? 'black' : 'white',
        border: '1px solid',
        borderColor: isSelected ? 'black' : 'transparent',
        backgroundColor: isSelected ? 'greenApple' : 'white25',
        boxShadow:
          isSelected && `0 0 0 2px ${theme.colors?.greenApple}, 0 0 0 1px ${theme.colors?.black}`,
        ...sx,
      }}
      {...props}
    />
  )
}
