import React, { ReactElement } from 'react'
import { ProxyLocation } from 'store/api/proxies'
import { Box, Flex, Text } from 'theme-ui'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import { Svg } from 'ui'
import DefaultRedirectOnIcon from 'images/default-redirect-icon-default.svg'
import DefaultRedirectOffIcon from 'images/default-redirect-icon-off.svg'
import { IconType } from 'components/Dashboard/ExternalSvgIcon'

export function CurrentLocationIcon({
  currentLocation,
  isConnected,
  isSmallIcon,
}: {
  currentLocation?: ProxyLocation
  isConnected: boolean
  isSmallIcon?: boolean
}): ReactElement {
  return (
    <Flex
      sx={{
        alignContent: 'center',
        width: isSmallIcon ? '1.6rem' : '3.2rem',
        height: isSmallIcon ? '1.6rem' : '3.2rem',
      }}
    >
      {currentLocation?.PK ? (
        <IconWithTippyPopup
          content={<Text sx={{ maxWidth: '25rem' }}>{`${currentLocation?.country_name}`}</Text>}
          testId={`default-redirect-menu-desktop-tablet-current-location-icon-${currentLocation.PK}`}
          externalIcon={{ icon: currentLocation?.country, type: IconType.LOCATIONS }}
          fill="null"
          sx={{
            flexShrink: 0,
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
          }}
        />
      ) : (
        <Svg
          svg={isConnected ? DefaultRedirectOnIcon : DefaultRedirectOffIcon}
          data-testid="default-redirect-menu-desktop-tablet-current-location-icon-AUTO"
          fill="white50"
          sx={{
            flexShrink: 0,
            height: '100%',
            width: '100%',
            pointerEvents: 'none',
          }}
        />
      )}
      {isConnected && (
        <Box
          sx={{
            position: 'absolute',
            right: currentLocation ? ['-0.2rem', '-1.4rem', '-0.2rem'] : '0rem',
            bottom: currentLocation ? '0.1rem' : '0.2rem',
            width: '0.8rem',
            height: '0.8rem',
            borderRadius: '500px',
            backgroundColor: 'primary',
            border: '1px solid black',
          }}
        />
      )}
    </Flex>
  )
}
