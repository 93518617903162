import React, {
  ReactElement,
  useState,
  useCallback,
  createContext,
  useMemo,
  useContext,
  FunctionComponent,
  ReactNode,
  useEffect,
} from 'react'
import { Setter } from 'utils'

export interface TrayRenderProps {
  dismiss: () => void
  isVisible?: boolean
  type?: string
}
export interface TrayInfo {
  id: string
  renderTray: (props: TrayRenderProps) => ReactElement
  isVisible?: boolean
  zIndexName?: string
}
interface TrayPresenterState {
  trays: TrayInfo[]
  showTray: (id: string) => void
  hideTray: (id: string) => void
}

export interface TrayActions {
  showTray: () => void
  hideTray: () => void
}
interface TrayPresenterClient {
  setTrays: Setter<TrayInfo[]>
  showTray: (id: string) => void
  hideTray: (id: string) => void
  removeTray: (id: string) => void
  isAnyTrayOpen: boolean
  hideAllTrays: () => void
}
const TrayPresenterStateContext = createContext<TrayPresenterState>({} as TrayPresenterState)

const TrayPresenterClientContext = createContext<TrayPresenterClient>({} as TrayPresenterClient)
export default function TrayContextProvider({ children }: { children?: ReactNode }): ReactElement {
  const [trays, setTrays] = useState<TrayInfo[]>([])
  const showTray = useCallback((id: string) => {
    setTrays(prev => prev.map(t => (t.id === id ? { ...t, isVisible: true } : t)))
  }, [])

  const hideTray = useCallback((id: string) => {
    setTrays(prev => prev.map(t => (t.id === id ? { ...t, isVisible: false } : t)))
  }, [])

  const removeTray = useCallback((id: string): void => {
    setTrays(prevTrays => prevTrays.filter(t => t.id !== id))
  }, [])

  const isAnyTrayOpen = useMemo(() => {
    return trays.some(t => t.isVisible)
  }, [trays])

  const hideAllTrays = useCallback(() => {
    setTrays(prev => prev.map(t => ({ ...t, isVisible: false })))
  }, [])

  const trayPresenterClient = useMemo(
    () => ({ setTrays, showTray, hideTray, removeTray, isAnyTrayOpen, hideAllTrays }),
    [hideTray, removeTray, showTray, isAnyTrayOpen, hideAllTrays],
  )

  const trayPresenterState = useMemo(
    () => ({
      trays,
      showTray,
      hideTray,
    }),
    [hideTray, trays, showTray],
  )

  return (
    <TrayPresenterStateContext.Provider value={trayPresenterState}>
      <TrayPresenterClientContext.Provider value={trayPresenterClient}>
        {children}
      </TrayPresenterClientContext.Provider>
    </TrayPresenterStateContext.Provider>
  )
}
/*
 *  useTrayPresenter always re-renders all components it is in and child components when show/hide tray.
 *  We should avoid using this hook in wrapper or container components which contain many other components.
 *  It's best to always use this hook only for small components and don't move this hook to the top level.
 * */
export const useTrayPresenter = (tray: TrayInfo): TrayActions => {
  const { setTrays, hideTray, showTray, removeTray } = useContext(TrayPresenterClientContext)

  useEffect(() => {
    return () => removeTray(tray.id)
  }, [removeTray, tray.id])

  useEffect(() => {
    setTrays(prevTrays => {
      const trayIndex = prevTrays.findIndex(item => item.id === tray.id)
      if (trayIndex !== -1) {
        const newTrays = [...prevTrays]
        newTrays[trayIndex] = {
          ...tray,

          // The `TrayInfo` passed by the client does not contain the visibility info.
          // This is tacked on internally through the `showTray` and `hideTray` functions,
          // so we forward this info to the updated `tray` when it changes.
          isVisible: newTrays[trayIndex].isVisible,
        }
        return newTrays
      } else {
        return [...prevTrays, tray]
      }
    })
  }, [removeTray, setTrays, tray])

  const hideClientTray = useCallback(() => {
    hideTray(tray.id)
  }, [hideTray, tray])

  const showClientTray = useCallback(() => showTray(tray.id), [showTray, tray])

  const trayActions = useMemo(
    () => ({ showTray: showClientTray, hideTray: hideClientTray }),
    [hideClientTray, showClientTray],
  )

  return trayActions
}

export const useTrayPresenterState = (): TrayPresenterState => useContext(TrayPresenterStateContext)
export const useTraysVisibility = (): { isAnyTrayOpen: boolean } => {
  const { isAnyTrayOpen } = useContext(TrayPresenterClientContext)

  return { isAnyTrayOpen }
}
export const useHideAllTrays = (): { hideAllTrays: () => void } => {
  const { hideAllTrays } = useContext(TrayPresenterClientContext)

  return { hideAllTrays }
}

export function withTrayContextProvider<P>(
  Component: FunctionComponent<P>,
  displayName?: string,
): (props: P) => ReactElement {
  const WrappedComponent = (props): ReactElement => {
    return (
      <TrayContextProvider>
        <Component {...props} />
      </TrayContextProvider>
    )
  }
  WrappedComponent.displayName = displayName ?? Component.name
  return WrappedComponent
}
