import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import HelpMenu from 'components/Dashboard/NavBar/NavBarHeader/HelpMenu'
import { useAppSelector } from 'store/hooks'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export default function NavBarBottomContent({ isFixed }: { isFixed?: boolean }): ReactElement {
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const isTablet = useBreakpointIndex() === 1

  return (
    <Flex
      sx={{
        position: !isNavBarExpanded || isFixed ? 'fixed' : 'static',
        bottom: '1.6rem',
        flexDirection: ['row', 'column', isNavBarExpanded ? 'row' : 'column'],
        gap: ['1.6rem', 0, '1.6rem'],
        zIndex: 'zIndex300',
      }}
    >
      {isNavBarExpanded && !isTablet && <HelpMenu />}
    </Flex>
  )
}
