import { useColorMode } from 'theme-ui'
import { Setter } from './index'
import { useLayoutEffect } from 'react'
import { useLocation } from '@reach/router'

// using dark mode for free-dns page and light for the rest of the app, so we can allow the tray and setup guide to use different styles
export enum ColorMode {
  DARK = 'dark',
  LIGHT = 'light',
}

// TODO: should be removed after the dashboard redesign is completed
export default function useColorModeHelper(): {
  isDarkMode: boolean
  colorMode?: ColorMode
  setColorMode: Setter<ColorMode>
} {
  const [colorMode, setColorMode] = useColorMode<ColorMode>()
  const location = useLocation()

  useLayoutEffect(() => {
    if (!location.pathname.includes('free-dns')) {
      setColorMode(ColorMode.LIGHT)
    } else {
      setColorMode(ColorMode.DARK)
    }
    // colorMode should be in the dependency array to apply correct mode when open the modal dialog via the link
  }, [colorMode, location, setColorMode])

  return {
    isDarkMode: colorMode === ColorMode.DARK,
    colorMode,
    setColorMode,
  }
}
