import React, { ElementType, ReactElement, useMemo, useState } from 'react'
import noop from 'lodash/noop'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import MagicFolder from 'images/magic-folder.svg'
import NoRuleFolder from 'images/folder-icon-default.svg'
import BlockFolder from 'images/blockfolder.svg'
import BypassFolder from 'images/bypassfolder.svg'
import RedirectFolder from 'images/redirectfolder.svg'
import ForwardIcon from 'images/forward-icon.svg'
import { Button, ListItemActionMenu, Svg } from 'ui'
import { RuleAction } from 'utils/uniteRules'
import { EnabledStatus, RuleType } from 'store/api/rules/rules.interface'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import type { Setter } from 'utils'
import { memoOmittingProps } from 'utils'
import EditIcon from 'images/edit-icon.svg'
import DeleteIcon from 'images/delete-icon.svg'
import ExportIcon from 'images/export-rule.svg'
import { setGroupForDeletion, setIsCustomRule, updateGroupDraft } from 'store/customRules'
import { downloadFile } from 'utils/downloadFile'
import { batch } from 'react-redux'
import RuleOrGroupDeletionConfirmation from './RuleOrGroupDeletionConfirmation'
import { useGetProxiesQuery } from 'store/api/proxies'
import { useDeleteGroupMutation, useLazyGetJsonGroupQuery } from 'store/api/groups'
import { getOnKeyPressWith, stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import customUnescape from 'utils/customUnescape'
import useGetSelectedProfile from 'components/Dashboard/utils/useGetSelectedProfile'
import ExternalSvgIcon, { IconType } from 'components/Dashboard/ExternalSvgIcon'

export const largeViewItemHeight = '8.4rem'
export const smallViewItemHeight = '4.8rem'

interface GroupProps {
  group: string
  onClick?: () => void
  action: Partial<RuleAction>
  count?: number
  is_magic?: EnabledStatus
  PK: number
  showRuleTray: () => void
}
const Group = ({
  group,
  PK,
  onClick = noop,
  action,
  count = 0,
  is_magic,
  showRuleTray,
}: GroupProps): ReactElement => {
  const dispatch = useAppDispatch()
  const { data: proxiesData } = useGetProxiesQuery('')
  const redirectLocation = proxiesData?.proxies?.find(p => p.PK === action?.via)
  const shouldShowLargeView = useAppSelector(s => s.customRules.shouldShowLargeView)
  const [isConfirmDeletionOpen, setIsConfirmDeletionOpen] = useState(false)
  const [shouldShowListActionsMenu, setShouldShowListActionsMenu] = useState(false)
  const groupForDeletion = useAppSelector(s => s.groups.groupForDeletion)
  const rulesProfile = useGetSelectedProfile()
  const [deleteGroup] = useDeleteGroupMutation()

  const groupDetails: { icon: ElementType; description?: string } = useMemo(() => {
    switch (action.do) {
      case RuleType.BLOCK:
        return { icon: BlockFolder, description: `${count} Block Rule${count !== 1 ? 's' : ''}` }
      case RuleType.SPOOF_IP:
        return {
          icon: RedirectFolder,
          description: `${count} Redirect Rule${count !== 1 ? 's' : ''}`,
        }
      case RuleType.SPOOF_TAG:
        return {
          icon: RedirectFolder,
          description: `${count} Redirect (${
            redirectLocation?.city ||
            (action?.via === '?'
              ? 'a random location'
              : action?.via === 'LOCAL'
              ? 'Auto'
              : action?.via)
          }) Rule${count !== 1 ? 's' : ''}`,
        }
      case RuleType.WHITELIST:
        return {
          icon: BypassFolder,
          description: `${count} Bypass Rule${count !== 1 ? 's' : ''} `,
        }
      default:
        return { icon: !!is_magic ? MagicFolder : NoRuleFolder }
    }
  }, [action.do, action?.via, count, is_magic, redirectLocation?.city])

  return (
    <>
      <Flex
        variant="transparent"
        data-testid="group-list-item"
        className="group-list-item"
        role="gridcell"
        tabIndex={0}
        sx={{
          position: 'relative',
          width: '100%',
          alignItems: 'center',
          py: shouldShowLargeView ? '2.4rem' : '1.6rem',
          px: '2rem',
          mb: '1.6rem',
          height: shouldShowLargeView ? largeViewItemHeight : smallViewItemHeight,
          cursor: 'pointer',
          backgroundColor: 'darkItemBG',
          borderRadius: '16px',
          ':hover, :focus, :focus-within': {
            backgroundColor: ['darkItemBG', 'darkItemBG', 'darkItemBG50'],
            '& > svg path': {
              fill: 'white',
            },
            '.folder-options': {
              opacity: 1,
            },
          },
        }}
        onClick={onClick}
        onKeyDown={getOnKeyPressWith({ callback: onClick })}
      >
        <Flex
          sx={{ position: 'relative', alignItems: 'center' }}
          // Hack-ish: this key needs to change when shouldShowLargeView changes to force the svg size to update
          key={`${groupDetails.description}-${shouldShowLargeView ? 'large' : 'small'}`}
        >
          <Svg
            svg={groupDetails.icon}
            sx={{
              width: shouldShowLargeView ? '2.4rem' : '1.6rem',
              height: shouldShowLargeView ? '2.4rem' : '1.6rem',
              marginRight: '2rem',
              flexShrink: 0,
            }}
          />
          {redirectLocation?.country && (
            <ExternalSvgIcon
              icon={redirectLocation?.country}
              type={IconType.LOCATIONS}
              sx={{
                position: 'absolute',
                bottom: '-0.4rem',
                right: '1.7rem',
                width: shouldShowLargeView ? '1.6rem' : '1.2rem',
                height: shouldShowLargeView ? '1.6rem' : '1.2rem',
              }}
            />
          )}
        </Flex>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          data-testid={`group-list-item-info-${group}`}
        >
          <Text
            data-testid="group-list-item-name"
            tabIndex={0}
            aria-label={`Folder: ${group}`}
            sx={{
              fontWeight: 1,
              color: 'white',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: '1.6rem',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {customUnescape(group)}
          </Text>
          {shouldShowLargeView && (
            <TextWithOverFlowAndTippyPopup
              data-testid="group-list-item-custom-rules-count"
              sxText={{
                fontSize: '1.6rem',
                color: 'white50',
                width: '100%',
                textAlign: 'initial',
              }}
              content={
                groupDetails.description
                  ? groupDetails.description
                  : count
                  ? `${count} Rule${count > 1 ? 's' : ''}`
                  : 'No Rules'
              }
            />
          )}
        </Flex>
        <Flex sx={{ flexGrow: 1 }} />
        <FolderOptions
          {...{ group, PK, action, count, showRuleTray, setIsConfirmDeletionOpen }}
          sx={{ display: ['none', 'none', 'flex'] }}
        />
        <Svg
          svg={ForwardIcon}
          fill="white25"
          sx={{
            display: ['none', 'none', 'block'],
            width: '2.4rem',
            height: '2.4rem',
            flexShrink: 0,
          }}
        />
        {!isConfirmDeletionOpen && (
          <ListItemActionMenu
            shouldShowListActionsMenu={shouldShowListActionsMenu}
            setShouldShowListActionsMenu={setShouldShowListActionsMenu}
          >
            <FolderOptions
              {...{
                group,
                PK,
                action,
                count,
                showRuleTray,
                shouldShowListActionsMenu,
                setIsConfirmDeletionOpen,
              }}
              sx={{ display: ['flex', 'flex', 'none'], opacity: +shouldShowListActionsMenu }}
            />
          </ListItemActionMenu>
        )}
      </Flex>
      <RuleOrGroupDeletionConfirmation
        {...{ isConfirmDeletionOpen, setIsConfirmDeletionOpen, hostname: group }}
        onCancelButtonClick={() => {
          dispatch(setGroupForDeletion(undefined))
          setIsConfirmDeletionOpen(false)
        }}
        onConfirmButtonClick={async () => {
          if (groupForDeletion && rulesProfile?.PK) {
            await deleteGroup({
              profileId: rulesProfile.PK.toString(),
              group: groupForDeletion,
            })
          }
        }}
        message="Delete this folder permanently?"
        sx={{ height: shouldShowLargeView ? largeViewItemHeight : smallViewItemHeight }}
        role="gridcell"
      />
    </>
  )
}

export default memoOmittingProps(Group, ['onClick'])

interface FolderOptionsProps extends Omit<GroupProps, 'onClick'> {
  setIsConfirmDeletionOpen: Setter<boolean>
  shouldShowListActionsMenu?: boolean
  sx?: ThemeUIStyleObject
}

export function FolderOptions({
  group,
  PK,
  action,
  count = 0,
  showRuleTray,
  setIsConfirmDeletionOpen,
  shouldShowListActionsMenu = true,
  sx,
}: FolderOptionsProps): ReactElement {
  const dispatch = useAppDispatch()
  const rulesProfile = useGetSelectedProfile()
  const [exportJsonGroup] = useLazyGetJsonGroupQuery()

  return (
    <Flex
      className="folder-options"
      sx={{
        alignItems: 'center',
        opacity: 0,
        zIndex: 'zIndex2',
        gap: '1.6rem',
        ...sx,
      }}
    >
      <Button
        data-testid="edit-folder"
        aria-label="edit-folder"
        tabIndex={shouldShowListActionsMenu ? 0 : -1}
        onKeyDown={stopPropagationOnKeyPress}
        onClick={e => {
          e.stopPropagation()
          batch(() => {
            dispatch(setIsCustomRule(false))
            dispatch(
              updateGroupDraft({
                group,
                PK,
                action,
                count,
              }),
            )
          })
          showRuleTray()
        }}
        variant="simple"
        sx={{
          p: 0,
        }}
      >
        <Svg
          svg={EditIcon}
          fill="white50"
          sx={{
            width: '2.4rem',
            height: '2.4rem',
          }}
        />
      </Button>
      <Button
        disabled={!count}
        variant="simple"
        data-testid="export-rules"
        ariaLabel="export rules"
        tabIndex={shouldShowListActionsMenu ? 0 : -1}
        onKeyDown={stopPropagationOnKeyPress}
        onClick={async e => {
          e.stopPropagation()
          if (count > 0 && rulesProfile?.PK) {
            const response = await exportJsonGroup({
              group: PK,
              profileId: rulesProfile.PK.toString(),
            })

            if (response.data?.body) {
              downloadFile({
                content: response.data?.body?.export,
                fileTitle: response.data?.body?.filename,
                fileType: 'application/json',
              })
            }
          }
        }}
        sx={{
          p: 0,
          ':disabled': {
            backgroundColor: 'transparent',
            'svg path': {
              fill: 'white10',
            },
          },
        }}
      >
        <Svg
          svg={ExportIcon}
          fill="white50"
          sx={{
            width: '2.4rem',
            height: '2.4rem',
          }}
        />
      </Button>
      <Button
        data-testid="delete-folder"
        variant="simple"
        aria-label="delete-folder"
        tabIndex={shouldShowListActionsMenu ? 0 : -1}
        onKeyDown={stopPropagationOnKeyPress}
        onClick={e => {
          e.stopPropagation()
          dispatch(setGroupForDeletion(PK))
          setIsConfirmDeletionOpen(true)
        }}
        sx={{
          mr: '1.6rem',
          p: 0,
        }}
      >
        <Svg
          svg={DeleteIcon}
          fill="white50"
          sx={{
            width: '2.4rem',
            height: '2.4rem',
          }}
        />
      </Button>
    </Flex>
  )
}
