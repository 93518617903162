import React, { ReactElement } from 'react'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import ProfileOptionsImage from 'images/tutorialFlows/ProfileOptions/profile-options-slide.svg'
import { Text } from 'theme-ui'

export function ProfileOptionsSlide(): ReactElement {
  return (
    <TutorialBaseSlide
      Illustration={ProfileOptionsImage}
      description={
        <Text>
          Profile Options allow you to customize your Profile and enable various advanced features.{' '}
          <br /> <br />
          These include the <Highlight svg={tutorialSlideIcons.defaultRule}>
            Default Rule
          </Highlight>{' '}
          (which allows you to <Highlight svg={tutorialSlideIcons.block}>Block</Highlight>,{' '}
          <Highlight svg={tutorialSlideIcons.redirect}>Redirect</Highlight>, or{' '}
          <Highlight svg={tutorialSlideIcons.bypass}>Bypass</Highlight> all DNS traffic), cache TTL
          override settings, Restricted Mode on search engines and YouTube, and more. <br /> <br />
          Each Option has a Learn More link where you can find out what it does.
        </Text>
      }
    />
  )
}
