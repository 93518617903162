import React, { ElementType, ReactElement, useMemo, useRef, useState } from 'react'
import { Flex, Text } from 'theme-ui'
import {
  BackIcon,
  Button,
  IconButtonContent,
  IconMenu,
  SimpleActionMenuItemProps,
  Tray,
  TrayRenderProps,
} from 'ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { resetFilterParameters } from 'store/activityLog'
import FilterIcon from 'images/menus/analytics/filter-icon.svg'
import ForwardIcon from 'images/analytics/forward-arrow.svg'
import LocationIcon from 'images/menus/analytics/location-icon.svg'
import ServicesIcon from 'images/menus/analytics/all-services-icon.svg'
import QueryFilterDropdownContent from './Dropdowns/QueryFilterDropdownContent'
import TrayHeader from 'components/TrayHeader'
import { CloseButton } from 'components/SetupGuide/Components'
import { ButtonStyles } from 'ui/ModalDialog/ModalDialogHeader'
import {
  aiMalware,
  getFilterTextForFiltersWithLevels,
} from 'components/Dashboard/Analytics/ActivityLog/Dropdowns/FiltersDropdown'
import { trayHeaderHeight } from 'components/TrayOrModalDialog/Tray.mobile'
import { useGetProxiesQuery } from 'store/api/proxies'
import { useGetAllFiltersQuery } from 'store/api/filters'
import { useGetAllServicesCategoriesQuery } from 'store/api/services/services'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export enum QueriesFilterMenuType {
  ACTION = 'action',
  PROTOCOL = 'protocol',
  FILTERS = 'filters',
  SERVICES = 'services',
  LOCATIONS = 'locations',
}

const menuOptions: Record<QueriesFilterMenuType, { label: string; icon: ElementType }> = {
  [QueriesFilterMenuType.FILTERS]: {
    label: 'Filters',
    icon: FilterIcon,
  },
  [QueriesFilterMenuType.LOCATIONS]: {
    label: 'Locations',
    icon: LocationIcon,
  },
  [QueriesFilterMenuType.SERVICES]: {
    label: 'Services',
    icon: ServicesIcon,
  },
  [QueriesFilterMenuType.ACTION]: {
    label: 'Action',
    icon: ServicesIcon,
  },
  [QueriesFilterMenuType.PROTOCOL]: {
    label: 'Protocol',
    icon: ServicesIcon,
  },
}

export default function QueryFilterMobileTray({ dismiss }: TrayRenderProps): ReactElement {
  const [selectedDropdownSubmenu, setSelectedDropdownSubmenu] = useState<
    QueriesFilterMenuType | 'none'
  >('none')
  const dispatch = useAppDispatch()
  const queriesFilters = Object.values(QueriesFilterMenuType)
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const { data: allFilters } = useGetAllFiltersQuery('')
  const nativeFilters = allFilters?.filters
  const externalFilters = allFilters?.external_filters
  const filtersWithLevels = getFilterTextForFiltersWithLevels(nativeFilters || [])
  const activeFilters = useMemo(
    () => [
      aiMalware,
      ...filtersWithLevels,
      ...(nativeFilters?.filter(f => f.PK === 'malware' || !f.levels?.length) || []),
      ...(externalFilters || []),
    ],
    [externalFilters, filtersWithLevels, nativeFilters],
  )

  // for getting the generic services data we do not need to pass profileid
  const { data: allServicesData } = useGetAllServicesCategoriesQuery({ profileId: undefined })
  const { data: proxiesData } = useGetProxiesQuery('')
  const activeLocations = proxiesData?.proxies
  const { data: userData } = useGetUser()
  const headerRef = useRef<HTMLDivElement>(null)

  const filtersOptions: SimpleActionMenuItemProps[] = useMemo(() => {
    const options: SimpleActionMenuItemProps[] =
      queriesFilters.map((type: QueriesFilterMenuType): SimpleActionMenuItemProps => {
        const filtersNotExist = type === QueriesFilterMenuType.FILTERS && !activeFilters?.length
        const servicesNotExist =
          type === QueriesFilterMenuType.SERVICES && !allServicesData?.services?.length
        const locationsNotExist =
          type === QueriesFilterMenuType.LOCATIONS && !activeLocations?.length

        const shouldHideOption = filtersNotExist || servicesNotExist || locationsNotExist

        if (shouldHideOption) {
          return {
            children: undefined,
          }
        }

        const option = menuOptions[type]
        const parametersNumber = filterParameters[type] === 'all' ? 0 : +!!filterParameters[type]

        return {
          dataTestId: `${type}-analytics-queries-dropdown-item`,
          children: (
            <IconButtonContent endIcon={ForwardIcon}>
              <Flex sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
                <Text
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {option?.label}
                </Text>

                {!!parametersNumber && (
                  <Text
                    sx={{
                      ml: '1.6rem',
                      mr: '0.8rem',
                      color: 'greenApple',
                    }}
                  >
                    {parametersNumber}
                  </Text>
                )}
              </Flex>
            </IconButtonContent>
          ),
          onClick: () => {
            setSelectedDropdownSubmenu(type)
          },
        }
      }) ?? []

    return options
  }, [queriesFilters, activeFilters, allServicesData?.services, activeLocations, filterParameters])

  const dropdownView = useMemo((): ReactElement => {
    return selectedDropdownSubmenu === 'none' ? (
      <IconMenu
        items={filtersOptions}
        sxContainer={{ width: '100%', height: '100%' }}
        sxButton={{
          ':hover': {
            fill: 'none',
            opacity: 1,
          },
        }}
      />
    ) : (
      <QueryFilterDropdownContent
        headerRef={headerRef}
        type={selectedDropdownSubmenu}
        filters={activeFilters}
        services={allServicesData?.services}
        locations={activeLocations}
        dismiss={dismiss}
      />
    )
  }, [
    dismiss,
    selectedDropdownSubmenu,
    filtersOptions,
    activeFilters,
    activeLocations,
    allServicesData?.services,
  ])

  return (
    <Tray
      dataTestId="filter-menu-tray"
      entersFrom="bottom"
      showOverlay={false}
      isOpen={true}
      sx={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        flexDirection: 'column',
        backgroundColor: 'darkItemBG',
        zIndex: 'zIndex550',
        overflowY: 'hidden',
      }}
      slideDimensionLength={'100%'}
      shouldVibrateOnPresent={!!userData?.haptics}
      disableFocusLock={true}
    >
      <TrayHeader
        headerRef={headerRef}
        sx={{
          width: '100%',
          p: 0,
          height: trayHeaderHeight,
          alignItems: 'center',
          fontSize: '1.8rem',
          fontWeight: 'bold',
        }}
        leftComponent={
          selectedDropdownSubmenu !== 'none' ? (
            <Button
              variant="simple"
              aria-label="go back"
              sx={{
                p: 0,
                ml: '1.6rem',
                border: 0,
                textAlign: 'center',
                justifyContent: 'center',
                ':hover': {
                  backgroundColor: 'white25',
                },
                '&:hover > svg path': {
                  fill: 'white',
                },
                ...ButtonStyles,
              }}
              onClick={(): void => {
                setSelectedDropdownSubmenu('none')
              }}
              data-testid="back-button"
            >
              <BackIcon fill="white50" sx={{ width: '2.4rem', height: '2.4rem' }} />
            </Button>
          ) : (
            <CloseButton sx={ButtonStyles} onClick={dismiss} />
          )
        }
      >
        {selectedDropdownSubmenu === 'none'
          ? `Queries Filters`
          : menuOptions[selectedDropdownSubmenu]?.label}
        {selectedDropdownSubmenu === 'none' && (
          <Button
            variant="transparent"
            sx={{
              position: 'fixed',
              bottom: '0rem',
              left: '0rem',
              width: '100%',
              color: 'white50',
              fontSize: '1.6rem',
              borderTop: '1px solid',
              borderTopColor: 'white10',
            }}
            data-testId={'clear-queries-filter'}
            onClick={() => {
              dispatch(resetFilterParameters())
              dismiss?.()
            }}
          >
            Clear Filters
          </Button>
        )}
      </TrayHeader>
      {dropdownView}
    </Tray>
  )
}
