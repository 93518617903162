import React, { CSSProperties, ReactElement, RefObject, useEffect } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import { Grid, AutoSizer, WindowScroller } from 'react-virtualized'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

const defaultItemHeight = 200

export type SizePerBreakpoint = [number, number, number]
export type GapPerBreakpoint = [string, string, string] | 0

interface CellRendererProps {
  key: string
  columnIndex: number
  isScrolling: boolean
  isVisible: boolean
  rowIndex: number
  style: CSSProperties
}

interface VirtualizedGridProps {
  dataTestId?: string
  itemCount: number
  itemHeightsPx?: SizePerBreakpoint
  itemRenderer: (index: number) => ReactElement | null
  itemsPerRow?: SizePerBreakpoint
  gap?: GapPerBreakpoint
  overscanRowCount?: number
  sx?: ThemeUIStyleObject
  scrollingContainerRef: RefObject<HTMLDivElement>
  tabIndex?: number
}

export default function VirtualizedGrid({
  dataTestId,
  itemCount = 0,
  itemHeightsPx = [defaultItemHeight, defaultItemHeight, defaultItemHeight],
  itemRenderer,
  itemsPerRow = [1, 1, 2],
  gap = ['1.6rem', '1.6rem', '2.4rem'],
  overscanRowCount = 0,
  sx,
  scrollingContainerRef,
  tabIndex,
}: VirtualizedGridProps): ReactElement | null {
  const breakpoint = useBreakpointIndex()

  useEffect(() => {
    scrollingContainerRef.current?.scrollTo(0, 0)
  }, [scrollingContainerRef])

  // Make sure everything's ready for proper size calc
  if (breakpoint < 0 || !itemCount || !scrollingContainerRef.current) {
    return null
  }

  const columnCount = itemsPerRow[breakpoint]
  const rowHeight = itemHeightsPx[breakpoint]
  const columnGap = gap && gap[breakpoint]

  return (
    <Flex
      data-testid={dataTestId}
      sx={{
        flexDirection: 'column',
        flex: '1 1 auto',
        ...sx,
      }}
    >
      <WindowScroller scrollElement={scrollingContainerRef.current}>
        {({ height = 0, isScrolling, scrollTop, onChildScroll }) => (
          <AutoSizer disableHeight>
            {({ width }) => {
              return (
                <Grid
                  autoHeight
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                  width={width}
                  height={height || 0}
                  overscanRowCount={overscanRowCount}
                  cellRenderer={({
                    columnIndex,
                    key,
                    isVisible,
                    rowIndex,
                    style,
                  }: CellRendererProps) => {
                    const item = itemRenderer(rowIndex * columnCount + columnIndex)
                    return (
                      item && (
                        <Flex
                          data-testid="grid-cell"
                          role="row" // role="row" important for accessibility
                          key={key}
                          style={{
                            ...style,
                            padding: `0 ${
                              columnIndex === columnCount - 1 ? 0 : `calc(${columnGap} / 2)`
                            } ${columnGap} ${columnIndex === 0 ? 0 : `calc(${columnGap} / 2)`}`,
                            pointerEvents: 'visible',
                          }}
                        >
                          {isVisible && item}
                        </Flex>
                      )
                    )
                  }}
                  columnCount={columnCount}
                  columnWidth={Math.trunc(width / columnCount)}
                  rowCount={Math.ceil(itemCount / columnCount)}
                  rowHeight={rowHeight}
                  tabIndex={tabIndex}
                />
              )
            }}
          </AutoSizer>
        )}
      </WindowScroller>
    </Flex>
  )
}
