export default {
  button: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  cardLabel: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '1.2rem',
    fontWeight: 'normal',
  },
  pageHeading: {
    fontSize: '4rem',
    fontWeight: 600,
  },
  title: {
    fontSize: '2.1rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  subTitle: {
    fontSize: '1.6rem',
    fontWeight: 400,
  },
  boldSubTitle: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  modalTitle: {
    fontSize: '2.1rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  sectionLabel: {
    fontSize: '1.2rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  sectionTitle: {
    fontSize: '1.7rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  buttonText: {
    fontSize: '1.7rem',
    fontWeight: 500,
    lineHeight: '138%',
  },
  subText: {
    fontSize: '1.2rem',
    fontWeight: 'normal',
  },
  tabText: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  button2: {
    fontSize: '1.7rem',
    fontWeight: 500,
    lineHeight: '138%',
  },
  address: {
    fontSize: '1.5rem',
    fontWeight: 'normal',
  },
  trayText: {
    fontSize: '1.6rem',
    fontWeight: 400,
  },
  pageHeader: {
    fontSize: '2.1rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  ipsInformation: {
    fontSize: '1.7rem',
    fontWeight: 'normal',
  },
  mobileSectionHeading: {
    fontSize: '2.8rem',
    fontWeight: 600,
  },
  standardConfigurationTab: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
  },
  standardConfigurationCardContent: {
    fontSize: '1.6rem',
    fontWeight: 'normal',
  },
  sectionTitle2: {
    fontSize: '1.5rem',
    fontWeight: 400,
  },
  size16Weight400Line150: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '150%',
  },
  size12Weight400: {
    fontSize: '1.2rem',
    fontWeight: 400,
  },
  size12Weight700: {
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  size16Weight400Line140: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '140%',
  },
  size18Weight500Line140: {
    fontSize: '1.8rem',
    fontWeight: 500,
    lineHeight: '140%',
  },
  size16Weight500Line138: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '138%',
  },
  size17Weight500Line138: {
    fontSize: '1.7rem',
    fontWeight: 500,
    lineHeight: '138%',
  },
  size18Weight800Line140: {
    fontSize: '1.8rem',
    fontWeight: 800,
    lineHeight: '140%',
  },
  size16Weight400: {
    fontSize: '1.6rem',
    fontWeight: 400,
  },
  size16Weight500Line140Letter008: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '140%',
    letterSpacing: 0.08,
  },
  size15Weight700Line138: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '138%',
  },
  size15Weight600Line138: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size14Weight600: {
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  size14Weight600Line138: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: '138%',
  },
  size15Weight400Line138: {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '138%',
  },
  size12Weight400Line138: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '138%',
  },
}
