import React, { ReactElement } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import {
  FreeResolverType,
  FilterResolver,
} from 'components/FreeDNSPage/FreeDNSTray/FreeDNSTrayContent'

export default function SingleResolverTypeContent({
  resolver,
  filterResolver,
}: {
  resolver?: FreeResolverType
  filterResolver: FilterResolver
}): ReactElement {
  return (
    <Flex sx={{ flexDirection: ['column', 'row'], display: ['none', 'flex'], mb: ['2.4rem', 0] }}>
      <Flex sx={{ flexDirection: 'column', justifyContent: 'center' }}>
        <ResolverValue
          title="PRIMARY"
          values={[resolver?.value || filterResolver.uri]}
          isSingle={true}
        />
      </Flex>
      {resolver?.secondaryValue && (
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            ml: [0, '2.4rem'],
            mt: ['1.6rem', 0],
          }}
        >
          <ResolverValue title="SECONDARY" values={[resolver.secondaryValue]} isSingle={true} />
        </Flex>
      )}
    </Flex>
  )
}

type ResolverSection = {
  title: string
  resolvers: string[]
}

export function MultipleResolverTypeContent({
  sections,
  shouldDisplayAsColumn,
}: {
  sections: ResolverSection[]
  shouldDisplayAsColumn?: boolean
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: ['column', shouldDisplayAsColumn ? 'column' : 'row', 'row'],
        gap: [0, shouldDisplayAsColumn ? '0.8rem' : '4.8rem', '4.8rem'],
        justifyContent: 'center',
      }}
    >
      {sections.map(section => {
        return (
          <Flex key={section.title} sx={{ flexDirection: 'column', justifyContent: 'center' }}>
            <ResolverValue
              title={section.title}
              values={section.resolvers}
              copyButtonSx={{ width: shouldDisplayAsColumn ? 'auto' : '100%' }}
            />
          </Flex>
        )
      })}
    </Flex>
  )
}

function ResolverValue({
  values,
  title,
  isSingle = false,
  copyButtonSx,
}: {
  values: string[]
  title: string
  isSingle?: boolean
  copyButtonSx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <>
      <Text
        variant="sectionTitle"
        sx={{
          color: 'title',
          mb: '0.8rem',
        }}
        tabIndex={0}
      >
        {title}
      </Text>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {values.map((value, index) => (
          <CopyToClipboard
            aria-label="copy resolver to clipboard"
            theme="org-tooltip"
            fillSvg="body"
            key={index}
            smallIcon={true}
            data={value}
            sx={{ justifyContent: 'space-between', width: '100%', ...copyButtonSx }}
            iconCustomStyle={{
              display: 'inline-block',
              maxWidth: '1.6rem',
              maxHeight: '1.6rem',
              flexShrink: 0,
              ml: '0.8rem',
              verticalAlign: 'middle',
            }}
          >
            <Text
              variant="subTitle"
              sx={{
                maxWidth: isSingle ? '82rem' : '35rem',
                color: 'body',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
              }}
            >
              {value}
            </Text>
          </CopyToClipboard>
        ))}
      </Flex>
    </>
  )
}
