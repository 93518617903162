import React, { ReactElement } from 'react'
import { Box, Divider, Flex } from 'theme-ui'
import { MainNavMenuItems } from './NavMenu'
import { PreferencesNavMenu } from './PreferencesNavMenu'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import UpcomingFeaturesPlaceholder from './UpcomingFeaturesPlaceholder'
import { useAppSelector } from 'store/hooks'

export default function NonOrganizationNavBarContent(): ReactElement {
  const isMobile = useBreakpointIndex() === 0
  const isDesktop = useBreakpointIndex() === 2
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)

  return (
    <Flex
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        pb: [0, '1.6rem'],
        pt: ['1.6rem', 0],
        px: ['1.6rem', 0],
        flexShrink: [0, 1],
      }}
    >
      <Box
        sx={{
          display: ['none', 'block'],
          position: 'absolute',
          width: '100%',
          top: 0,
          pb: '1.8rem',
          backgroundImage: 'linear-gradient(180deg, #12131C 40%, rgba(18, 19, 28, 0) 100%)',
          zIndex: 'zIndex50',
        }}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          flexShrink: 0,
          pb: ['1.6rem', '1.2rem'],
          pt: [0, '1.6rem'],
          maxHeight: ['fit-content', 'calc(100% - 4.8rem)'],
          overflowY: ['hidden', 'auto'],
        }}
        className="hide-scrollbar"
      >
        <MainNavMenuItems />
        <Divider sx={{ my: ['1.6rem', '2.4rem'], color: 'white10' }} />
        <PreferencesNavMenu />
      </Flex>
      {(isMobile || (isDesktop && isNavBarExpanded)) && (
        <Flex sx={{ pb: '1.6rem' }}>
          <UpcomingFeaturesPlaceholder
            sx={{
              position: ['relative', 'fixed'],
              bottom: ['unset', '1.6rem'],
            }}
          />
        </Flex>
      )}
    </Flex>
  )
}
