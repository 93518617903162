import React, { ReactElement } from 'react'
import { Flex, Spinner, Box } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function ConfigurationCode({
  text,
  shouldWrap,
}: {
  text: string
  shouldWrap?: boolean
}): ReactElement {
  const { isLoading } = useGetUser()
  const splitText = text.split('\n')

  return (
    <Flex
      sx={{
        width: ['100%', '57.6rem'],
        height: '9.6rem',
        backgroundColor: 'greenApple15',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'space-between',
        borderRadius: '16px',
        px: '1.6rem',
        '& .copy-icon-wrapper': {
          p: '1.2rem',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'greenApple15',
          ':hover': {
            backgroundColor: 'white10',
          },
        },
      }}
    >
      {isLoading ? (
        <Spinner color="greenApple" />
      ) : (
        <Box>
          {splitText.map(text => (
            <Box
              key={text}
              sx={{
                maxWidth: ['100%', '43rem', '69rem'],
                color: 'greenApple',
                whiteSpace: shouldWrap ? 'normal' : 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {text}
            </Box>
          ))}
        </Box>
      )}
      <CopyToClipboard smallIcon data={text.trim()} fillSvg="greenApple" />
    </Flex>
  )
}
