import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg } from 'ui'
import CheckmarkIcon from 'images/checkmark.svg'
import InfoIcon from 'images/infoicon.svg'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'

interface FeatureProps {
  type?: string
  name: string | ReactElement
  tooltip?: string
}
function Feature({ name, tooltip, type }: FeatureProps): ReactElement {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        my: '0.8rem',
        fontSize: ['1.6rem', '1.8rem'],
        height: '2.7rem',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Svg
          svg={CheckmarkIcon}
          fill={type ? 'greenApple' : 'white50'}
          sx={{ width: '3.2rem', height: '3.2rem' }}
        />
        <Text
          sx={{
            fontSize: '1.6rem',
            ml: '1.4rem',
            color: type ? 'greenApple' : 'white50',
          }}
        >
          {name}
        </Text>
      </Flex>
      {tooltip && (
        <Flex
          sx={{
            alignItems: 'center',
            ml: '0.4rem',
            ':hover': {
              '& svg path': {
                fill: [undefined, undefined, 'white'],
              },
            },
          }}
        >
          <IconWithTippyPopup
            content={tooltip}
            svg={InfoIcon}
            fill="white50"
            sx={{ width: '1.6rem', height: '1.6rem' }}
            maxWidth={280}
          />
        </Flex>
      )}
    </Flex>
  )
}
export default Feature
