import React, { ReactElement, ReactNode } from 'react'
import GreenCheck from 'images/ui/green-check-grid.svg'
import WhiteCheck from 'images/ui/white-check-grid.svg'
import { Svg } from 'ui'
import Cell from './components/Cell'
import Column from './components/Column'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

const DataColumn = ({
  columnData,
  columnIndex,
  totalColumns,
  isFirstColumn,
  isControlDColumn,
}: {
  columnData: (ReactNode | boolean)[]
  columnIndex: number
  totalColumns: number
  isFirstColumn?: boolean
  isControlDColumn?: boolean
}): ReactElement => {
  const breakpointIndex = useBreakpointIndex()
  const isTablet = breakpointIndex === 1
  return (
    <Column dataTestId={`column-${columnData[0]}`}>
      {columnData.map((cellData, rowIndex) => (
        <Cell
          key={`cell-${rowIndex}-${columnIndex}-${cellData}`}
          sx={{
            minHeight: '4.4rem',
            lineHeight: '2.8rem',
            ...(rowIndex === 0
              ? {
                  // only first row
                  fontWeight: 700,
                  p: '2.4rem 1.6rem',
                  whiteSpace: 'nowrap',
                }
              : rowIndex > 6 && isTablet
              ? {
                  p: '0.8rem 1.6rem',
                  // to handle text wrapping
                  minHeight: '7.2rem',
                }
              : { p: '0.8rem 1.6rem' }),

            ...(rowIndex % 2 ? { backgroundColor: 'white5' } : {}), // alternate row
            ...(rowIndex % 2 && isFirstColumn
              ? {
                  // first column rounded borders
                  borderStartStartRadius: '0.8rem',
                  borderEndStartRadius: '0.8rem',
                }
              : {}),
            ...(rowIndex % 2 && columnIndex === totalColumns - 1
              ? {
                  // last column rounded borders
                  borderStartEndRadius: '0.8rem',
                  borderEndEndRadius: '0.8rem',
                }
              : {}),
            ...(isControlDColumn
              ? {
                  // colntrol-d column green
                  color: 'greenApple',
                }
              : {
                  color:
                    rowIndex === 0 //header row is white
                      ? 'white'
                      : 'white50',
                }),
            ...(isFirstColumn
              ? isTablet
                ? {}
                : { whiteSpace: 'nowrap' } // no wrap for first column on desktop
              : { justifyContent: 'center' }),
          }}
        >
          {cellData === true ? (
            <Svg
              svg={isControlDColumn ? GreenCheck : WhiteCheck}
              sx={{
                width: '2.2rem',
                height: '1.55rem',
                mt: ['0.5rem', rowIndex > 6 ? '2rem' : '0.5rem', '0.5rem'],
              }}
            />
          ) : (
            cellData
          )}
        </Cell>
      ))}
    </Column>
  )
}
export default DataColumn
