import React, { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { standardConfigurationsData } from 'components/FreeDNSPage/constants/freeDNS'
import { Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui'
import CloseIcon from 'images/freeDNS/close-icon.svg'
import CheckIcon from 'images/organization/homepage/sections/industries/check.svg'
import FreeDNSTrayTabs from 'components/FreeDNSPage/FreeDNSTray/FreeDNSTrayTabs'
import { DnsProtocol, DnsProtocolCollection } from 'constants/dnsServers'
import {
  remove3rdPartyFilterPK,
  removeSelectedDNSKey,
  removeSelectedFiltersPK,
} from 'store/freeDNS'
import { staticPageDesktopMaxWidth } from 'components/StaticPage/StaticPage'
import { ThemeUIStyleObject } from '@theme-ui/css'
import type { Setter } from 'utils'
import { useGetFreeDNSFiltersQuery } from 'store/api/freeDns'

const FreeDNSTrayHeader = ({
  selectedDnsProtocol,
  setSelectedDnsProtocol,
  setIsOpenTray,
}: {
  selectedDnsProtocol: DnsProtocol | DnsProtocolCollection
  setSelectedDnsProtocol: Setter<DnsProtocol | DnsProtocolCollection>
  setIsOpenTray: Setter<boolean>
}): ReactElement => {
  const selectedFiltersCount = useAppSelector(s => s.freeDNS.selectedFiltersPKs?.length || 0)
  const selectedDNSKey = useAppSelector(s => s.freeDNS.selectedDNSKey)
  const { data: freeDnsData } = useGetFreeDNSFiltersQuery('')
  const selected3rdPartyFilterPK = useAppSelector(s => s.freeDNS.selected3rdPartyFilterPK)
  const selected3rdPartyFilterTitle = freeDnsData?.external_filters.find(
    filter => filter.PK === selected3rdPartyFilterPK,
  )?.name
  const selectedDNS = standardConfigurationsData.find(item => item.key === selectedDNSKey) || {
    title: '',
  }

  return (
    <Flex
      sx={{
        position: 'relative',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: '1.2rem',
        borderTopRightRadius: '1.2rem',
        borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'color4',
      }}
    >
      <Flex
        sx={{
          width: ['100%', '100%', staticPageDesktopMaxWidth],
          px: '1.6rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 0,
          '@media screen and (min-width: 1120px) and (max-width: 1170px)': {
            width: '104rem',
          },
        }}
      >
        <Flex
          data-testid="free-dns-config-header-content"
          sx={{
            display: 'flex',
            width: '100%',
            pr: [0, '2.4rem', 0],
            height: ['6.4rem', 'auto'],
            '@media screen and (max-width: 900px)': { pr: '1.6rem' },
          }}
        >
          <FreeDnsTrayConfigurationLabel
            selectedFiltersCount={selectedFiltersCount}
            title={selectedDNS.title}
            thirdPartyFilterTitle={selected3rdPartyFilterTitle}
          />
          <FreeDNSTrayTabs
            selectedDnsProtocol={selectedDnsProtocol}
            setSelectedDnsProtocol={setSelectedDnsProtocol}
          />
        </Flex>
        <CloseButton setIsOpenTray={setIsOpenTray} />
      </Flex>
    </Flex>
  )
}

const FreeDnsTrayConfigurationLabel = ({
  title,
  selectedFiltersCount,
  thirdPartyFilterTitle,
}: {
  title: string
  selectedFiltersCount: number
  thirdPartyFilterTitle?: string
}): ReactElement => (
  <Flex sx={{ alignItems: 'center' }}>
    <Svg svg={CheckIcon} sx={{ mr: '1.1rem', flexShrink: 0 }} />
    <Text
      variant="modalTitle"
      sx={{
        color: 'greenAppleNew',
        whiteSpace: 'nowrap',
        '@media screen and (max-width: 900px)': { fontSize: '1.7rem' },
      }}
      tabIndex={0}
    >
      {thirdPartyFilterTitle
        ? `Configure ${thirdPartyFilterTitle}`
        : title
        ? `${title} Configuration`
        : `${selectedFiltersCount} Filter${selectedFiltersCount > 1 ? 's' : ''} Selected`}
    </Text>
  </Flex>
)

const CloseButton = ({ setIsOpenTray }: { setIsOpenTray: Setter<boolean> }): ReactElement => {
  const dispatch = useAppDispatch()
  const isDesktop = useBreakpointIndex() === 2

  const absoluteStyles: ThemeUIStyleObject = isDesktop
    ? {
        position: 'absolute',
        right: '1.6rem',
        top: '50%',
        transform: 'translate(0, -50%)',
      }
    : {}

  return (
    <Button
      variant="simple"
      sx={{
        width: '4rem',
        height: '4rem',
        border: 'none',
        flexShrink: 0,
        borderRadius: '50%',
        backgroundColor: 'color15',
        ...absoluteStyles,
      }}
      onClick={(): void => {
        setIsOpenTray(false)
        dispatch(removeSelectedDNSKey())
        dispatch(removeSelectedFiltersPK())
        dispatch(remove3rdPartyFilterPK())
      }}
      data-testid="close-free-dns-tray-button"
      ariaLabel="close tray"
    >
      <Svg
        svg={CloseIcon}
        fill="white50"
        sx={{ width: '2.4rem', height: '2.4rem', flexShrink: 0 }}
      />
    </Button>
  )
}

export default FreeDNSTrayHeader
