import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import { Svg } from 'ui'
import ExternalLinkIcon from 'images/dashboard/external-link-icon.svg'
import ArrowIcon from 'images/dashboard/arrow-right.svg'
import ExternalLink from 'ui/ExternalLink/ExternalLink.base'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

interface MenuItemProps {
  to?: string
  text: string
  boundaryElement?: Element | 'parent'
}
export function MenuItem({ text, to }: MenuItemProps): ReactElement {
  return to ? (
    <ExternalLink to={to} sx={{ width: '100%' }} data-testid={`test-${text}`}>
      <MenuItemContent text={text} hasExternalLinkIcon />
    </ExternalLink>
  ) : (
    <MenuItemContent text={text} data-testid={`test-${text}`} />
  )
}

interface MenuItemContentProps {
  text: string
  hasExternalLinkIcon?: boolean
}
export function MenuItemContent({
  text,
  hasExternalLinkIcon = false,
}: MenuItemContentProps): ReactElement {
  return (
    <Flex
      sx={{
        position: 'relative',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <TextWithOverFlowAndTippyPopup
        sxText={{ color: 'white50', fontSize: '1.6rem', maxWidth: '20rem' }}
        sxTooltipContent={{ color: 'white50', p: '0.8rem' }}
        content={text}
      />
      <Svg svg={hasExternalLinkIcon ? ExternalLinkIcon : ArrowIcon} sx={{ flexShrink: 0 }} />
    </Flex>
  )
}
