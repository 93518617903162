import React, { ReactElement } from 'react'
import TabsContainer, { Tab } from 'components/HomePage/TabsContainer'
import { DnsProtocol, DnsProtocolCollection } from 'constants/dnsServers'
import { Text } from 'theme-ui'
import type { Setter } from 'utils'
import { useAppSelector } from 'store/hooks'

export const dnsDefaultProtocolOptions = [
  {
    text: 'DNS-over-HTTPS/3',
    value: DnsProtocol.DNS_OVER_HTTPS,
  },
  {
    text: 'DNS-over-TLS/DoQ',
    value: DnsProtocol.DNS_OVER_TLS,
  },
]

export const dnsIpv4Ipv6ProtocolOptions = [
  {
    text: 'Legacy DNS - IPv4',
    value: DnsProtocol.LEGACY_DNS_V4,
  },
  {
    text: 'Legacy DNS - IPv6',
    value: DnsProtocol.LEGACY_DNS_V6,
  },
]

const legacyOrSecureOptions = [
  { text: 'Legacy Resolvers', value: DnsProtocolCollection.ALL_LEGACY },
  { text: 'Secure Resolvers', value: DnsProtocolCollection.ALL_SECURE },
]

const FreeDNSTrayTabs = ({
  selectedDnsProtocol,
  setSelectedDnsProtocol,
}: {
  selectedDnsProtocol: DnsProtocol | DnsProtocolCollection
  setSelectedDnsProtocol: Setter<DnsProtocol | DnsProtocolCollection>
}): ReactElement => {
  const selectedFiltersPK = useAppSelector(s => s.freeDNS.selectedFiltersPKs) ?? []
  const tabs = selectedFiltersPK.length ? dnsDefaultProtocolOptions : legacyOrSecureOptions

  return (
    <TabsContainer
      sx={{
        display: ['none', 'flex'],
        border: 'none',
        width: '100%',
        alignSelf: 'flex-start',
        justifyContent: ['flex-start', 'flex-end'],
        pl: ['1.6rem', '2.4rem', 0],
        pt: 0,
      }}
    >
      {tabs.map(option => (
        <Tab
          variant="transparent"
          key={option.text}
          onClick={() => setSelectedDnsProtocol(option.value || 0)}
          isActive={selectedDnsProtocol === option.value}
          sxTab={{
            pt: 0,
            pb: '0.4rem',
            mt: '2.6rem',
            mb: '1.8rem',
            ml: '2.4rem',
            mr: 0,
            px: 0,
            maxWidth: 'fit-content',
            '&:hover': {
              color: 'title',
            },
            color: selectedDnsProtocol === option.value ? 'title' : 'body',
            borderBottom: '2px solid',
            borderBottomColor: selectedDnsProtocol === option.value ? 'title' : 'transparent',
            '@media screen and (max-width: 900px)': { ml: '1.4rem' },
          }}
        >
          <Text variant="sectionTitle">{option.text}</Text>
        </Tab>
      ))}
    </TabsContainer>
  )
}

export default FreeDNSTrayTabs
