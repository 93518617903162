import React, { ReactElement } from 'react'
import { VerdictTypeFilter } from 'components/Dashboard/Analytics/Statistics/QueriesByVerdictLineGraph/QueriesByVerdictLineGraph.interface'
import { RuleType } from 'store/api/rules'
import { useAppSelector } from 'store/hooks'
import { QueriesFilterMenuType } from '../QueryFilterMobileTray'
import QueryFilterDropdownContent from './QueryFilterDropdownContent'
import QueryFilterDropdown from './QueryFilterDropdown'
import actionIcons from 'components/Dashboard/IconMap/ActionIcons'

interface Action {
  label: string
}

const actionByVerdict: Record<Exclude<VerdictTypeFilter, 'all'>, Action> = {
  [RuleType.RESET]: { label: 'Failed' },
  [RuleType.BLOCK]: { label: 'Blocked' },
  [RuleType.WHITELIST]: { label: 'Bypassed' },
  [RuleType.SPOOF_TAG]: {
    label: 'Redirected',
  },
}

export const verdictTypeFilters: VerdictTypeFilter[] = [
  'all',
  RuleType.BLOCK,
  RuleType.WHITELIST,
  RuleType.SPOOF_TAG,
  RuleType.RESET,
]

export const verdictTypesToDropdownItemData: Record<VerdictTypeFilter, { label: string }> = {
  all: { label: 'All Actions' },
  ...actionByVerdict,
}

export default function ActionsDropdown(): ReactElement {
  const filterParameters = useAppSelector(s => s.activityLog.queryFilters)
  const selectedFilterName =
    verdictTypesToDropdownItemData[filterParameters[QueriesFilterMenuType.ACTION] || 'all'].label

  const icon = actionIcons[filterParameters[QueriesFilterMenuType.ACTION] || ''] || undefined

  return (
    <QueryFilterDropdown
      selectedValue={selectedFilterName}
      icon={icon}
      type={QueriesFilterMenuType.ACTION}
    >
      <QueryFilterDropdownContent type={QueriesFilterMenuType.ACTION} />
    </QueryFilterDropdown>
  )
}
