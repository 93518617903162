import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import { Button, Svg } from 'ui'
import useQueryString from 'utils/useQueryString'
import { DeviceDialogType } from 'components/Dashboard/Devices/DeviceTrayOrModalDialog'
import DeviceIcons, {
  defaultIconName,
  getIconDropDownItem,
} from 'components/Dashboard/Devices/DeviceIcons'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import { OsFamily } from 'utils/platform'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { EnabledStatus } from 'store/api/rules'

const PlatformItem = ({
  platform,
  name,
  legacyIpv4Status,
  learnIp,
  stats,
}: {
  platform: string
  name: string
  legacyIpv4Status?: EnabledStatus
  learnIp?: EnabledStatus
  stats?: StatLevel
}): ReactElement => {
  const { qs, nav } = useQueryString()
  const iconItem = getIconDropDownItem(platform)
  const isTV = platform.includes('tv')
  const isRouter = platform.includes('router')

  return (
    <Button
      data-testid={platform}
      variant="simple"
      sx={{
        width: '10.1rem',
        height: isTV || isRouter ? '12.4rem' : '10rem',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        py: '1.4rem',
        px: '0.8rem',
        alignItems: 'center',
        borderRadius: '1.6rem',
        border: '1px solid',
        borderColor: 'white10',
        backgroundColor: 'white5',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
        color: 'white50',
        '&:hover': {
          color: 'white',
          backgroundColor: 'white10',
        },
      }}
      onClick={() => {
        const helpPane = (() => {
          if (qs.helpPane) {
            switch (platform) {
              case undefined:
                return
              case SetupOs.MAC:
              case SetupOs.WINDOWS:
              case SetupOs.LINUX:
              case SetupOs.ASUS:
              case SetupOs.DD_WRT:
              case SetupOs.OPEN_WRT:
              case SetupOs.UBIQUITI:
              case SetupOs.FRESH_TOMATO:
              case SetupOs.GL_INET:
              case SetupOs.SYNOLOGY:
              case SetupOs.PF_SENSE:
              case SetupOs.OPN_SENSE:
              case SetupOs.GENERIC_LINUX:
              case SetupOs.WINDOWS_SERVER:
              case SetupOs.FIREWALLA:
              case SetupOs.ANDROID:
              case SetupOs.IOS:
                return SetupGuideIntermediateStates.Configure
              case SetupOs.FIREFOX:
                return SetupGuideIntermediateStates.ProtocolVersion
              case SetupOs.APPLE_TV:
                return SetupGuideIntermediateStates.AppleTVVersion
              default:
                return platform as SetupOs
            }
          }
          return
        })()

        const queryString = {
          ...qs,
          ...(helpPane ? { helpPane } : { deviceDialog: DeviceDialogType.ADD, deviceName: name }),
          setupOs: platform as SetupOs,
        }

        if (platform === OsFamily.WINDOWS) {
          queryString.setupOs = SetupOs.WINDOWS_10_OR_OLDER
        }

        if (legacyIpv4Status !== undefined) {
          queryString.legacyIpv4Status = legacyIpv4Status
        }

        if (stats !== undefined) {
          queryString.stats = stats
        }

        if (learnIp !== undefined) {
          queryString.learnIp = learnIp
        }

        nav({
          ...queryString,
        })
      }}
    >
      <Svg
        svg={iconItem.Icon || DeviceIcons[defaultIconName]}
        fill="white"
        sx={{ mb: '0.8rem', flexShrink: 0 }}
      />
      <Flex
        sx={{
          height: '100%',
          alignItems: 'center',
          fontSize: '1.6rem',
          fontWeight: 'bold',
          whiteSpace: isTV || isRouter ? 'normal' : 'nowrap',
          wordBreak: 'break-word',
          width: name === 'Fire TV' ? '3.3rem' : 'auto',
        }}
      >
        {name}
      </Flex>
    </Button>
  )
}

export default PlatformItem
