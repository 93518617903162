import React, { ReactElement } from 'react'
import { DatePickerShortcut } from '@blueprintjs/datetime'
import DateTimePicker, { DateTimePickerProps } from 'ui/DateTimePicker/DateTimePicker'
import moment from 'moment'

export default function FutureDateTimePicker(props: DateTimePickerProps): ReactElement {
  const shortcuts: DatePickerShortcut[] = [
    {
      date: moment().add(1, 'hour').toDate(),
      includeTime: true,
      label: 'after 1 hour',
    },
    {
      date: moment().add(1, 'day').toDate(),
      includeTime: true,
      label: 'after 1 day',
    },
    {
      date: moment().add(1, 'week').toDate(),
      includeTime: true,
      label: 'after 1 week',
    },
    {
      date: moment().add(1, 'month').toDate(),
      includeTime: true,
      label: 'after 1 month',
    },
  ]

  return <DateTimePicker {...{ ...props, shortcuts }} />
}
