import React, { Dispatch, ReactElement, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import { SimpleActionMenuItemProps, Svg } from 'ui'
import Check from 'images/analytics/check.svg'
import SettingsDropdown from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsDropdowns/SettingsDropdown'
import { DeviceStatus } from 'store/api/devices/devices.interface'
import {
  ActionType,
  DeviceActionType,
} from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsState'

const deviceStatuses = [
  {
    PK: DeviceStatus.PENDING,
    title: 'Pending',
  },
  {
    PK: DeviceStatus.ACTIVE,
    title: 'Active',
  },
  {
    PK: DeviceStatus.SOFT_DISABLED,
    title: 'Soft Disabled',
  },
  {
    PK: DeviceStatus.HARD_DISABLED,
    title: 'Hard Disabled',
  },
]

interface StatusDropdownProps {
  deviceStatus?: DeviceStatus
  settingsDispatch: Dispatch<ActionType>
  boundaryDataTestId?: string
}
export default function StatusDropdown({
  deviceStatus,
  settingsDispatch,
  boundaryDataTestId,
}: StatusDropdownProps): ReactElement {
  const hideRef = useRef<() => void>()

  const statOptions: SimpleActionMenuItemProps[] = deviceStatuses.map(status => ({
    isSelected: status.PK === deviceStatus,
    isBackgroundHighlighted: true,
    children: (
      <Flex
        data-testid={status.title}
        sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Text>{status.title}</Text>
        {(deviceStatus ?? 0) === status.PK && <Svg data-testid="check-icon" svg={Check}></Svg>}
      </Flex>
    ),
    buttonContent: <Text>{status.title}</Text>,
    onClick: async () => {
      settingsDispatch({
        type: DeviceActionType.STATUS,
        payload: status.PK,
      })
      hideRef.current?.()
    },
  }))

  return (
    <SettingsDropdown
      testId="settings-status-dropdown"
      items={statOptions}
      hideRef={hideRef}
      boundaryDataTestId={boundaryDataTestId}
    />
  )
}
