import React, { ReactElement } from 'react'
import ReportsImage from 'images/tutorialFlows/Reports/reports-slide.svg'
import {
  Highlight,
  TutorialBaseSlide,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import { Box, Text } from 'theme-ui'

export function ReportsSlide(): ReactElement {
  return (
    <TutorialBaseSlide
      Illustration={ReportsImage}
      description={
        <Box>
          <Text>
            Control D Reports allow you to see a bird&apos;s eye view of DNS traffic data across
            your entire <Highlight svg={tutorialSlideIcons.org}>Organization</Highlight>, right in
            your email inbox. You can generate reports based on templates that we are always
            expanding upon, select the frequency at which you&apos;d like the report, and add as
            many email addresses as you like for the report to be sent to. <br /> <br /> This is
            useful for keeping stakeholders in the loop on the effectiveness of Control D, as well
            as for noticing technical anomalies that may require more stringent security measures or
            tweaks to your Profiles.
          </Text>
        </Box>
      }
    />
  )
}
