import React, { ReactElement, useState } from 'react'
import { Flex, Image, Spinner } from '@theme-ui/components'
import ErrorBox from 'components/LoginSignUpForm/ErrorBox'
import { Button } from 'ui'
import type { Setter } from 'utils'

interface QrCodeProps {
  url: string
  isQrCodeLoading: boolean
  setIsQrCode: Setter<boolean>
  setIsQrCodeLoading: Setter<boolean>
}

export default function QrCode({
  url,
  isQrCodeLoading,
  setIsQrCode,
  setIsQrCodeLoading,
}: QrCodeProps): ReactElement {
  const [error, setError] = useState<string>('')

  return (
    <>
      {error ? (
        <ErrorBox errorMessage={error} sx={{ mb: ['2.4rem', '4.8rem'] }} />
      ) : (
        <Flex
          sx={{
            width: ['17rem', '26rem'],
            height: ['17rem', '26rem'],
            mb: ['2.4rem', '4.8rem'],
            mt: ['-1.8rem', '-3.2rem'],
            alignItems: 'center',
            justifyContent: 'center',
            bg: 'white',
            borderRadius: '2.4rem',
          }}
        >
          {isQrCodeLoading && <Spinner />}
          <Image
            data-testid="qr-code"
            sx={{
              width: ['15.4rem', '24.4rem'],
              height: ['15.4rem', '24.4rem'],
              flexShrink: 0,
              display: isQrCodeLoading ? 'none' : 'block',
            }}
            src={url}
            onLoad={() => {
              setError('')
              setIsQrCodeLoading(false)
            }}
            onError={() => {
              setIsQrCodeLoading(false)
              setError('Failed to generate QR code. Please try again.')
            }}
          />
        </Flex>
      )}
      <Button
        variant="secondary"
        onClick={() => {
          setIsQrCode(false)
          setError('')
        }}
        sx={{
          fontSize: '1.8rem',
          fontWeight: 'bold',
          borderRadius: '59px',
          px: '2.4rem',
          py: '1.2rem',
        }}
      >
        Back
      </Button>
    </>
  )
}
