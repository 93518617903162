import palette from './palette'
import { ColorRoleToPaletteMap, ThemeMode } from './themeObject.interface'

// generally, colors that are subject to change
const colorMap: ColorRoleToPaletteMap = {
  // Test colors (colors should be used only from palette)
  primary: 'greenApple',
  primaryShadow: 'darkItemBoxShadow',
  secondary: 'darkItemBG50',
  text: 'white',
  background: 'blue800',
  cardBackground: 'black',
  cardShadow: 'black',
  modalBackground: 'darkItemBG50',
  accent: 'white15',
  muted: 'white50',
  error: 'pomegranate',
  alertError: 'pomegranate15',
  active: 'white25',
  successBackground: 'greenApple15',
  scrollbarThumb: 'darkItemBG50',
  staticPageBackground: 'black',

  dialogElementBg: 'inputBG',
  dialogBodyBg: 'smokyBlack',
  subTextColor: 'title60',
  textColor: 'body',
  labelColor: 'title',
}

const colors = Object.entries(colorMap).reduce((acc, [k, v]) => {
  acc[k] = palette[v]
  return acc
}, {})

const darkModeObj: ThemeMode = {
  dark: {
    ...colors,
  },
}

export default darkModeObj
