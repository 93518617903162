import React, { ForwardedRef, forwardRef, useEffect } from 'react'
import { Text, Flex, Input as _Input, Label, Textarea } from 'theme-ui'
import InputProps from './Input.interface'
import { visuallyHiddenStyle } from 'ui/utils/sxMixins'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import customUnescape from 'utils/customUnescape'

let lines = 1

function limitLines(e): false | void {
  if (e.keyCode == 13) {
    if (lines == e.target.rows) {
      e.preventDefault()
      return false
    } else {
      lines++
    }
  }
}

export const inputNewStyles = {
  alignSelf: 'end',
  borderRadius: '1.2rem',
  background: 'inputBG',
  p: '1.6rem',
  fontSize: '1.5rem',
  color: 'title',
}

export const inputLabelNewStyles = {
  mx: 0,
  color: 'title',
  span: {
    color: 'title',
  },
  opacity: 0.7,
  fontSize: '1.5rem',
  fontWeight: 400,
}

export default forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>(function Input(
  props: InputProps,
  ref,
): React.ReactElement {
  const {
    hideLabel = false,
    label,
    labelColor,
    activeLabelColor,
    id,
    name = '',
    errorMessage = '',
    onChange = (): void => {},
    onBlur = (): void => {},
    onFocus = (): void => {},
    onPaste = (): void => {},
    variant = 'primary',
    hasText = false,
    containerStyle = {},
    backgroundColor = 'white10',
    backgroundColorOnFocus,
    sx,
    isTextarea,
    errorMessageStyles,
    value,
    rows = 10,
    rightContent,
    shouldUseNewDesign,
    ...attrs
  } = props

  const isDesktop = useBreakpointIndex() === 2

  const labelId: string = id + '-label'

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    onChange(e)

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    onBlur(e)

  const handleFocus = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
    onFocus(e)

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = onPaste

  useEffect(() => {
    lines = `${value}`.split('\n').length

    return () => {
      lines = 1
    }
  }, [value])

  return (
    <>
      <Flex
        sx={{
          flexDirection: 'column-reverse',
          flexGrow: 1,
          ...containerStyle,
        }}
      >
        {!isTextarea ? (
          <_Input
            variant={variant}
            type="text"
            tabIndex={0}
            sx={{
              width: '100%',
              height: '5.4rem',
              px: '2.4rem',
              backgroundColor: backgroundColor,
              border: 'none',
              borderRadius: '45px',
              fontSize: '1.8rem',
              color: 'white50',
              '--theme-ui-input-autofill-bg': backgroundColor,
              '& + div > label': {
                mb: '0rem',
              },
              fontFamily: 'body',
              '::placeholder': {
                fontFamily: 'body',
              },
              '&:focus': {
                backgroundColor: backgroundColorOnFocus || backgroundColor,
                color: 'white',
                '& + div > label': {
                  span: {
                    color: activeLabelColor,
                  },
                  mb: '0rem',
                  color: activeLabelColor,
                },
              },
              '&:focus-visible': !isDesktop
                ? {
                    outline: 0,
                  }
                : {},
              ':disabled': {
                border: '2px solid',
                borderColor: 'white10',
                backgroundColor: 'transparent',
                color: 'white25',
                '& + div > label': {
                  color: 'white25',
                  span: {
                    color: 'white25',
                  },
                },
              },
              ...(shouldUseNewDesign && {
                height: ['4.8rem', '4.7rem'],
                ...inputNewStyles,
              }),
              ...sx,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onPaste={handlePaste}
            id={id}
            name={name}
            ref={ref as ForwardedRef<HTMLInputElement>}
            value={typeof value === 'string' ? customUnescape(value) : value}
            {...attrs}
          />
        ) : (
          <Textarea
            data-testid={attrs['data-testid']}
            className="hide-scrollbar"
            variant="textarea.primary"
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
            id={id}
            name={name}
            value={value}
            placeholder={attrs.placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onPaste={handlePaste}
            disabled={attrs.disabled}
            rows={rows}
            onKeyDown={limitLines}
            sx={{
              width: '100%',
              height: '10rem',
              backgroundColor: backgroundColor,
              resize: 'none',
              color: 'white50',
              '& + div > label': {
                mb: '0rem',
              },
              '&:focus': {
                backgroundColor: backgroundColorOnFocus || backgroundColor,
                color: 'white',
                '& + div > label': {
                  span: {
                    color: activeLabelColor,
                  },
                  color: activeLabelColor,
                },
              },
              '&:focus-visible': !isDesktop
                ? {
                    outline: 0,
                  }
                : {},
              ':disabled': {
                border: '2px solid',
                borderColor: 'white10',
                backgroundColor: 'transparent',
                color: 'white25',
                '& + div > label': {
                  color: 'white25',
                  span: {
                    color: 'white25',
                  },
                },
              },
              ...(shouldUseNewDesign && {
                ...inputNewStyles,
                border: 'none',
              }),
              ...sx,
            }}
          />
        )}
        {label && (
          <Flex
            sx={{
              display: hideLabel ? 'none' : 'inherit',
              flexDirection: 'row',
              justifyContent: 'space-between',
              mb: '0.8rem',
              alignItems: 'center',
            }}
          >
            <Label
              sx={{
                color: labelColor || (hasText ? 'white' : 'white50'),
                width: 'fit-content',
                fontSize: '1.6rem',
                fontWeight: 'bold',
                mx: '2.4rem',
                ...(hideLabel && visuallyHiddenStyle),
                ...(shouldUseNewDesign && inputLabelNewStyles),
              }}
              id={labelId}
              htmlFor={id}
            >
              {label}
            </Label>
            {rightContent}
          </Flex>
        )}
      </Flex>
      {errorMessage && (
        // Color to
        <Text
          aria-live="polite"
          color="error"
          data-testid={`${name}-error-message`}
          sx={{ mt: 1, fontSize: '1.6rem', ...errorMessageStyles }}
        >
          {errorMessage}
        </Text>
      )}
    </>
  )
})
