import React, { ReactElement, useEffect, useState } from 'react'
import { SelectVersionPane, SetupGuidePane } from 'components/SetupGuide/Components'
import { SetupOs } from 'components/SetupGuide/SetupGuide.interface'

import { Button } from 'ui'
import useQueryString from 'utils/useQueryString'
import ModalDialogHeader, { ButtonType } from 'ui/ModalDialog/ModalDialogHeader'

export default function WindowsVersionSelector(): ReactElement {
  const { setupGuideNav, qs } = useQueryString()
  const [isWindows11, setIsWindows11] = useState(false)

  useEffect(() => {
    navigator?.userAgentData?.getHighEntropyValues(['platformVersion']).then(ua => {
      if (navigator?.userAgentData?.platform === 'Windows') {
        const majorPlatformVersion = parseInt((ua.platformVersion ?? '0')?.split('.')[0])
        if (majorPlatformVersion >= 13) {
          setIsWindows11(true)
        }
      }
    })
  }, [])

  return (
    <SetupGuidePane
      header={<ModalDialogHeader title={`Windows - ${qs.mode}`} buttonType={ButtonType.BACK} />}
    >
      <SelectVersionPane>
        <Button
          variant="secondary"
          sx={{
            backgroundColor: isWindows11 ? 'plum' : 'transparent',
            color: 'white',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            border: isWindows11 ? 'none' : '1px solid',
            borderColor: 'plum',
            borderRadius: '59px',
          }}
          onClick={(): void => {
            setupGuideNav({
              ...qs,
              helpPane: SetupOs.WINDOWS_11,
              setupOs: SetupOs.WINDOWS_11,
              mode: qs.mode,
              step: '0',
            })
          }}
        >
          Windows 11
        </Button>
        <Button
          variant="secondary"
          sx={{
            backgroundColor: !isWindows11 ? 'plum' : 'transparent',
            color: 'white',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            border: !isWindows11 ? 'none' : '1px solid',
            borderColor: 'plum',
            borderRadius: '59px',
          }}
          onClick={(): void => {
            setupGuideNav({
              ...qs,
              helpPane: SetupOs.WINDOWS_10_OR_OLDER,
              setupOs: SetupOs.WINDOWS_10_OR_OLDER,
              mode: qs.mode,
              step: '0',
            })
          }}
        >
          Windows 10 or older
        </Button>
      </SelectVersionPane>
    </SetupGuidePane>
  )
}
