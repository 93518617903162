import React, { ReactElement } from 'react'
import { Button, useAlertPresenter, BackIcon } from 'ui'
import useQueryString from 'utils/useQueryString'
import { troubleshootingAlertName } from 'components/SetupGuide/SetupGuide'
import { SetupGuideIntermediateStates } from 'components/SetupGuide/SetupGuide.interface'
import { ThemeUIStyleObject } from 'theme-ui'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { useAppSelector } from 'store/hooks'
import useGetProvisioningState from 'store/provisioning/useGetProvisioningState'
import { useGetDevicesQuery } from 'store/api/devices'

export default function BackButton({ sx }: { sx?: ThemeUIStyleObject }): ReactElement {
  const { setupGuideNav } = useQueryString()
  const { dismissAlert } = useAlertPresenter()
  const { qs, nav } = useQueryString()
  const location = useLocation()
  const { icon } = useGetProvisioningState()

  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const { data: devicesData } = useGetDevicesQuery('', { skip: !sessionToken })
  /**
   * Allow the user to change the platform when the device does not have a platform selected.
   */
  const isPlatformTypePresent =
    icon || devicesData?.devices.find(device => device.PK === qs?.deviceId)?.icon

  return (
    <Button
      variant="simple"
      aria-label="go back"
      sx={{
        p: 0,
        ml: '1.6rem',
        border: 0,
        textAlign: 'center',
        justifyContent: 'center',
        width: '2.4rem',
        height: '2.4rem',
        ':hover': {
          backgroundColor: 'white25',
        },
        '&:hover > svg path': {
          fill: 'white',
        },
        ...sx,
      }}
      onClick={(): void => {
        dismissAlert(troubleshootingAlertName)

        if (location.key === 'initial') {
          nav({ ...pick(qs, ['overlay']) })
          return
        }

        qs.step
          ? setupGuideNav({
              ...(isPlatformTypePresent ? qs : omit(qs, ['setupOs', 'step'])),
              helpPane: SetupGuideIntermediateStates.DNS,
            })
          : navigate(-1)
      }}
      data-testid="back-button"
    >
      <BackIcon fill="white50" sx={{ width: '2.4rem', height: '2.4rem' }} />
    </Button>
  )
}
