import React, { ReactElement } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export const errorBoxHeight = '3.2rem'
export default function ErrorBox({
  errorMessage = '',
  isLoading = false,
  sx,
}: {
  errorMessage?: string
  isLoading?: boolean
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Flex
      sx={{
        textAlign: ['left', 'center'],
        borderRadius: '7.2rem',
        px: '1.6rem',
        py: '0.8rem',
        alignItems: 'center',
        my: ['0.8rem', 0],
        mx: [0, '6.8rem'],
        opacity: !!errorMessage && !isLoading ? 1 : 0.5,
        minHeight: errorBoxHeight,
        transition: 'opacity 0.12s ease',
        fontSize: '14px',
        fontWeight: 'bold',
        backgroundColor: !!errorMessage ? 'pomegranate15' : 'transparent',
        color: 'pomegranate',
        ...sx,
      }}
      data-testid="error"
      tabIndex={errorMessage ? 0 : -1}
    >
      {errorMessage}
    </Flex>
  )
}
