import React, { ReactElement, ReactNode } from 'react'
import { Button, Svg } from 'ui'
import type { TrayProps } from 'ui'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import TrayHeader from 'components/TrayHeader'
import CloseIcon from 'images/menu-close.svg'

const headerHeight = '6.8rem'

interface TrayWithCloseButton extends Omit<TrayProps, 'entersFrom' | 'isOpen'> {
  headerContent?: ReactNode
  rightHeaderContent?: ReactElement
  leftHeaderContent?: ReactElement
  trayBodySx?: ThemeUIStyleObject
  headerSx?: ThemeUIStyleObject
  rightComponent?: ReactElement
  dataTestId?: string
}

export default function TrayWithCloseButton({
  dismiss,
  headerContent,
  rightHeaderContent,
  leftHeaderContent,
  children,
  trayBodySx,
  headerSx,
  dataTestId,
}: TrayWithCloseButton): ReactElement {
  return (
    <Flex
      data-testid={dataTestId}
      sx={{ flexDirection: 'column', width: '100%', height: '100%', position: 'fixed' }}
    >
      <TrayHeader
        rightComponent={rightHeaderContent}
        leftComponent={
          <>
            <Button
              data-testid="close-tray-button"
              ariaLabel="close tray"
              variant="icon"
              sx={{
                width: ['3.2rem', '4rem', '4.8rem'],
                height: ['3.2rem', '4rem', '4.8rem'],
                backgroundColor: 'white10',
                borderRadius: '50%',
                flexShrink: 0,
                p: 0,
                ':hover > svg path': {
                  fill: 'white',
                },
                ':hover': {
                  backgroundColor: 'white25',
                },
              }}
              onClick={dismiss}
            >
              <Svg
                svg={CloseIcon}
                fill="white50"
                sx={{ width: ['2rem', '2.4rem'], height: ['2rem', '2.4rem'], flexShrink: 0 }}
              />
            </Button>
            {leftHeaderContent}
          </>
        }
        sx={{
          borderBottom: ({ colors }): string => `1px solid ${colors?.white15}`,
          height: headerHeight,
          position: 'relative',
          px: '1.6rem',
          py: 0,
          ...headerSx,
        }}
      >
        <Flex
          data-testid="nav-tray-header"
          sx={{
            fontSize: '2.1rem',
            fontWeight: 'bold',
            color: 'white',
            width: '100%',
            justifyContent: 'center',
          }}
          className={'reset-tippy-width centered-header-tippy mobile'}
        >
          {headerContent}
        </Flex>
      </TrayHeader>
      <Flex
        sx={{
          flexDirection: 'column',
          height: `calc(100% - ${headerHeight})`,
          overflowY: 'auto',
          position: 'relative',
          ...trayBodySx,
        }}
        className="show-scrollbar"
      >
        {children}
      </Flex>
    </Flex>
  )
}
