import { Flex, Text, ThemeUIStyleObject, FlexProps } from 'theme-ui'
import React, { useRef } from 'react'
import CancelDeleteIcon from 'images/confirmDeletion/cancel-delete-icon.svg'
import ConfirmDeleteIcon from 'images/confirmDeletion/confirm-delete-icon.svg'
import { Button, Svg } from 'ui'
import useOnClickOutside from 'utils/useOnClickOutside'

interface RuleOrGroupDeletionConfirmationProps extends FlexProps {
  isConfirmDeletionOpen: boolean
  setIsConfirmDeletionOpen: (boolean) => void
  onCancelButtonClick: () => void
  onConfirmButtonClick: () => void
  message: string
  sx?: ThemeUIStyleObject
}

const RuleOrGroupDeletionConfirmation = ({
  isConfirmDeletionOpen,
  setIsConfirmDeletionOpen,
  onCancelButtonClick,
  onConfirmButtonClick,
  message,
  sx,
  ...props
}: RuleOrGroupDeletionConfirmationProps) => {
  const deletionConfirmationRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([deletionConfirmationRef], () => {
    setIsConfirmDeletionOpen(false)
  })

  return (
    <Flex
      ref={deletionConfirmationRef}
      data-testid="confirm-delete-rule-container"
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        borderRadius: '16px',
        height: 'calc(100% - 1.6rem)',
        width: `${isConfirmDeletionOpen ? '100%' : '0'}`,
        alignItems: 'center',
        transition: 'width 300ms',
        backgroundColor: 'darkItemBG',
        zIndex: 'zIndex650',
        ...sx,
      }}
      {...props}
    >
      <Flex
        sx={{ width: '100%', px: '1.6rem', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Text
          data-testid="confirm-delete-rule-text"
          sx={{
            color: 'white50',
            fontSize: '1.6rem',
            lineHeight: '1.6rem',
            minWidth: '20rem',
            opacity: `${isConfirmDeletionOpen ? '1' : '0'}`,
            transition: 'opacity 300ms',
          }}
        >
          {message}
        </Text>
        <Flex>
          <Button
            data-testid="confirm-delete-rule-button"
            variant="transparent"
            sx={{
              mr: '1.6rem',
              p: 0,
              '&:hover': {
                ':not([disabled])': {
                  '& svg path': {
                    fill: 'pomegranate',
                  },
                },
              },
            }}
            onClick={onConfirmButtonClick}
            tabIndex={isConfirmDeletionOpen ? 0 : -1}
          >
            <Svg svg={ConfirmDeleteIcon} fill="pomegranate50" />
          </Button>
          <Button
            data-testid="cancel-delete-rule-button"
            variant="simple"
            sx={{ mr: '0.8rem', p: 0 }}
            onClick={onCancelButtonClick}
            tabIndex={isConfirmDeletionOpen ? 0 : -1}
          >
            <Svg svg={CancelDeleteIcon} fill="white50" />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default React.memo(RuleOrGroupDeletionConfirmation)
