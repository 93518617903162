import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { Flex, Text, Box } from 'theme-ui'
import { TutorialArrow, useAlertPresenter, Tray } from 'ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import {
  TrackingEventAction,
  TrackingEventCategory,
  useTrackingHelper,
} from 'utils/useTrackingHelper'
import useArrowNavForHelpPane from 'utils/useArrowKeyHelpPaneNavigation'
import { SetupGuidePaneTypes } from 'components/SetupGuide/SetupGuide.interface'
import useStepHelper from 'components/SetupGuide/useStepHelper'
import { ResolverStatus } from 'store/api/user/user.interface'
import ModalDialog, { modalDialogMinHeight } from 'ui/ModalDialog/ModalDialog.base'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { detectConfiguredDevice } from 'store/multiprofile'
import useOnClickOutside from 'utils/useOnClickOutside'
import ConfigurationPane from './Components/ConfigurationPane'
import WindowsVersionSelector from 'components/SetupGuide/Panes/WindowsVersionSelector'
import { freeDnsPagePath } from 'components/FreeDNSPage/FreeDNSPage'
import AppleTVVersionSelector from 'components/SetupGuide/Panes/AppleTVVersionSelector'
import { useLocation } from '@reach/router'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/ShowAlertOnModalOrTrayWrapper'
import { useGetDevicesTypesQuery } from 'store/api/devices/devices'
import CustomThemeProvider from 'ui/Theme/ThemeProvider'
import useGetUser from 'components/Dashboard/utils/useGetUser'
const MacOSVersionSelector = React.lazy(
  () => import('components/SetupGuide/Panes/MacOSVersionSelector'),
)
const StepContainer = React.lazy(() => import('components/SetupGuide/Panes/StepContainer'))
const PlatformPane = React.lazy(() => import('components/SetupGuide/Panes/PlatformPane'))
const ShowDNS = React.lazy(() => import('components/SetupGuide/Panes/ShowDNS'))
const ProtocolVersionSelector = React.lazy(
  () => import('components/SetupGuide/Panes/ProtocolVersionSelector'),
)
export const troubleshootingAlertName = 'troubleshooting'

function SetupGuide(): ReactElement | null {
  const dispatch = useAppDispatch()
  const { trackEvent } = useTrackingHelper()
  const [shouldShowTray, setShouldShowTray] = useState(false)
  const trayRef = useRef<HTMLDivElement>(null)
  const breakpoint = useBreakpointIndex()
  const isMobile = breakpoint === 0
  const location = useLocation()

  const { dismissAlert } = useAlertPresenter()
  const { data: userData } = useGetUser()
  const isHapticsEnabled = !!userData?.haptics
  const { goToPreviousStep, goToNextStep, configuredStatus, closePane, qs } = useStepHelper()

  const isFreeDNS = location.pathname.includes(freeDnsPagePath)
  const {
    selectedFiltersPKs = [],
    selectedDNSKey,
    selected3rdPartyFilterPK,
  } = useAppSelector(s => s.freeDNS)
  const isFreeDnsModalDialogOpened = !!(
    selectedFiltersPKs.length ||
    selectedDNSKey ||
    selected3rdPartyFilterPK
  )
  useGetDevicesTypesQuery('')

  // tray should be visible only after the first render, otherwise the tray is not displaying after page reload
  useEffect(() => {
    setShouldShowTray(true)
  }, [setShouldShowTray])

  useEffect(() => {
    if (!!qs.helpPane) {
      trackEvent({
        category: TrackingEventCategory.Nav,
        action: TrackingEventAction.Tutorial,
      })
    }
  }, [qs.helpPane, trackEvent])

  useEffect(() => {
    // only do this when the help pane is actually open to avoid duplicating configured device detection api calls
    if (!!qs.helpPane && configuredStatus === ResolverStatus.VERIFIED) {
      dispatch(detectConfiguredDevice())
      dismissAlert(troubleshootingAlertName)
    }
  }, [configuredStatus, dismissAlert, dispatch, qs.helpPane])

  const modalDialogRef = useRef<HTMLDivElement>(null)

  useArrowNavForHelpPane({
    onLeftArrowPress: goToPreviousStep,
    onRightArrowPress: goToNextStep,
    onClose: closePane,
    isButtonVisible: !!qs.step,
    isConfigured: configuredStatus === ResolverStatus.VERIFIED,
  })

  const currentPane = useMemo(() => {
    switch (qs.helpPane) {
      case SetupGuidePaneTypes.DNS:
        return <ShowDNS />
      case SetupGuidePaneTypes.Platform:
        return <PlatformPane />
      case SetupGuidePaneTypes.MacVersion:
        return <MacOSVersionSelector />
      case SetupGuidePaneTypes.WindowsVersion:
        return <WindowsVersionSelector />
      case SetupGuidePaneTypes.AppleTVVersion:
        return <AppleTVVersionSelector />
      case SetupGuidePaneTypes.ProtocolVersion:
        return <ProtocolVersionSelector />
      case SetupGuidePaneTypes.Configure:
        return <ConfigurationPane />
      default:
        return qs.helpPane && <StepContainer /> //return undefined if helppane is not defined
    }
  }, [qs.helpPane])
  const alertPresenterContainerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside([alertPresenterContainerRef], () => {
    dismissAlert(troubleshootingAlertName)
  })

  return shouldShowTray ? (
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={!!qs.helpPane}>
      <CustomThemeProvider>
        {!isMobile ? (
          <ModalDialog
            modalDialogRef={modalDialogRef}
            dataTestId="setup-guide"
            // fix modal dialog blinking on free dns page after page reload
            isOpen={isFreeDNS ? isFreeDnsModalDialogOpened : !!qs.helpPane}
            dismiss={closePane}
            sx={{
              minHeight: modalDialogMinHeight,
              height: '90vh',
              maxHeight: '120rem',
            }}
            zIndexName="zIndex450"
            shouldVibrateOnPresent={isHapticsEnabled}
          >
            {currentPane}
          </ModalDialog>
        ) : (
          <Tray
            trayRef={trayRef}
            dataTestId="setup-guide"
            entersFrom="bottom"
            isOpen={!!qs.helpPane}
            dismiss={closePane}
            slideDimensionLength="100%"
            sx={{ height: '100%' }}
            zIndexName="zIndex450"
            shouldVibrateOnPresent={isHapticsEnabled}
            overlayContent={
              <OverlayContent
                isOpen={!!qs.helpPane}
                trayWidth={trayRef.current?.clientWidth ?? 0}
              />
            }
          >
            {currentPane}
          </Tray>
        )}
      </CustomThemeProvider>
    </ShowAlertOnModalOrTrayWrapper>
  ) : null
}

const OverlayContent = ({ isOpen, trayWidth }: { isOpen: boolean; trayWidth: number }) =>
  // fix shifting when tray is displayed
  trayWidth ? (
    <Flex
      data-testid="configure-controld-overlay"
      sx={{
        width: `calc(100% - ${trayWidth}px)`,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        px: '5rem',
      }}
    >
      <TutorialArrow
        testId="configure"
        isVisible={isOpen}
        tooltipPlacement="right"
        rotateDegree={-90}
      >
        <Box
          sx={{
            maxWidth: '28rem',
          }}
        >
          <Text
            sx={{
              fontSize: '2.8rem',
              fontWeight: 'bold',
            }}
          >
            Configure Control D first by following the guide on the right.
          </Text>
        </Box>
      </TutorialArrow>
    </Flex>
  ) : null

export default SetupGuide
