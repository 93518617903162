import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Flex } from 'theme-ui'
import { setSelectedDeviceId } from 'store/access'
import { useAlertPresenter } from 'ui'
import sortBy from 'lodash/sortBy'
import IPRow from 'components/Dashboard/IPs/IPRow'
import { RouteComponentProps, useParams } from '@reach/router'
import { clearErrors } from 'store/errors'
import { scrollingContainerRef } from 'components/Dashboard/Dashboard'
import VirtualizedGrid, { SizePerBreakpoint } from 'components/Dashboard/VirtualizedGrid'
import IPsFixedButtons from './IPsFixedButtons'
import NoResults from 'components/Dashboard/NoResults'
import { useGetAccessQuery } from 'store/api/access'
import { useGetProxiesQuery } from 'store/api/proxies'
import { useGetDevicesQuery } from 'store/api/devices'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export const myIPsHeadingStyles = {
  fontSize: '1.8rem',
  fontWeight: 'bold',
  color: 'white50',
  pb: '1.6rem',
}

export const contentMarginTop = ['2.4rem', '4.8rem', '4.8rem']

const ipHeightsPx: SizePerBreakpoint = [130, 100, 100]

export default function IPsContent({}: RouteComponentProps): ReactElement {
  const dispatch = useAppDispatch()
  const { data: proxiesData } = useGetProxiesQuery('')
  const locations = proxiesData?.proxies
  const accessSelectedDeviceId = useAppSelector(s => s.access.selectedDeviceId || '')
  const { data: devicesData } = useGetDevicesQuery('')
  const { data: userData } = useGetUser()
  const sourceIp = userData?.debug?.source_ip
  const [ipsToDelete, setIpsToDelete] = useState<string[]>([])
  const [isMultiSelectMode, setIsMultiSelectMode] = useState(false)
  const nonCurrentIPsCardRef = useRef<HTMLDivElement>(null)
  const { dismissAllAlerts } = useAlertPresenter()
  const params = useParams()
  const { data } = useGetAccessQuery(
    { deviceId: accessSelectedDeviceId },
    { skip: !accessSelectedDeviceId },
  )
  const sortedIps = sortBy(data?.ips, item => (item.ip === sourceIp ? 0 : 1))

  useEffect(() => {
    return () => {
      dismissAllAlerts()
      dispatch(clearErrors())
    }
  }, [dispatch, dismissAllAlerts])

  useEffect(() => {
    if (!accessSelectedDeviceId && devicesData?.devices.length) {
      dispatch(setSelectedDeviceId(params.deviceId))
    }
  }, [devicesData?.devices, accessSelectedDeviceId, dispatch, params.deviceId])

  const ipRenderer = (index: number) => {
    const ip = sortedIps[index]
    return ip ? (
      <IPRow
        ipData={ip}
        locations={locations || []}
        isCurrentIp={false}
        isMultiSelectMode={isMultiSelectMode}
        setIsMultiSelectMode={setIsMultiSelectMode}
        setIpsToDelete={setIpsToDelete}
        ipsToDelete={ipsToDelete}
        isSelected={ipsToDelete.includes(ip.ip)}
      />
    ) : null
  }

  return (
    <>
      {accessSelectedDeviceId || devicesData?.devices.length ? (
        (data?.ips?.length || 0) > 0 ? (
          <Flex ref={nonCurrentIPsCardRef} sx={{ height: 'fit-content' }}>
            <VirtualizedGrid
              dataTestId="dashboard-ips"
              itemCount={sortedIps.length}
              itemHeightsPx={ipHeightsPx}
              itemRenderer={ipRenderer}
              itemsPerRow={[1, 1, 1]}
              scrollingContainerRef={scrollingContainerRef}
            />
          </Flex>
        ) : (
          <NoResults message="You do not have any IPs recorded for this endpoint. Add an IP using the + button below." />
        )
      ) : (
        <NoResults message="Please create a device to view IPs." />
      )}
      <IPsFixedButtons
        ipsToDelete={ipsToDelete}
        setIpsToDelete={setIpsToDelete}
        isMultiSelectMode={isMultiSelectMode}
        setIsMultiSelectMode={setIsMultiSelectMode}
        nonCurrentIPsCardRef={nonCurrentIPsCardRef}
      />
    </>
  )
}
