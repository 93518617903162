import React, { ElementType, ReactElement, ReactNode } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Svg } from 'ui'

const SettingsItem = ({
  icon,
  title,
  description,
  descriptionLink,
  expandedContent,
  warningMessage,
  actionContent,
  sx,
  testId,
}: {
  icon?: ElementType
  title: string
  description: string
  descriptionLink?: ReactNode
  expandedContent?: ReactNode
  warningMessage?: string
  actionContent?: ReactNode
  sx?: ThemeUIStyleObject
  testId?: string
}): ReactElement => {
  return (
    <Flex
      data-testid={testId}
      sx={{
        width: '100%',
        pb: '1.6rem',
        backgroundColor: 'white5',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05)',
        borderRadius: '1.6rem',
        flexDirection: 'column',
        ...sx,
      }}
    >
      {warningMessage && (
        <Flex
          sx={{
            borderTopLeftRadius: '1.6rem',
            borderTopRightRadius: '1.6rem',
            backgroundColor: 'banana25',
            color: 'banana',
            fontSize: '1.6rem',
            justifyContent: 'center',
            alignItems: 'center',
            py: '1.2rem',
            px: '2.4rem',
          }}
        >
          {warningMessage}
        </Flex>
      )}
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: '1.6rem',
          px: '2.4rem',
        }}
      >
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'column',
            mb: '0.6rem',
            mr: ['0.4rem', '1.2rem'],
          }}
        >
          <Flex sx={{ alignItems: 'center', mb: '0.4rem' }}>
            {icon && <Svg svg={icon} sx={{ width: '3.2rem', height: '3.2rem', mr: '0.8rem' }} />}
            <Text sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{title}</Text>
          </Flex>
          <Text sx={{ fontSize: '1.6rem', color: 'white50' }}>
            {description} {descriptionLink}
          </Text>
        </Flex>
        <Flex sx={{ flexShrink: 0 }}>{actionContent}</Flex>
      </Flex>
      {expandedContent && <Flex sx={{ mt: '1.6rem', px: '2.4rem' }}>{expandedContent}</Flex>}
    </Flex>
  )
}

export default SettingsItem
