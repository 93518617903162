import React, { ReactElement, ReactNode, useEffect, useRef } from 'react'
import { useAppDispatch } from 'store/hooks'
import { ThemeUIStyleObject } from 'theme-ui'
import ModalDialog from 'ui/ModalDialog/ModalDialog.base'
import { ButtonType } from 'ui/ModalDialog/ModalDialogHeader'
import useQueryString from 'utils/useQueryString'
import { setIsModalDialogVisible } from 'store/dashboard/dashboard'
import useGetUser from 'components/Dashboard/utils/useGetUser'

const DesktopAndTabletModal = ({
  title,
  onCloseClick,
  isOpen,
  dataTestId,
  sxDialog,
  sxHeader,
  children,
  infoUrl,
}: {
  title?: string
  dataTestId?: string
  sxDialog?: ThemeUIStyleObject
  sxHeader?: ThemeUIStyleObject
  onCloseClick: () => void
  isOpen: boolean
  children: ReactNode
  infoUrl?: string
}): ReactElement => {
  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const { data: userData } = useGetUser()
  const isHapticsEnabled = !!userData?.haptics
  const { qs } = useQueryString()

  useEffect(() => {
    dispatch(setIsModalDialogVisible(true))
    return () => {
      dispatch(setIsModalDialogVisible(false))
    }
  }, [dispatch])

  useEffect(() => {
    const handleKeydown = (e): void => {
      if (e.key === 'Escape') {
        onCloseClick()
      }
    }

    window.addEventListener('keydown', handleKeydown)
    return (): void => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [onCloseClick])

  return (
    <ModalDialog
      modalDialogRef={containerRef}
      dataTestId={dataTestId}
      isOpen={isOpen}
      dismiss={onCloseClick}
      sx={{
        display: ['none', 'flex'],
        flexDirection: 'column',
        height: 'fit-content',
        ...sxDialog,
      }}
      shouldVibrateOnPresent={isHapticsEnabled}
      title={title}
      headerButtonType={
        qs.setupOs || title?.includes('Other Endpoint') ? ButtonType.BACK : ButtonType.CLOSE
      }
      onHeaderButtonClick={onCloseClick}
      headerStyles={{
        backgroundColor: 'white5',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 0 rgba(255, 255, 255, 0.1)',
        ...sxHeader,
      }}
      infoUrl={infoUrl}
    >
      {children}
    </ModalDialog>
  )
}

export default DesktopAndTabletModal
