import React, { ReactElement, useCallback, useMemo } from 'react'
import useQueryString from 'utils/useQueryString'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import omit from 'lodash/omit'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/ShowAlertOnModalOrTrayWrapper'
import { DesktopAndTabletModal, MobileTray } from 'components/TrayOrModalDialog'
import { AddProvisionView } from './AddProvisionView'
import { ProvisionDialogType } from 'components/Organization/Provision'
import { InstructionsView } from './InstractionsView'

const ProvisionTrayOrModalDialog = ({
  resolverId,
  deviceType,
}: {
  resolverId?: string
  deviceType?: string
}): ReactElement | null => {
  const { qs, nav } = useQueryString()
  const breakpoint = useBreakpointIndex()
  const isMobile = breakpoint === 0

  const onClose = useCallback(() => {
    nav({ ...omit(qs, 'provisionDialog') })
  }, [qs, nav])

  const currentView = useMemo(() => {
    switch (qs.provisionDialog) {
      case ProvisionDialogType.ADD:
        return {
          title: 'Create Provisioning Code',
          content: <AddProvisionView dismiss={onClose} />,
          testId: 'add-provision',
        }
      case ProvisionDialogType.INSTRUCTIONS:
        return {
          title: 'Provisioning Instructions',
          content: <InstructionsView resolverId={resolverId} deviceType={deviceType} />,
          testId: 'provision-instructions',
        }
      default:
        return
    }
  }, [deviceType, onClose, qs.provisionDialog, resolverId])

  return (
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={!!qs.provisionDialog}>
      {isMobile ? (
        <MobileTray
          title={currentView?.title}
          onCloseClick={onClose}
          dataTestId={`${currentView?.testId}-tray`}
        >
          {currentView?.content}
        </MobileTray>
      ) : (
        <DesktopAndTabletModal
          title={currentView?.title}
          onCloseClick={onClose}
          dataTestId={`${currentView?.testId}-dialog`}
          isOpen={!!qs.provisionDialog}
        >
          {currentView?.content}
        </DesktopAndTabletModal>
      )}
    </ShowAlertOnModalOrTrayWrapper>
  )
}

export default ProvisionTrayOrModalDialog
