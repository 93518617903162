/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Button, ButtonProps } from 'ui'
import React, { ReactElement, ReactChild } from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'
import omit from 'lodash/omit'

/**
 * React does not like camelcase on the prop names and throws a warning
 * "Warning: React does not recognize the `dropdownContent/tippyProps` prop on a DOM element.
 * If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase
 * `dropdowncontent/tippyprops` instead. If you accidentally passed it from a parent component,
 * remove it from the DOM element."
 */
export type DropdownProps = ButtonProps & {
  dropdowncontent: ReactChild | ReactChild[]
  tippyprops?: Omit<TippyProps, 'content' | 'children'>
}

/**
 * A button with a pre-styled Tippy-backed interactive dropdown menu that automatically
 * hides/shows on click and takes arbitrary content.
 *
 * This should be used as a base to build more complex dropdowns, such as styled selects.
 * No modification to this component should be necessary to build new dropdowns, as all
 * customization can be achieved by changing the content passed in and the props passed
 * to Tippy.
 *
 * There are now 3 different dropdown implementations in the app. This one should be
 * preferred as the base to implement any new dropdowns, as `Dropdown` and `LegacyDropdown`
 * have become quite complex and inflexible and cannot be easily used in a compositional
 * way.
 */
export default function Dropdown(props: DropdownProps): ReactElement {
  const { dropdowncontent, tippyprops, sx, buttonRef } = props
  return (
    <Tippy
      sx={{
        backgroundColor: 'grey',
        boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.15)',
        border: '1px solid',
        borderColor: 'darkItemBG50',
        '.tippy-content': {
          p: 0,
        },
        ...sx,
      }}
      interactive
      content={dropdowncontent}
      trigger="click"
      appendTo="parent"
      arrow={false}
      placement="bottom-end"
      zIndex={200}
      {...tippyprops}
    >
      <Button
        ref={buttonRef}
        sx={{
          ':disabled': {
            cursor: 'auto',
            backgroundColor: 'transparent',
            color: 'white25',
            border: '1px solid',
            borderColor: 'white10',
            '& > div span': {
              color: 'white25',
            },
            '& svg path': {
              fill: 'white25',
            },
          },
        }}
        {...omit(props, 'buttonRef', 'dropdowncontent', 'tippyprops')}
      />
    </Tippy>
  )
}
