import { RuleAction } from 'utils/uniteRules'
import { EnabledStatus } from 'store/api/rules'
import { ApiResponse } from 'store/api/http'
import { StatLevel } from 'store/api/analytics/analytics.interface'

interface ItemCount {
  count: number
}

export interface BrandedBlockPageOption {
  custom_logo?: string
  custom_url?: string
  custom_message?: string
  custom_title?: string
  custom_anchor?: string
}

export interface OptionData {
  PK: string
  value?: string | number // value is only used if the field type is a dropdown or text field. for toggles, the presence of an option implies it is set.
  custom_value?: string // custom_value is only used if the block response field.
  cbp?: BrandedBlockPageOption
}

export interface ProfileOptionsData extends ItemCount {
  data: OptionData[]
}
export interface ProfileDetails {
  flt: ItemCount
  cflt: ItemCount
  ipflt: ItemCount
  rule: ItemCount
  svc: ItemCount
  grp: ItemCount
  opt: ProfileOptionsData
  da: RuleAction
}
export interface Profile {
  //  The very first few CD profiles had a numerical PK, but we switched to strings later.
  //  Some users see issues as a result, so we _always_ need to be converting to a string explicitly when using these.
  PK: string | number
  name: string
  profile?: ProfileDetails
  updated?: number
  stats?: StatLevel
  disable_ttl?: number
  showDisable?: boolean
  lock_status?: number
  lock_message?: string
}

export type ProfileResponse = ApiResponse<{ profiles: Profile[]; shared_profiles?: Profile[] }>

export interface ModifyProfile {
  name?: string
  stats?: StatLevel
  disable_ttl: number // 0 for reset
  user_nuke_analytics?: number
  lock_status?: number
  lock_message?: string
  password?: string
}

export interface NewProfile {
  name: string
  clone_profile_id?: string
  stats?: number
}

export enum ProfileOptionFieldType {
  TOGGLE = 'toggle',
  FIELD = 'field',
  DROPDOWN = 'dropdown',
}
export interface ProfileOptionsMetaData {
  id: string
  PK: string
  title: string
  description?: string
  type: ProfileOptionFieldType
  default_value: string | Record<string, string> | number
  cbp?: BrandedBlockPageOption
  info_url: string
}
export type ProfileOptionsRespone = ApiResponse<{
  options: ProfileOptionsMetaData[]
}>

export interface UserProfileOption {
  status: EnabledStatus
  optionId: string
  value?: string | number
  type: ProfileOptionFieldType
  custom_value?: string
  cbp?: BrandedBlockPageOption
}

export interface PutProfileOptionArgs extends UserProfileOption {
  profileId?: string
  cbp?: BrandedBlockPageOption
}

export interface PutProfileOptionResponse {
  options: { PK: string; value: number | string; cbp: BrandedBlockPageOption }[]
}

export type ExportJsonData = {
  export: Profile
  filename: string
}
export type ProfileJsonResponse = Promise<ApiResponse<ExportJsonData>>

export type ImportJsonBody = {
  config: Profile
}
