import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Text } from 'theme-ui'
import { useLocation } from '@reach/router'
import { Accordion } from 'ui'
import { AnalyticsNavRelativePath } from './AnalyticsNavMenu'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { PreferencesNavRelativePath } from './PreferencesNavMenu'
import { OrganizationNavRelativePath } from 'components/Organization/OrganizationNavBar/OrganizationNavBarContent'
import { setAccordionType } from 'store/dashboard/dashboard'
import useQueryString from 'utils/useQueryString'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export enum NavBarAccordionType {
  ORGANIZATION = 'organization',
  DEVICES = 'devices',
  ANALYTICS = 'analytics',
  PREFERENCES = 'preferences',
}

export default function NavBarAccordion({
  title,
  titleIcon,
  type,
  defaultPath,
  relativePath,
  children,
  onOpen,
}: {
  title: string | ReactNode
  titleIcon: ReactElement
  type: NavBarAccordionType
  defaultPath: string
  relativePath:
    | typeof AnalyticsNavRelativePath
    | typeof PreferencesNavRelativePath
    | typeof OrganizationNavRelativePath
  children: ReactNode
  onOpen?: () => void
}): ReactElement {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const breakpoint = useBreakpointIndex()
  const isDesktop = breakpoint === 2
  const accordionType = useAppSelector(s => s.dashboard.accordionType)
  const location = useLocation()
  const isFirstRender = useRef(true)
  /**
   * using relocate to preserve the query string when navigating to a new page
   * this is needed because the orgId is stored in the query string
   */
  const { relocate } = useQueryString()

  useEffect(() => {
    const shouldOpen = Object.values(relativePath).some(path => location.pathname.includes(path))

    if (shouldOpen) {
      dispatch(setAccordionType(type))
      setIsOpen(shouldOpen)
    }

    if (!isDesktop || isFirstRender.current) {
      setIsOpen(shouldOpen)
    }

    isFirstRender.current = false
  }, [dispatch, isDesktop, location, relativePath, type])

  return (
    <Accordion
      buttonVariant="simple"
      id={`${type}-accordion`}
      testId={`${type}-accordion`}
      tabIndex={0}
      title={
        typeof title === 'string' ? (
          <Text
            sx={{
              display: ['flex', 'none', isNavBarExpanded ? 'flex' : 'none'],
              fontSize: '1.8rem',
              fontWeight: 'bold',
            }}
          >
            {title}
          </Text>
        ) : (
          title
        )
      }
      titleIcon={titleIcon}
      sxTitleIcon={{
        width: '2.4rem',
        height: '2.4rem',
        flexShrink: 0,
        m: 0,
      }}
      sxTitleContainer={{
        minWidth: 'auto',
        pr: 0,
      }}
      titleStyle={{
        ml: ['1.6rem', 0, isNavBarExpanded ? '1.6rem' : 0],
      }}
      openTitleStyle={{
        ml: ['1.6rem', 0, isNavBarExpanded ? '1.6rem' : 0],
      }}
      containerStyle={{ width: '100%' }}
      buttonStyle={{
        boxSizing: 'content-box',
        height: ['4.8rem', '7.2rem', isNavBarExpanded ? '4.8rem' : '7.2rem'],
        flexDirection: ['row', 'column', isNavBarExpanded ? 'row' : 'column'],
        gap: [0, '1.6rem', '1.6rem'],
        justifyContent: 'space-between',
        fontSize: '1.8rem',
        fontWeight: 'bold',
        px: ['1.6rem', 0, isNavBarExpanded ? '1.6rem' : 0],
        pt: [0, '0.8rem', 0],
        pb: isOpen ? '1.6rem' : 0,
        ':hover': {
          '& > div > span': {
            color: 'white',
          },
          '& > div svg:not(.crown-icon) path': {
            fill: 'white',
          },
        },
      }}
      svgStyle={{
        p: 0,
      }}
      isOpenControlled={isOpen}
      setIsOpenControlled={isOpen => {
        if (isDesktop || accordionType === type) {
          setIsOpen(isOpen)
        }

        if (isOpen) {
          onOpen?.()
          dispatch(setAccordionType(type))
          relocate(`/dashboard/${defaultPath}`)
        }
      }}
    >
      {children}
    </Accordion>
  )
}
