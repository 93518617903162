import React, { Dispatch, ReactElement, useCallback, useMemo, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import { SimpleActionMenuItemProps, Svg, useAlertPresenter } from 'ui'
import { useAppSelector } from 'store/hooks'
import Check from 'images/analytics/check.svg'
import SettingsDropdown from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsDropdowns/SettingsDropdown'
import {
  ActionType,
  DeviceActionType,
} from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsState'
import ErrorIcon from 'images/erroricon.svg'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { useGetStatLevelsQuery } from 'store/api/analytics'
import useGetUserState from 'store/api/user/useGetUserState'

const alertId = 'disabling-analytics'

interface AnalyticsDropdownProps {
  deviceStats?: number
  settingsDispatch: Dispatch<ActionType>
  boundaryDataTestId?: string
}
export default function AnalyticsDropdown({
  deviceStats,
  settingsDispatch,
  boundaryDataTestId,
}: AnalyticsDropdownProps): ReactElement {
  const hideRef = useRef<() => void>()
  const { data: statLevelsData } = useGetStatLevelsQuery('')
  const { presentAlert, dismissAlert } = useAlertPresenter()
  const { nuke_analytics: nukeAnalytics, region: storageRegion = '' } = useGetUserState()
  const { editDeviceId, data } = useAppSelector(s => s.devices)
  const device = useMemo(() => data?.devices.find(d => d.PK === editDeviceId), [data, editDeviceId])

  const editDeviceAnalytics = useCallback(
    async (endpointPK: string, shouldDeleteData?: boolean) => {
      const settings = {
        stats_endpoint: endpointPK,
      }

      if (shouldDeleteData && nukeAnalytics) {
        settings[nukeAnalytics] = 1
      }

      settingsDispatch({
        type: DeviceActionType.REGION_SETTINGS,
        payload: settings,
      })
    },
    [nukeAnalytics, settingsDispatch],
  )

  const allLevels = [...(statLevelsData?.levels ?? [])]
  const statOptions: SimpleActionMenuItemProps[] = allLevels.map(level => {
    const title = level.title.split(' ')[0]

    return {
      isSelected: level.PK === deviceStats,
      isBackgroundHighlighted: true,
      children: (
        <Flex
          data-testid={level.title}
          sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Text>{title}</Text>
          <Flex>
            {(deviceStats ?? 0) === level.PK && <Svg data-testid="check-icon" svg={Check}></Svg>}
          </Flex>
        </Flex>
      ),
      buttonContent: <Text>{title}</Text>,
      onClick: async () => {
        settingsDispatch({
          type: DeviceActionType.STATS,
          payload: level.PK,
        })
        hideRef.current?.()

        if (level.PK) {
          dismissAlert(alertId)
        }

        // device stats can be undefined or StatLevel.NO
        if (!!device?.stats && level.PK === StatLevel.NO) {
          presentAlert(
            {
              message:
                'Would you like to delete your data? This will remove ALL Analytics data from your account.',
              variant: 'primary',
              isSticky: true,
              icon: ErrorIcon,
              buttons: [
                {
                  onClick: () => {
                    editDeviceAnalytics(storageRegion)
                    dismissAlert(alertId)
                  },
                  text: 'Keep Data',
                },
                {
                  onClick: () => {
                    editDeviceAnalytics(storageRegion, true)
                    dismissAlert(alertId)
                  },
                  text: 'Delete Permanently',
                },
              ],
            },
            alertId,
          )
        }
      },
    }
  })

  return (
    <SettingsDropdown
      testId="settings-analytics-dropdown"
      items={statOptions}
      hideRef={hideRef}
      boundaryDataTestId={boundaryDataTestId}
    />
  )
}
