import palette from './palette'
import { ColorRoleToPaletteMap, ThemeMode } from './themeObject.interface'

// TODO: light theme colors should be updated
const colorMap: ColorRoleToPaletteMap = {
  // Test colors (colors should be used only from palette)
  primary: 'greenApple',
  primaryShadow: 'darkItemBoxShadow',
  secondary: 'darkItemBG50',
  text: 'white',
  background: 'darkItemBG50',
  cardBackground: 'white',
  cardShadow: 'white',
  modalBackground: 'darkItemBG50',
  accent: 'white15',
  muted: 'white50',
  error: 'pomegranate',
  alertError: 'pomegranate15',
  active: 'white25',
  successBackground: 'greenApple15',
  scrollbarThumb: 'darkItemBG50',
  staticPageBackground: 'white',

  dialogElementBg: 'white5',
  dialogBodyBg: 'darkItemBG',
  subTextColor: 'white50',
  textColor: 'white50',
  labelColor: 'white',
}

const colors = Object.entries(colorMap).reduce((acc, [k, v]) => {
  acc[k] = palette[v]
  return acc
}, {})

const lightModeObj: ThemeMode = {
  light: {
    ...colors,
  },
}

export default lightModeObj
