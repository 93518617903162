import React, { MutableRefObject, ReactElement, useState } from 'react'
import { Flex, Spinner } from 'theme-ui'
import { Dropdown, IconMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import BalancedIcon from 'images/dashboard/filters/balanced-icon.svg'
import RelaxedIcon from 'images/dashboard/filters/relaxed-icon.svg'
import StrictIcon from 'images/dashboard/filters/strict-icon.svg'
import DropdownIcon from 'images/profileManagement/context-menu-arrow-down.svg'
import { FilterLevel } from 'store/api/filters/filters.interface'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

export const filterLevelIcons = {
  [FilterLevel.RELAXED]: RelaxedIcon,
  [FilterLevel.BALANCED]: BalancedIcon,
  [FilterLevel.STRICT]: StrictIcon,
}

export default function FilterLevelsDropdown({
  selectedTitle,
  items,
  hideRef,
  isLoading = false,
}: {
  selectedTitle: string
  items: SimpleActionMenuItemProps[]
  hideRef: MutableRefObject<(() => void) | undefined>
  isLoading?: boolean
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  if (isLoading) {
    return <Spinner sx={{ width: '2.4rem', height: '2.4rem' }} />
  }
  return (
    <Dropdown
      data-testid="filter-levels-dropdown"
      sx={{
        p: 0,
      }}
      tippyprops={{
        appendTo: 'parent',
        theme: 'screen',
        placement: 'bottom-start',
        offset: [0, 8],
        onHide: () => {
          setIsOpen(false)
        },
        onShow: () => {
          setIsOpen(true)
        },
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        zIndex: 20,
      }}
      dropdowncontent={
        <IconMenu
          dataTestId="toggles-dropdown-content"
          hideRef={hideRef}
          isOpen={isOpen}
          items={items}
          sxButton={{
            ':hover': {
              fill: 'none',
              opacity: 1,
            },
          }}
          sxContainer={{
            width: '22rem',
            border: 0,
          }}
        />
      }
      variant="transparent"
    >
      <Flex
        sx={{
          width: ['100%', 'fit-content'],
          alignItems: 'center',
          justifyContent: ['space-between', 'flex-start'],
          ':hover': {
            '& > span': {
              color: 'white !important',
            },
            path: {
              fill: 'white',
            },
          },
        }}
      >
        <Svg
          svg={filterLevelIcons[selectedTitle || '']}
          fill={isOpen ? 'white' : 'white50'}
          sx={{ mr: '0.8rem' }}
        />
        <TextWithOverFlowAndTippyPopup
          sxText={{
            fontSize: '1.6rem',
            color: isOpen ? 'white' : 'white50',
            mr: '0.4rem',
          }}
          content={selectedTitle}
        />
        <Svg
          svg={DropdownIcon}
          fill={isOpen ? 'white' : 'white50'}
          sx={{
            width: '1.6rem',
            height: '1.6rem',
            ml: '0.5rem',
            flexShrink: 0,
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
          }}
        />
      </Flex>
    </Dropdown>
  )
}
