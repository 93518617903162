import React, { ReactElement, ReactChild } from 'react'
import { Flex, Box } from 'theme-ui'
import { modalDialogHeaderMinHeight } from 'ui/ModalDialog/ModalDialogHeader'

export const footerHeight = '7.8rem'

export default function SetupGuidePane({
  header,
  footer,
  children,
}: {
  header?: ReactElement
  footer?: ReactElement
  children: ReactChild
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        flexDirection: 'column',
      }}
    >
      {header}
      <Box
        sx={{
          height: `calc(100% - ${modalDialogHeaderMinHeight} - ${
            !!footer ? footerHeight : '0rem'
          })`,
          top: modalDialogHeaderMinHeight,
          left: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollbarWidth: 'thin',
        }}
        className="show-scrollbar"
      >
        <Flex
          sx={{
            flex: 1,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            flexDirection: 'column',
          }}
        >
          {children}
        </Flex>
      </Box>
      {footer}
    </Flex>
  )
}
