import React, { ReactElement } from 'react'
import { Box, Divider, Flex, ThemeUIStyleObject } from 'theme-ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import LogoutIcon from 'images/dashboard/navBar/logout-icon.svg'
import usePresentConfirmLogoutAlert from 'components/Dashboard/utils/usePresentConfirmLogoutAlert'
import { MainNavMenuItems } from 'components/Dashboard/NavBar/NavMenu'
import NavMenuItem from 'components/Dashboard/NavBar/NavMenuItem'
import UpcomingFeaturesPlaceholder from 'components/Dashboard/NavBar/UpcomingFeaturesPlaceholder'
import OrganizationNavMenu from 'components/Organization/OrganizationNavBar/OrganizationNavMenu'
import ImpersonationNavMenu from 'components/Organization/OrganizationNavBar/ImpersonationNavMenu'
import useQueryString from 'utils/useQueryString'
import { useAppSelector } from 'store/hooks'
import useIsElementVisibleInDOM from 'utils/useIsElementVisibleInDOM'
import { warningMessageRef } from 'components/WarningMessage'

export enum OrganizationNavRelativePath {
  MY_ORGANIZATION = 'my-org',
  SUB_ORGANIZATIONS = 'sub-orgs',
  BILLING = 'org-billing',
  API = 'api',
  ACCOUNT = 'account',
}

export default function OrganizationNavBarContent(): ReactElement {
  const isMobile = useBreakpointIndex() === 0
  const isDesktop = useBreakpointIndex() === 2
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const onLogout = usePresentConfirmLogoutAlert()
  const { qs } = useQueryString()
  const isWarningMessageVisible = useIsElementVisibleInDOM(warningMessageRef?.current)

  return (
    <Flex
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        px: ['1.6rem', 0],
        flexShrink: [0, 1],
        minHeight: '30rem',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          flexShrink: 0,
          height: ['auto', `calc(100% - 0.2rem - ${isWarningMessageVisible ? '4rem' : '0rem'})`],
        }}
      >
        <OrgNavMenuDivider
          sx={{
            display: ['none', 'flex'],
            mt: 0,
            mb: 0,
          }}
        />
        <Box
          sx={{
            display: ['none', 'block'],
            position: 'absolute',
            width: '100%',
            top: '0.1rem',
            pb: '1.8rem',
            backgroundImage: 'linear-gradient(180deg, #12131C 40%, rgba(18, 19, 28, 0) 100%)',
            zIndex: 'zIndex50',
          }}
        />

        <Flex
          sx={{
            flexDirection: 'column',
            overflowY: 'auto',
            pt: '1.6rem',
            pb: '1.6rem',
          }}
          className="hide-scrollbar"
        >
          {qs.orgId ? <ImpersonationNavMenu /> : <OrganizationNavMenu />}

          <OrgNavMenuDivider />
          <MainNavMenuItems />
          <OrgNavMenuDivider />
          <NavMenuItem onClick={onLogout} activeIcon={LogoutIcon} label="Log out" />
        </Flex>
      </Flex>
      {(isMobile || (isDesktop && isNavBarExpanded)) && (
        <Flex sx={{ pb: '1.6rem' }}>
          <UpcomingFeaturesPlaceholder
            sx={{
              position: ['static', 'absolute'],
              bottom: ['unset', '1.6rem'],
            }}
          />
        </Flex>
      )}
    </Flex>
  )
}

const OrgNavMenuDivider = ({ sx }: { sx?: ThemeUIStyleObject }) => (
  <Divider sx={{ my: '1.6rem', color: 'white10', ...sx }} />
)
