export const getFormattedCityCountryIsp = ({
  city,
  country,
  isp,
}: {
  city?: string
  country?: string
  isp?: string
}): string => {
  /* this is to show commas in appropriate places and handle the cases where any combo of city, country or isp is not defined */
  let cityCountryIspString = ''
  if (!city && !country) {
    cityCountryIspString += 'Unknown location'
  }
  if (city) {
    cityCountryIspString = city
  }
  if (country) {
    cityCountryIspString += (!!cityCountryIspString ? ', ' : '') + country
  }

  cityCountryIspString += `, ${isp ?? `Unknown ISP`}`

  return cityCountryIspString
}

export const getFormattedIsp = (isp?: string): string => {
  return `${isp ?? `Unknown ISP`}`
}
