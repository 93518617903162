import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import { SetupGuidePane } from 'components/SetupGuide/Components/index'
import { CONTROLDText } from 'ui/CONTROLD'
import ModalDialogHeader, { ButtonType } from 'ui/ModalDialog/ModalDialogHeader'
import { Button } from 'ui'
import useStartSetupFlow from 'components/SetupGuide/useStartSetupFlow'

export default function ConfigurationPane(): ReactElement {
  const { auto: startAutoSetup, manual: startManualSetup } = useStartSetupFlow()

  return (
    <SetupGuidePane
      header={
        <ModalDialogHeader title={`Configure ${CONTROLDText}`} buttonType={ButtonType.BACK} />
      }
    >
      <Flex
        sx={{
          width: '100%',
          height: '100%',
          px: ['1.6rem', '4.2rem', '8rem'],
          flexDirection: 'column',
          py: ['1.6rem', '3.2rem'],
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          sx={{
            fontSize: ['1.6rem', '1.8rem'],
            mb: '4.8rem',
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          Set up {CONTROLDText} manually or download an app to configure it with a single click
        </Text>
        <Flex
          sx={{
            height: 'fit-content',
            flexDirection: 'column',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              mr: 0,
              mb: '2.4rem',
            }}
          >
            <Button
              variant="secondary"
              sx={{
                width: '25.2rem',
                py: '1.2rem',
                fontSize: '1.8rem',
                fontWeight: 'bold',
                color: 'white',
                border: '2px solid',
                borderColor: 'plum',
                borderRadius: '59px',
                backgroundColor: 'plum',
              }}
              onClick={startAutoSetup}
            >
              Automatic Setup
            </Button>
            <Text sx={{ fontSize: '1.2rem', color: 'white50', mt: '0.8rem' }}>Use the App</Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
            <Button
              variant="secondary"
              sx={{
                width: '25.2rem',
                py: '1.2rem',
                fontSize: '1.8rem',
                fontWeight: 'bold',
                color: 'white',
                border: '2px solid',
                borderColor: 'plum',
                borderRadius: '59px',
                backgroundColor: 'transparent',
              }}
              onClick={startManualSetup}
            >
              Manual Setup
            </Button>
            <Text sx={{ fontSize: '1.2rem', color: 'white50', mt: '0.8rem' }}>Advanced</Text>
          </Flex>
        </Flex>
      </Flex>
    </SetupGuidePane>
  )
}
