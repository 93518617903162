import React, { ReactElement } from 'react'
import { SelectVersionPane, SetupGuidePane } from 'components/SetupGuide/Components'
import { SetupOs } from 'components/SetupGuide/SetupGuide.interface'

import { Button } from 'ui'
import useQueryString from 'utils/useQueryString'
import ModalDialogHeader, { ButtonType } from 'ui/ModalDialog/ModalDialogHeader'

export default function AppleTVVersionSelector(): ReactElement {
  const { qs, setupGuideNav } = useQueryString()
  return (
    <SetupGuidePane header={<ModalDialogHeader title="Apple TV" buttonType={ButtonType.BACK} />}>
      <SelectVersionPane>
        <Button
          variant="simple"
          sx={{
            backgroundColor: 'plum',
            color: 'white',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            border: 'none',
            borderColor: 'plum',
            borderRadius: '59px',
          }}
          onClick={(): void => {
            setupGuideNav({
              ...qs,
              helpPane: SetupOs.APPLE_TV_OS_13,
              setupOs: SetupOs.APPLE_TV_OS_13,
              step: '0',
            })
          }}
        >
          tvOS13 or older
        </Button>
        <Button
          variant="simple"
          sx={{
            backgroundColor: 'plum',
            color: 'white',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            border: 'none',
            borderColor: 'plum',
            borderRadius: '59px',
          }}
          onClick={(): void => {
            setupGuideNav({
              ...qs,
              helpPane: SetupOs.APPLE_TV_OS_14,
              setupOs: SetupOs.APPLE_TV_OS_14,
              step: '0',
            })
          }}
        >
          tvOS14+
        </Button>
      </SelectVersionPane>
    </SetupGuidePane>
  )
}
