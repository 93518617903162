import React, { RefObject, ReactElement, useCallback, useMemo } from 'react'
import FloatingActionButton from 'ui/FloatingActionButton'
import { useAppSelector } from 'store/hooks'
import { Setter } from 'utils'
import { ThemeUIStyleObject } from 'theme-ui'
import { useDeleteIpsMutation } from 'store/api/access'

const buttonSpace = '1.6rem'
const buttonWidth = '4.8rem'
const buttonSpaceAndWidth = `calc(${buttonSpace} + ${buttonWidth})`
const rightSpaceForButtonTabletOrDesktop = (additionalSpace: number): string =>
  `calc(${additionalSpace} * ${buttonSpaceAndWidth} + ${buttonSpace})`

interface SelectButtonProps {
  selectButtonRef: RefObject<HTMLButtonElement>
  ipsToDelete: string[]
  areAllIPsSelected: boolean
  shouldShowDeleteConfirmation: boolean
  setShouldShowDeleteConfirmation: Setter<boolean>
  isMultiSelectMode: boolean
  setIsMultiSelectMode: Setter<boolean>
  selectAllIPs: () => void
  deselectAllIPs: () => void
  isDisabled?: boolean
  sx?: ThemeUIStyleObject
}
const SelectButton = ({
  selectButtonRef,
  ipsToDelete,
  areAllIPsSelected,
  shouldShowDeleteConfirmation,
  setShouldShowDeleteConfirmation,
  isMultiSelectMode,
  setIsMultiSelectMode,
  selectAllIPs,
  deselectAllIPs,
  isDisabled,
  sx,
}: SelectButtonProps): ReactElement => {
  const ipsCount = ipsToDelete.length
  const [deleteIps] = useDeleteIpsMutation()
  const accessSelectedDeviceId = useAppSelector(s => s.access.selectedDeviceId || '')

  const selectionButtonText: string = useMemo((): string => {
    if (isMultiSelectMode) {
      if (shouldShowDeleteConfirmation) {
        return `Delete ${ipsCount} IP${ipsCount > 1 ? 's' : ''}`
      } else {
        return areAllIPsSelected ? 'Deselect All' : 'Select All'
      }
    } else {
      return ''
    }
  }, [shouldShowDeleteConfirmation, areAllIPsSelected, ipsCount, isMultiSelectMode])

  const selectButtonOnClick = useCallback(async (): Promise<void> => {
    if (isMultiSelectMode) {
      if (shouldShowDeleteConfirmation) {
        deleteIps({ ips: ipsToDelete, deviceId: accessSelectedDeviceId })
        deselectAllIPs()
        setShouldShowDeleteConfirmation(false)
        return
      }

      if (areAllIPsSelected) {
        deselectAllIPs()
        setIsMultiSelectMode(false)
      } else {
        selectAllIPs()
      }
    } else {
      setIsMultiSelectMode(true)
    }
  }, [
    isMultiSelectMode,
    shouldShowDeleteConfirmation,
    areAllIPsSelected,
    deleteIps,
    ipsToDelete,
    accessSelectedDeviceId,
    deselectAllIPs,
    setShouldShowDeleteConfirmation,
    setIsMultiSelectMode,
    selectAllIPs,
  ])

  return (
    <FloatingActionButton
      disabled={isDisabled}
      variant="simple"
      dataTestId="select-button"
      ref={selectButtonRef}
      fill={'white50'}
      text={selectionButtonText || 'Select All'}
      hasOverlay
      sx={{
        left: [0, 'unset'],
        right: (): string[] => ['unset', rightSpaceForButtonTabletOrDesktop(1)],
        bottom: 0,
        backgroundColor: 'darkItemBG',
        border: 'none',
        color: shouldShowDeleteConfirmation ? 'pomegranate' : 'white50',
        p: '0.8rem',
        width: 'max-content',
        height: 'max-content',
        '& > div:first-of-type': {
          width: 'max-content',
          height: 'max-content',
        },
        ...sx,
      }}
      onClick={selectButtonOnClick}
    />
  )
}

export default SelectButton
