import React, { ReactElement } from 'react'
import { ThemeProvider, Theme } from 'theme-ui'
import base from './themeObject.base'

// to get the current mode and switch between modes use "const [colorMode, setColorMode] = useColorMode()" where needed
export default function CustomThemeProvider({
  children,
}: {
  children: ReactElement[] | ReactElement
}): React.ReactElement {
  return <ThemeProvider theme={base as Theme}>{children}</ThemeProvider>
}
