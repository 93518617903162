import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initialResponseState, ResponseState, LoadingState } from 'store/fetchingLogic'
import { VERIFY_INTERVAL } from 'gatsby-env-variables'
import { devicesApi } from 'store/api/devices/devices'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import { sessionLogout } from '../session'
import { RootState } from 'store/rootReducer'

const devicesPollingIntervalMs = 2_000

export interface DeviceState extends ResponseState<{ devices: DeviceInfo[] }> {
  showScheduleDeviceTray: boolean
  editDeviceId?: string
  configuredDevice?: DeviceInfo | undefined
  checkConfiguredDeviceLoadingState?: LoadingState
  shouldSortDevicesAlphabetically?: boolean
  isDeviceStatusVerified: boolean
}

const initialState: DeviceState = {
  ...initialResponseState,
  showScheduleDeviceTray: false,
  shouldSortDevicesAlphabetically: true,
  isDeviceStatusVerified: false,
}

let devicePollingResponse
export const startPollingDevices = createAsyncThunk(
  'devices/startPolling',
  async (
    { deviceId, shouldSubscribe = true }: { deviceId?: string; shouldSubscribe?: boolean },
    { dispatch },
  ): Promise<void> => {
    if (!shouldSubscribe) {
      devicePollingResponse.unsubscribe()
      dispatch(detectConfiguredDevice(false))
    } else {
      devicePollingResponse = dispatch(
        devicesApi.endpoints.getDevice.initiate(deviceId, {
          subscribe: true,
          subscriptionOptions: {
            pollingInterval: devicesPollingIntervalMs,
          },
        }),
      )
      dispatch(detectConfiguredDevice(true))
    }
  },
)

export const stopPollingDevices = createAsyncThunk(
  'devices/stopPolling',
  async (_, { dispatch }) => {
    dispatch(startPollingDevices({ shouldSubscribe: false }))
  },
)

let configuredDeviceResponse
export const detectConfiguredDevice = createAsyncThunk(
  'devices/detectConfiguredDevice',
  async (shouldSubscribe: boolean | undefined = true, { dispatch, getState }) => {
    const configuredDevice = (getState() as RootState).devices.configuredDevice

    if (!shouldSubscribe) {
      configuredDeviceResponse?.unsubscribe()
    } else {
      if (configuredDeviceResponse) {
        configuredDeviceResponse?.unsubscribe()
      }
      configuredDeviceResponse = dispatch(
        devicesApi.endpoints.detectDevice.initiate('', {
          subscribe: true,
          subscriptionOptions: {
            pollingInterval: configuredDevice
              ? undefined
              : VERIFY_INTERVAL || devicesPollingIntervalMs,
          },
        }),
      )
    }

    return
  },
)

export const scrubProfilesInDevice = (device: DeviceInfo): DeviceInfo => {
  const scrubbedDevice = {
    ...device,
    profile_id: device.profile_id?.toString(),
    profile_id2: device.profile_id2?.toString(),
  }
  if (scrubbedDevice.profile?.PK) {
    scrubbedDevice.profile = { ...scrubbedDevice.profile, PK: scrubbedDevice.profile.PK.toString() }
  }
  if (scrubbedDevice.profile2?.PK) {
    scrubbedDevice.profile2 = {
      ...scrubbedDevice.profile2,
      PK: scrubbedDevice.profile2.PK.toString(),
    }
  }
  return scrubbedDevice
}

export const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    clearError(state): void {
      state.error = undefined
    },
    toggleScheduleDeviceTray(state, action: PayloadAction<boolean | undefined>): void {
      state.showScheduleDeviceTray = action.payload ?? !state.showScheduleDeviceTray
    },
    setDeviceForEdit(state, action: PayloadAction<string | undefined>): void {
      state.editDeviceId = action.payload
    },
    setShouldSortDevicesAlphabetically(state, action: PayloadAction<boolean>): void {
      state.shouldSortDevicesAlphabetically = action.payload
    },
    setIsDeviceStatusVerified(state, action: PayloadAction<boolean>): void {
      state.isDeviceStatusVerified = action.payload
    },
    setConfigureDevice(state, action: PayloadAction<DeviceInfo | undefined>): void {
      state.configuredDevice = action.payload
    },
    clearDevices(state): void {
      state.data && (state.data.devices = [])
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, () => initialState)
      .addMatcher(devicesApi.endpoints.deleteDevice.matchFulfilled, (state, action) => {
        if (action.meta.arg.originalArgs.pk == state.configuredDevice?.PK) {
          state.configuredDevice = undefined
        }
      })
  },
})

export const {
  toggleScheduleDeviceTray,
  setDeviceForEdit,
  clearDevices,
  setShouldSortDevicesAlphabetically,
  setIsDeviceStatusVerified,
  setConfigureDevice,
} = deviceSlice.actions
