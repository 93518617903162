import React, { Dispatch, ReactElement } from 'react'
import { Box, Divider, Flex, Text } from 'theme-ui'
import SettingsItem from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsItem'
import AnalyticsIcon from 'images/profileManagement/settings/analytics.svg'
import { ExternalLink, Input, Switch } from 'ui'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import AnalyticsDropdown from './AnalyticsDropdown'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import RegionDropdown from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsDropdowns/RegionDropdown'
import { ActionType, InitialStateType, ProvisionActionType } from './ProvisionState'
import PreventDeactivationIcon from 'images/profileManagement/settings/prevent-deactivation.svg'
import { EnabledStatus } from 'store/api/rules'
import { isDeactivationPinLengthValid } from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/AddOrEditDevice/AddOrEditDeviceView'
import ErrorBox from 'components/LoginSignUpForm/ErrorBox'
import { numericalMask } from './AddProvisionView'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'

export default function ProvisionSettings({
  provisionState,
  provisionDispatch,
}: {
  provisionState: InitialStateType
  provisionDispatch: Dispatch<ActionType>
}): ReactElement {
  const { data: orgData } = useGetOrganization()
  const orgStatisticsRegion = orgData?.organization.stats_endpoint

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Text
        sx={{
          ml: '2.4rem',
          mb: '0.8rem',
          fontSize: '1.6rem',
          fontWeight: 'bold',
          color: 'white50',
        }}
      >
        Settings
      </Text>
      <Flex sx={{ flexDirection: 'column', gap: ['1.6rem', '2.4rem'] }}>
        <SettingsItem
          testId="analytics"
          icon={AnalyticsIcon}
          title="Analytics"
          description="Log DNS queries and generate activity reports."
          descriptionLink={
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/analytics-levels`}
              sx={{
                color: 'white50',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
              }}
            >
              Learn more
            </ExternalLink>
          }
          actionContent={
            <Box
              data-testid="provision-analytics-dropdown-container"
              sx={{ position: 'relative', flexShrink: 0 }}
              className="reset-tooltip"
            >
              <AnalyticsDropdown
                deviceStats={provisionState.stats}
                settingsDispatch={provisionDispatch}
                boundaryDataTestId="provision-analytics-dropdown-container"
              />
            </Box>
          }
          expandedContent={
            provisionState.stats !== StatLevel.NO &&
            !orgStatisticsRegion && (
              <Flex sx={{ width: '100%', flexDirection: 'column' }}>
                <Divider sx={{ width: '100%', color: 'white10' }} />
                <SettingsItem
                  title="Storage Region"
                  description="The region you select will be effective for every Endpoint on your account and can be changed in your Account settings."
                  actionContent={
                    <Box
                      data-testid="settings-region-dropdown-container"
                      sx={{ position: 'relative' }}
                      className="reset-tooltip"
                    >
                      <RegionDropdown
                        region={provisionState.regionSettings?.stats_endpoint}
                        setRegionSettings={payload => {
                          provisionDispatch({
                            type: ProvisionActionType.REGION_SETTINGS,
                            payload,
                          })
                        }}
                        boundaryDataTestId="settings-region-dropdown-container"
                      />
                    </Box>
                  }
                  sx={{
                    backgroundColor: 'transparent',
                    borderRadius: 'unset',
                    boxShadow: 'none',
                    pb: 0,
                    '& > div:first-of-type': {
                      p: 0,
                    },
                  }}
                />
              </Flex>
            )
          }
        />

        <SettingsItem
          testId="prevent-deactivation"
          icon={PreventDeactivationIcon}
          title="Prevent Deactivation"
          description="Set a PIN that is required to disable Control D if apps are used."
          descriptionLink={
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/prevent-deactivation`}
              sx={{
                color: 'white50',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
              }}
            >
              Learn more
            </ExternalLink>
          }
          actionContent={
            <Switch
              data-testid="prevent-deactivation-switch"
              checked={!!provisionState.deactivationStatus}
              onClick={() => {
                const payload = provisionState.deactivationStatus
                  ? EnabledStatus.DISABLED
                  : EnabledStatus.ENABLED

                provisionDispatch({
                  type: ProvisionActionType.PREVENT_DEACTIVATION_STATUS,
                  payload,
                })

                if (payload === EnabledStatus.DISABLED) {
                  provisionDispatch({
                    type: ProvisionActionType.PREVENT_DEACTIVATION_PIN,
                    payload: undefined,
                  })
                }
              }}
            />
          }
          expandedContent={
            !!provisionState.deactivationStatus ? (
              <Flex sx={{ width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                <Input
                  name="prevent-deactivation-input"
                  data-testid="prevent-deactivation-input"
                  aria-label="prevent deactivation input"
                  activeLabelColor="white"
                  hideLabel
                  value={provisionState.deactivation_pin || ''}
                  placeholder="Enter a numeric PIN"
                  sx={{
                    borderRadius: '45px',
                    alignItems: 'center',
                    backgroundColor: 'white10',
                  }}
                  onChange={(event): void => {
                    provisionDispatch({
                      type: ProvisionActionType.PREVENT_DEACTIVATION_PIN,
                      payload: event.target.value.length
                        ? +numericalMask(event.target.value)
                        : undefined,
                    })
                  }}
                />
                {(!provisionState.deactivation_pin ||
                  !isDeactivationPinLengthValid(provisionState.deactivation_pin)) && (
                  <ErrorBox
                    sx={{
                      width: 'fit-content',
                      minHeight: 'auto',
                      backgroundColor: 'transparent',
                      mx: 0,
                      my: '0.4rem',
                      fontWeight: 'normal',
                    }}
                    errorMessage="Please enter a deactivation PIN consisting of 1-10 characters."
                  />
                )}
              </Flex>
            ) : null
          }
        />
      </Flex>
    </Flex>
  )
}
