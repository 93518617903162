import React, { ReactElement } from 'react'
import TabsContainer, { Tab } from 'components/HomePage/TabsContainer'
import { Text } from 'theme-ui'
import type { Setter } from 'utils'

export enum DeviceTab {
  SINGLE,
  MULTIPLE,
}

const deviceTabs = [
  { text: 'Single Endpoint', value: DeviceTab.SINGLE },
  { text: 'Multiple Endpoints', value: DeviceTab.MULTIPLE },
]

const AddOrEditDeviceTabs = ({
  selectedTab,
  setSelectedTab,
  resetDeviceInput,
}: {
  selectedTab: DeviceTab
  setSelectedTab: Setter<DeviceTab>
  resetDeviceInput: () => void
}): ReactElement => {
  return (
    <TabsContainer
      sx={{
        display: 'flex',
        border: 'none',
        width: '100%',
        alignSelf: 'flex-start',
        '& button:last-of-type': {
          ml: '2.4rem',
        },
      }}
    >
      {deviceTabs.map(option => (
        <Tab
          key={option.text}
          onClick={() => {
            setSelectedTab(option.value)
            resetDeviceInput()
          }}
          isActive={selectedTab === option.value}
          activeColor="white"
          sxTab={{
            pt: 0,
            pb: '0.8rem',
            mr: 0,
            px: 0,
            maxWidth: 'fit-content',
          }}
        >
          <Text sx={{ fontSize: ['1.6rem', '1.8rem'], fontWeight: 'bold' }}>{option.text}</Text>
        </Tab>
      ))}
    </TabsContainer>
  )
}

export default AddOrEditDeviceTabs
