import React, { ReactElement } from 'react'
import PlatformItem from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/PlatformItem'
import { Flex, Text } from 'theme-ui'
import { DeviceSettings } from 'store/api/devices/devices.interface'

export default function GroupItem({
  title,
  icons,
}: {
  title: string
  icons: DeviceSettings[]
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Text sx={{ fontSize: '1.6rem', fontWeight: 'bold', color: 'white50', mb: '3.2rem' }}>
        {title}
      </Text>
      <Flex
        sx={{
          flexWrap: 'wrap',
          gap: '1.6rem',
          justifyContent: 'center',
        }}
      >
        {icons.map(({ type, name, settings }) => (
          <PlatformItem
            key={type}
            platform={type}
            name={name}
            legacyIpv4Status={settings?.legacy_ipv4_status}
            stats={settings?.stats}
            learnIp={settings?.learn_ip}
          />
        ))}
      </Flex>
    </Flex>
  )
}
