import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import { resolverText } from 'components/SetupGuide/ResolverTabs/Components/ResolverTabTitle'

export interface SecureResolverData {
  resolverId?: string
  doh?: string
  dot?: string
}
export default function SecureResolver({
  isResolverTypeHighlighted,
  canCopy,
  address,
  shouldShowFullAddress,
  type,
}: {
  isResolverTypeHighlighted: boolean
  address: string
  canCopy: boolean
  shouldShowFullAddress?: boolean
  type?: string
}): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        minWidth: '100%',
        mb: '1.6rem',
      }}
    >
      <Flex
        sx={{
          minWidth: '100%',
          flexShrink: 0,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          borderRadius: '16px',
          px: '1.6rem',
          py: '1.3rem',
          color: isResolverTypeHighlighted ? 'greenApple' : 'white50',
          backgroundColor: isResolverTypeHighlighted ? 'greenApple15' : 'dialogBodyBg',
          border: '1px solid',
          borderColor: isResolverTypeHighlighted ? 'greenApple' : 'white15',
          flexDirection: 'column',
        }}
      >
        {type && (
          <Text
            variant="cardLabel"
            sx={{
              whiteSpace: 'nowrap',
              flexShrink: 0,
              mr: '0.8rem',
              color: 'textColor',
            }}
          >
            {resolverText[type]}
          </Text>
        )}
        <Flex sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
          <Text
            variant="address"
            sx={{
              color: 'textColor',
              mr: '2rem',
              fontSize: '1.6rem',
              overflow: 'hidden',
              textAlign: 'left',
              ...(shouldShowFullAddress
                ? { wordWrap: 'break-word' }
                : {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%', // to enable the textoverflow
                  }),
              minWidth: '20rem',
            }}
            tabIndex={0}
          >
            {address}
          </Text>
          {canCopy && (
            <CopyToClipboard
              data={address}
              smallIcon
              ariaLabelText="copy resolver to clipboard"
              fillSvg={isResolverTypeHighlighted ? 'greenApple' : 'textColor'}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}
