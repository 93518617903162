import React, { ReactElement } from 'react'
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui'
import { Table } from 'ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

export default function ReceiptContainer({
  headers,
  children,
  sx,
}: {
  headers: string[]
  children: ReactElement[]
  sx?: ThemeUIStyleObject
}): ReactElement {
  const breakpoint = useBreakpointIndex()
  const isDesktop = breakpoint === 2
  return (
    <>
      {isDesktop ? (
        <Box
          sx={{
            display: ['none', 'none', 'initial'],
            '& table tbody tr:nth-of-type(odd)': {
              backgroundColor: 'white10',
            },
            ...sx,
          }}
        >
          <Table headers={headers} hideHeaders={false}>
            {children}
          </Table>
        </Box>
      ) : (
        <Flex
          sx={{
            flexDirection: 'column',
            flexShrink: 0,
            display: ['inherit', 'inherit', 'none'],
            backgroundColor: 'darkItemBG',
            borderRadius: '16px',
            justifyContent: 'flex-end',
            py: '0.8rem',
            mb: '2.4rem',
            '& > div:not(:last-of-type)': {
              borderBottom: '1px solid',
              borderBottomColor: 'white10',
            },
          }}
        >
          {children}
        </Flex>
      )}
    </>
  )
}
