import React, { ReactElement, useRef, useState } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Dropdown, SimpleActionMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import ArrowUpDownIcon from 'images/ui/arrow-up-down.svg'
import useBoundaryElement from 'utils/useBoundaryElement'

export default function SettingsDropdown({
  disabled = false,
  items,
  hideRef,
  testId,
  defaultValue,
  boundaryDataTestId = '',
  buttonSx,
}: {
  disabled?: boolean
  items: SimpleActionMenuItemProps[]
  hideRef: React.MutableRefObject<(() => void) | undefined>
  testId?: string
  defaultValue?: string | ReactElement
  boundaryDataTestId?: string
  buttonSx?: ThemeUIStyleObject
}): ReactElement {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const buttonContentRef = useRef<HTMLButtonElement>(null)
  const selectedItem = items.find(item => item.isSelected)
  const boundaryElement = useBoundaryElement(boundaryDataTestId)

  return (
    <Dropdown
      disabled={disabled}
      buttonRef={buttonContentRef}
      data-testid={testId}
      variant="simple"
      onKeyDown={stopPropagationOnKeyPress}
      sx={{
        width: '100%',
        p: 0,
      }}
      tippyprops={{
        appendTo: boundaryElement,
        placement: 'top-end',
        animation: 'fade',
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        onHide() {
          setIsDropdownOpened(false)
        },
        onTrigger() {
          setIsDropdownOpened(true)
        },
        theme: 'screen',
        maxWidth: 'fit-content',
      }}
      dropdowncontent={
        <Flex
          className="show-scrollbar"
          data-testid={`${testId}-content`}
          sx={{
            flexDirection: 'column',
            width: '25rem',
            px: '1.6rem',
            py: '0.8rem',
          }}
        >
          <SimpleActionMenu
            hideRef={hideRef}
            isOpen={isDropdownOpened}
            items={items}
            sxContainer={{ px: 0, py: '0.8rem', position: 'relative' }}
            sxButton={{
              maxHeight: 'auto',
              m: 0,
              py: '0.8rem',
              px: '1.6rem',
              fontSize: '1.6rem',
              fontWeight: 'normal',
              alignItems: 'center',
            }}
          />
        </Flex>
      }
    >
      <Flex sx={{ width: '100%', alignItems: 'center', flexShrink: 0, ...buttonSx }}>
        <Text
          sx={{
            fontSize: '1.8rem',
            fontWeight: 'bold',
            color: isDropdownOpened ? 'white' : 'white50',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            mr: ['0.2rem', '0.8rem'],
          }}
        >
          {selectedItem?.buttonContent || defaultValue}
        </Text>
        <Svg
          svg={ArrowUpDownIcon}
          fill={isDropdownOpened ? 'white' : 'white50'}
          sx={{ opacity: 1, flexShrink: 0 }}
        />
      </Flex>
    </Dropdown>
  )
}
