import React, { ReactElement, ReactNode } from 'react'
import { Flex } from 'theme-ui'

interface PreferencesWrapperProps {
  children: ReactNode
}

export default function PreferencesWrapper({ children }: PreferencesWrapperProps): ReactElement {
  return (
    <Flex
      sx={{
        flexWrap: 'unset',
        flexDirection: 'column',
        flexGrow: 1,
        gap: '1.6rem',
        pl: 0,
        pb: '4.8rem',
      }}
    >
      {children}
    </Flex>
  )
}
