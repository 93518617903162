import React, { MutableRefObject, ReactElement, ReactNode, useMemo, useRef } from 'react'
import { Divider, Flex, ThemeUIStyleObject } from 'theme-ui'
import { stopPropagationOnKeyPress } from 'utils/getOnKeyPressWith'
import { Button, ButtonProps } from '../Buttons'
import omit from 'lodash/omit'
import useDropdownKeyboardControl from 'utils/useDropdownKeyboardControl'
import { useAppSelector } from 'store/hooks'
import useColorModeHelper from 'utils/useColorModeHelper'

export interface SimpleActionMenuItemProps extends ButtonProps {
  dataTestId?: string
  buttonContent?: string | ReactNode
  isBackgroundHighlighted?: boolean
  isBackgroundHighlightedOnHover?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  isTopDivider?: boolean
  ref?: MutableRefObject<HTMLButtonElement | null>
  tabIndex?: number
  isKeyboardHover?: boolean
  highlightedBackgroundColor?: string
  customHoverStyles?: ThemeUIStyleObject // pass custom hover styles in this prop to get the same hover styles using the keyboard or mouse
}

export function SimpleActionMenuItem({
  children,
  dataTestId,
  isBackgroundHighlighted,
  isBackgroundHighlightedOnHover,
  highlightedBackgroundColor,
  isDisabled,
  isSelected,
  isTopDivider,
  ref,
  sx,
  tabIndex,
  isKeyboardHover,
  customHoverStyles,
  ...buttonProps
}: SimpleActionMenuItemProps): ReactElement | null {
  const refButton = useRef<HTMLButtonElement | null>(null)
  const { isDarkMode } = useColorModeHelper()

  if (isKeyboardHover) {
    refButton.current?.focus()
  }

  const keyboardHoverStyles = useMemo(
    () =>
      isKeyboardHover
        ? customHoverStyles || {
            color: 'white',
            backgroundColor: 'white10',
            svg: {
              opacity: !isDisabled && 1,
            },
            a: {
              color: 'white',
            },
            span: {
              color: 'white',
            },
            '.icon-circle': {
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            },
            '.configure-text': {
              color: 'pomegranate',
              opacity: 1,
            },
          }
        : {},
    [isKeyboardHover, customHoverStyles, isDisabled],
  )

  return children ? (
    <>
      {isTopDivider && (
        <Divider
          key={`${buttonProps.key || 'menu-item'}-divider`}
          sx={{
            backgroundColor: 'white10',
            border: 'none',
            height: '1px',
            mx: !isDarkMode && '-1.6rem',
            my: '0rem',
          }}
        />
      )}
      <Button
        ref={ref || refButton}
        data-testid={dataTestId}
        disabled={isDisabled}
        variant="transparent"
        sx={{
          backgroundColor:
            (isSelected && isBackgroundHighlighted) || isKeyboardHover
              ? highlightedBackgroundColor || isDarkMode
                ? 'color3'
                : 'white10'
              : 'transparent',
          borderRadius: '0.8rem',
          color: isSelected ? 'labelColor' : 'subTextColor',
          border: '1px solid',
          borderColor: isDarkMode && isSelected ? 'blue500' : 'transparent',
          fontSize: '1.6rem',
          fontWeight: 'normal',
          justifyContent: 'flex-start',
          flexShrink: 0,
          maxHeight: 'fit-content',
          mx: '0.8rem',
          p: '0.8rem',
          img: {
            opacity: isSelected && !isDisabled ? 1 : 0.6,
          },
          svg: {
            opacity: isSelected && !isDisabled ? 1 : 0.6,
          },
          a: {
            color: isDarkMode ? 'title' : isSelected ? 'white' : 'white50',
          },
          ...keyboardHoverStyles,
          '&:hover': {
            ':not([disabled])': {
              backgroundColor: [
                isSelected || isBackgroundHighlightedOnHover
                  ? highlightedBackgroundColor || 'white10'
                  : 'transparent',
                isDisabled ? 'transparent' : highlightedBackgroundColor || 'white10',
              ],
              opacity: 1,
              a: {
                color: 'white',
              },
              color: [
                isSelected || isBackgroundHighlightedOnHover ? 'white' : 'white50',
                isDisabled ? 'white50' : 'white',
              ],
              span: {
                color: [
                  isSelected || isBackgroundHighlightedOnHover ? 'white' : 'white50',
                  isDisabled ? 'white50' : 'white',
                ],
              },
              svg: {
                opacity: [isSelected ? 1 : 0.5, isDisabled ? 0.5 : 1],
              },
              img: {
                opacity: [isSelected ? 1 : 0.5, isDisabled ? 0.5 : 1],
              },
              '.icon-circle': {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
              },
              '.configure-text': {
                color: 'pomegranate',
                opacity: 1,
              },
              '.shared-profile-badge': {
                color: 'berryBlue',
              },
              ...customHoverStyles,
            },
          },
          ':disabled': {
            backgroundColor: 'transparent',
            opacity: 0.5,
            '& svg,path': {
              fill: 'white',
            },
          },
          '.icon-circle': isKeyboardHover
            ? {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
              }
            : {},
          ...sx,
        }}
        tabIndex={tabIndex}
        onKeyDown={stopPropagationOnKeyPress}
        {...omit(buttonProps, 'buttonContent')}
      >
        {children}
      </Button>
    </>
  ) : null
}

export interface SimpleActionMenuProps {
  dataTestId?: string
  items: SimpleActionMenuItemProps[]
  sxContainer?: ThemeUIStyleObject
  sxButton?: ThemeUIStyleObject
  customHoverIndex?: number
  customHoverStyles?: ThemeUIStyleObject
  hasTopBorder?: boolean
  highlightedBackgroundColor?: string
  hideRef?: MutableRefObject<(() => void) | undefined>
  isOpen?: boolean
  tabIndex?: number
}

export default function SimpleActionMenu({
  dataTestId,
  items,
  sxContainer,
  sxButton,
  customHoverIndex,
  customHoverStyles,
  hasTopBorder,
  hideRef,
  isOpen,
  tabIndex,
}: SimpleActionMenuProps): ReactElement {
  const isSearchDropdownVisible = useAppSelector(s => s.dashboard.isSearchDropdownVisible)
  const hoverIndex = useDropdownKeyboardControl(
    items.length,
    isSearchDropdownVisible,
    hideRef,
    isOpen,
  )
  const keyboardHoverIndex = isSearchDropdownVisible ? customHoverIndex : hoverIndex

  return (
    <Flex
      data-testid={dataTestId}
      sx={{
        flexDirection: 'column',
        px: 0,
        pt: 0,
        py: hasTopBorder ? '1.6rem' : '0.4rem',
        gap: '0.4rem',
        borderTop: hasTopBorder ? '1px solid' : 'none',
        borderColor: hasTopBorder ? 'white15' : 'none',
        lineHeight: hasTopBorder ? '2.2rem' : 'none',
        ...sxContainer,
      }}
      className="show-scrollbar"
    >
      {items.map(({ sx, ...item }, index) => (
        <SimpleActionMenuItem
          key={index}
          sx={{ ...sxButton, ...sx }}
          {...item}
          isKeyboardHover={keyboardHoverIndex === index}
          customHoverStyles={customHoverStyles}
          tabIndex={tabIndex}
        />
      ))}
    </Flex>
  )
}
