import React, { ReactElement } from 'react'
import { Flex, Text } from 'theme-ui'
import ConfigurationCode from './ConfigurationCode'
import { Svg } from 'ui'
import NetshInterfaceImage from 'images/helpPaneSteps/windows/win-netsh-interface.svg'

export default function WindowsInterfaceConfiguration({ ip }: { ip: string }): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: ['1.6rem', 0],
        textAlign: 'center',
        pb: '1.8rem',
      }}
    >
      <Text sx={{ mb: '1.6rem' }}>
        Find the Interface you are currently using or want to change the DNS server on. The one
        you&apos;re most likely looking for will be called “Ethernet” or “Wi-fi”. To know for sure,
        run this command.
      </Text>
      <ConfigurationCode text="netsh interface show interface" />
      <Text sx={{ mt: '2.4rem', mb: '1.6rem' }}>You should see something similar to this:</Text>
      <Svg
        svg={NetshInterfaceImage}
        sx={{ width: ['29rem', '57.6rem'], height: ['8rem', '16rem'] }}
      />
      <Text sx={{ mt: '2.4rem', mb: '1.6rem' }}>
        Choose the &quot;Interface Name&quot; that is both Enabled and Connected. In the above
        example it&apos;s &quot;Ethernet&quot;. Now run this command.
      </Text>
      <ConfigurationCode text={`netsh interface ipv4 set dns name="Ethernet" static ${ip}`} />
    </Flex>
  )
}
