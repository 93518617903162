import React, { ReactElement } from 'react'
import { EditDataRowProps } from 'components/Dashboard/Preferences/CardItems/DataRow.interface'
import { Flex, Text, IconButton } from 'theme-ui'
import EditIcon from 'images/edit-icon.svg'
import { Svg } from 'ui'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

export default function EditableDataRow({
  name,
  leftContent,
  data,
  children,
  isEditing,
  setIsEditing,
  handleIconClick,
  disableEdit = false,
  icon,
  sxContainer,
  sxRow,
  bottomContent,
  testId,
  extraDataToAppend,
}: EditDataRowProps): ReactElement {
  const handleClick = handleIconClick ? handleIconClick : () => setIsEditing?.(true)

  return (
    <Flex
      data-testid={testId}
      sx={{
        width: '100%',
        backgroundColor: 'darkItemBG',
        borderRadius: '16px',
        flexDirection: 'column',
        px: ['1.6rem', '2.4rem', '2.4rem'],
        py: '1.6rem',
        minHeight: 'headerHeight',
        ...sxRow,
      }}
    >
      <Flex
        sx={{
          width: '100%',
          flexDirection: ['column', 'row', 'row'],
          alignItems: ['flex-start', 'center'],
          ...sxContainer,
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            flexShrink: [1, 0],
            width: 'fit-content',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Text
            sx={{
              minWidth: ['100%', '18.6rem'],
              mr: [0, '2.4rem'],
              mb: ['1.6rem', 0],
              color: 'white',
              alignSelf: 'baseline',
              fontSize: '1.8rem',
              fontWeight: 'bold',
            }}
            tabIndex={name ? 0 : undefined}
          >
            {name}
          </Text>
          {leftContent && <Flex>{leftContent}</Flex>}
        </Flex>
        {isEditing ? (
          children
        ) : (
          <Flex
            sx={{
              flexDirection: 'row',
              flex: 1,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Flex sx={{ width: extraDataToAppend ? 'auto' : '100%', color: 'white50' }}>
              <TextWithOverFlowAndTippyPopup
                testId="profile-row-data"
                sxText={{
                  pr: extraDataToAppend ? 0 : '3.2rem',
                  flex: 10,
                  width: ['100%', extraDataToAppend ? '4.8rem' : '100%'],
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  textAlign: extraDataToAppend ? ['left', 'center'] : 'left',
                }}
                content={data}
              />
              {extraDataToAppend}
            </Flex>

            {!disableEdit && (
              <Flex sx={{ flex: 2, justifyContent: 'flex-end', alignItems: 'center' }}>
                <IconButton
                  data-testid={`edit-${name.toLocaleLowerCase()}`}
                  aria-label={`Edit ${name}`}
                  sx={{
                    flexShrink: 0,
                    justifyContent: 'flex-end',
                    pl: '0.8rem',
                    '&:hover svg path': {
                      fill: 'white',
                    },
                  }}
                  onClick={handleClick}
                >
                  <Svg
                    svg={icon ? icon : EditIcon}
                    fill="white50"
                    sx={{
                      width: '2.8rem',
                      height: '2.8rem',
                      flexShrink: 0,
                    }}
                  />
                </IconButton>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      {!isEditing && bottomContent}
    </Flex>
  )
}
