import React, { ReactElement } from 'react'
import { DataRowProps } from 'components/Dashboard/Preferences/CardItems/DataRow.interface'
import { Flex, Text } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import { Svg } from 'ui'
import EncryptedIcon from 'images/encryptedicon.svg'

export default function DataRow({
  ariaLabelText,
  name,
  testId,
  data,
  shouldShowIcon,
  shouldShowEncryptedIcon,
  smallIcon,
  sxText,
  sxContainer,
}: DataRowProps): ReactElement {
  return (
    <Flex
      data-testid={testId || name}
      sx={{
        flexDirection: ['column', 'row', 'row'],
        alignItems: 'center',
        minHeight: 'headerHeight',
        px: ['1.6rem', '2.4rem', '2.4rem'],
        py: '1.6rem',
        backgroundColor: 'darkItemBG',
        borderRadius: '16px',
        ...sxContainer,
      }}
    >
      <Text
        sx={{
          width: ['100%', '18.6rem'],
          mr: [0, '2.4rem'],
          mb: ['1.6rem', 0],
          height: '100%',
          alignItems: 'center',
          color: 'white',
          fontSize: '1.8rem',
          fontWeight: 'bold',
          ...sxText,
        }}
        tabIndex={name ? 0 : undefined}
      >
        {name}
      </Text>
      <Flex
        sx={{
          flexDirection: 'row',
          flex: 1,
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Text
          data-testid="profile-row-data"
          sx={{
            flex: 10,
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: 'white50',
          }}
          tabIndex={typeof data === 'string' ? 0 : undefined}
        >
          {data}
        </Text>
        {shouldShowEncryptedIcon && (
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              borderRight: '1px solid',
              borderRightColor: 'white15',
              px: '0.8rem',
            }}
          >
            <Svg svg={EncryptedIcon} />
          </Flex>
        )}
        {shouldShowIcon && (
          <Flex
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'center',
              pl: '0.8rem',
            }}
          >
            <CopyToClipboard
              data={data as string}
              ariaLabelText={ariaLabelText}
              smallIcon={smallIcon}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
