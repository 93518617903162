import React, { ReactElement } from 'react'
import { Flex, Spinner } from 'theme-ui'
import CopyToClipboard from 'components/CopyToClipboard'
import KeyIcon from 'images/key-icon.svg'
import { Svg } from 'ui'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function CopyableResolverId({ text }: { text: string }): ReactElement {
  const { isLoading } = useGetUser()
  const splitText = text?.split('\n')

  return (
    <Flex
      sx={{
        width: ['100%', '37.2rem'],
        height: '5.3rem',
        flexShrink: 0,
        backgroundColor: 'white15',
        border: '1px solid',
        borderColor: 'white10',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'space-between',
        borderRadius: '2.6rem',
        px: '1.6rem',
      }}
    >
      {isLoading ? (
        <Spinner color="greenApple" />
      ) : (
        <Flex sx={{ height: '100%', alignItems: 'center' }}>
          <Svg svg={KeyIcon} />
          <Flex
            sx={{ width: '0.1rem', height: '100%', backgroundColor: 'white10', mx: '1.2rem' }}
          />
          {splitText?.map(text => (
            <Flex
              key={text}
              sx={{
                color: 'white',
              }}
            >
              {text}
            </Flex>
          ))}
        </Flex>
      )}
      <CopyToClipboard smallIcon data={text.trim()} fillSvg="white50" />
    </Flex>
  )
}
