import React, { ReactElement } from 'react'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { Box, Flex, Link, Text } from 'theme-ui'
import { Accordion, Button, Svg, TableCell, TableRow } from 'ui'
import CopyToClipboard from 'components/CopyToClipboard'
import { Currency } from 'store/api/billing'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import getFormattedPrice from 'utils/getFormattedPrice'

import ExternalLinkIcon from 'images/profileManagement/external-link-icon.svg'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import { InvoiceState, OrganizationInvoice } from 'store/api/billing/payments.interface'

const mobileHeaderStyle = { color: 'white', fontWeight: 'bold' }
const buttonBorderRadius = '5.9rem'

export default function OrganizationInvoiceElement({
  invoice,
  currencies,
  tableHeaders,
}: {
  invoice: OrganizationInvoice
  currencies: Currency[]
  tableHeaders: string[]
}): ReactElement {
  const breakpoint = useBreakpointIndex()
  const { currency } = invoice

  const paidCurrency = currencies.find(c => c.code === currency)

  const OrganizationInvoiceElement =
    breakpoint > 1 ? InvoiceElementDesktop : InvoiceElementMobileAndTablet

  const currentInvoiceState =
    invoice.method === 'credit card' && !invoice.iv_state ? invoice.tx_status : invoice.iv_state

  const invoiceState =
    {
      [InvoiceState.PAID]: 'Paid',
      [InvoiceState.PENDING]: 'Pending',
      [InvoiceState.CANCELLED]: 'Cancelled',
    }[currentInvoiceState] || ''

  return (
    <OrganizationInvoiceElement
      invoice={invoice}
      currency={paidCurrency}
      tableHeaders={tableHeaders}
      state={invoiceState}
    />
  )
}

const InvoiceElementDesktop = ({
  invoice,
  currency,
  state,
}: {
  invoice: OrganizationInvoice
  currency?: Currency
  state: string
}): ReactElement => {
  const { date, tx_id, amount, method, currency_amount } = invoice

  return (
    <TableRow sx={{ color: 'white50' }}>
      <TableCell
        sx={{
          pl: '2.4rem',
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
      >
        {date}
      </TableCell>
      <TableCell data-testid="invoices-cell-tid" sx={{ maxWidth: '15rem' }}>
        <Flex
          sx={{
            flexDirection: 'row',
            mr: '1.6rem',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <TextWithOverFlowAndTippyPopup content={tx_id} sxText={{ mr: '1.6rem' }} />
          <CopyToClipboard
            data={tx_id}
            smallIcon={true}
            ariaLabelText="Copy transaction ID to clipboard"
          />
        </Flex>
      </TableCell>
      <TableCell data-testid="org-invoices-cell-method">
        {/* Converts payment method to Title Case so as to remain cohesive with Plan section */}
        {startCase(toLower(method))}
      </TableCell>
      <TableCell>
        {getFormattedPrice(currency, currency ? currency_amount : amount, true)}
      </TableCell>
      <TableCell data-testid="invoices-cell-status">{state}</TableCell>

      <TableCell
        sx={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px', alignItems: 'center' }}
      >
        <Link
          href={`/invoice?id=${tx_id}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Open invoice in new window"
          sx={{
            cursor: 'pointer',
            ':hover': {
              color: 'white',
            },
            '&:hover > svg path': {
              fill: 'white',
            },
          }}
        >
          <Svg fill="white50" svg={ExternalLinkIcon} />
        </Link>
      </TableCell>
    </TableRow>
  )
}

const InvoiceElementMobileAndTablet = ({
  invoice,
  currency,
  tableHeaders,
  state,
}: {
  invoice: OrganizationInvoice
  currency?: Currency
  tableHeaders: string[]
  state: string
}): ReactElement => {
  const { date, tx_id, method, currency_amount, amount } = invoice
  return (
    <Box>
      <Accordion
        id={tx_id}
        title={<Text>{date} &nbsp;</Text>}
        titleStyle={{ fontWeight: 'bold', color: 'white50' }}
        openTitleStyle={{ fontWeight: 'bold', color: 'white50' }}
      >
        <Flex
          sx={{
            width: '100%',
            maxHeight: 'fit-content',
            px: '1.6rem',
            flexDirection: ['column', 'row'],
            flexWrap: ['nowrap', 'wrap'],
            justifyContent: 'space-between',
            '& > div': {
              py: '1.6rem',
              width: ['100%', '48%'],
              alignItems: 'center',
            },
            '& > div > div:first-of-type': {
              color: 'white50',
            },
          }}
        >
          <Flex sx={{ justifyContent: 'space-between', pt: '1.6rem' }}>
            <Text
              sx={{
                flexShrink: 0,
                pr: '1.2rem',
                alignSelf: 'center',
                ...mobileHeaderStyle,
              }}
            >
              Transaction ID
            </Text>
            <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextWithOverFlowAndTippyPopup content={tx_id} sxText={{ mr: '0.8rem' }} />
              <CopyToClipboard data={tx_id} smallIcon={true} />
            </Flex>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>{tableHeaders[2]}</Text>
            <Text sx={{ color: 'white50' }}>{startCase(toLower(method))}</Text>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>{tableHeaders[3]}</Text>

            <Text sx={{ color: 'white50' }}>
              {getFormattedPrice(currency, currency ? currency_amount : amount, true)}
            </Text>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>{tableHeaders[4]}</Text>
            <Text sx={{ color: 'white50' }}>{state}</Text>
          </Flex>
          <Button
            sx={{
              width: '100%',
              height: '5.4rem',
              p: 0,
              fontWeight: 'bold',
              backgroundColor: 'plum',
              color: 'white',
              borderRadius: buttonBorderRadius,
              border: 'none',
              my: '1.6rem',
            }}
            variant="secondary"
            tabIndex={-1}
          >
            <Link
              href={`/invoice?id=${tx_id}`}
              target="_blank"
              rel="noreferrer"
              aria-label="Open invoice in new window"
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                textDecoration: 'none',
                color: 'white',
                borderRadius: buttonBorderRadius,
              }}
            >
              View Invoice
            </Link>
          </Button>
        </Flex>
      </Accordion>
    </Box>
  )
}
