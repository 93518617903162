import { alpha } from '@theme-ui/color'

const basePalette = {
  black: '#000000',
  darkBodyBG: '#12131c',
  darkItemBG: '#252737',
  white: '#ffffff',
  darkItemBoxShadow: '#0d0e13',
  plum: '#6e34e9',
  greenApple: '#5fd800',
  greenAppleNew: '#1BE3AD',
  green: '#1fbf10',
  primaryHoverBG: '#47a200',
  secondaryHoverBG: '#9267ef',
  pomegranate: '#eb2e15',
  purpleHeart: '#b81edf',
  blueRibbon: '#1949f3',
  eggplant: '#9b2edd',
  banana: '#f3ce0a',
  yellow: '#d6a62d',
  berryBlue: '#00b7ff',
  transparent: 'transparent',
  analyticsYellow: '#FFFF00',
  analyticsRed: '#FF0000',
  pieChartNoData: '#2A2B32',
  grayBG: '#252737',
  grayLogoBG: '#2f3241',
  unavailableBG: '#92939b',

  // organizations
  blue800: '#010818',
  blue700: '#1F0A6D',
  blue600: '#4A20E5',
  blue500: '#7696E0',
  blue400: '#C6D7FF',
  cyan900: '#007AA6',
  cyan800: '#63D6FF',
  cyan700: '#A7E8FF',
  cyan600: '#DFF7FF',
  caption: '#E8EFFFB2',
  body: '#AAB8D9',
  title: '#E8EFFF',
  borderLight: ' #5C6D9680',
  borderFaint: '#5C6D9633',
  blue: '#050C1D',
  successOpaque: '#1BE3AD',
  successTransparent: '#1BE3AD26',
  errorOpaque: '#E3571B',
  errorTransparent: '#E3571B33',
  iconCTA: '#767F95',
  iconCTAHover: '#B6BDD0',
  unavailableYellow: '#FFCC1B',

  // unknown colors (needs name)
  logo: '#D0E4F3',
  magenta: '#CD3BE626',
  borderFaint005: '#5C6D960D',
  roundedTertiary: '#76DBFF',
  darkItemBGHover: '#B7C4E3',
  cyanGlow: '#3BD7E6',
  mattBlue: '#5C6D96',
  babyBlue: '#6AA2B6',
  blueModalBG: '#01091D',
  headerSectionGlowBG: '#CD3BE6',
  pinkStar: '#DEABFF',
  color1: 'rgba(102, 255, 255, 0.08)',
  color2: 'rgba(92, 109, 150, 0.20)',
  color3: 'rgba(118, 150, 224, 0.05)',
  color4: 'rgba(92, 109, 150, 0.50)',
  color5: 'rgba(198, 215, 255, 0.05)',
  color94151182: 'rgba(94, 151, 182, 0.15)',
  color6: 'rgba(118, 150, 224, 0.10)',
  color7: 'rgba(92, 109, 150, 0.05)',
  color8: 'rgba(92, 109, 150, 0.04)',
  color9: 'rgba(255, 102, 180, 0.10)',
  color10: 'rgba(92, 109, 150, 0.50)',
  color11: 'rgba(255, 255, 255, 0.05)',
  color12: 'rgba(95, 216, 0, 0.06)',
  color13: 'rgba(235, 46, 21, 0.06)',
  color15: 'rgba(118, 219, 255, 0.05)',
  color16: 'rgba(118, 219, 255, 0.10)',
  color17: 'rgba(102, 255, 255, 0.15)',
  color18: 'rgba(92, 109, 150, 0.30)',
  solutionExampleBackground: 'rgba(233, 239, 255, 0.10)',
  inputBG: 'rgba(92, 109, 150, 0.10)',
  blueShades: 'rgba(6, 13, 30, 0.80)',
  smokyBlack: '#060D1E',
}

const palette = {
  ...basePalette,
  darkFullScreenOverlay: alpha('#181919', 0.8),
  white90: alpha(basePalette.white, 0.9),
  white40: alpha(basePalette.white, 0.4),
  white30: alpha(basePalette.white, 0.3),
  white70: alpha(basePalette.white, 0.7),
  white6: alpha(basePalette.white, 0.06),
  white50: alpha(basePalette.white, 0.5),
  white25: alpha(basePalette.white, 0.25),
  white17: alpha(basePalette.white, 0.17),
  white15: alpha(basePalette.white, 0.15),
  white12: alpha(basePalette.white, 0.12),
  white10: alpha(basePalette.white, 0.1),
  white8: alpha(basePalette.white, 0.08),
  white5: alpha(basePalette.white, 0.05),
  black90: alpha(basePalette.black, 0.9),
  black70: alpha(basePalette.black, 0.7),
  black50: alpha(basePalette.black, 0.5),
  black25: alpha(basePalette.black, 0.25),
  black10: alpha(basePalette.black, 0.1),
  black5: alpha(basePalette.black, 0.05),
  greenApple50: alpha(basePalette.greenApple, 0.5),
  greenApple25: alpha(basePalette.greenApple, 0.25),
  greenApple15: alpha(basePalette.greenApple, 0.15),
  banana10: alpha(basePalette.banana, 0.1),
  banana15: alpha(basePalette.banana, 0.15),
  banana25: alpha(basePalette.banana, 0.25),
  banana50: alpha(basePalette.banana, 0.5),
  banana70: alpha(basePalette.banana, 0.7),
  yellow80: alpha(basePalette.yellow, 0.8),
  berryBlue15: alpha(basePalette.berryBlue, 0.15),
  darkItemBG50: alpha(basePalette.darkItemBG, 0.5),
  pomegranate50: alpha(basePalette.pomegranate, 0.5),
  pomegranate25: alpha(basePalette.pomegranate, 0.25),
  pomegranate15: alpha(basePalette.pomegranate, 0.15),
  plum50: alpha(basePalette.plum, 0.5),
  plum25: alpha(basePalette.plum, 0.25),
  plum10: alpha(basePalette.plum, 0.1),
  black15: alpha(basePalette.black, 0.15),
  blue90: alpha(basePalette.blue, 0.95),
  blue80: alpha(basePalette.blue, 0.8),
  blue60: alpha(basePalette.blue, 0.6),
  blue40: alpha(basePalette.blue, 0.4),
  cyan90: alpha(basePalette.cyan800, 0.9),
  cyan60: alpha(basePalette.cyan800, 0.6),
  cyan40: alpha(basePalette.cyan800, 0.4),
  cyan30: alpha(basePalette.cyan800, 0.3),
  cyan20: alpha(basePalette.cyan800, 0.2),
  cyan10: alpha(basePalette.cyan800, 0.1),
  cyanGlow20: alpha(basePalette.cyanGlow, 0.2),
  roundedTertiary05: alpha(basePalette.roundedTertiary, 0.05),
  roundedTertiary10: alpha(basePalette.roundedTertiary, 0.1),
  roundedTertiary15: alpha(basePalette.roundedTertiary, 0.15),
  roundedTertiary20: alpha(basePalette.roundedTertiary, 0.2),
  roundedTertiary5: alpha(basePalette.roundedTertiary, 0.05),
  mattBlue30: alpha(basePalette.mattBlue, 0.3),
  mattBlue50: alpha(basePalette.mattBlue, 0.5),
  mattBlue20: alpha(basePalette.mattBlue, 0.2),
  darkItemBGHover10: alpha(basePalette.darkItemBGHover, 0.1),
  title70: alpha(basePalette.title, 0.7),
  title60: alpha(basePalette.title, 0.6),
  title15: alpha(basePalette.title, 0.15),
  inputBG20: alpha(basePalette.inputBG, 0.2),
  smokyBlack80: alpha(basePalette.smokyBlack, 0.8),
}
export default palette
