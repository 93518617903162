import React, { ReactElement, useState } from 'react'
import { useAppSelector } from 'store/hooks'
import { Flex, Text, Box } from 'theme-ui'
import ConfirmDeleteIcon from 'images/confirmDeletion/confirm-delete-icon.svg'
import CancelDeleteIcon from 'images/confirmDeletion/cancel-delete-icon.svg'
import { IPData, useDeleteIpMutation } from 'store/api/access'
import { CurrentLocationIcon } from 'components/Dashboard/Profiles/DefaultLocation/CurrentLocationIcon'
import { Button, Svg } from 'ui'
import { ProxyLocation } from 'store/api/proxies'
import { formatDistanceStrict } from 'date-fns'
import { Setter } from 'utils'
import GlobeIcon from 'images/small-globe-icon.svg'
import Tippy from '@tippyjs/react'
import noop from 'lodash/noop'
import MultiSelectCheckbox from 'components/Dashboard/IPs/MultiSelectCheckbox'
import CurrentIPLabel from 'components/Dashboard/IPs/CurrentIPLabel'
import moment from 'moment'
import { getFormattedCityCountryIsp } from 'utils/getFormattedCityCountryIsp'

interface IPRowProps {
  ipData: IPData
  locations: ProxyLocation[]
  isCurrentIp: boolean
  isMultiSelectMode: boolean
  setIsMultiSelectMode: Setter<boolean>
  setIpsToDelete: Setter<string[]>
  ipsToDelete: string[]
  isSelected: boolean
}

export default function IPRow({
  ipData,
  locations,
  isCurrentIp,
  isMultiSelectMode,
  setIsMultiSelectMode,
  setIpsToDelete,
  isSelected,
}: IPRowProps): ReactElement {
  const [isDeleting, setIsDeleting] = useState(false)
  const dateIPLastUsed = typeof ipData.ts === 'number' ? new Date(ipData.ts * 1000) : undefined
  const now = new Date()
  const city = typeof ipData.city === 'string' ? ipData.city : undefined
  const country = typeof ipData.country === 'string' ? ipData.country : undefined
  const isp = typeof ipData.isp === 'string' ? ipData.isp : undefined
  const [deleteIp] = useDeleteIpMutation()
  const accessSelectedDeviceId = useAppSelector(s => s.access.selectedDeviceId || '')

  const appendIPIfNew = () => {
    setIpsToDelete(currentIpsToDelete => {
      if (currentIpsToDelete.includes(ipData.ip)) {
        return currentIpsToDelete
      } else {
        return [...currentIpsToDelete, ipData.ip]
      }
    })
  }

  const onIpRowClick = () => {
    if (isMultiSelectMode) {
      if (isSelected) {
        setIpsToDelete(currentIpsToDelete => currentIpsToDelete.filter(ip => ip !== ipData.ip))
      } else {
        appendIPIfNew()
      }
    } else {
      appendIPIfNew()
    }
    setIsMultiSelectMode(true)
  }

  return (
    <Flex
      data-testid={`${isCurrentIp ? 'current' : 'non-current'}-ip-row`}
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        alignItems: ['center', 'flex-end', 'center'],
        pl: '1.6rem',
        pb: ['0.8rem', '1.6rem'],
        pr: isCurrentIp && '1.6rem',
        pt: [isCurrentIp ? '1.6rem' : '0.8rem', isCurrentIp && '1.6rem', '1.6rem'],
        backgroundColor: 'darkItemBG',
        border: '1px solid',
        borderColor: 'transparent',
        borderRadius: '16px',
        justifyContent: 'space-between',
        mb: '3.2rem',
        flexShrink: 0,
        ':hover': {
          backgroundColor: [undefined, undefined, !isCurrentIp && 'greenApple15'],
          borderColor: [undefined, undefined, !isCurrentIp && 'greenApple'],
          cursor: !isCurrentIp && 'pointer',
        },
      }}
      onClick={isCurrentIp ? noop : onIpRowClick}
    >
      {!isCurrentIp && (
        <Flex
          sx={{
            display: ['flex', 'none'],
            pr: isMultiSelectMode ? '1.6rem' : 0,
            transition: 'all 0.4s ease-in-out',
          }}
        >
          <MultiSelectCheckbox
            isMultiSelectMode={isMultiSelectMode}
            isSelected={isSelected}
            onChange={wasChecked => {
              if (wasChecked) {
                setIpsToDelete(currentIpsToDelete => [...currentIpsToDelete, ipData.ip])
              } else {
                setIpsToDelete(currentIpsToDelete =>
                  currentIpsToDelete.filter(ip => ip !== ipData.ip),
                )
              }
            }}
          />
        </Flex>
      )}
      <Button
        variant="transparent"
        onClick={isCurrentIp ? noop : onIpRowClick}
        tabIndex={!isCurrentIp ? 0 : undefined}
        sx={{
          p: 0,
          flexDirection: ['column', 'row'],
          alignItems: ['flex-start', 'center'],
          width: '100%',
          fontSize: '1.6rem',
          cursor: isCurrentIp ? 'default' : 'pointer',
        }}
      >
        <Flex
          sx={{
            mr: [0, '2rem', '2.4rem'],
            mb: ['1rem', '0.2rem'],
            whiteSpace: 'nowrap',
            mt: '0.2rem',
            minHeight: ['auto', '4.2rem'],
            alignItems: 'center',
            minWidth: ['100%', '14.8rem', '21.6rem'],
            justifyContent: 'space-between',
            color: 'white50',
          }}
        >
          <Flex sx={{ justifyContent: 'flex-start' }}>
            <Box sx={{ display: ['none', 'flex'] }}>
              <MultiSelectCheckbox
                isMultiSelectMode={isMultiSelectMode}
                isSelected={isSelected}
                onChange={wasChecked => {
                  if (wasChecked) {
                    setIpsToDelete(currentIpsToDelete => [...currentIpsToDelete, ipData.ip])
                  } else {
                    setIpsToDelete(currentIpsToDelete =>
                      currentIpsToDelete.filter(ip => ip !== ipData.ip),
                    )
                  }
                }}
              />
            </Box>
            {dateIPLastUsed ? (
              <Tippy
                theme="screen"
                content={
                  <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
                    <Text>{moment(dateIPLastUsed).format('YYYY-MM-DD')}</Text>
                    <Text> {moment(dateIPLastUsed).format('HH:mm')}</Text>
                  </Flex>
                }
              >
                <Text>{formatDistanceStrict(dateIPLastUsed, now)} ago</Text>
              </Tippy>
            ) : (
              <Text sx={{ width: '11.2rem' }}>N/A</Text>
            )}
          </Flex>

          {isCurrentIp && (
            <Flex sx={{ flex: 1, justifyContent: 'flex-end', display: ['flex', 'none'] }}>
              <CurrentIPLabel />
            </Flex>
          )}
        </Flex>
        <Flex
          sx={{
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            width: '100%',
          }}
        >
          {!isDeleting && (
            <Flex
              sx={{
                width: '100%',
                flexDirection: 'column',
                alignItems: 'flex-start',
                mr: [0, '2rem', '2.4rem'],
                mb: ['0.8rem', 0],
                minHeight: ['auto', '4.2rem'],
                color: 'white50',
                textAlign: 'left',
              }}
            >
              <Text
                sx={{
                  flex: 10,
                  color: 'white',
                  mb: '0.4rem',
                  fontWeight: 'bold',
                  pr: ['1.8rem', 0],
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                className="fill-available"
              >
                {ipData.ip}
              </Text>
              <Flex sx={{ alignItems: 'center', textAlign: 'left' }} className="fill-available">
                {/* check that there is a country code associated with this IP and a proxy location associated with that country code */}
                {country && locations?.find(location => location.country === country) ? (
                  <Flex sx={{ mr: '0.9rem' }}>
                    <CurrentLocationIcon
                      currentLocation={locations?.find(location => location.country === country)}
                      isConnected={false}
                      isSmallIcon
                    />
                  </Flex>
                ) : (
                  <Flex sx={{ mr: '0.8rem' }}>
                    <Svg
                      svg={GlobeIcon}
                      fill="white50"
                      sx={{ height: '1.6rem', width: '1.7rem' }}
                    />
                  </Flex>
                )}
                <Text
                  data-testid="my-ips-city-country-isp"
                  sx={{
                    width: '100%',
                    pr: ['2.4rem', 0],
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getFormattedCityCountryIsp({ city, country, isp })}
                </Text>
              </Flex>
            </Flex>
          )}
          {isDeleting ? (
            <Flex
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '5.6rem',
                pr: '1.6rem',
              }}
            >
              <Text
                sx={{
                  width: '100%',
                  color: 'white',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  pr: '1.2rem',
                }}
              >
                Are you sure you want to delete this IP?
              </Text>
              <Button
                variant="simple"
                data-testid="confirm-delete-ip-button"
                onClick={(event): void => {
                  event.stopPropagation()
                  deleteIp({ ip: ipData.ip, deviceId: accessSelectedDeviceId })
                }}
                sx={{ mr: '0.8rem', p: 0, flexShrink: 0 }}
              >
                <Svg svg={ConfirmDeleteIcon} />
              </Button>
              <Button
                variant="simple"
                data-testid="cancel-delete-ip-button"
                onClick={event => {
                  event.stopPropagation()
                  setIsDeleting(false)
                }}
                sx={{ p: 0, flexShrink: 0 }}
              >
                <Svg svg={CancelDeleteIcon} />
              </Button>
            </Flex>
          ) : (
            <Flex
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            />
          )}
        </Flex>
      </Button>
    </Flex>
  )
}
