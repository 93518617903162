import React from 'react'
import { Box, Text } from 'theme-ui'
import { TableProps } from '.'
import { Card } from '../Cards'
import { visuallyHiddenStyle } from 'ui/utils/sxMixins'

export default function Table(props: TableProps): React.ReactElement {
  const { children, headers, tableCaption, hideHeaders = true, sx } = props

  return (
    <Card
      sx={{
        overflow: 'hidden',
        backgroundColor: 'darkItemBG',
        borderRadius: '16px',
        p: '0 1.6rem 2rem',
        borderSpacing: 0,
      }}
      variant="table"
    >
      <Box variant="tables.primary" as="table" sx={sx}>
        <Text as="caption" sx={visuallyHiddenStyle}>
          {tableCaption}
        </Text>
        <Box as="thead" className="table-header" sx={hideHeaders ? visuallyHiddenStyle : {}}>
          <Box as="tr">
            {headers.map((header, i) => (
              <Box
                data-testid="table-header"
                as="th"
                sx={{
                  color: 'white',
                  textAlign: 'left',
                  height: '6.4rem',
                  pl: i === 0 ? '2.4rem' : 0,
                }}
                key={header + i}
                // @ts-ignore
                scope="col"
                tabIndex={0}
              >
                {header}
              </Box>
            ))}
          </Box>
        </Box>
        <Box as="tbody">{children}</Box>
      </Box>
    </Card>
  )
}
