import React from 'react'
import { Flex } from 'theme-ui'
import TrayHeaderProps from 'components/TrayHeader/TrayHeader.interface'

export default function TrayHeader({
  headerRef,
  leftComponent,
  rightComponent,
  sx,
  children,
  dataTestId,
}: TrayHeaderProps): React.ReactElement {
  return (
    <Flex
      ref={headerRef}
      data-testid={dataTestId}
      sx={{
        pt: '2.4rem',
        alignItems: 'center',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 0 rgba(255, 255, 255, 0.1)',
        backgroundColor: 'white5',
        borderBottom: 'none',
        zIndex: 'zIndex700',
        ...sx,
      }}
    >
      <Flex sx={{ flex: 1, gap: '1.6rem' }}>{leftComponent}</Flex>
      <Flex
        className="children-container"
        sx={{
          width: '100%',
          flex: 4,
          justifyContent: 'center',
        }}
      >
        {children}
      </Flex>
      <Flex sx={{ flex: 1, justifyContent: 'flex-end' }}>{rightComponent}</Flex>
    </Flex>
  )
}
