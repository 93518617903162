import React, { ReactElement } from 'react'
import AttentionPlaceholder from 'ui/AttentionPlaceholder'
import { ThemeUIStyleObject } from 'theme-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { setHasTutorialBeenViewed, TutorialType } from 'store/tutorial/tutorial'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function UpcomingFeaturesPlaceholder({
  sx,
}: {
  sx?: ThemeUIStyleObject
}): ReactElement | null {
  const dispatch = useAppDispatch()
  const { data: userData, isLoading } = useGetUser()
  const userPk = userData?.PK || ''
  const hasUpcomingFeaturesSeenBefore = useAppSelector(
    s =>
      s.tutorial.viewedStateByUserPk[userPk ?? '']?.[TutorialType.UPCOMING_FEATURES]?.hasBeenViewed,
  )

  if (hasUpcomingFeaturesSeenBefore || isLoading) {
    return null
  }

  return (
    <AttentionPlaceholder
      testId="upcoming-features-placeholder"
      title="Upcoming Features"
      description="Get an inside scoop on what we’re actively working on and help us build a better Control D for you."
      buttonText="See Features"
      sx={sx}
      onCloseClick={() => {
        dispatch(
          setHasTutorialBeenViewed({
            tutorialType: TutorialType.UPCOMING_FEATURES,
            hasBeenViewed: true,
            userPk: userPk || '',
          }),
        )
      }}
      onButtonClick={() => {
        window.open(
          `${DOCS_CONTROL_D_DOMAIN}/discuss?tag=feature`,
          '_blank',
          'noopener, noreferrer',
        )
      }}
    />
  )
}
