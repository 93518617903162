import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { profilesApi } from 'store/api/profiles'
import { userApi } from 'store/api/user'
import { sessionLogout } from '../session'

interface ActivityLogInfo {
  debugUid?: string
  debugLastSessionStartTs?: number
  debugTtl?: number
}

export interface PersistedState {
  sessionToken?: string
  sessionTtl?: number
  queryLogData?: ActivityLogInfo
  currentProfileId?: string
  selectedDevicePK?: string
  wasRememberMeSelected?: boolean
  shouldShowBarry?: boolean
  // shouldShowBarry is responsible for other things, so we need shouldLoadBarryE2eTest only for e2e tests
  shouldLoadBarryE2eTest?: boolean
  // Upgrade path hack - we used to nest `persistData` one level into the actual
  // persisted localStorage JSON object, so we continue to read this to avoid
  // logout after upgrading to new multi-profile release.
  persistData?: {
    sessionToken?: string
  }
}

export const CONTROLD_USER = 'controlDUser'

export const persistSlice = createSlice({
  name: 'persist',
  initialState: {
    sessionToken: undefined,
  } as PersistedState,
  reducers: {
    setSessionToken(state, action): void {
      state.sessionToken = action.payload

      // as part of the multi-profile upgrade path, clear the legacy nested `persistData`
      // object
      state.persistData = {}
    },
    setSessionTtl(state, action): void {
      state.sessionTtl = action.payload
    },
    setCurrentProfileId(state, action: PayloadAction<string>): void {
      // if the profile id changes, delete the activity log details
      if (state.currentProfileId !== action.payload) {
        state.queryLogData = undefined
      }
      state.currentProfileId = action.payload
    },
    setSelectedDevicePK(state, action: PayloadAction<string | undefined>): void {
      state.selectedDevicePK = action.payload
    },
    setWasRememberMeSelected(state, action: PayloadAction<boolean>): void {
      state.wasRememberMeSelected = action.payload
    },
    setShouldShowBarry(state, action: PayloadAction<boolean>): void {
      state.shouldShowBarry = action.payload
    },
    clearDebugSessionData(state): void {
      state.queryLogData = undefined
    },
    clear(): PersistedState {
      return {}
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sessionLogout.fulfilled, state => {
        state.sessionToken = undefined
        state.currentProfileId = ''
        state.selectedDevicePK = undefined
      })

      .addMatcher(profilesApi.endpoints.deleteProfile.matchFulfilled, (state, action) => {
        if (state.currentProfileId === action.meta.arg.originalArgs.pk) {
          state.currentProfileId = ''
        }
      })
      .addMatcher(userApi.endpoints.userLogin.matchFulfilled, (state, action) => {
        state.sessionToken = action.payload?.session
        state.shouldShowBarry = false
      })
      .addMatcher(userApi.endpoints.userRegister.matchFulfilled, (state, action) => {
        state.sessionToken = action.payload?.session
      })
  },
})

export const {
  setCurrentProfileId,
  setSessionTtl,
  setSessionToken,
  setSelectedDevicePK,
  setWasRememberMeSelected,
  setShouldShowBarry,
  clear: clearPerSessionPersistData,
  clearDebugSessionData,
} = persistSlice.actions
