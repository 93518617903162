import React, { ReactChild, ReactElement, useRef, useEffect } from 'react'
import Tippy, { TippyProps } from '@tippyjs/react'
import { Instance } from 'tippy.js'
import { Box } from 'theme-ui'
import Arrow from './Arrow.base'

export interface TutorialArrowProps {
  isVisible: boolean
  rotateDegree?: number
  tooltipPlacement?: TippyProps['placement']
  testId?: string
  children?: ReactChild | ReactChild[]
}
const TutorialArrow = ({
  isVisible,
  rotateDegree = 180,
  tooltipPlacement = 'bottom',
  testId = '',
  children,
}: TutorialArrowProps): ReactElement => {
  const tippyInstance = useRef<Instance>()

  useEffect(() => {
    isVisible ? tippyInstance.current?.show() : tippyInstance.current?.hide()
  }, [isVisible])

  return (
    <Tippy
      content={<Arrow testId={testId} rotateDegree={rotateDegree} />}
      className="transparent-theme"
      arrow={false}
      interactive
      hideOnClick={false}
      placement={tooltipPlacement}
      animation="fade"
      trigger="manual"
      appendTo="parent"
      onCreate={instance => {
        tippyInstance.current = instance
      }}
    >
      <Box sx={{ backgroundColor: 'transparent' }}>{children}</Box>
    </Tippy>
  )
}

export default TutorialArrow
