import React from 'react'
import { Box } from 'theme-ui'
import { TableCellProps } from '../index'

export default function TableCell(props: TableCellProps): React.ReactElement {
  const { sx = {}, children = null, align = null, colSpan = 1, ...attrs } = props

  return (
    <Box
      as="td"
      // @ts-ignore
      colSpan={colSpan}
      tabIndex={0}
      sx={{
        ...sx,
        // @ts-ignore
        textAlign: align,
      }}
      {...attrs}
    >
      {children}
    </Box>
  )
}
