import { Button, Svg, TrayRenderProps } from 'ui'
import React, { ReactElement } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import TrayHeader from 'components/TrayHeader'
import CloseIcon from 'images/profileManagement/close-icon.svg'

export default function LimitationsTrayContent({ dismiss }: TrayRenderProps): ReactElement {
  return (
    <Box data-testid="limitations-tray">
      <TrayHeader
        sx={{
          pb: '1.6rem',
          px: '1.6rem',
          paddingTop: '1.6rem',
        }}
        leftComponent={
          <Button
            data-testid="close-limitations-tray"
            variant="simple"
            sx={{ p: 0 }}
            onClick={dismiss}
          >
            <Svg svg={CloseIcon} fill="white50" />
          </Button>
        }
      >
        <Text variant="cardLabel" sx={{ color: 'labelColor' }}>
          Limitations
        </Text>
      </TrayHeader>
      <Flex
        sx={{
          flexDirection: 'column',
          pt: '2.4rem',
          px: '1.6rem',
          height: '100%',
        }}
      >
        <Text variant="trayText" data-testid="limitation-text" sx={{ color: 'textColor' }}>
          If your network deploys advanced DPI techniques that filter SNI headers, this resolver
          will have no effect until we deploy Encrypted Client Hello (ECH) support in the near
          future. Once this is deployed, browsers that support it (currently only Firefox) will be
          able to take advantages of this bleeding edge technology to mask the SNI header from being
          filtered, which will make this feature a lot more useful on those types of networks.
        </Text>
      </Flex>
    </Box>
  )
}
