export default {
  warning: {
    backgroundColor: 'primary',
    '> div': {
      color: 'black',
    },
    m: 3,
  },
  success: {
    backgroundColor: 'successBackground',
    color: 'primary',
    justifyContent: 'center',
  },
  error: {
    backgroundColor: 'alertError',
    color: 'error',
    justifyContent: 'center',
  },
  successSmall: {
    backgroundColor: 'successBackground',
    borderRadius: 2,
    fontWeight: 0,
    width: 'fit-content',
    margin: `4.8rem auto`,
    '> div': {
      color: 'primary',
      justifyContent: 'center',
    },
    textAlign: 'center',
    padding: `1.2rem '2.4rem`,
  },
  primary: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    p: '0 1.6rem 1.6rem ',
    backgroundColor: 'plum',
    color: 'white',
    border: '1px solid',
    borderColor: 'white10',
    borderRadius: '16px',
    zIndex: 'zIndex650',
  },
}
