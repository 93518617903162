import React, { ReactElement } from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { Button, Svg } from 'ui'
import CloseIcon from 'images/close-icon.svg'
interface CloseButtonProps {
  sx?: ThemeUIStyleObject
  onClick: () => void
}
export default function CloseButton({
  onClick,
  sx = {},
  ...props
}: CloseButtonProps): ReactElement {
  return (
    <Button
      variant="simple"
      sx={{
        flexShrink: 0,
        borderRadius: '100%',
        backgroundColor: 'darkItemBG',
        color: 'white',
        width: '5rem',
        height: '5rem',
        fontSize: '2.4rem',
        ...sx,
      }}
      onClick={onClick}
      {...props}
    >
      <Svg svg={CloseIcon} fill="white" width="3.2rem" height="3.2rem" />
    </Button>
  )
}
