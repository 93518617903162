import { PromoCodeContainerProps } from 'components/Plans/SelectPlan/PromoCode/PromoCodeContainer'
import TabButton from 'components/Plans/SelectPlan/PromoCode/TabButton'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { PromoMode, useLazyGetProductsQuery } from 'store/api/billing'
import { clearError, setPromoCode, setPromoMode } from 'store/billing'
import { useAppDispatch } from 'store/hooks'
import { Flex, Text } from 'theme-ui'
import { Button, Input } from 'ui'
import { resetPromo } from 'store/billing/products'
import { SerializedError } from '@reduxjs/toolkit'
import ErrorMessage from 'components/Plans/SelectPlan/PromoCode/ErrorMessage'
import CloseButton from 'components/Plans/SelectPlan/PromoCode/CloseButton'
import { clearErrors } from 'store/errors'

interface PromoCodeFormProps extends PromoCodeContainerProps {
  promoMode?: PromoMode
  promoCodeError?: SerializedError
  closeForm: () => void
}
export default function PromoCodeForm({
  tabsContainerSx,
  promoMode,
  isMobileView,
  promoCodeError,
  inputSx,
  closeForm,
}: PromoCodeFormProps): ReactElement {
  const dispatch = useAppDispatch()
  const [promo, setPromo] = useState('')
  const [getProducts] = useLazyGetProductsQuery()

  const applyCode = useCallback(async (): Promise<void> => {
    const resp = await getProducts({ promoMode, promoCode: promo })

    if (!resp.error) {
      dispatch(setPromoCode(promo))
    }
  }, [dispatch, getProducts, promo, promoMode])

  //clear promo code and error on load
  useEffect(() => {
    setPromo('')

    return () => {
      dispatch(clearError())
      dispatch(clearErrors())
    }
  }, [dispatch])

  return (
    <>
      <Flex
        data-testid="promo-code-form"
        sx={{ flexDirection: 'column', width: '100%', px: [0, 0, '1.6rem'] }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            ml: [0, '6rem'],
            justifyContent: ['center', 'flex-start'],
            alignItems: 'center',

            ...tabsContainerSx,
          }}
        >
          <TabButton
            onClick={() => {
              dispatch(setPromoMode(PromoMode.PROMO))
              dispatch(clearError())
            }}
            isSelected={promoMode === PromoMode.PROMO}
            sx={{ mr: '0.8rem', ml: ['0.6rem', '0.8rem', '1.6rem'] }}
          >
            Promo Code
          </TabButton>
          <TabButton
            onClick={() => {
              dispatch(setPromoMode(PromoMode.USERNAME))
              dispatch(clearError())
            }}
            isSelected={promoMode === PromoMode.USERNAME}
          >
            Windscribe Username
          </TabButton>
        </Flex>
        <Flex
          sx={{
            mt: '0.8rem',
            width: '100%',
            alignItems: 'center',
            flexDirection: isMobileView ? 'column' : 'row',
          }}
        >
          <Flex
            sx={{
              position: 'relative',
              width: isMobileView ? '100%' : '50.7rem',
              flexDirection: isMobileView ? 'column' : 'row',
              alignItems: 'center',
              ml: isMobileView ? 0 : '6rem',
              ...inputSx,
            }}
          >
            <Input
              data-testid="promo-code-input"
              sx={{
                borderRadius: '5.4rem',
                border: 'none',
                fontSize: '1.8rem',
                p: isMobileView ? '1.6rem' : '2rem',
                pr: isMobileView ? 0 : '12.6rem',

                mb: isMobileView ? '1.6rem' : 0,
              }}
              containerStyle={{
                width: '100%',
                mr: isMobileView ? 0 : '2.4rem',
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  applyCode()
                }
              }}
              placeholder={promoMode === PromoMode.PROMO ? 'Enter Code' : 'Enter Username'}
              aria-label={promoMode === PromoMode.PROMO ? 'enter promo code' : 'enter username'}
              onChange={e => {
                setPromo(e.target.value)
              }}
              onFocus={() => {
                dispatch(clearError())
              }}
            />
            {promoMode === PromoMode.USERNAME && isMobileView && (
              <Text
                sx={{
                  color: 'white50',
                  fontSize: '1.2rem',
                  mb: '0.8rem',
                  textAlign: 'center',
                  maxWidth: '32rem',
                }}
              >
                Enter existing Windscribe username here. Promo and Build-a-Plan not eligible.
              </Text>
            )}
            <Button
              data-testid="apply-promo-code-button"
              onClick={applyCode}
              sx={{
                position: isMobileView ? 'static' : 'absolute',
                right: '3rem',
                width: isMobileView ? '100%' : 'auto',
                backgroundColor: 'white',
                borderRadius: '5.4rem',
                border: 'none',
                fontWeight: 'bold',
                fontSize: '2.1rem',
                p: '0.8rem 2.4rem',
                mb: isMobileView ? '1.6rem' : 0,
                flexShrink: 0,
              }}
            >
              Apply
            </Button>
          </Flex>
          {promoCodeError?.message && isMobileView && (
            <ErrorMessage promoCodeError={promoCodeError.message} isMobileView={isMobileView} />
          )}

          <CloseButton
            data-testid="close-promo-code-form"
            onClick={async () => {
              dispatch(resetPromo())
              closeForm()
            }}
          />
        </Flex>
      </Flex>
      {promoCodeError?.message && !isMobileView && (
        <ErrorMessage promoCodeError={promoCodeError.message} isMobileView={isMobileView} />
      )}
      {promoMode === PromoMode.USERNAME && !isMobileView && (
        <Text
          sx={{
            color: 'white50',
            fontSize: '1.2rem',
            my: '0.8rem',
            px: ['2.4rem', '1.6rem', 0],
            textAlign: 'center',
          }}
        >
          Enter existing Windscribe username here. Free accounts not eligible.
        </Text>
      )}
    </>
  )
}
