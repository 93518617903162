import React, { ReactElement, useCallback } from 'react'
import { Flex, Text } from '@theme-ui/components'
import { ProfileDownloadButton } from '../index'
import useMobileConfigUrl from 'components/SetupGuide/useMobileConfigUrl'
import useQueryString from 'utils/useQueryString'
import { Box, Divider } from 'theme-ui'
import CopyableResolverId from './CopyableResolverId'
import CopyToClipboard from 'components/CopyToClipboard'
import {
  SetupGuideIntermediateStates,
  SetupGuideMode,
  SetupOs,
} from 'components/SetupGuide/SetupGuide.interface'
import { BASE_URL, DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import DividerWithText from 'ui/DividerWithText'
import { Button } from 'ui'

export default function AutomaticProfileDownload({
  resolverUid,
  children,
}: {
  resolverUid: string
  children?: ReactElement | ReactElement[]
}): ReactElement {
  const { setupGuideNav, qs } = useQueryString()
  const shouldHideTerminal = [SetupOs.ANDROID_OS, SetupOs.IOS_OS].includes(qs.setupOs as SetupOs)
  const shouldHideDownloadPart = [
    SetupOs.LINUX_OS,
    SetupOs.ROUTER,
    SetupOs.PF_SENSE,
    SetupOs.OPN_SENSE,
  ].includes(qs.setupOs as SetupOs)
  const isIos = [SetupOs.IOS_OS].includes(qs.setupOs as SetupOs)
  const shouldShowGenerateDnsProfileButton = [SetupOs.IOS_OS, SetupOs.MAC_OS].includes(
    qs.setupOs as SetupOs,
  )
  const isRouter = qs.setupOs?.includes(SetupOs.ROUTER) && qs.setupOs !== SetupOs.WINDOWS_SERVER
  const curl =
    qs.helpPane?.includes('windows') || qs.helpPane === SetupOs.WINDOWS_SERVER
      ? `powershell -Command "(Invoke-WebRequest -Uri '${BASE_URL}/dl' -UseBasicParsing).Content | Set-Content '%TEMP%\\ctrld_install.bat'" && %TEMP%\\ctrld_install.bat ${resolverUid} forced`
      : qs.helpPane?.includes('pfsense') || qs.helpPane?.includes('opnsense')
      ? `sh -c 'sh -c "$(curl -sSL ${BASE_URL}/dl)" -- -s ${resolverUid} forced'`
      : `sh -c 'sh -c "$(curl -sSL ${BASE_URL}/dl)" -s ${resolverUid} forced'`

  const onGenerateProfileClick = useCallback(() => {
    setupGuideNav({
      ...qs,
      helpPane:
        qs.setupOs === SetupOs.MAC_OS ? SetupGuideIntermediateStates.MacVersion : SetupOs.IOS,
      mode: SetupGuideMode.MANUAL,
      setupOs: qs.setupOs === SetupOs.MAC_OS ? SetupOs.MAC : SetupOs.IOS,
    })
  }, [qs, setupGuideNav])

  const url = useMobileConfigUrl()

  return (
    <Flex
      sx={{
        width: ['100%', '40rem'],
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:
          (shouldHideTerminal || shouldHideDownloadPart) && !isIos ? 'center' : 'flex-start',
        textAlign: 'center',
      }}
    >
      {!shouldHideDownloadPart && (
        <>
          <Text sx={{ fontSize: ['1.6rem', '1.8rem'], mt: [0, '2rem'], mb: '3.2rem' }}>
            1. Click the button below to download the setup utility and open it.
          </Text>
          <Flex
            sx={{
              justifyContent: 'center',
            }}
          >
            <ProfileDownloadButton sx={{ width: '25.8rem' }} url={url}>
              Download Setup Utility
            </ProfileDownloadButton>
          </Flex>
          <Text sx={{ fontSize: ['1.6rem', '1.8rem'], mt: ['2.4rem', '4rem'], mb: '3.2rem' }}>
            2. Copy the Resolver ID below, paste it into the utility app and press Configure.
          </Text>
          <CopyableResolverId text={resolverUid} />
        </>
      )}

      {!shouldHideDownloadPart && !shouldHideTerminal && (
        <DividerWithText text="OR" sx={{ my: '4.8rem' }} />
      )}

      {!shouldHideTerminal && (
        <>
          <Text sx={{ fontSize: ['1.6rem', '1.8rem'], mb: ['2.4rem', '3.2rem'] }}>
            Copy this command and paste it into your admin terminal
            {qs.helpPane?.includes('windows') ? ' (cmd, not Powershell)' : ''}.
          </Text>

          <Flex
            data-testid="terminal-container"
            sx={{
              width: ['100%', '60rem'],
              backgroundColor: 'black50',
              p: ['1.6rem', '2.4rem'],
              borderRadius: '1.6rem',
              textAlign: 'left',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <Text sx={{ width: '58rem', color: 'white50', fontSize: '1.4rem' }}>{curl}</Text>
            <CopyToClipboard smallIcon data={curl} fillSvg="white50" />
          </Flex>
        </>
      )}

      {shouldShowGenerateDnsProfileButton && (
        <>
          <DividerWithText text="OR" sx={{ my: '4.8rem' }} />
          <Button
            variant="secondary"
            sx={{
              width: '25.2rem',
              py: '1.2rem',
              fontSize: '1.8rem',
              fontWeight: 'bold',
              mb: '3.6rem',
              p: '1.2rem 2.4rem',
              color: 'white',
              border: '2px solid',
              borderColor: 'plum',
              borderRadius: '59px',
              backgroundColor: 'plum',
            }}
            onClick={onGenerateProfileClick}
          >
            Generate DNS Profile
          </Button>
        </>
      )}
      {isRouter && (
        <Flex
          sx={{
            width: ['100%', '60rem'],
            flexDirection: 'column',
            gap: ['2.4rem', '3.2rem'],
            alignItems: 'center',
            mt: '2.4rem',
          }}
        >
          <Divider sx={{ width: '100%', color: 'white15', mt: '0.8rem' }} />
          <Text
            sx={{
              fontSize: ['1.6rem', '1.8rem'],
            }}
          >
            Alternatively, download and deploy the binary manually.
          </Text>
          <Button
            variant="secondary"
            aria-label="open provisioning doc"
            sx={{
              width: 'fit-content',
              borderRadius: '5.2rem',
              border: 'none',
              py: '1.6rem',
              px: '2.4rem',
            }}
            onClick={() => {
              window.open(`${DOCS_CONTROL_D_DOMAIN}/docs/ctrld`, '_blank', 'noopener, noreferrer')
            }}
          >
            <Text sx={{ fontSize: ['1.6rem', '1.8rem'], fontWeight: 'bold' }}>
              View Documentation
            </Text>
          </Button>
        </Flex>
      )}

      {children}
      <Box sx={{ width: '100%', py: '0.8rem' }} />
    </Flex>
  )
}
