import React, { ReactNode, ReactElement, ElementType, useState, useRef } from 'react'
import 'tippy.js/dist/svg-arrow.css'
import { TippyProps } from '@tippyjs/react'
import { ButtonVariant, Dropdown, SimpleActionMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import { DropdownMenuItem } from './index'
import { ThemeUIStyleObject } from 'theme-ui'
import ArrowDownIcon from 'images/organization/homepage/headerNavMenu/arrow-sm-down.svg'
import useDetermineTypeOfHeader from 'utils/useDetermineTypeOfHeader'
import FocusLock from 'react-focus-lock'

type DropdownItem = {
  header: string
  description?: string
  icon?: ElementType
  to?: string | undefined
  href?: string
}

interface DropdownCommonProps {
  dropdownItems: DropdownItem[]
  children: ReactNode
  withArrow?: boolean
  tippyprops?: TippyProps
  sxContainer?: ThemeUIStyleObject
  testId?: string
}

interface OrgHomepageDropdownBaseProps extends DropdownCommonProps {
  variant: ButtonVariant
  sx?: ThemeUIStyleObject
  sxContainer?: ThemeUIStyleObject
  sxButton?: ThemeUIStyleObject
  ariaLabel?: string
}

function HomepageDropdownBase({
  dropdownItems,
  children,
  variant,
  withArrow = true,
  sx,
  sxContainer,
  sxButton,
  tippyprops,
  testId,
  ariaLabel,
}: OrgHomepageDropdownBaseProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const hideRef = useRef<() => void>()
  const dismiss = () => hideRef.current?.()
  const options: SimpleActionMenuItemProps[] = dropdownItems.map(
    ({ header, icon, description, to, href }) => ({
      children: (
        <DropdownMenuItem {...{ header, icon, description, to, href, dismiss, testId: href }} />
      ),
    }),
  )

  return (
    <Dropdown
      aria-label={ariaLabel}
      variant={variant}
      data-testid={testId}
      tippyprops={{
        arrow: true,
        placement: 'bottom',
        maxWidth: 'none',
        theme: 'orgs-homepage',
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        onShow: () => setIsOpen(true),
        onHide: () => setIsOpen(false),
        ...tippyprops,
      }}
      dropdowncontent={
        isOpen ? (
          <FocusLock returnFocus={true}>
            <SimpleActionMenu
              hideRef={hideRef}
              items={options}
              tabIndex={-1}
              sxContainer={{
                flexWrap: 'wrap',
                rowGap: '0',
                columnGap: '1.6rem',
                px: '0',
                ...sxContainer,
              }}
              customHoverStyles={{
                backgroundColor: 'darkItemBGHover10',
                svg: {
                  opacity: 1,
                },
                'svg path': {
                  opacity: 1,
                  strokeOpacity: 1,
                  fillOpacity: 1,
                },
              }}
              sxButton={{
                mx: '0.8rem',
                py: '1.2rem',
                px: '0.8rem',
                borderRadius: '0.8rem',
                svg: {
                  opacity: 1,
                },
                'svg path': {
                  strokeOpacity: 0.7,
                  fillOpacity: 0.7,
                },
                ...sxButton,
              }}
            />
          </FocusLock>
        ) : (
          <></>
        )
      }
      sx={{
        fontSize: '1.7rem',
        fontWeight: 500,
        ':not(.tippy-box)': {
          height: '3.9rem',
          display: 'flex',
          alignItems: 'center',
        },
        '> svg': {
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
          fill: isOpen ? 'blue400' : 'body',
          transition: 'transform 0.2s ease-out',
        },
        ...sx,
      }}
    >
      {children}
      {withArrow && (
        <Svg
          svg={ArrowDownIcon}
          sx={{
            ml: '0.8rem',
            width: '1.6rem',
            height: '1.6rem',
            flexShrink: 0,
          }}
        />
      )}
    </Dropdown>
  )
}

export function MainMenuDropdown({
  dropdownItems,
  children,
  withArrow,
  tippyprops,
  sxContainer,
  testId,
}: DropdownCommonProps): ReactElement {
  const { isPersonalHeader } = useDetermineTypeOfHeader()

  return (
    <HomepageDropdownBase
      testId={testId}
      variant="roundedSimple"
      sxContainer={{
        flexDirection: isPersonalHeader ? 'column' : 'row',
        width: isPersonalHeader ? '38rem' : '77.6rem',
        py: '2.4rem',
        ...sxContainer,
      }}
      sxButton={{
        width: '36.4rem',
        minHeight: '8.2rem',
      }}
      sx={{
        ':not(.tippy-box)': {
          py: '0.8rem',
          px: '1.6rem',
          '@media screen and (max-width: 1306px)': {
            px: '0.8rem',
          },
        },
      }}
      {...{ dropdownItems, children, withArrow, tippyprops }}
    />
  )
}

export function GetStartedDropdown({
  dropdownItems,
  children,
  withArrow,
  tippyprops,
  sxContainer,
}: DropdownCommonProps): ReactElement {
  return (
    <HomepageDropdownBase
      testId="org-get-started-button"
      ariaLabel="Get Started"
      variant="roundedSecondary"
      sxContainer={{
        flexDirection: 'column',
        py: '0.8rem',
        width: ['calc(100vw - 1rem)', '28rem'],
        ...sxContainer,
      }}
      sxButton={{ width: 'auto', minHeight: 'auto' }}
      sx={{
        ':not(.tippy-box)': {
          height: ['3.3rem', '4.1rem'],
          py: ['0.6rem', '0.6rem', '0.9rem'],
          px: ['1.2rem', '1.2rem', '2rem'],
          fontSize: ['1.5rem', '1.5rem', '1.7rem'],
          fontWeight: [600, 600, 500],
        },
      }}
      {...{ dropdownItems, children, withArrow, tippyprops }}
    />
  )
}
