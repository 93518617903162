import React, { ReactElement } from 'react'
import {
  Highlight,
  TutorialBaseSlide,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import AdminLogImage from 'images/tutorialFlows/AdminLogs/admin-log-illustration.svg'
import ExportIcon from 'images/menus/external-link-icon.svg'

import { Box, Text } from 'theme-ui'

export function AdminLogsSlide(): ReactElement {
  return (
    <TutorialBaseSlide
      Illustration={AdminLogImage}
      description={
        <Box>
          <Text>
            The <Highlight svg={tutorialSlideIcons.adminLog}>Admin Logs</Highlight> section provides
            an audit trail of all actions performed by admins within an{' '}
            <Highlight svg={tutorialSlideIcons.org}>Organization</Highlight>.{' '}
            <p>
              Filter <Highlight svg={tutorialSlideIcons.adminLog}>Admin Logs</Highlight> by{' '}
              <Highlight svg={tutorialSlideIcons.org}>Organization</Highlight>, Admin or Action
              Performed with the dropdown menus, or use the search bar to find logs that include the
              name of the item modified, previous values, or any other associated metadata.
            </p>{' '}
            <p>
              You can export <Highlight svg={tutorialSlideIcons.adminLog}>Admin Logs</Highlight>{' '}
              using the <Highlight svg={ExportIcon}>button</Highlight> at the top right of the
              screen.
            </p>
          </Text>
        </Box>
      }
    />
  )
}
