import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import { foregroundStyle } from 'components/Dashboard/PlaceholderElements'

export default function RowPlaceholder(): ReactElement {
  return (
    <Flex sx={{ width: '100%', alignItems: 'center' }}>
      <Box
        sx={{
          ...foregroundStyle,
          width: '85%',
          height: '2.4rem',
        }}
      />
    </Flex>
  )
}
