import React, { ReactElement, ReactNode, useRef } from 'react'
import { Text } from 'theme-ui'
import Tippy from '@tippyjs/react'
import { Button, Svg, SvgProps } from 'ui'
import { Instance, Placement } from 'tippy.js'
import { useHideTooltipOnScroll } from 'utils/useHideTooltipOnScroll'
import ExternalSvgIcon, { ExternalIcon } from 'components/Dashboard/ExternalSvgIcon'

interface IconWithTippyPopupProps extends Omit<SvgProps, 'svg'> {
  content: string | ReactNode
  tippyContent?: string
  isEnabled?: boolean
  testId?: string
  maxWidth?: number
  onShow?: (instance: Instance) => void | false
  placement?: Placement
  tippyProps?: Partial<React.ComponentProps<typeof Tippy>>
  theme?: string
  shouldStopPropagation?: boolean
  svg?: React.ElementType
  externalIcon?: ExternalIcon
}

export default function IconWithTippyPopup({
  content,
  tippyContent,
  sx,
  isEnabled = true,
  testId,
  svg,
  externalIcon,
  maxWidth,
  fill,
  descriptiveText,
  onShow,
  placement,
  tippyProps = {},
  theme,
  shouldStopPropagation,
}: IconWithTippyPopupProps): ReactElement {
  const hideRef = useRef<() => void>()

  useHideTooltipOnScroll(hideRef)

  return (
    <Tippy
      onShow={onShow}
      onCreate={instance => {
        hideRef.current = () => {
          instance.hide()
        }
      }}
      content={<Text sx={{ overflowWrap: 'break-word' }}>{tippyContent || content}</Text>}
      arrow={false}
      theme={theme || 'screen'}
      touch="hold"
      interactive={true}
      appendTo="parent"
      maxWidth={maxWidth}
      disabled={!isEnabled}
      placement={placement}
      {...tippyProps}
    >
      {/* Button is important for accessibility */}
      <Button variant="transparent" ariaLabel="show tooltip" sx={{ p: 0, borderRadius: '50%' }}>
        {externalIcon && (
          <ExternalSvgIcon
            icon={externalIcon.icon}
            type={externalIcon.type}
            fill={fill}
            sx={{
              ...sx,
            }}
          />
        )}
        {svg && (
          <Svg
            data-testid={testId}
            svg={svg}
            fill={fill}
            descriptiveText={descriptiveText}
            sx={{
              ...sx,
              '@media (hover: none)': {
                '&:hover': { opacity: 0.5 },
              },
            }}
            onClick={shouldStopPropagation ? e => e.stopPropagation() : undefined}
          />
        )}
      </Button>
    </Tippy>
  )
}
