import React, { ReactElement } from 'react'
import NavMenuItem from 'components/Dashboard/NavBar/NavMenuItem'
import { Flex } from 'theme-ui'
import OrganizationsAccordion from 'components/Organization/OrganizationNavBar/OrganizationAccordion'
import MyOrganizationIcon from 'images/organization/my-organization-icon.svg'
import SubOrganizationIcon from 'images/organization/sub-organizations-icon.svg'
import BillingIcon from 'images/dashboard/navBar/billing-icon.svg'
import ApiIcon from 'images/dashboard/navBar/api-icon.svg'
import AccountIcon from 'images/dashboard/navBar/my-account-icon.svg'
import { OrganizationNavRelativePath } from 'components/Organization/OrganizationNavBar/OrganizationNavBarContent'
import { OrgPermissionLevel } from 'store/api/organization/organization.interface'
import { useGetUnImpersonatedUserQuery } from 'store/api/user'
import customUnescape from 'utils/customUnescape'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'

export default function OrganizationNavMenu(): ReactElement {
  const { isFetching, data } = useGetOrganization()
  const { data: userData } = useGetUnImpersonatedUserQuery('')
  const isSubOrg = !!userData?.org?.parent_org
  const permissionLevel = userData?.org?.permission?.level
  const isViewer = permissionLevel === OrgPermissionLevel.VIEWER

  return (
    <OrganizationsAccordion
      isFetching={isFetching}
      name={customUnescape(data?.organization.name)}
      isSubOrg={isSubOrg}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1.6rem',
        }}
      >
        <NavMenuItem
          activeIcon={MyOrganizationIcon}
          relativePath={OrganizationNavRelativePath.MY_ORGANIZATION}
          label="My Organization"
        />
        {!isSubOrg && (
          <NavMenuItem
            activeIcon={SubOrganizationIcon}
            relativePath={OrganizationNavRelativePath.SUB_ORGANIZATIONS}
            label="Sub-Organizations"
          />
        )}
        {!isSubOrg && (
          <NavMenuItem
            activeIcon={BillingIcon}
            relativePath={OrganizationNavRelativePath.BILLING}
            label="Billing"
          />
        )}
        {!isViewer && (
          <NavMenuItem
            activeIcon={ApiIcon}
            relativePath={OrganizationNavRelativePath.API}
            label="API"
            sx={{ mb: '0.8rem' }}
          />
        )}
        <NavMenuItem
          activeIcon={AccountIcon}
          relativePath={OrganizationNavRelativePath.ACCOUNT}
          label="Account"
        />
      </Flex>
    </OrganizationsAccordion>
  )
}
