import React, { ReactElement, useMemo } from 'react'
import { CONTROLDText } from 'ui/CONTROLD'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'

const ControlDVersionAndDateInfo = (): ReactElement => {
  const lastUpdate = useMemo(() => {
    const date = new Date(process.env.TIME_STAMP || '')

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
  }, [])

  return (
    <>
      <TextWithOverFlowAndTippyPopup
        testId="header-title"
        content={`${CONTROLDText} ${process.env.TAG_NAME || process.env.REF_NAME?.split('-')[0]}`}
        sxText={{
          maxWidth: ['28rem', '22rem'],
        }}
      />
      {`Updated ${lastUpdate}`}
    </>
  )
}

export default ControlDVersionAndDateInfo
