import React, { ReactElement } from 'react'
import PreferencesWrapper from 'components/Dashboard/Preferences/PreferencesWrapper'
import UpgradeOrganizationPane from './UpgradeOrganizationPane'
import PreferencesSectionTitle from 'components/Dashboard/Preferences/PreferencesSectionTitle'
import { DataRow } from 'components/Dashboard/Preferences/CardItems'
import { Flex, Link, Text } from 'theme-ui'
import { navigate } from 'gatsby'
import { BillingDialogType } from './ModalDialogs/BillingModalDialog'
import getFormattedPrice from 'utils/getFormattedPrice'
import OrganizationInvoicesTable from './OrganizationInvoicesTable'
import { OrganizationInvoice } from 'store/api/billing/payments.interface'
import NoResults from 'components/Dashboard/NoResults'
import { useAppSelector } from 'store/hooks'
import { OrgPermissionLevel } from 'store/api/organization/organization.interface'
import useQueryString from 'utils/useQueryString'
import useGetBillingAndSubscriptions from './useGetBillingAndSubscriptions'
import { IconWithTippyPopup } from 'ui'
import InfoIcon from 'images/dashboard/info-icon.svg'
import moment from 'moment'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'

export enum BillingMethod {
  NONE,
  CREDIT_CARD,
  PURCHASE_ORDER,
  RESELLER,
  PURCHASER,
  NFR,
}

const specialBillingMethods = [
  BillingMethod.PURCHASE_ORDER,
  BillingMethod.RESELLER,
  BillingMethod.PURCHASER,
  BillingMethod.NFR,
]

export function isBillingMethodValidToDisplayBanner(
  billingMethod: BillingMethod = BillingMethod.NONE,
): boolean {
  return !specialBillingMethods.includes(billingMethod)
}

const getBillingMethods = (
  isSubscriptionActive: boolean,
): { title: string; linkText?: string }[] => [
  {
    title: 'None',
    linkText: 'Add',
  },
  {
    title: 'Credit Card',
    linkText: isSubscriptionActive ? 'Change Card' : 'Resume Subscription',
  },
  {
    title: 'Purchase Order',
  },
  {
    title: 'Reseller',
  },
  {
    title: 'Purchaser',
  },
]

export default function BillingPreferences({
  usersTotalPrice,
}: {
  usersTotalPrice?: number
}): ReactElement {
  const { data } = useGetOrganization()
  const organization = data?.organization
  const currencies = useAppSelector(s => s.products.currencies)
  const { data: userData } = useGetUser()
  const permissionLevel = userData?.org?.permission?.level
  const isViewer = permissionLevel === OrgPermissionLevel.VIEWER
  const { nav } = useQueryString()
  const { billingSubscription, lastPayment, billingPayment, isSubscriptionActive } =
    useGetBillingAndSubscriptions()
  const subscriptionCurrency = currencies.find(c => c.code === billingSubscription?.currency)

  const isPurchaser = organization?.billing_method === BillingMethod.PURCHASER
  const isTrial = moment().isBefore(organization?.trial_end)
  const isExpired = organization?.status_printed === 'Expired'

  const billingMethods = getBillingMethods(isSubscriptionActive)

  return (
    <PreferencesWrapper>
      {!isViewer &&
        isBillingMethodValidToDisplayBanner(organization?.billing_method) &&
        (isTrial || isExpired) && (
          <UpgradeOrganizationPane
            isSubscriptionActive={isSubscriptionActive}
            trialEnd={organization?.trial_end}
            isExpired={isExpired}
          />
        )}
      <PreferencesSectionTitle title="Details" sx={{ mb: 0 }} />
      <DataRow
        name="Organization Status"
        data={<Text tabIndex={0}>{organization?.status_printed || ''}</Text>}
      />
      <DataRow
        name="Payment Method"
        data={
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Text tabIndex={0}>{organization?.billing_method_printed}</Text>
            {!isViewer &&
              (billingMethods[organization?.billing_method || 0]?.linkText ? (
                <Link
                  sx={{
                    fontSize: '1.6rem',
                    fontWeight: 700,
                    color: !!billingSubscription?.status ? 'white50' : 'greenApple',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    ':hover': {
                      color: !!billingSubscription?.status ? 'white' : 'greenApple50',
                    },
                  }}
                  onClick={() => {
                    navigate('/change-payment-method')
                  }}
                >
                  {billingMethods[organization?.billing_method || 0].linkText}
                </Link>
              ) : (
                <IconWithTippyPopup
                  content="Please contact business@controld.com if you wish to change your payment method."
                  svg={InfoIcon}
                  sx={{ opacity: 0.5 }}
                />
              ))}
          </Flex>
        }
      />

      {!!billingSubscription?.state && (
        <>
          <DataRow
            name="Subscription Status"
            data={
              <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Text data-testid="subscription-status" sx={{ textTransform: 'capitalize' }}>
                  {billingSubscription.state ?? ''}
                </Text>
                {!!billingSubscription.status && (
                  <Link
                    sx={{
                      fontSize: '1.6rem',
                      fontWeight: 700,
                      color: 'white50',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      ':hover': {
                        color: 'white',
                      },
                    }}
                    onClick={() => {
                      nav({ billingDialog: BillingDialogType.CANCEL_SUBSCRIPTION })
                    }}
                  >
                    Cancel
                  </Link>
                )}
              </Flex>
            }
          />
          {isSubscriptionActive && billingSubscription.next_rebill_date !== 'N/A' && (
            <DataRow
              name="Next Billing Date"
              data={<Text>{billingSubscription.next_rebill_date}</Text>}
            />
          )}
        </>
      )}
      <Flex sx={{ flexDirection: 'column', gap: '0.1rem' }}>
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <PreferencesSectionTitle title="Products" sx={{ mb: '1.6rem' }} />
        </Flex>
        {/* products stuff here */}
        <DataRow
          testId="orgs-users-count-and-price"
          name={
            <Flex sx={{ justifyContent: 'space-between' }}>
              <Flex sx={{ flexDirection: 'column' }}>
                <Text>Connected Devices</Text>
                {!isPurchaser && (
                  <Text sx={{ fontSize: '1.2rem', fontWeight: 400, color: 'white50' }}>
                    {getFormattedPrice(
                      subscriptionCurrency,
                      organization?.users?.price || 0,
                      false,
                    )}
                    /month
                  </Text>
                )}
              </Flex>
              {isPurchaser ? undefined : (
                <Flex sx={{ display: ['flex', 'none'], fontWeight: 400, color: 'white50' }}>
                  {getFormattedPrice(
                    subscriptionCurrency,
                    organization?.users?.max ? usersTotalPrice : 0,
                    false,
                    2,
                  )}
                </Flex>
              )}
            </Flex>
          }
          data={
            <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Text sx={{ color: 'white50' }}>{`${
                organization?.users?.max ? organization?.users?.max?.toString() : '0'
              }`}</Text>

              <Flex
                // @ts-ignore
                as="form"
                sx={{
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  flexDirection: ['column', 'row', 'row'],
                  gap: '1.6rem',
                }}
              >
                {!isPurchaser && (
                  <Flex sx={{ display: ['flex'], alignItems: 'center' }}>
                    <Text sx={{ fontSize: '1.6rem', fontWeight: 700, color: 'white50' }}>
                      {getFormattedPrice(subscriptionCurrency, usersTotalPrice || 0, false, 2)}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Flex>
          }
        />
      </Flex>
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <PreferencesSectionTitle title="Invoices" />
        {/* invoices here */}
        {!!lastPayment ? (
          <OrganizationInvoicesTable
            paymentDataArray={billingPayment?.payments as OrganizationInvoice[]}
            currencies={currencies}
          />
        ) : (
          <NoResults message="No invoices yet!" />
        )}
      </Flex>
    </PreferencesWrapper>
  )
}
