import React, { ReactElement, ElementType, forwardRef, useCallback } from 'react'
import AddIcon from 'images/add-icon.svg'
import { Button, ButtonVariant, Svg } from 'ui'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import {
  TrackingEventAction,
  TrackingEventCategory,
  TrackingEventName,
  useTrackingHelper,
} from 'utils/useTrackingHelper'

interface FloatingActionButtonProps {
  onClick?: () => void
  isVisible?: boolean
  icon?: ElementType
  fill?: string
  dataTestId?: string
  sx?: ThemeUIStyleObject
  text?: string
  disabled?: boolean
  hasOverlay?: boolean
  variant?: ButtonVariant
  tabIndex?: number
  ariaLabel?: string
}
const FloatingActionButton = forwardRef<HTMLButtonElement, FloatingActionButtonProps>(
  function FloatingActionButton(
    {
      onClick,
      isVisible = true,
      icon = AddIcon,
      fill,
      dataTestId,
      sx = {},
      text,
      disabled = false,
      hasOverlay = false,
      variant = 'primary',
      tabIndex,
      ariaLabel,
    }: FloatingActionButtonProps,
    ref,
  ): ReactElement {
    const { trackEvent } = useTrackingHelper()
    const handleClick = useCallback(() => {
      if (onClick) {
        icon === AddIcon &&
          trackEvent({
            category: TrackingEventCategory.Nav,
            action: TrackingEventAction.CustomRules,
            name: TrackingEventName.Add,
          })
        onClick()
      }
    }, [icon, onClick, trackEvent])
    return (
      <>
        <Button
          ref={ref}
          variant={variant}
          data-testid={dataTestId}
          sx={{
            display: 'flex',
            position: 'absolute',
            right: ['1.6rem', 0],
            zIndex: 'zIndex50',
            transform: isVisible ? 'translateY(0)' : `translateY(100px)`,
            transition: 'transform 0.5s ease',
            borderRadius: 3,
            boxShadow: '0px 5px 30px #000',
            height: '4.8rem',
            width: text ? 'max-content' : '4.8rem',
            cursor: 'pointer',
            ...sx,
          }}
          tabIndex={tabIndex}
          ariaLabel={ariaLabel}
          onClick={handleClick}
          disabled={disabled}
        >
          {hasOverlay && (
            <Flex
              sx={{
                position: 'absolute',
                borderRadius: '100%',
                transform: isVisible ? 'translateY(0)' : `translateY(100px)`,
                zIndex: 'zIndex40',
                height: '4.8rem',
                width: text ? 'max-content' : '4.8rem',
                backgroundColor: 'white10',
              }}
            />
          )}
          {text ? (
            <Text
              sx={{
                whiteSpace: 'nowrap',
                flex: '1 0 auto',
                px: ['0.8rem', '1.2rem'],
              }}
            >
              {text}
            </Text>
          ) : (
            <Svg
              fill={fill || 'secondary'}
              svg={icon}
              sx={{
                width: '2.4rem',
                height: '2.4rem',
              }}
            />
          )}
        </Button>
      </>
    )
  },
)

export default FloatingActionButton
