import React, {
  FormEvent,
  ReactElement,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Button, ExternalLink, Input, useCustomAlerts } from 'ui'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import { useAppDispatch } from 'store/hooks'
import { setDeviceForEdit } from 'store/multiprofile'
import DeviceSettings from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/DeviceSettings'
import {
  ActionType,
  DeviceActionType,
  initialState,
  InitialStateType,
  settingsReducer,
} from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsState'
import useQueryString from 'utils/useQueryString'
import { DeviceDialogType } from 'components/Dashboard/Devices/DeviceTrayOrModalDialog'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { EnabledStatus } from 'store/api/rules'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { useGetStatLevelsQuery } from 'store/api/analytics'
import { SetupGuideIntermediateStates, SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import MultipleProfiles from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/MultipleProfiles'
import { trayHeaderHeight } from 'components/TrayOrModalDialog/Tray.mobile'
import AddOrEditDeviceTabs, {
  DeviceTab,
} from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDeviceTabs'
import { useEditOrganizationMutation } from 'store/api/organization'
import AdvancedSettings from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/AdvancedSettings'
import AnalyticsSettingsItem from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/AnalyticsSettingsItem'
import { usePutUserMutation } from 'store/api/user'
import useGetUserState from 'store/api/user/useGetUserState'
import {
  useGetDevicesTypesQuery,
  usePostDeviceMutation,
  usePutDeviceMutation,
} from 'store/api/devices/devices'
import { DeviceInfo } from 'store/api/devices/devices.interface'
import { NukeAnalyticsType } from 'store/api/user/user.interface'
import { OrgPermissionLevel } from 'store/api/organization/organization.interface'
import TextWithOverFlowAndTippyPopup from 'components/TextWithOverFlowAndTippyPopup'
import useGetUser from 'components/Dashboard/utils/useGetUser'
import ConnectedDevicesIcon from 'images/organization/connected-devices.svg'
import SettingsItem from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/SettingsItem'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'

export function deviceNameMask(value: string): string {
  return value.replace(/\W/g, '-')
}

const payloadProperties = [
  'name',
  'profile_id',
  'profile_id2',
  'icon',
  'status',
  'learn_ip',
  'desc',
  'nuke_analytics',
  'user_nuke_analytics',
  'deactivation_pin',
  'client_count',
]

export const ddnsSubdomainMinLength = 5
export const deactivationPinMinLength = 1
export const deactivationPinMaxLength = 10

export function isDeactivationPinLengthValid(pin?: number): boolean {
  if (!pin) {
    return false
  }

  const pinLength = pin.toString().length

  return pinLength >= deactivationPinMinLength && pinLength < deactivationPinMaxLength
}

interface MappedDeviceProperties {
  profile_id?: string
  profile_id2?: string | number
  restricted?: string | number
  legacy_ipv4_status?: EnabledStatus
  ddns_status?: EnabledStatus
  ddns_subdomain?: string
  ddns_ext_status?: EnabledStatus
  ddns_ext_host?: string
  stats?: StatLevel
  deactivation_pin?: number
  client_count?: number
}

// device properties for compliance with settingsState. These properties are the ones that the api expects.
const getMappedDeviceProperties = (state: InitialStateType, device?: Partial<DeviceInfo>) => {
  const mappedObj = {} as MappedDeviceProperties
  if (state.profile?.PK !== device?.profile?.PK) {
    mappedObj.profile_id = state.profile?.PK.toString()
  }

  if (state.profile2?.PK !== device?.profile2?.PK) {
    mappedObj.profile_id2 = state.profile2?.PK || -1
  }

  if (state.restricted !== device?.restricted) {
    mappedObj.restricted = state.restricted
  }

  if (state.stats !== (device?.stats || StatLevel.NO)) {
    mappedObj.stats = state.stats
  }

  if (state.ddns?.status !== (device?.ddns?.status || EnabledStatus.DISABLED)) {
    mappedObj.ddns_status = state.ddns?.status
  }

  if (state.ddns?.subdomain !== (device?.ddns?.subdomain || '')) {
    mappedObj.ddns_subdomain = state.ddns?.subdomain
    mappedObj.ddns_status = EnabledStatus.ENABLED
  }

  if (state.ddns_ext?.status !== (device?.ddns_ext?.status || EnabledStatus.DISABLED)) {
    mappedObj.ddns_ext_status = state.ddns_ext?.status
  }

  if (state.ddns_ext?.host !== (device?.ddns_ext?.host || '')) {
    mappedObj.ddns_ext_host = state.ddns_ext?.host
    mappedObj.ddns_ext_status = EnabledStatus.ENABLED
  }

  if (
    state.legacy_ipv4?.status === EnabledStatus.DISABLED &&
    mappedObj.ddns_ext_status !== device?.ddns_ext?.status
  ) {
    delete mappedObj.ddns_ext_host
    mappedObj.ddns_ext_status = EnabledStatus.DISABLED
  }

  if (state.legacy_ipv4?.status !== (device?.legacy_ipv4?.status || EnabledStatus.DISABLED)) {
    mappedObj.legacy_ipv4_status = state.legacy_ipv4?.status
  }

  if (!!device?.deactivation_pin && state.deactivationStatus === EnabledStatus.DISABLED) {
    mappedObj.deactivation_pin = -1
  }

  if (device?.client_count !== state.client_count) {
    mappedObj.client_count = state.client_count
  }

  return mappedObj
}

const getRequestPayloadFromState = (state: InitialStateType, device?: Partial<DeviceInfo>) => {
  const mappedProperties = getMappedDeviceProperties(state, device)
  if (!device) {
    const payload = {
      // create an object that contains only truthy values so that unmodified values are not included in the request payload
      ...pickBy({ ...pick(state, ...payloadProperties), ...mappedProperties }),
    }
    return payload
  }

  const payload = {
    ...pick(state, ...payloadProperties),
    ...mappedProperties,
  }

  // remove properties from requestDevice that are the same as in existing device
  for (const key in payload) {
    if (payload[key] === device[key] || key === 'icon') {
      delete payload[key]
    }
  }
  return payload
}

const AddOrEditDeviceView = ({
  device,
  dismiss,
}: {
  device?: Partial<DeviceInfo>
  dismiss: () => void
}): ReactElement => {
  const dispatch = useAppDispatch()
  const { qs, nav } = useQueryString()
  const [isRequestInFlight, setIsRequestInFlight] = useState(false)
  const [selectedTab, setSelectedTab] = useState(DeviceTab.SINGLE)
  /**
   * For retail users, session is the only place from which we can get the statistics storage region
   * For org users, the region is present in the user object but we should use the one present in the
   * organization data since we need to handle impersonation
   */
  const { isOrganization, region: userStatisticsRegion } = useGetUserState()
  const { data: orgData } = useGetOrganization()
  const orgStatisticsRegion = orgData?.organization.stats_endpoint
  const maxDevices =
    (orgData?.organization?.users?.max ?? 0) - (orgData?.organization?.users?.count ?? 0)

  const isSingleDevice = selectedTab === DeviceTab.SINGLE || !!device?.PK
  const [loadingMessage, setLoadingMessage] = useState('')
  const { nuke_analytics: nukeAnalytics } = useGetUserState()
  const { data: typesData } = useGetDevicesTypesQuery('')
  const [editOrganization] = useEditOrganizationMutation()
  const [putDevice] = usePutDeviceMutation()
  const [postDevice] = usePostDeviceMutation()
  const organizationName = orgData?.organization?.name
  const [settingsState, settingsDispatch] = useReducer<Reducer<InitialStateType, ActionType>>(
    settingsReducer,
    {
      ...initialState,
      ...device,
      deactivationStatus: !!device?.deactivation_pin
        ? EnabledStatus.ENABLED
        : EnabledStatus.DISABLED,
    } as InitialStateType,
  )
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const endpointNamesOrEmails = useMemo(
    // can be a number
    () => `${settingsState.name}`?.split('\n').filter(Boolean) || [],
    [settingsState.name],
  )

  const isSubdomainValid = !!(
    settingsState.ddns?.status &&
    (settingsState.ddns.subdomain?.length || 0) < ddnsSubdomainMinLength
  )

  const isDeactivationPinInvalid =
    settingsState.deactivationStatus === EnabledStatus.ENABLED &&
    !isDeactivationPinLengthValid(settingsState.deactivation_pin)

  // need statlevels to be loaded when tray is opened from analytics page
  useGetStatLevelsQuery('')
  const [putUser] = usePutUserMutation()
  const { data: userData } = useGetUser()
  const permissionLevel = userData?.org?.permission?.level
  const isOwner = permissionLevel === OrgPermissionLevel.OWNER

  useEffect(() => {
    if (!device) {
      if (qs.legacyIpv4Status !== undefined) {
        settingsDispatch({
          type: DeviceActionType.LEGACY_RESOLVER,
          payload: +qs.legacyIpv4Status,
        })
      }

      if (qs.learnIp !== undefined) {
        settingsDispatch({
          type: DeviceActionType.AUTO_AUTH_IP,
          payload: +qs.learnIp,
        })
      }

      if (qs.stats) {
        settingsDispatch({
          type: DeviceActionType.STATS,
          payload: +qs.stats,
        })
      }
    }
  }, [device, typesData?.types, qs.legacyIpv4Status, qs.stats, qs.learnIp])

  useEffect(() => {
    settingsDispatch({
      type: DeviceActionType.ICON,
      payload: qs.setupOs,
    })
  }, [qs.setupOs])

  useEffect(() => {
    if (isOrganization) {
      settingsDispatch({
        type: DeviceActionType.CLIENT_COUNT,
        payload: device?.client_count || (qs.setupOs?.includes('router') ? 5 : 1),
      })
    }
  }, [device?.client_count, isOrganization, qs.setupOs])

  useEffect(() => {
    if (qs.deviceDialog === DeviceDialogType.EDIT && !device) {
      dispatch(setDeviceForEdit())
      dismiss()
    }
  }, [dismiss, dispatch, qs.deviceDialog, device])

  useEffect(() => {
    if (!settingsState?.ddns_ext?.status) {
      settingsDispatch({
        type: DeviceActionType.DDNS_HOST,
        payload: device?.ddns_ext?.host || '',
      })
    }
  }, [settingsState?.ddns_ext?.status, device])

  useEffect(() => {
    if (!settingsState?.ddns?.status) {
      settingsDispatch({
        type: DeviceActionType.EXPOSE_IP_SUBDOMAIN,
        payload: device?.ddns?.subdomain || '',
      })
    }
  }, [settingsState?.ddns?.status, device])

  useEffect(() => {
    if (qs?.learnIp === undefined && !settingsState?.legacy_ipv4?.status) {
      settingsDispatch({
        type: DeviceActionType.AUTO_AUTH_IP,
        payload: device?.learn_ip || EnabledStatus.DISABLED,
      })
    }
  }, [qs?.learnIp, settingsState?.legacy_ipv4?.status, device])

  useEffect(() => {
    if (!!settingsState?.restricted) {
      settingsDispatch({
        type: DeviceActionType.AUTO_AUTH_IP,
        payload: EnabledStatus.DISABLED,
      })
    }
  }, [device, settingsState?.restricted])

  const getUpdatedRequestDevice = useCallback(
    (requestDevice, deviceNameOrEmail) => {
      requestDevice.name = deviceNameOrEmail || ''

      if (qs.deviceName) {
        requestDevice.icon = qs.setupOs
      }

      if (qs.clientId) {
        //this is a client being changed to a device
        // add the profile ids to the payload
        if (settingsState.profile) {
          requestDevice.profile_id = settingsState.profile.PK.toString()
        }
        if (settingsState.profile2) {
          requestDevice.profile_id2 = settingsState.profile2.PK.toString()
        }
        // add the stat to the payload
        if (settingsState.stats) {
          requestDevice.stats = settingsState.stats
        }
        requestDevice.remap_client_id = settingsState.remap_client_id
        requestDevice.remap_device_id = settingsState.remap_device_id
      }

      return requestDevice
    },
    [
      qs.clientId,
      qs.deviceName,
      qs.setupOs,
      settingsState.profile,
      settingsState.profile2,
      settingsState.remap_client_id,
      settingsState.remap_device_id,
      settingsState.stats,
    ],
  )

  const areOptionsChanged = useMemo(() => {
    const payload = getRequestPayloadFromState(settingsState, device)
    return !!Object.entries(payload).length
  }, [device, settingsState])

  const createMultipleDevices = useCallback(
    async requestDevice => {
      let response

      for (let i = 0; i < endpointNamesOrEmails.length; i++) {
        setLoadingMessage(`Adding Endpoint ${i + 1}/${endpointNamesOrEmails.length}`)
        requestDevice = getUpdatedRequestDevice(requestDevice, endpointNamesOrEmails[i])

        response = await postDevice({ newDevice: requestDevice })

        if (response.error) {
          setLoadingMessage('')
          break
        }

        if (settingsState.name) {
          settingsState.name = settingsState.name?.split('\n')?.slice(1).join('\n')
        }
      }

      if (!response.error) {
        setLoadingMessage('')
        dismiss()
      }

      /**
       * settingsState.regionSettings will only be set if the user set it using the region
       * selection dropdown. This is when we would call `PUT /user` to update the analytics
       * region for the user.
       */
      if (settingsState.regionSettings && !qs.clientId) {
        if (nukeAnalytics === NukeAnalyticsType.USER) {
          await putUser(settingsState.regionSettings)
        } else {
          editOrganization(settingsState.regionSettings)
        }
      }
    },
    [
      endpointNamesOrEmails,
      dismiss,
      getUpdatedRequestDevice,
      putUser,
      editOrganization,
      nukeAnalytics,
      postDevice,
      qs.clientId,
      settingsState,
    ],
  )
  const { presentCautionOkAlert } = useCustomAlerts()

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault()

      const isAnalyticsInvalid = !(
        settingsState.stats === StatLevel.NO ||
        (orgStatisticsRegion ?? userStatisticsRegion) ||
        settingsState.regionSettings
      )

      if (isAnalyticsInvalid) {
        presentCautionOkAlert('Please select a valid analytics region.')
        return
      }

      let requestDevice = {
        ...getRequestPayloadFromState(settingsState, device),
      } as unknown as DeviceInfo

      if (!isSingleDevice) {
        await createMultipleDevices(requestDevice)
        return
      }

      setIsRequestInFlight(true)

      let response

      if (device?.PK) {
        response = await putDevice({ PK: device.PK, device: requestDevice as DeviceInfo })
      } else {
        requestDevice = getUpdatedRequestDevice(requestDevice, requestDevice.name)

        response = await postDevice({ newDevice: requestDevice })
      }

      /**
       * settingsState.regionSettings will only be set if the user set it using the region
       * selection dropdown. This is when we would call `PUT /user` to update the analytics
       * region for the user.
       */
      if (settingsState.regionSettings && !qs.clientId) {
        if (nukeAnalytics === NukeAnalyticsType.USER) {
          await putUser(settingsState.regionSettings)
        } else {
          editOrganization(settingsState.regionSettings)
        }
      }

      if (!response.error) {
        dismiss()
        dispatch(setDeviceForEdit())
      }

      const payloadBody = response?.data

      if (!device?.PK && payloadBody && isSingleDevice) {
        nav({
          ...omit(qs, 'deviceDialog'),
          helpPane: SetupGuideIntermediateStates.DNS,
          setupOs: payloadBody.icon as SetupOs, // can be SetupOS or something new that has not been added to the SetupOs
          deviceId: payloadBody.device_id,
        })
      }

      setIsRequestInFlight(false)
    },
    [
      settingsState,
      orgStatisticsRegion,
      userStatisticsRegion,
      device,
      isSingleDevice,
      qs,
      presentCautionOkAlert,
      createMultipleDevices,
      putDevice,
      getUpdatedRequestDevice,
      postDevice,
      nukeAnalytics,
      putUser,
      editOrganization,
      dismiss,
      dispatch,
      nav,
    ],
  )

  return (
    <Flex
      ref={containerRef}
      as="form"
      // @ts-ignore
      onSubmit={handleSubmit}
      sx={{
        position: 'relative',
        width: '100%',
        maxHeight: [`calc(100% - ${trayHeaderHeight})`, '100%', '100%'],
        gap: '2.4rem',
        px: ['1.6rem', '4.8rem'],
        pt: ['2.4rem', isOrganization && device?.PK ? '3.2rem' : '4.8rem'],
        overflowY: 'auto',
        flexDirection: 'column',
        alignSelf: 'center',
      }}
      className="show-scrollbar"
    >
      {isOrganization && !device?.PK && (
        <AddOrEditDeviceTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          resetDeviceInput={() => {
            settingsDispatch({
              type: DeviceActionType.RESET_SETTINGS,
              payload: undefined,
            })

            if (qs.stats) {
              settingsDispatch({
                type: DeviceActionType.STATS,
                payload: +qs.stats,
              })
            }
          }}
        />
      )}
      {isOrganization && device?.PK && (
        <Flex
          sx={{
            width: '100%',
            p: '1.3rem 2.4rem',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '1.6rem',
            backgroundColor: 'black25',
          }}
        >
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
            }}
          >
            <Text
              variant="subTitle"
              sx={{
                flexShrink: 0,
                mx: 'auto',
                whiteSpace: 'pre-wrap',
              }}
            >
              Organization: {''}
            </Text>
            <TextWithOverFlowAndTippyPopup
              sxTooltipContent={{ wordBreak: 'break-all' }}
              maxWidth="67rem"
              textVariant="boldSubTitle"
              sxText={{
                textAlign: 'center',
              }}
              content={organizationName}
            />
          </Flex>
        </Flex>
      )}
      {isOrganization && (
        <SettingsItem
          testId="connected-devices"
          icon={ConnectedDevicesIcon}
          title="Connected Devices"
          description="How many devices will connect to this Endpoint?"
          descriptionLink={
            <ExternalLink
              to={`${DOCS_CONTROL_D_DOMAIN}/docs/connected-devices`}
              sx={{
                color: 'white50',
                cursor: 'pointer',
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
              }}
            >
              Learn more
            </ExternalLink>
          }
          actionContent={
            <Box
              data-testid="settings-connected-devices-container"
              sx={{ position: 'relative', flexShrink: 0 }}
              className="reset-tooltip"
            >
              <Input
                name="client-count-input"
                data-testid="client-count-input"
                aria-label="client count input"
                activeLabelColor="white"
                value={settingsState.client_count}
                sx={{
                  borderRadius: '45px',
                  alignItems: 'center',
                  maxWidth: '13rem',
                  backgroundColor: 'white10',
                }}
                onChange={(event): void => {
                  settingsDispatch({
                    type: DeviceActionType.CLIENT_COUNT,
                    payload: +event.target.value ? +event.target.value : 0,
                  })
                }}
              />
            </Box>
          }
        />
      )}
      <Input
        ref={inputRef}
        id="input-device-name"
        name="device-name-input"
        data-testid="device-name-input"
        aria-label="device name input"
        label={isSingleDevice ? 'Endpoint Name' : `User Emails Or Endpoint Names`}
        activeLabelColor="white"
        value={settingsState.name}
        isTextarea={!isSingleDevice}
        rows={maxDevices}
        placeholder={
          isSingleDevice
            ? ''
            : `james.tiberius.kirk@starfleet.com\npicard@starfleet.com\njaneway@starfleet.com\ntaylor-swifts-fridge\n`
        }
        sx={{
          borderRadius: isSingleDevice ? '45px' : '16px',
          alignItems: 'center',
          backgroundColor: 'white10',
          height: isSingleDevice ? '5.4rem' : '14rem',
        }}
        containerStyle={{
          flex: 1,
        }}
        onChange={(event): void => {
          const nameMask = isSingleDevice ? deviceNameMask(event.target.value) : event.target.value
          const selectionStart = event.target.selectionStart
          settingsDispatch({
            type: DeviceActionType.DEVICE_NAME,
            payload: nameMask,
          })
          if (event.target.value !== nameMask) {
            inputRef.current?.setSelectionRange(selectionStart, selectionStart)
          }
        }}
      />

      <MultipleProfiles
        settingsDispatch={settingsDispatch}
        settingsState={settingsState}
        devicePk={device?.PK}
        globalProfilePk={orgData?.organization?.parent_profile?.PK}
      />
      <Input
        name="comment-input"
        data-testid="comment-input"
        aria-label="comment input"
        label="Comments (Optional)"
        activeLabelColor="white"
        value={settingsState.desc}
        sx={{
          borderRadius: '45px',
          alignItems: 'center',
          backgroundColor: 'white10',
        }}
        onChange={(event): void => {
          settingsDispatch({
            type: DeviceActionType.COMMENT,
            payload: event.target.value,
          })
        }}
      />

      <AnalyticsSettingsItem
        settingsStats={settingsState.stats}
        regionSettingsStats={settingsState.regionSettings?.stats_endpoint}
        settingsDispatch={settingsDispatch}
        /* only show storage region selection if the user (or org) has not selected a region
         * For orgs, this is moot, since they will always have a region selected
         */
        shouldShowStorageRegionSelection={
          !orgStatisticsRegion && !userStatisticsRegion && (!isOrganization || isOwner)
        }
      />

      <AdvancedSettings>
        <DeviceSettings
          settingsState={settingsState}
          settingsDispatch={settingsDispatch}
          isStatusVisible={!!device && !qs.clientId}
          isSingleDevice={isSingleDevice}
          deviceDeactivationPin={device?.deactivation_pin}
        />
      </AdvancedSettings>
      <Flex
        sx={{
          position: 'sticky',
          bottom: 0,
          width: '100%',
          flexDirection: 'column',
          zIndex: 'zIndex50',
        }}
      >
        <Box
          sx={{
            width: '100%',
            pt: '2.4rem',
            background: 'linear-gradient(to bottom, rgba(37, 39, 55, 0) 0%, #252737)',
          }}
        />
        <Flex
          sx={{
            width: '100%',
            backgroundColor: 'darkItemBG',
            pb: ['1.6rem', '2.4rem'],
            justifyContent: 'center',
          }}
        >
          {device?.PK ? (
            <Flex sx={{ width: '100%' }}>
              <Button
                variant="secondary"
                data-testid="view-config-button"
                sx={{
                  flex: 1,
                  height: '5.4rem',
                  mt: '1.6rem',
                  alignSelf: 'center',
                  mr: '2.4rem',
                  color: 'white',
                  border: '2px solid',
                  borderColor: 'plum',
                  borderRadius: '59px',
                  backgroundColor: 'transparent',
                }}
                onClick={() => {
                  nav({
                    ...omit(qs, 'deviceDialog'),
                    helpPane: SetupGuideIntermediateStates.DNS,
                    deviceId: device.PK,
                    setupOs: device?.icon as SetupOs,
                  })
                }}
              >
                <Text sx={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'white' }}>
                  View Config
                </Text>
              </Button>
              <ButtonWithLoadingState
                disabled={isSubdomainValid || isDeactivationPinInvalid || !areOptionsChanged}
                type="submit"
                data-testid="save-device-button"
                sx={{
                  flex: 1,
                  height: '5.4rem',
                  mt: '1.6rem',
                  borderRadius: '59px',
                  backgroundColor: 'plum',
                  border: 'none',
                  alignSelf: 'center',
                  color: 'white',
                }}
                isLoading={isRequestInFlight}
              >
                <Text sx={{ fontSize: '1.8rem', fontWeight: 'bold' }}>Save</Text>
              </ButtonWithLoadingState>
            </Flex>
          ) : (
            <ButtonWithLoadingState
              disabled={
                !settingsState.name ||
                !settingsState.profile ||
                isSubdomainValid ||
                !!loadingMessage ||
                isDeactivationPinInvalid ||
                (isOrganization && !settingsState.client_count)
              }
              type="submit"
              data-testid="add-device-button"
              sx={{
                width: ['100%', '48rem'],
                height: '5.4rem',
                mt: '1.6rem',
                borderRadius: '59px',
                backgroundColor: 'plum',
                border: 'none',
                alignSelf: 'center',
                fontSize: '1.8rem',
                fontWeight: 'bold',
              }}
              isLoading={isRequestInFlight}
            >
              {isSingleDevice
                ? `Add Endpoint`
                : loadingMessage ||
                  `Add ${endpointNamesOrEmails?.length || ''} Endpoint${
                    endpointNamesOrEmails?.length > 1 ? 's' : ''
                  }`}
            </ButtonWithLoadingState>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AddOrEditDeviceView
