import React, { useRef, useState, ReactElement } from 'react'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { Flex, Text } from 'theme-ui'
import { Dropdown, IconButtonContent, SimpleActionMenu, SimpleActionMenuItemProps, Svg } from 'ui'
import { MenuItem } from './MenuItem'
import HelpIcon from 'images/dashboard/navBar/mobile-help-icon.svg'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import useBoundaryElement from 'utils/useBoundaryElement'
import { useAppSelector } from 'store/hooks'
import useHandleOpenBarry from 'utils/useHandleOpenBarry'

export default function HelpMenu(): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const hideRef = useRef<() => void>()
  const breakpoint = useBreakpointIndex()
  const isMobile = breakpoint === 0
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const boundaryElement = useBoundaryElement(isMobile ? 'nav-tray-header' : 'nav-bar-header')
  const handleOpenBarry = useHandleOpenBarry()

  const options: SimpleActionMenuItemProps[] = [
    {
      children: (
        <IconButtonContent dataTestId={'barry-button'}>
          <Flex
            sx={{
              width: '100%',
              pr: '1rem',
            }}
          >
            <Text>Talk to Barry</Text>
          </Flex>
        </IconButtonContent>
      ),
      onClick: () => {
        handleOpenBarry()
      },
    },
    {
      children: <MenuItem text="Contact Us" to={'/contact'} />,
    },
    {
      children: <MenuItem to={`${DOCS_CONTROL_D_DOMAIN}/docs`} text="Docs" />,
    },
    {
      children: (
        <MenuItem to={`${DOCS_CONTROL_D_DOMAIN}/discuss?tag=feature`} text="Upcoming Features" />
      ),
    },
  ]

  return (
    <Dropdown
      data-testid="help-menu-dropdown-button"
      aria-label="help menu"
      variant="simple"
      sx={{
        p: 0,
      }}
      tippyprops={{
        appendTo: isNavBarExpanded || isMobile ? boundaryElement : 'parent',
        animation: 'fade',
        onHide: () => {
          setIsOpen(false)
        },
        onShow: () => {
          setIsOpen(true)
        },
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        offset: [117, 18],
        theme: 'screen',
        placement: 'bottom-start',
        maxWidth: '100%',
      }}
      dropdowncontent={
        <Flex
          sx={{
            width: ['100%', '26rem'],
            flexDirection: 'column',
            backgroundColor: 'darkItemBG',
            borderRadius: '1.6rem',
            py: '1.6rem',
            '& > div': {
              pb: '0.8rem',
            },
          }}
        >
          <SimpleActionMenu
            hideRef={hideRef}
            items={options}
            sxContainer={{ pt: 0, px: '1.2rem' }}
            sxButton={{
              maxHeight: 'auto',
              borderRadius: '0.8rem',
              m: 0,
              px: '1.6rem',
            }}
          />
        </Flex>
      }
    >
      <Flex
        sx={{
          display: 'flex',
          width: ['fit-content', '4.8rem'],
          height: ['fit-content', '4.8rem'],
          p: 0,
          mb: [0, '1.6rem', 0],
          backgroundColor: ['transparent', isOpen ? 'white25' : 'white10'],
          borderRadius: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          flexShrink: 0,
          '&:hover': {
            ':not([disabled])': {
              backgroundColor: isMobile ? 'transparent' : 'white25',
              color: 'white',
            },
          },
        }}
      >
        <Svg svg={HelpIcon} fill={isOpen ? 'white' : 'white50'} />
      </Flex>
    </Dropdown>
  )
}
