import React, { ReactElement } from 'react'
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui'
import { Button, Svg } from 'ui'
import CloseIcon from 'images/menu-close.svg'
import ArrowRight from 'images/freeDNS/arrow-right.svg'

export default function AttentionPlaceholder({
  testId,
  title,
  description,
  buttonText,
  onCloseClick,
  onButtonClick,
  sx,
}: {
  testId?: string
  title: string
  description: string
  buttonText: string
  onCloseClick: () => void
  onButtonClick: () => void
  sx?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Flex
      sx={{
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: 'darkBodyBG',
        borderRadius: '10px',
        ...sx,
      }}
    >
      <Flex
        data-testid={testId}
        sx={{
          width: ['100%', '24rem'],
          p: '1.6rem',
          flexDirection: 'column',
          border: '2px solid',
          borderColor: 'plum',
          borderRadius: '10px',
          backgroundColor: 'plum10',
        }}
      >
        <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text sx={{ fontSize: '1.6rem', fontWeight: 'bold' }}>{title}</Text>
          <Button
            data-testid={`${testId}-close-button`}
            variant="simple"
            sx={{
              p: 0,
            }}
            onClick={onCloseClick}
          >
            <Svg svg={CloseIcon} fill="white50" sx={{ width: '1.6rem', height: '1.6rem' }} />
          </Button>
        </Flex>
        <Text sx={{ fontSize: '1.4rem', color: 'white50', mt: '0.8rem', mb: '1.6rem' }}>
          {description}
        </Text>
        <Button
          variant="simple"
          sx={{
            fontSize: '1.6rem',
            fontWeight: 'bold',
            color: 'white50',
            p: 0,
            justifyContent: 'flex-start',
            gap: '0.8rem',
          }}
          onClick={onButtonClick}
        >
          {buttonText}
          <Svg svg={ArrowRight} fill="white50" />
        </Button>
      </Flex>
    </Flex>
  )
}
