import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import { foregroundStyle } from 'components/Dashboard/PlaceholderElements'

export default function RowPlaceholder(): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: '1.6rem',
      }}
    >
      <Box
        sx={{
          ...foregroundStyle,
          width: '20rem',
          height: '2.4rem',
        }}
      />
      <Box
        sx={{
          ...foregroundStyle,
          width: '2.4rem',
          height: '2.4rem',
        }}
      />
    </Flex>
  )
}
