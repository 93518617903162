import React, { ReactElement } from 'react'
import AccountIcon from 'images/dashboard/navBar/my-account-icon.svg'
import BillingIcon from 'images/dashboard/navBar/billing-icon.svg'
import ApiIcon from 'images/dashboard/navBar/api-icon.svg'
import NavMenuItem from 'components/Dashboard/NavBar/NavMenuItem'
import { Flex } from 'theme-ui'
import PreferencesAccordion from './PreferencesAccordion'
import usePresentConfirmLogoutAlert from 'components/Dashboard/utils/usePresentConfirmLogoutAlert'
import LogoutIcon from 'images/dashboard/navBar/logout-icon.svg'

export enum PreferencesNavRelativePath {
  ACCOUNT = 'account',
  BILLING = 'billing',
  API = 'api',
}

export function PreferencesNavMenu(): ReactElement {
  const onLogout = usePresentConfirmLogoutAlert()

  return (
    <PreferencesAccordion>
      <Flex
        sx={{
          flexDirection: 'column',
          gap: '1.6rem',
        }}
      >
        <NavMenuItem
          activeIcon={AccountIcon}
          relativePath={PreferencesNavRelativePath.ACCOUNT}
          label="Account"
        />
        <NavMenuItem
          activeIcon={BillingIcon}
          relativePath={PreferencesNavRelativePath.BILLING}
          label="Billing"
        />
        <NavMenuItem
          activeIcon={ApiIcon}
          relativePath={PreferencesNavRelativePath.API}
          label="API"
        />
        <NavMenuItem
          onClick={onLogout}
          activeIcon={LogoutIcon}
          label="Log out"
          sx={{ mb: '0.8rem' }}
        />
      </Flex>
    </PreferencesAccordion>
  )
}
