import React from 'react'
import { Flex, Text } from 'theme-ui'
import { Button, Svg } from 'ui'
import ConfirmEmailIcon from 'images/organization/confirm-email-icon.svg'
import Overlay from 'ui/Overlay'
import { navigate } from 'gatsby'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function ConfirmEmailScreen(): React.ReactElement {
  const { data: userData } = useGetUser()
  const signUpEmailAddress = userData?.email

  return (
    <Overlay>
      <Flex
        sx={{
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center',
          px: '1.6rem',
        }}
      >
        <Text sx={{ fontSize: ['3.2rem', '4.8rem'], fontWeight: 'bold', mb: ['4.2rem', '8rem'] }}>
          Confirm your email.
        </Text>
        <Svg svg={ConfirmEmailIcon} />
        <Text sx={{ maxWidth: ['100%', '42.6rem'], fontSize: '1.8rem', mt: '4.8rem' }}>
          To finish up, please confirm your email {signUpEmailAddress} to get access to your
          dashboard.
        </Text>
        <Button
          variant="transparent"
          sx={{
            width: '19.8rem',
            height: '5.4rem',
            fontSize: '1.8rem',
            fontWeight: 'bold',
            alignItems: 'center',
            justifyContent: 'center',
            mt: '6.3rem',
            border: '1px solid',
            borderColor: 'white',
            borderRadius: '59px',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
            },
          }}
          onClick={() => navigate('/dashboard/account')}
        >
          Change Email
        </Button>
      </Flex>
    </Overlay>
  )
}
