import React, { ReactNode } from 'react'
import { Box, Text } from 'theme-ui'

export default function SelectVersionPane({
  children,
}: {
  children?: ReactNode
}): React.ReactElement {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        button: { height: '4.8rem', width: '32rem' },
        '& > button:not(:last-child)': {
          mb: 6,
        },
      }}
    >
      <Text
        sx={{
          fontSize: '1.8rem',
          textAlign: 'center',
          mb: ['2.4rem', '3.2rem'],
        }}
      >
        For which version?
      </Text>
      {children}
    </Box>
  )
}
