import React, { PropsWithChildren, RefObject } from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'

export const modalDialogFooterHeight = '5.4rem'

export enum ButtonType {
  CLOSE = 'close',
  BACK = 'back',
}

export const ButtonStyles = {
  width: ['4rem', '4.8rem'],
  height: ['4rem', '4.8rem'],
  borderRadius: '50%',
  backgroundColor: 'white10',
}

export interface ModalDialogFooterProps {
  headerRef?: RefObject<HTMLDivElement>
  sx?: ThemeUIStyleObject
}
export default function ModalDialogFooter({
  headerRef,
  sx,
  children,
}: PropsWithChildren<ModalDialogFooterProps>): React.ReactElement {
  return (
    <Flex
      data-testid="modal-dialog-footer"
      ref={headerRef}
      sx={{
        height: modalDialogFooterHeight,
        alignItems: 'center',
        flexShrink: 0,
        backgroundColor: 'blue800',
        px: '1.6rem',
        borderBottomRightRadius: [0, '1.2rem'],
        borderBottomLeftRadius: [0, '1.2rem'],
        ...sx,
      }}
    >
      {children}
    </Flex>
  )
}
