import React, { ReactElement } from 'react'
import { Button, Flex, ThemeUIStyleObject } from 'theme-ui'
import { navigate } from 'gatsby'
import { useAppSelector } from 'store/hooks'
import { GetStartedDropdown } from 'components/HomePage/Header/HomepageDropdowns'
import { getStarted } from 'components/HomePage/Header/menus'
import { TippyProps } from '@tippyjs/react'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'

interface ButtonGroupProps {
  sx?: ThemeUIStyleObject
  buttonSx?: ThemeUIStyleObject
  tippyprops?: TippyProps
  dropdownContainerSx?: ThemeUIStyleObject
}

const compactStyles = { py: '0.6rem', px: '1.2rem', fontSize: '1.5rem', fontWeight: 600 }

export function ButtonGroup({
  sx,
  tippyprops,
  dropdownContainerSx,
  buttonSx = {},
}: ButtonGroupProps): ReactElement {
  const sessionToken = useAppSelector(s => s.persistData.sessionToken)
  const isMobileOrTablet = useBreakpointIndex() < 2

  return (
    <Flex
      sx={{
        gap: '0.8rem',
        flexShrink: 0,
        alignItems: 'center',
        ...sx,
      }}
    >
      {sessionToken ? (
        <Button
          name="button"
          aria-label="go to dashboard"
          variant="roundedSecondary"
          sx={{
            ...(isMobileOrTablet ? compactStyles : {}),
            ...buttonSx,
          }}
          onClick={() => {
            navigate('/dashboard/profiles')
          }}
        >
          Go to Dashboard
        </Button>
      ) : (
        <>
          <Button
            name="button"
            aria-label="login button"
            data-testid="org-login-button"
            variant="roundedSimple"
            sx={{
              ...(isMobileOrTablet ? compactStyles : {}),
              ...buttonSx,
            }}
            onClick={() => {
              navigate('/login')
            }}
          >
            Log in
          </Button>
          <GetStartedDropdown
            dropdownItems={getStarted}
            tippyprops={tippyprops}
            sxContainer={dropdownContainerSx}
          >
            Get Started
          </GetStartedDropdown>
        </>
      )}
    </Flex>
  )
}
