import React, { ReactElement } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { Accordion, Svg, TableCell, TableRow } from 'ui'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import Options from './Options'
import { Provision, StatusType } from 'store/api/provision'
import moment from 'moment'
import DeviceIcons, { defaultIconName } from 'components/Dashboard/Devices/DeviceIcons'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import PreventDeactivationIcon from 'images/profileManagement/settings/prevent-deactivation.svg'

const mobileHeaderStyle = { color: 'white', fontWeight: 'bold' }

export default function ProvisionElement({
  provision,
  tableHeaders,
}: {
  provision: Provision
  tableHeaders: string[]
}): ReactElement {
  const breakpoint = useBreakpointIndex()

  const ProvisionElement =
    breakpoint > 1 ? ProvisionElementDesktop : ProvisionElementMobileAndTablet

  return <ProvisionElement provision={provision} tableHeaders={tableHeaders} />
}

const ProvisionElementDesktop = ({ provision }: { provision: Provision }): ReactElement => {
  const { PK, status, ts_exp, profile, max, ts, icon, count, expired, deactivation_pin } = provision

  return (
    <TableRow data-testid="provision-row" sx={{ color: 'white50', maxWidth: '100%' }}>
      <TableCell
        data-testid="provision-created-on"
        sx={{ pl: '2.4rem', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}
      >
        {moment(new Date(ts * 1000)).format('YYYY-MM-DD, HH:mm:ss')}
      </TableCell>
      <TableCell data-testid="provision-device-icon">
        <Flex sx={{ pl: deactivation_pin ? '1.2rem' : '2.8rem', gap: '0.8rem' }}>
          <Svg
            data-testid="provision-device-icon"
            svg={DeviceIcons[icon ?? defaultIconName]}
            sx={{ width: '2.4rem', height: '2.4rem' }}
            fill="white50"
          />
          {deactivation_pin && (
            <IconWithTippyPopup
              content={deactivation_pin}
              svg={PreventDeactivationIcon}
              fill="white50"
              sx={{ width: '2.4rem', height: '2.4rem', flexShrink: 0 }}
            />
          )}
        </Flex>
      </TableCell>
      <TableCell data-testid="provision-profile-name">{profile.name}</TableCell>
      <TableCell data-testid="provision-status">
        {!!expired
          ? 'Expired'
          : status === StatusType.INVALIDATED
          ? 'Invalidated'
          : status === StatusType.LIMIT_REACHED
          ? 'Limit Reached'
          : 'Active'}
      </TableCell>
      <TableCell data-testid="provision-expired-date">
        {ts_exp === 0 ? 'Never' : moment(new Date(ts_exp * 1000)).format('YYYY-MM-DD, HH:mm:ss')}
      </TableCell>
      <TableCell data-testid="provision-limit">{`${count}/${max}`}</TableCell>
      <TableCell
        sx={{
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          justifyContent: 'flex-end',
        }}
      >
        <Options provisionPk={PK} />
      </TableCell>
    </TableRow>
  )
}

const ProvisionElementMobileAndTablet = ({
  provision,
  tableHeaders,
}: {
  provision: Provision
  tableHeaders: string[]
}): ReactElement => {
  const { PK, status, ts_exp, profile, max, ts, icon, count, expired, deactivation_pin } = provision

  return (
    <Box>
      <Accordion
        id={PK}
        title={
          <Flex sx={{ alignItems: 'center', mr: '0.8rem' }}>
            {moment(new Date(ts * 1000)).format('YYYY-MM-DD, HH:mm:ss')}
          </Flex>
        }
        titleStyle={{ color: 'white50' }}
        openTitleStyle={{ color: 'white50' }}
        fillColor="white50"
      >
        <Flex
          sx={{
            width: '100%',
            maxHeight: 'fit-content',
            px: '1.6rem',
            flexDirection: ['column', 'row'],
            flexWrap: ['nowrap', 'wrap'],
            justifyContent: 'space-between',
            '& > div': {
              py: '1.6rem',
              width: ['100%', '48%'],
              alignItems: 'center',
            },
            '& > div > div:first-of-type': {
              color: 'muted',
            },
          }}
        >
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>{tableHeaders[1]}</Text>
            <Flex sx={{ color: 'white50', gap: '0.8rem' }}>
              <Svg
                data-testid="provision-device-icon"
                svg={DeviceIcons[icon ?? defaultIconName]}
                sx={{ width: '2.4rem', height: '2.4rem' }}
                fill="white50"
              />
              {deactivation_pin && (
                <IconWithTippyPopup
                  content={deactivation_pin}
                  svg={PreventDeactivationIcon}
                  fill="white50"
                  sx={{ width: '2.4rem', height: '2.4rem', flexShrink: 0 }}
                />
              )}
            </Flex>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>{tableHeaders[2]}</Text>
            <Text sx={{ color: 'white50' }}>{profile.name}</Text>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>{tableHeaders[3]}</Text>
            <Text sx={{ color: 'white50' }}>
              {!!expired
                ? 'Expired'
                : status === StatusType.INVALIDATED
                ? 'Invalidated'
                : status === StatusType.LIMIT_REACHED
                ? 'Limit Reached'
                : 'Active'}
            </Text>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>{tableHeaders[4]}</Text>
            <Text sx={{ color: 'white50' }}>
              {ts_exp === 0
                ? 'Never'
                : moment(new Date(ts_exp * 1000)).format('YYYY-MM-DD, HH:mm:ss')}
            </Text>
          </Flex>{' '}
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>{tableHeaders[5]}</Text>
            <Text sx={{ color: 'white50' }}>{`${count}/${max}`}</Text>
          </Flex>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text sx={{ ...mobileHeaderStyle }}>Options</Text>
            <Options provisionPk={PK} />
          </Flex>
        </Flex>
      </Accordion>
    </Box>
  )
}
