import iOSIcon from 'images/profileManagement/deviceIcons/ios.svg'
import AndroidIcon from 'images/profileManagement/deviceIcons/android.svg'
import WindowsIcon from 'images/profileManagement/deviceIcons/windows.svg'
import MacOSIcon from 'images/profileManagement/deviceIcons/desktop-mac-os-icon.svg'
import LinuxIcon from 'images/profileManagement/deviceIcons/linux.svg'
import DeviceIcon from 'images/profileManagement/deviceIcons/default-device-icon.svg'
import TVIcon from 'images/profileManagement/deviceIcons/tv-icon.svg'
import FireTVIcon from 'images/profileManagement/deviceIcons/fire-tv.svg'
import AppleTVIcon from 'images/profileManagement/deviceIcons/apple-tv.svg'
import AndroidTVIcon from 'images/profileManagement/deviceIcons/android-tv.svg'
import SamsungTVIcon from 'images/profileManagement/deviceIcons/samsung.svg'
import ChromeIcon from 'images/profileManagement/deviceIcons/browser-chrome.svg'
import FirefoxIcon from 'images/profileManagement/deviceIcons/browser-firefox.svg'
import EdgeIcon from 'images/profileManagement/deviceIcons/browser-edge.svg'
import OtherIcon from 'images/profileManagement/deviceIcons/browser-other.svg'
import BraveIcon from 'images/profileManagement/deviceIcons/browser-brave.svg'
import OpenWRTIcon from 'images/profileManagement/deviceIcons/open-wrt-icon.svg'
import UbiquitiIcon from 'images/profileManagement/deviceIcons/ubiquiti-icon.svg'
import AsusIcon from 'images/profileManagement/deviceIcons/asus.svg'
import DDWRTIcon from 'images/profileManagement/deviceIcons/ddwrt.svg'
import SynologyIcon from 'images/profileManagement/deviceIcons/synology.svg'
import GlInet from 'images/profileManagement/deviceIcons/gl-inet.svg'
import PfSense from 'images/profileManagement/deviceIcons/pfsense.svg'
import OPNSense from 'images/profileManagement/deviceIcons/opnsense.svg'
import FreshTomato from 'images/profileManagement/deviceIcons/fresh-tomato.svg'
import FireWalla from 'images/profileManagement/deviceIcons/firewalla.svg'
import RouterIcon from 'images/profileManagement/deviceIcons/router-icon.svg'
import WindowsServerIcon from 'images/profileManagement/deviceIcons/windows-server.svg'
import LinuxServerIcon from 'images/profileManagement/deviceIcons/linux-server.svg'
import { DropdownItemData } from 'ui'
import EndpointIcon from 'images/dashboard/navBar/devices-icon.svg'
import UserDevicesIcon from 'images/profileManagement/deviceIcons/user-devices.svg'

interface SelectedIcon extends DropdownItemData {
  key: string
}

export const iconsMapping = {
  'mobile-ios': 'iOS',
  'mobile-android': 'Android',
  'desktop-windows': 'Windows',
  'desktop-mac': 'MacOS',
  'desktop-mac-tv': 'Apple TV',
  'desktop-linux': 'Linux',
  default: 'Device',
  router: 'Router',
  tv: 'TV',
  'fire-tv': 'Fire TV',
  'mobile-android-tv': 'Android TV',
  'browser-chrome': 'Chrome',
  'browser-firefox': 'Firefox',
  'browser-brave': 'Brave',
  'browser-edge': 'Edge',
  'browser-other': 'Other',
}

const dropdownItemIconStyle = {
  height: '1.6rem',
  width: '2.4rem',
}

export const getIconDropDownItem = (iconKey: string): SelectedIcon => ({
  key: iconKey,
  text: iconsMapping[iconKey] || '',
  Icon: DeviceIcons[iconKey],
  dropdownItemIconStyle,
})
// this is what the backend expects when reseting to the default device icon
export const defaultIconName = 'default'

const DeviceIcons = {
  all: EndpointIcon,
  user: UserDevicesIcon,
  'mobile-ios': iOSIcon,
  'mobile-android': AndroidIcon,
  'desktop-windows': WindowsIcon,
  'desktop-mac': MacOSIcon,
  'desktop-linux': LinuxIcon,
  default: DeviceIcon,
  router: RouterIcon,
  tv: TVIcon,
  'tv-firetv': FireTVIcon,
  'tv-apple': AppleTVIcon,
  'tv-android': AndroidTVIcon,
  'tv-samsung': SamsungTVIcon,
  'browser-chrome': ChromeIcon,
  'browser-firefox': FirefoxIcon,
  'browser-edge': EdgeIcon,
  'browser-brave': BraveIcon,
  'browser-other': OtherIcon,
  'router-openwrt': OpenWRTIcon,
  'router-ubiquiti': UbiquitiIcon,
  'router-asus': AsusIcon,
  'router-ddwrt': DDWRTIcon,
  'router-firewalla': FireWalla,
  'router-freshtomato': FreshTomato,
  'router-glinet': GlInet,
  'router-synology': SynologyIcon,
  'router-pfsense': PfSense,
  'router-opnsense': OPNSense,
  'router-linux': LinuxServerIcon,
  'router-windows': WindowsServerIcon,
}

export default DeviceIcons
