import {
  baseApi,
  getQueryArgs,
  throwErrorFromResponseData,
  transformErrorResponse,
} from 'store/api'
import {
  PostGroupsBody,
  PutGroupBody,
  ImportJsonBody,
  GroupsResponse,
  PostGroupsResponse,
  GroupJsonResponse,
} from './groups.interface'
import { ApiResponse } from '../http'

export const replaceEmptyViaInRequestBody = <T>(
  requestBody: T & { via?: string; via_v6?: string },
): T => {
  const body = {
    ...requestBody,
  }

  if (body?.via === '') {
    body.via = '-1'
  }

  if (body?.via_v6 === '') {
    body.via_v6 = '-1'
  }

  return body
}

export const groupApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getGroups: builder.query({
      query: ({
        profileId,
        ignoreImpersonation = false,
      }: {
        profileId: string | number
        ignoreImpersonation?: boolean
      }) => {
        const queryArgs = getQueryArgs(`/profiles/${profileId}/groups`, 'GET')
        if (ignoreImpersonation) {
          queryArgs.headers = undefined
        }
        return queryArgs
      },
      transformResponse: (response: GroupsResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      providesTags: ['Group'],
    }),
    postGroup: builder.mutation({
      query: ({ body, profileId }: { body: PostGroupsBody; profileId: string }) =>
        getQueryArgs(
          `/profiles/${profileId}/groups`,
          'POST',
          replaceEmptyViaInRequestBody<PostGroupsBody>(body),
        ),
      transformResponse: (response: PostGroupsResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      async onQueryStarted({ profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            groupApi.util.updateQueryData('getGroups', { profileId }, draft => {
              draft.groups.push(...data.groups)
              draft.groups?.sort((a, b) => {
                if (a.group.toString() < b.group.toString()) {
                  return -1
                }
                if (a.group.toString() > b.group.toString()) {
                  return 1
                }
                return 0
              })
            }),
          )
        } catch {}
      },
    }),
    putGroup: builder.mutation({
      query: ({
        group,
        body,
        profileId,
      }: {
        group: number
        body: PutGroupBody
        profileId: string
      }) => getQueryArgs(`/profiles/${profileId}/groups/${group}`, 'PUT', body),
      transformResponse: (response: PostGroupsResponse) => {
        throwErrorFromResponseData(response)

        return response.body
      },
      transformErrorResponse,
      async onQueryStarted({ profileId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            groupApi.util.updateQueryData('getGroups', { profileId }, draft => {
              const updatedGroup = data.groups[0]

              const currentGroupIndex = draft.groups.findIndex(f => f.PK === updatedGroup.PK)
              draft.groups[currentGroupIndex] = updatedGroup
            }),
          )
        } catch {}
      },
    }),
    deleteGroup: builder.mutation({
      query: ({ profileId, group }: { profileId: string; group: number }) =>
        getQueryArgs(`/profiles/${profileId}/groups/${group}`, 'DELETE'),
      transformResponse: (response: PostGroupsResponse) => {
        return response.body
      },
      transformErrorResponse,
      async onQueryStarted({ profileId, group }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            groupApi.util.updateQueryData('getGroups', { profileId }, draft => {
              draft.groups = draft.groups.filter(x => x.PK !== group)
            }),
          )
        } catch {}
      },
    }),
    getJsonGroup: builder.query({
      query: ({ profileId, group }: { profileId: string; group: number }) =>
        getQueryArgs(`/profiles/${profileId}/groups/${group}/export/json`, 'GET'),
      transformResponse: (response: GroupJsonResponse) => {
        return response
      },
      transformErrorResponse,
    }),
    postJsonGroup: builder.mutation({
      query: ({ body, profileId }: { body: ImportJsonBody; profileId: string }) =>
        getQueryArgs(`/profiles/${profileId}/groups/import`, 'POST', body),
      transformResponse: (response: ApiResponse<ImportJsonBody>) => {
        return response.body
      },
      transformErrorResponse,
    }),
  }),
})

export const {
  useGetGroupsQuery,
  usePostGroupMutation,
  usePutGroupMutation,
  useDeleteGroupMutation,
  useLazyGetJsonGroupQuery,
  usePostJsonGroupMutation,
} = groupApi
