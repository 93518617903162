import React, { ReactElement } from 'react'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import WhatAreCustomRulesImage from 'images/tutorialFlows/CustomRules/custom-rules-slide.svg'
import { Text } from 'theme-ui'
import { CONTROLDText } from 'ui/CONTROLD'

export function CustomRulesSlide(): ReactElement {
  return (
    <TutorialBaseSlide
      Illustration={WhatAreCustomRulesImage}
      description={
        <Text>
          The <Highlight svg={tutorialSlideIcons.customRules}>Custom Rules</Highlight> section gives
          you highly specific control over domain names and TLDs. <br /> <br /> Override
          authoritative DNS records to <Highlight svg={tutorialSlideIcons.block}>Block</Highlight>,{' '}
          <Highlight svg={tutorialSlideIcons.redirect}>Redirect</Highlight> (spoofing to a different
          IP or redirecting via one of {CONTROLDText}&apos;s proxies), or{' '}
          <Highlight svg={tutorialSlideIcons.bypass}>Bypass</Highlight> any domain to resolve
          normally.
          <br /> <br /> You can group{' '}
          <Highlight svg={tutorialSlideIcons.customRules}>Custom Rules</Highlight> into Folders to
          keep things tidy and optionally assign rules to whole Folders.
        </Text>
      }
    />
  )
}
