import React, { ReactElement } from 'react'
import { Spinner, ThemeUIStyleObject } from 'theme-ui'
import { ButtonProps, Button } from 'ui'

export default function ButtonWithLoadingState({
  isLoading = false,
  children,
  disabled,
  variant = 'secondary',
  sxSpinner,
  ...rest
}: ButtonProps & {
  isLoading?: boolean
  sxSpinner?: ThemeUIStyleObject
}): ReactElement {
  return (
    <Button disabled={isLoading || disabled} variant={variant} {...rest}>
      {isLoading ? (
        <Spinner
          data-testid="button-loading-spinner"
          color="white"
          sx={{ width: '3.2rem', height: '3.2rem', ...sxSpinner }}
        />
      ) : (
        children
      )}
    </Button>
  )
}
