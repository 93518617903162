import { useAppDispatch } from 'store/hooks'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { setShouldShowBarry } from 'store/persist'
import { setIsMobileNavMenuOpen } from 'store/dashboard/dashboard'
import { useRef } from 'react'

const useHandleOpenBarry = (): (() => void) => {
  const dispatch = useAppDispatch()
  const timeoutId = useRef<NodeJS.Timeout | undefined>()
  const isMobile = useBreakpointIndex() === 0

  const handleOpenBarry = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    dispatch(setShouldShowBarry(true))
    if (isMobile) {
      dispatch(setIsMobileNavMenuOpen?.(false))
    }
    timeoutId.current = setTimeout(() => {
      dispatch(setShouldShowBarry(false))
    }, 500)
  }

  return handleOpenBarry
}

export default useHandleOpenBarry
