import React, { ReactElement, ReactNode } from 'react'
import { Flex } from 'theme-ui'

interface FeaturesProps {
  children: ReactNode
  tabIndex?: number
}
function Features({ children }: FeaturesProps): ReactElement {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {children}
    </Flex>
  )
}
export default Features
