import React, { ReactElement } from 'react'
import { Button, Svg, useAlertPresenter } from 'ui'
import useQueryString from 'utils/useQueryString'
import CloseIcon from 'images/menu-close.svg'
import { ThemeUIStyleObject } from 'theme-ui'
import useStepHelper from 'components/SetupGuide/useStepHelper'

export default function CloseButton({
  sx,
  onClick,
  dataTestId,
}: {
  sx?: ThemeUIStyleObject
  dataTestId?: string
  onClick?: () => void
}): ReactElement {
  const { qs } = useQueryString()
  const { closePane } = useStepHelper()
  const { dismissAlert } = useAlertPresenter()
  return (
    <Button
      variant="simple"
      aria-label="close dialog"
      sx={{
        p: 0,
        ml: '1.6rem',
        border: 0,
        textAlign: 'center',
        justifyContent: 'center',
        width: '2.4rem',
        height: '2.4rem',
        '&:hover > svg path': {
          fill: 'white',
        },
        ':hover': {
          backgroundColor: 'white25',
        },
        flexShrink: 0,
        ...sx,
      }}
      onClick={
        onClick
          ? onClick
          : (): void => {
              dismissAlert('troubleshooting')
              if (qs.helpPane) {
                closePane()
              }
            }
      }
      data-testid={dataTestId ?? 'close-button'}
    >
      <Svg svg={CloseIcon} fill="white50" />
    </Button>
  )
}
