import { Setter } from 'utils'
import { toggleTwoFa, TwoFactorStep } from 'store/twofa'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Flex, Spinner, Text } from 'theme-ui'
import { Button, Input } from 'ui'
import { LoadingState } from 'store/fetchingLogic'
import useGetUser from 'components/Dashboard/utils/useGetUser'

export default function AccountPasswordStep({
  setStep,
}: {
  setStep: Setter<TwoFactorStep>
}): React.ReactElement {
  const dispatch = useAppDispatch()
  const twofa = useAppSelector(s => s.twofa)
  const [password, setPassword] = useState('')
  const { data: userData } = useGetUser()

  return (
    <Flex
      as="form"
      onSubmit={(e): void => {
        e.preventDefault()
        dispatch(
          toggleTwoFa({
            password,
            setTwofaStep: setStep,
            closeEditing: () => {},
            sessionTwofa: userData?.twofa,
          }),
        )
      }}
      sx={{
        width: '100%',
        flexDirection: 'column',
        gap: ['2.4rem', '4.8rem'],
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Text sx={{ maxWidth: '43.8rem', fontSize: ['1.4rem', '1.6rem'], color: 'white50' }}>
        2FA is required to join this organization. To enable, please enter your account password
        first.
      </Text>
      <Flex
        sx={{
          width: '100%',
          gap: '2.4rem',
          alignItems: 'flex-end',
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <Input
          data-testid="confirm-two-factor-password"
          placeholder="Current Password"
          aria-label="current password"
          autoComplete="current-password"
          type="password"
          variant="primary"
          id="confirm-two-factor-password"
          label="Password"
          name="password"
          value={password}
          activeLabelColor="white"
          onChange={e => setPassword(e.target.value)}
          sx={{
            border: 'none',
            borderRadius: '59px',
            height: '5.4rem',
            flexShrink: 0,
          }}
          containerStyle={{
            width: ['100%', '100%', 'auto'],
          }}
        />
        <Button
          type="submit"
          sx={{
            width: ['100%', '100%', '17.4rem'],
            minWidth: '15.2rem',
            height: ['4.8rem', '5.4rem'],
            fontSize: ['1.8rem', '2.1rem'],
            fontWeight: 'bold',
            borderRadius: '59px',
            border: 'none',
            backgroundColor: 'plum',
            color: 'white',
          }}
          variant="secondary"
          data-testid="confirm-password-button"
        >
          {twofa.loading !== LoadingState.PENDING && 'Continue'}
          {twofa.loading === LoadingState.PENDING && (
            <Spinner
              color="white"
              sx={{
                height: '2.1rem',
              }}
            />
          )}
        </Button>
      </Flex>
    </Flex>
  )
}
