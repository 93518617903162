import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import { QueriesCardLoaderTabletAndDesktop } from './QueriesCardLoader.tabletAndDesktop'
import { QueriesCardLoaderMobile } from './QueriesCardLoader.mobile'

export function QueriesCardBox(): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        p: '1.6rem',
      }}
    >
      <QueriesCardLoaderMobile />
      <QueriesCardLoaderTabletAndDesktop />
    </Flex>
  )
}
