import React, { ReactElement } from 'react'
import { Flex } from 'theme-ui'
import LogoMenu from './LogoMenu'
import NavBarBottomContent from 'components/Dashboard/NavBar/NavBarBottomContent'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import { useAppSelector } from 'store/hooks'

export default function NavBarHeader(): ReactElement {
  const isNavBarExpanded = useAppSelector(s => s.dashboard.isNavBarExpanded)
  const isDesktop = useBreakpointIndex() === 2

  return (
    <Flex
      data-testid="nav-bar-header"
      sx={{
        position: 'relative',
        mb: '2.4rem', // todo: ask Danny if we can change this value for org + non org or if its meant to be only org
        height: '4.8rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0,
      }}
      className={isNavBarExpanded && isDesktop ? 'centered-header-tippy desktop' : ''}
    >
      <LogoMenu />
      {isNavBarExpanded && isDesktop && <NavBarBottomContent />}
    </Flex>
  )
}
