import React, { ReactElement, useMemo, useState, useRef } from 'react'
import { Flex, Text } from 'theme-ui'
import {
  Dropdown,
  IconButtonContent,
  SimpleActionMenu,
  SimpleActionMenuItemProps,
  Svg,
  useAlertPresenter,
} from 'ui'
import { useAppDispatch } from 'store/hooks'
import OptionsMenuIcon from 'images/profileManagement/options-menu-icon.svg'
import useQueryString from 'utils/useQueryString'
import { setSelectProvisionPk } from 'store/organization/organization'
import InstructionIcon from 'images/organization/instruction-icon.svg'
import EmailIcon from 'images/organization/email-icon.svg'
import InvalidateCodeIcon from 'images/organization/invalidate-code-icon.svg'
import DeleteIcon from 'images/delete-icon.svg'
import { useDeleteProvisionMutation, usePutProvisionInvalidateMutation } from 'store/api/provision'
import { ProvisionDialogType } from '../../index'
import ErrorIcon from 'images/erroricon.svg'

const deleteAlertId = 'delete-alert'

export default function ProvisionOptionsMenu({
  provisionPk,
}: {
  provisionPk: string
}): ReactElement {
  const dispatch = useAppDispatch()
  const { nav, qs } = useQueryString()
  const { presentAlert, dismissAlert } = useAlertPresenter()
  const [putProvisionInvalidateCode] = usePutProvisionInvalidateMutation()
  const [deleteProvision] = useDeleteProvisionMutation()

  const options: SimpleActionMenuItemProps[] = useMemo(() => {
    const options: SimpleActionMenuItemProps[] = [
      {
        children: (
          <IconButtonContent startIcon={EmailIcon}>
            <Text sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '26.4rem' }}>
              Email Code
            </Text>
          </IconButtonContent>
        ),
        onClick: () => {
          dispatch(setSelectProvisionPk(provisionPk))
          nav({ ...qs, provisionDialog: ProvisionDialogType.EMAIL })
        },
      },
      {
        children: (
          <IconButtonContent startIcon={InvalidateCodeIcon}>
            <Text sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '26.4rem' }}>
              Invalidate Code
            </Text>
          </IconButtonContent>
        ),
        onClick: () => {
          putProvisionInvalidateCode({ pk: provisionPk })
        },
      },
      {
        children: (
          <IconButtonContent startIcon={DeleteIcon}>
            <Text sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '26.4rem' }}>
              Delete Code
            </Text>
          </IconButtonContent>
        ),
        onClick: () => {
          presentAlert(
            {
              message: `Deleting the provisioning code will prevent onboarding of more devices via RMM that use this code.
               Are you sure you want to do this?`,
              variant: 'primary',
              isSticky: true,
              shouldDismissOnClickOutside: true,
              icon: ErrorIcon,
              buttons: [
                {
                  onClick: () => {
                    deleteProvision({ pk: provisionPk })
                    dismissAlert(deleteAlertId)
                  },
                  text: 'Yes',
                },
                {
                  onClick: () => {
                    dismissAlert(deleteAlertId)
                  },
                  text: 'No',
                },
              ],
            },
            deleteAlertId,
          )
        },
      },
      {
        children: (
          <IconButtonContent startIcon={InstructionIcon}>
            <Text sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '26.4rem' }}>
              Provisioning Instructions
            </Text>
          </IconButtonContent>
        ),
        onClick: () => {
          dispatch(setSelectProvisionPk(provisionPk))
          nav({
            ...qs,
            provisionDialog: ProvisionDialogType.INSTRUCTIONS,
          })
        },
      },
    ]

    return options
  }, [
    dispatch,
    provisionPk,
    nav,
    qs,
    putProvisionInvalidateCode,
    presentAlert,
    deleteProvision,
    dismissAlert,
  ])

  const [isOpen, setIsOpen] = useState(false)
  const hideRef = useRef<() => void>()

  return (
    <Dropdown
      data-testid="provision-options-dropdown-button"
      variant="simple"
      sx={{ p: 0 }}
      tippyprops={{
        appendTo: 'parent',
        animation: 'fade',
        duration: 300,
        onCreate: instance => {
          hideRef.current = () => {
            instance.hide()
          }
        },
        onShow: () => {
          setIsOpen(true)
        },
        onHidden: () => {
          setIsOpen(false)
        },
        theme: 'screen',
      }}
      dropdowncontent={
        <Flex
          data-testid="provision-options-menu"
          sx={{
            width: ['100%', '28.8rem'],
            maxHeight: '35rem',
            overflow: 'auto',
            flexDirection: 'column',
            backgroundColor: 'darkItemBG',
            borderRadius: '1.6rem',
            my: '0.8rem',
            opacity: 1,
          }}
          className="show-scrollbar"
        >
          <SimpleActionMenu
            hideRef={hideRef}
            items={options}
            sxContainer={{ pt: 0, px: '1.6rem' }}
            sxButton={{
              maxHeight: 'auto',
              borderRadius: '0.8rem',
              m: 0,
            }}
          />
        </Flex>
      }
    >
      <Svg
        svg={OptionsMenuIcon}
        fill={isOpen ? 'white' : 'white50'}
        sx={{
          width: '3.2rem',
          height: '3.2rem',
          '&:hover path': {
            fill: 'white',
          },
        }}
      />
    </Dropdown>
  )
}
