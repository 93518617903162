import React, { ReactElement } from 'react'
import { Flex, Text } from '@theme-ui/components'
import { Button, Svg } from 'ui'
import LogoIcon from 'images/dashboard/billing/logo-icon.svg'
import { navigate } from 'gatsby'

export default function UpgradeOrganizationPane({
  isSubscriptionActive,
  trialEnd,
  isExpired,
}: {
  isSubscriptionActive: boolean
  trialEnd?: string
  isExpired?: boolean
}): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        minHeight: '5.4rem',
        flexDirection: ['column', 'row'],
        position: 'relative',
        borderRadius: '1.6rem',
        border: '1px solid',
        borderColor: 'white17',
        backgroundColor: 'plum',
        overflow: 'hidden',
        alignItems: 'center',
        gap: '1.2rem',
        pl: '2.4rem',
        pr: ['2.4rem', '0.8rem'],
        py: ['1.6rem', '0.4rem'],
      }}
    >
      <Svg svg={LogoIcon} sx={{ position: 'absolute', bottom: '-0.2rem', left: 0 }} />
      <Flex
        sx={{
          width: '100%',
          gap: [0, 0, '1.6rem'],
          alignItems: ['flex-start', 'flex-start', 'center'],
          flexDirection: ['column', 'column', 'row'],
        }}
      >
        <Text sx={{ fontSize: '1.8rem', fontWeight: 700, color: 'white' }}>
          {isExpired ? 'Your Trial Has Expired' : `Trial Expires on ${trialEnd}`}
        </Text>
        <Text
          sx={{
            fontSize: ['1.4rem', '1.4rem', '1.6rem'],
            fontWeight: 500,
            color: 'white',
            opacity: 0.6,
          }}
        >
          {isSubscriptionActive
            ? 'You will be billed on this date.'
            : isExpired
            ? 'Please add a method of payment to contnue using Control D.'
            : 'Add payment method to keep your account active.'}
        </Text>
      </Flex>
      {!isSubscriptionActive && (
        <Button
          variant="transparent"
          sx={{
            width: ['100%', 'fit-content'],
            flexShrink: 0,
            fontSize: '1.6rem',
            fontWeight: 700,
            px: '1.6rem',
            backgroundColor: 'title',
            borderRadius: '10rem',
            color: 'black',
            zIndex: 'zIndex9',
          }}
          onClick={() => {
            navigate('/change-payment-method')
          }}
        >
          Add Payment Method
        </Button>
      )}
    </Flex>
  )
}
