import React, { useCallback, useRef, useEffect, useState } from 'react'
import { SwitchProps } from './index'
import { Button, Box, Spinner, Text } from 'theme-ui'

export default function Switch(props: SwitchProps): React.ReactElement {
  const {
    checked,
    onClick,
    leftIcon,
    leftLabel,
    rightIcon,
    rightLabel,
    sxLeftLabel,
    sxRightLabel,
    ariaLabelledby,
    ariaLabel,
    sx,
    hasHapticFeedback = false,
    isLoading,
    isDisabled,
    sxSwitchToggle,
    isDefaultSwitchToggle = true,
    sxLeftLabelAndIconContainer,
    sxRightLabelAndIconContainer,
    sxSpinner,
    checkedColor = null,
    unCheckedColor = 'darkBodyBG',
    needSaveFocus,
    ...attrs
  } = props

  const [wasFocused, setWasFocused] = useState(false)

  const ref = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    // adding focus on isLoading changes
    if (needSaveFocus && wasFocused) {
      ref.current?.focus()
    }
  }, [isLoading, needSaveFocus, wasFocused])

  const memoizedOnClick = useCallback(
    event => {
      if (hasHapticFeedback && window.navigator.vibrate) {
        try {
          window.navigator.vibrate(10)
        } catch (err) {}
      }

      onClick(event)
    },
    [hasHapticFeedback, onClick],
  )

  return (
    <Button
      variant="switch"
      onFocus={() => setWasFocused(true)}
      sx={{
        backgroundColor: checked ? checkedColor ?? null : unCheckedColor,
        minHeight: '3.2rem',
        height: '3.2rem',
        width: '5.8rem',
        borderRadius: '45px',
        overflow: 'hidden',
        flexShrink: 0,
        pointerEvents: 'visible',
        cursor: isLoading || isDisabled ? 'auto' : 'pointer',
        ...sx,
      }}
      onClick={memoizedOnClick}
      data-testid="switch-base"
      role="checkbox"
      aria-checked={!!checked}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledby}
      tabIndex={0}
      type="button" // to prevent getting triggered by enter inside a form
      {...attrs}
      disabled={isLoading || isDisabled}
      ref={ref}
    >
      {(leftIcon || leftLabel) && (!isLoading || checked) && (
        <Box
          sx={{
            position: 'absolute',
            left: '0.5rem',
            top: 1,
            fontSize: 2,
            fill: 'black',
            ...sxLeftLabelAndIconContainer,
          }}
        >
          <Text
            sx={{
              ...sxLeftLabel,
            }}
          >
            {leftLabel}
          </Text>
          {leftIcon}
        </Box>
      )}
      {(rightIcon || rightLabel) && (!isLoading || !checked) && (
        <Box
          sx={{
            position: 'absolute',
            right: '0.6rem',
            top: 1,
            fontSize: 2,
            fill: 'black',
            ...sxRightLabelAndIconContainer,
          }}
        >
          <Text
            sx={{
              ...sxRightLabel,
            }}
          >
            {rightLabel}
          </Text>
          {rightIcon}
        </Box>
      )}
      <Box
        className={isDefaultSwitchToggle ? 'switch-toggle' : ''}
        aria-hidden="true"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: checked ? 'darkItemBG' : 'white',
          minHeight: '2.6rem',
          minWidth: '2.6rem',
          transform: checked ? 'translate(2.8rem)' : 'translate(0.3rem)',
          ...sxSwitchToggle,
        }}
      >
        {isLoading && (
          <Spinner
            data-testid="loading-spinner"
            color={checked ? 'white' : 'darkItemBG'}
            sx={{
              height: '2.0rem',
              width: '2.0rem',
              ...sxSpinner,
            }}
          />
        )}
      </Box>
    </Button>
  )
}
