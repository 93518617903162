import React, { useState } from 'react'
import { Flex, IconButton } from 'theme-ui'
import { Svg } from 'ui'
import MoreIcon from 'images/ui/more-icon.svg'
import CloseIcon from 'images/ui/more-menu-close-icon.svg'
import { ListItemActionMenuProps } from './ListItemActionMenu.interface'
import {
  largeViewItemHeight,
  smallViewItemHeight,
} from 'components/Dashboard/Profiles/CustomRules/Views/Group'
import { useAppSelector } from 'store/hooks'

export default function ListItemActionMenu({
  children,
  menuBackgroundColor = 'darkItemBG',
  isShowMoreButtonDisabled = false,
  shouldShowListActionsMenu,
  setShouldShowListActionsMenu,
  blockReadMoreTabFocus,
  isCustomRule,
}: ListItemActionMenuProps): React.ReactElement {
  const [showActionMenuInner, setShowActionMenuInner] = useState(false)
  const showActionMenu = shouldShowListActionsMenu ?? showActionMenuInner
  const setShowActionMenu = setShouldShowListActionsMenu ?? setShowActionMenuInner
  const shouldShowLargeView = useAppSelector(s => s.customRules.shouldShowLargeView)

  return (
    <Flex
      onBlur={(event): void => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowActionMenu(false)
        }
      }}
      sx={{
        display: ['flex', 'flex', 'none'],
        flexShrink: 0,
        alignItems: [shouldShowLargeView ? 'flex-start' : 'center', 'center'],
        height: [
          '100%',
          '100%',
          `calc(${shouldShowLargeView ? largeViewItemHeight : smallViewItemHeight})`,
        ],
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            transition: 'transform 0.4s ease-in-out',
            transform: `translateX(${showActionMenu ? 50 : 150}%)`,
            position: 'absolute',
            height: '100%',
            width: ['30rem', '50rem'],
            right: 0,
          }}
        >
          <Flex
            sx={{
              maskImage: `linear-gradient(
               to right,
               rgba(0, 0, 0, 1),
               rgba(0, 0, 0, 0)
           )`,
              position: 'absolute',
              zIndex: 'zIndex650',
              height: '100%',
              backgroundColor: menuBackgroundColor,
              transform: `rotate(180deg) translateX(100%)`,
              width: '25%',
              opacity: showActionMenu ? 1 : 0,
              transition: `opacity 0.2s ease`,
              alignItems: 'center',
            }}
          />
          <Flex
            sx={{
              display: showActionMenu ? 'flex' : 'none',
              width: '100%',
              mr: 'calc(50% - 3.2rem)',
              alignItems: 'center',
              outline: 'none',
              backgroundColor: menuBackgroundColor,
              justifyContent: 'flex-end',
            }}
            data-testid="list-item-action-menu-children"
            aria-hidden={!showActionMenu}
          >
            {children}
            <IconButton
              data-testid="close-show-more-button"
              aria-label="Close Show More"
              onClick={(e): void => {
                e.stopPropagation()
                setShowActionMenu(false)
              }}
              tabIndex={showActionMenu ? 0 : -1}
            >
              <Svg
                fill="white50"
                svg={CloseIcon}
                sx={{
                  width: '2.4rem',
                  height: '2.4rem',
                }}
              />
            </IconButton>
          </Flex>
        </Flex>
      </Flex>
      <IconButton
        data-testid="show-more-button"
        aria-label="Show more"
        as="button"
        onClick={(e): void => {
          e.stopPropagation()
          setShowActionMenu(true)
        }}
        sx={{
          flexShrink: 0,
          mr: -2,
          mt: [shouldShowLargeView && isCustomRule ? '3.8rem' : 0, 0],
          ':disabled': {
            backgroundColor: 'transparent',
          },
        }}
        disabled={isShowMoreButtonDisabled}
        tabIndex={blockReadMoreTabFocus || showActionMenu ? -1 : 0}
      >
        <Svg
          fill={isShowMoreButtonDisabled ? 'accent' : 'muted'}
          svg={MoreIcon}
          sx={{
            width: '2.4rem',
            height: '2.4rem',
          }}
        />
      </IconButton>
    </Flex>
  )
}
