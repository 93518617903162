import { baseApi, getQueryArgs, transformErrorResponse } from 'store/api'
import { ApiResponse } from 'store/api/http'
import { EnabledStatus } from 'store/api/rules'

export interface IPData {
  city: string
  country: string
  ip: string
  isp: string
  ts: number
}

export interface AccessData {
  ips: IPData[]
  ddns: {
    status: EnabledStatus
    hostname?: string
  }
}

export type AccessResponse = ApiResponse<AccessData>

export const accessApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAccess: builder.query({
      query: ({ deviceId }: { deviceId: string }) =>
        getQueryArgs(`/access?device_id=${deviceId}`, 'GET'),
      transformResponse: (response: AccessResponse): AccessData => response.body,
      transformErrorResponse,
      providesTags: ['Access'],
    }),
    deleteIp: builder.mutation({
      query: ({ ip, deviceId }: { ip: string; deviceId: string }) =>
        getQueryArgs(`/access/${ip}?device_id=${deviceId}`, 'DELETE'),
      transformResponse: (response: AccessResponse): AccessData => response.body,
      transformErrorResponse,
      async onQueryStarted({ ip, deviceId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            accessApi.util.updateQueryData('getAccess', { deviceId }, draft => {
              draft.ips = draft.ips.filter(draftIp => draftIp.ip !== ip)
            }),
          )
        } catch {}
      },
    }),
    addIp: builder.mutation({
      query: ({ ip, deviceId }: { ip: string; deviceId: string }) =>
        getQueryArgs(`/access?device_id=${deviceId}`, 'POST', { ips: [ip] }),
      // onQueryStarted can't be applied, because getting empty body instead of created ip obj from the server side
      transformResponse: (response: AccessResponse): boolean => response.success,
      transformErrorResponse,
      invalidatesTags: ['Access'],
    }),
    deleteIps: builder.mutation({
      query: ({ ips, deviceId }: { ips: string[]; deviceId: string }) =>
        getQueryArgs(`/access?device_id=${deviceId}`, 'DELETE', { ips }),
      transformResponse: (response: AccessResponse): AccessData => response.body,
      transformErrorResponse,
      async onQueryStarted({ ips, deviceId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled

          dispatch(
            accessApi.util.updateQueryData('getAccess', { deviceId }, draft => {
              draft.ips = draft.ips.filter(draftIp => !ips.includes(draftIp.ip))
            }),
          )
        } catch {}
      },
    }),
    updateDdns: builder.mutation({
      query: ({
        status,
        deviceId,
        hostname,
      }: {
        status: number
        deviceId: string
        hostname?: string
      }) => getQueryArgs(`/access/ddns?device_id=${deviceId}`, 'PUT', { status, hostname }),
      transformResponse: (response: AccessResponse): AccessData => response.body,
      transformErrorResponse,
      async onQueryStarted({ deviceId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(
            accessApi.util.updateQueryData('getAccess', { deviceId }, draft => {
              draft.ddns = data.ddns
            }),
          )
        } catch {}
      },
    }),
  }),
})

export const {
  useGetAccessQuery,
  useDeleteIpMutation,
  useDeleteIpsMutation,
  useAddIpMutation,
  useUpdateDdnsMutation,
} = accessApi
