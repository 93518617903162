import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import useQueryString from 'utils/useQueryString'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import omit from 'lodash/omit'
import { Flex } from 'theme-ui'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { DesktopAndTabletModal, MobileTray } from 'components/TrayOrModalDialog'
import { setSelectProvisionPk } from 'store/organization'
import { Input } from 'ui'
import { usePutProvisionEmailMutation } from 'store/api/provision'
import { ProvisionDialogType } from 'components/Organization/Provision'
import { ApiResponse } from 'store/api/http'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/ShowAlertOnModalOrTrayWrapper'

export default function EmailProvisioningTrayOrModalDialog(): ReactElement | null {
  const { qs, nav } = useQueryString()
  const breakpoint = useBreakpointIndex()
  const isMobile = breakpoint === 0
  const dispatch = useAppDispatch()

  const onClose = useCallback(() => {
    nav({ ...omit(qs, 'provisionDialog') })
  }, [qs, nav])

  useEffect(() => {
    return () => {
      dispatch(setSelectProvisionPk(''))
    }
  }, [dispatch, onClose])

  return (
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={!!qs.provisionDialog}>
      {isMobile ? (
        <MobileTray
          title="Email Code"
          dataTestId="email-provisioning-tray"
          onCloseClick={onClose}
          infoUrl="email-provisioning"
        >
          <EmailProvisioningView dismiss={onClose} />
        </MobileTray>
      ) : (
        <DesktopAndTabletModal
          title="Email Code"
          onCloseClick={onClose}
          dataTestId="email-provisioning-dialog"
          sxDialog={{ minHeight: '36.6rem' }}
          isOpen={qs.provisionDialog === ProvisionDialogType.EMAIL}
          infoUrl="email-provisioning"
        >
          <EmailProvisioningView dismiss={onClose} />
        </DesktopAndTabletModal>
      )}
    </ShowAlertOnModalOrTrayWrapper>
  )
}

function EmailProvisioningView({ dismiss }: { dismiss: () => void }): ReactElement {
  const [email, setEmail] = useState('')
  const emailRegex = RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [putProvisionEmail, { isLoading }] = usePutProvisionEmailMutation()
  const selectProvisionPk = useAppSelector(s => s.organization.selectProvisionPk || '')

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mt: ['2.4rem', '1.8rem'],
        px: ['1.6rem', '4.8rem'],
        pt: ['1.2rem', '2.4rem'],
        pb: '4.8rem',
      }}
    >
      <Input
        variant="new"
        sx={{
          mb: '2.4rem',
          height: ['4.8rem', '6.4rem'],
          alignSelf: 'end',
          borderRadius: '45px',
          p: '2.4rem',
          '& + label': {
            fontWeight: ['normal', 'bold'],
          },
          mt: '0.4rem',
        }}
        backgroundColorOnFocus="white25"
        activeLabelColor="white"
        color="white"
        label="Email"
        id="email"
        type="email"
        name="email"
        data-testid="email-provisioning-input"
        onChange={(e): void => {
          setEmail(e.target.value)

          if (e.target.value.match(emailRegex)?.length) {
            setIsEmailValid(true)
          } else {
            setIsEmailValid(false)
          }
        }}
      />
      <ButtonWithLoadingState
        disabled={!isEmailValid}
        data-testid="send-email-code-button"
        sx={{
          width: ['100%', '17rem'],
          height: '5.4rem',
          mt: '0.4rem',
          borderRadius: '59px',
          backgroundColor: 'plum',
          border: 'none',
          alignSelf: 'center',
          fontSize: '1.8rem',
          fontWeight: 'bold',
        }}
        isLoading={isLoading}
        onClick={async () => {
          const response = (await putProvisionEmail({
            pk: selectProvisionPk,
            body: { email },
          })) as ApiResponse

          if (!response.error) {
            dismiss()
          }
        }}
      >
        Send Code
      </ButtonWithLoadingState>
    </Flex>
  )
}
