import React, { ReactElement, useState } from 'react'
import { Accordion } from 'ui'

export default function AdvancedSettings({ children }: { children?: ReactElement }): ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Accordion
      id="advanced-settings"
      title="Advanced Settings"
      buttonVariant="simple"
      sxTitleContainer={{
        pr: '2.4rem',
      }}
      titleStyle={{
        color: isOpen ? 'white' : 'white50',
        textAlign: 'left',
      }}
      containerStyle={{
        width: '100%',
        gap: '1.6rem',
        '& > div:first-of-type': {
          width: '100%',
          px: ['1.6rem', '2.4rem'],
        },
        alignItems: 'center',
      }}
      buttonStyle={{
        width: '100%',
        justifyContent: 'space-between',
        pl: 0,
        pr: '0.8rem',
        py: 0,
        fontSize: '1.8rem',
        fontWeight: 'bold',
      }}
      svgStyle={{
        backgroundColor: 'transparent',
        flexShrink: 0,
        '& svg': {
          flexShrink: 0,
        },
      }}
      isOpenControlled={isOpen}
      setIsOpenControlled={setIsOpen}
    >
      {children}
    </Accordion>
  )
}
