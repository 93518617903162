import { RootState } from '../rootReducer'
import { BASE_URL } from 'gatsby-env-variables'
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { ControlDError } from './http'
import { SerializedError } from '@reduxjs/toolkit'
import * as errorCodes from './errorCodes'

export type ResponseType<T> = { data: T } & { error: ControlDError }

export const getQueryArgs = <T>(
  url: string,
  method?: string,
  body?: T,
): { url: string; method?: string; body?: T; headers?: Record<string, string> } => {
  const urlParams = new URLSearchParams(window.location.search)
  const subOrganization = urlParams.get('orgId') || ''

  return {
    url,
    method,
    body,
    // injecting the impersonation header here. This can be removed from the call if needed in the query
    ...(subOrganization ? { headers: { 'x-force-org-id': subOrganization } } : {}),
  }
}

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const token = (getState() as RootState).persistData?.sessionToken

      headers.set('content-type', 'application/json')
      if (token && endpoint !== 'getProxiesNoAuth') {
        headers.set('authorization', token)
      }
      return headers
    },
  }),
  tagTypes: [
    'Access',
    'Billing',
    'Tracking',
    'Notifications',
    'Network',
    'FreeDns',
    'FeaturedProfiles',
    'DefaultRule',
    'Payment',
    'Product',
    'Status',
    'TwoFa',
    'Token',
    'Proxies',
    'Organization',
    'Member',
    'SubOrg',
    'Provision',
    'Devices',
    'Schedules',
    'User',
    'Filters',
    'Services',
    'Analytics',
    'ActivityLog',
    'Profiles',
    'ProfileOptions',
    'CustomRules',
    'AllCustomRules',
    'Group',
    'Reports',
  ],
  endpoints: () => ({}),
})

export const transformErrorResponse = (
  baseQueryReturnValue: FetchBaseQueryError,
): { status: number } & ControlDError => {
  const errorData = baseQueryReturnValue.data as {
    error: ControlDError
  }

  return {
    status: baseQueryReturnValue.status as number,
    code: errorData?.error?.code || '',
    message: errorData?.error?.message || '',
    actions: errorData?.error?.actions || undefined,
  }
}

export const isExceptionalErrorCode = (errorCode?: string): boolean =>
  [errorCodes.ERROR_MISSING_REQUIRED, errorCodes.ERROR_PAY_ME].includes(errorCode ?? '')

export const throwErrorFromResponseData = (response: {
  body: unknown
  error?: SerializedError & ControlDError
  success: boolean
  message?: string
}): void => {
  if (response?.error || !response?.success) {
    const error = {
      ...response.error,
      code: response.error?.code?.toString() || '',
    }

    if (response.error?.actions) {
      throw JSON.stringify({
        ...error,
        actions: response.error?.actions,
      })
    } else {
      throw error
    }
  }

  const body = response?.body as { error?: SerializedError & ControlDError }

  if (isExceptionalErrorCode(body?.error?.code?.toString())) {
    return
  }

  if (body?.error) {
    if (body?.error?.code) {
      const error = {
        ...body?.error,
        code: body?.error?.code?.toString() || '',
      }

      if (body?.error?.actions) {
        throw JSON.stringify({
          ...error,
          actions: body?.error?.actions,
        })
      } else {
        throw error
      }
    }

    throw { message: body?.error?.message }
  }
}
