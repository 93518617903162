import React, { ReactElement, Reducer, useReducer } from 'react'
import { Flex, Text } from 'theme-ui'
import ProfileSelectionDropDown, {
  ProfileDropDownItem,
} from 'components/Dashboard/Devices/ProfileSelectionDropDown'
import DeviceTypeDropdown from './DeviceTypeDropdown'
import ExpiresDropdown, { ExpiresType } from './ExpiresDropdown'
import { Input, useCustomAlerts } from 'ui'
import IconWithTippyPopup from 'ui/IconWithTippyPopup'
import InfoIcon from 'images/infoicon.svg'
import { ButtonWithLoadingState } from 'components/ButtonWithLoadingState'
import {
  ActionType,
  initialState,
  InitialStateType,
  ProvisionActionType,
  provisionReducer,
} from './ProvisionState'
import {
  CreateProvisionRequestBody,
  PostProvisionResponse,
  ProvisionResponseData,
  useCreateProvisionMutation,
} from 'store/api/provision'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import moment from 'moment'
import { trayHeaderHeight } from 'components/TrayOrModalDialog/Tray.mobile'
import useQueryString from 'utils/useQueryString'
import { ProvisionDialogType } from 'components/Organization/Provision'
import { setSelectProvisionPk } from 'store/organization'
import { useAppDispatch } from 'store/hooks'
import { StatLevel } from 'store/api/analytics/analytics.interface'
import { useEditOrganizationMutation } from 'store/api/organization'
import useGetUserState from 'store/api/user/useGetUserState'
import { EnabledStatus } from 'store/api/rules'
import { isDeactivationPinLengthValid } from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/AddOrEditDevice/AddOrEditDevice/AddOrEditDeviceView'
import ProvisionSettings from './ProvisionSettings'
import useGetOrganization from 'components/Dashboard/utils/useGetOrganization'

const payloadProperties = ['icon', 'max', 'profile_id', 'stats', 'name_prefix', 'deactivation_pin']

export function numericalMask(value: string): string {
  return value.replace(/\D/g, '')
}

function prefixNameMask(value: string): string {
  return value.replace(/\W/g, '-')
}

const getRequestPayloadFromState = (state: InitialStateType) => {
  return {
    // create an object that contains only truthy values so that unmodified values are not included in the request payload
    ...pickBy(
      { ...pick(state, ...payloadProperties) },
      value => isString(value) || isNumber(value),
    ),
  }
}

const getRequestExpTs = label => {
  switch (label) {
    case ExpiresType.HOUR:
      return Math.floor(moment().add(1, 'hour').valueOf() / 1000)
    case ExpiresType.DAY:
      return Math.floor(moment().add(1, 'day').valueOf() / 1000)
    case ExpiresType.WEEK:
      return Math.floor(moment().add(1, 'week').valueOf() / 1000)
    case ExpiresType.MONTH:
      return Math.floor(moment().add(1, 'month').valueOf() / 1000)
    case ExpiresType.YEAR:
      return Math.floor(moment().add(1, 'year').valueOf() / 1000)
    case ExpiresType.NEVER:
      return 0
    default:
      return 0
  }
}

export function AddProvisionView({ dismiss }: { dismiss?: () => void }): ReactElement {
  const { qs, nav } = useQueryString()
  const dispatch = useAppDispatch()
  const { data: orgData } = useGetOrganization()
  const [createProvision, { isLoading }] = useCreateProvisionMutation()
  const { region: sessionRegion } = useGetUserState()
  const [editOrganization] = useEditOrganizationMutation()
  const [provisionState, provisionDispatch] = useReducer<Reducer<InitialStateType, ActionType>>(
    provisionReducer,
    {
      ...initialState,
    } as InitialStateType,
  )

  const { presentCautionOkAlert } = useCustomAlerts()
  const isDeactivationPinInvalid =
    provisionState.deactivationStatus === EnabledStatus.ENABLED &&
    !isDeactivationPinLengthValid(provisionState.deactivation_pin)

  return (
    <Flex
      sx={{
        pt: ['2.4rem', '4.8rem'],
        pb: '4rem',
        px: ['1.6rem', '4.8rem'],
        flexDirection: 'column',
        gap: '2.4rem',
        maxHeight: [`calc(100% - ${trayHeaderHeight})`, '100%', '100%'],
        overflowY: 'auto',
      }}
      className="show-scrollbar"
    >
      <Flex
        sx={{
          width: '100%',
          gap: '1.6rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
        }}
      >
        <DeviceTypeDropdown
          selectedType={provisionState.icon}
          setSelectedType={type => {
            provisionDispatch({ type: ProvisionActionType.DEVICE_TYPE, payload: type })
          }}
        />
        <ProfileSelectionDropDown
          label="Enforced Profile"
          selectedProfile={provisionState.selectedProfile}
          globalProfilePk={orgData?.organization?.parent_profile?.PK}
          setSelectedProfile={profile => {
            provisionDispatch({
              type: ProvisionActionType.SELECTED_PROFILE,
              payload: profile as ProfileDropDownItem | undefined,
            })
          }}
          description=""
          tooltipText="The Profile whose rules you want to enforce on this Endpoint"
          sx={{
            width: '100%',
            flex: 1,
            flexShrink: 0,
            p: 0,
          }}
        />
      </Flex>
      <Flex
        sx={{
          width: '100%',
          gap: '1.6rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
        }}
      >
        <ExpiresDropdown
          selectedDuration={provisionState.expired}
          setSelectedDuration={duration => {
            provisionDispatch({ type: ProvisionActionType.EXPIRED, payload: duration })
          }}
          tooltipText="Provisioning Code will be invalid after this time."
        />
        <Input
          name="limit-input"
          data-testid="limit-input"
          aria-label="limit input"
          label="Limit"
          activeLabelColor="white"
          value={provisionState.max}
          containerStyle={{
            width: '100%',
            flex: 1,
          }}
          rightContent={
            <Flex sx={{ gap: '0.8rem', alignItems: 'center' }}>
              <IconWithTippyPopup
                placement="top"
                content={
                  <Flex
                    sx={{
                      alignSelf: 'center',
                      width: '15.6rem',
                      textAlign: 'center',
                      fontSize: '1.2rem',
                    }}
                  >
                    Number of times this Provisioning Code can be used.
                  </Flex>
                }
                svg={InfoIcon}
                fill="white50"
                sx={{
                  width: '1.6rem',
                  height: '1.6rem',
                }}
              />
            </Flex>
          }
          sx={{
            borderRadius: '45px',
            alignItems: 'center',
            backgroundColor: 'white10',
          }}
          onChange={(event): void => {
            provisionDispatch({
              type: ProvisionActionType.LIMIT,
              payload: +numericalMask(event.target.value),
            })
          }}
        />
      </Flex>
      <Input
        name="device-prefix-input"
        data-testid="device-prefix-input"
        aria-label="device prefix input"
        label="Endpoint Name Prefix"
        activeLabelColor="white"
        value={provisionState.name_prefix}
        rightContent={
          <Flex sx={{ gap: '0.8rem', alignItems: 'center' }}>
            <Text sx={{ fontSize: '1.4rem', color: 'white50' }}>Optional</Text>
            <IconWithTippyPopup
              placement="top"
              content={
                <Flex
                  sx={{
                    alignSelf: 'center',
                    width: '15.6rem',
                    textAlign: 'center',
                    fontSize: '1.2rem',
                  }}
                >
                  Prefix for the name of all Endpoints created with this Provisioning Code (valid
                  characters: a-z, 0-9, -)
                </Flex>
              }
              svg={InfoIcon}
              fill="white50"
              sx={{
                width: '1.6rem',
                height: '1.6rem',
              }}
            />
          </Flex>
        }
        sx={{
          borderRadius: '45px',
          alignItems: 'center',
          backgroundColor: 'white10',
        }}
        onChange={(event): void => {
          provisionDispatch({
            type: ProvisionActionType.NAME_PREFIX,
            payload: prefixNameMask(event.target.value),
          })
        }}
      />
      <ProvisionSettings provisionState={provisionState} provisionDispatch={provisionDispatch} />

      <ButtonWithLoadingState
        disabled={
          !provisionState.selectedProfile ||
          !provisionState.expired ||
          !provisionState.icon ||
          !provisionState.max ||
          isDeactivationPinInvalid
        }
        data-testid="add-provision-code-button"
        isLoading={isLoading}
        sx={{
          width: ['100%', '21.6rem'],
          height: '5.4rem',
          mt: '1.6rem',
          borderRadius: '59px',
          backgroundColor: 'plum',
          border: 'none',
          alignSelf: 'center',
          fontSize: '1.8rem',
          fontWeight: 'bold',
        }}
        onClick={async () => {
          const isAnalyticsInvalid = !(
            provisionState.stats === StatLevel.NO ||
            sessionRegion ||
            provisionState.regionSettings
          )

          if (isAnalyticsInvalid) {
            presentCautionOkAlert('Please select a valid analytics region.')
            return
          }

          const requestDevice = {
            ...getRequestPayloadFromState(provisionState),
          } as unknown as CreateProvisionRequestBody

          requestDevice.ts_exp = getRequestExpTs(provisionState?.expired?.value || ExpiresType.DAY)

          const response = (await createProvision({
            body: requestDevice,
          })) as PostProvisionResponse & {
            data: ProvisionResponseData
          }

          if (provisionState.regionSettings && !qs.clientId) {
            editOrganization(provisionState.regionSettings)
          }

          if (!response.error) {
            dismiss?.()
            dispatch(setSelectProvisionPk(response.data.provision.PK))
            nav({ ...qs, provisionDialog: ProvisionDialogType.INSTRUCTIONS })
          }
        }}
      >
        Create Code
      </ButtonWithLoadingState>
    </Flex>
  )
}
