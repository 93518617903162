import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Input } from 'ui'
import { Flex } from 'theme-ui'
import PasswordInput from 'components/PasswordInput'
import { PasswordValidationState, validatePassword } from 'utils/passwordValidation'

export const EmailEdit = ({
  setIsFormValid,
}: {
  setIsFormValid: (isValid: boolean) => void
}): ReactElement => {
  const [currentPwd, setCurrentPwd] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const emailRegex = RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)

  useEffect(() => {
    // the password length is checked to be at least 7 since old passwords were allowed to be 7 characters long.
    setIsFormValid(!!email.match(emailRegex)?.length && currentPwd.length >= 7)
  }, [currentPwd, email, setIsFormValid, emailRegex])
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'column', 'row'],
        mb: [0, 0, '1.6rem'],
      }}
    >
      <Flex sx={{ flex: 1, mr: [0, 0, '1.6rem'], mb: ['1.6rem', '1.6rem', 0] }}>
        <Input
          data-testid="new-email"
          autoComplete="new-email"
          placeholder="Email"
          aria-label="email"
          variant="primary"
          id="edit-profile-email"
          label="Email"
          hideLabel
          name="email"
          type="email"
          backgroundColor="white10"
          sx={{
            borderRadius: '45px',
          }}
          onChange={(e): void => setEmail(e.target.value)}
        />
      </Flex>
      <Flex sx={{ width: '100%', flex: 1 }}>
        <PasswordInput
          data-testid="confirm-password"
          placeholder="Confirm Password"
          aria-label="confirm password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="confirm-password"
          variant="primary"
          id="edit-profile-conf-password"
          label="Confirm Password"
          hideLabel
          name="password"
          backgroundColor="white10"
          shouldShowShowPasswordButton
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          sx={{
            borderRadius: '45px',
          }}
          onChange={(e): void => setCurrentPwd(e.target.value)}
          sxShowPasswordStyle={{ top: '50%', transform: 'translate(0, -50%)' }}
        />
      </Flex>
    </Flex>
  )
}

export const PasswordEdit = ({
  setIsFormValid,
}: {
  setIsFormValid: (isValid: boolean) => void
}): ReactElement => {
  const [currentPwd, setCurrentPwd] = useState('')

  const passwordInputRef = useRef<HTMLInputElement>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [passwordHint, setPasswordHint] = useState('')
  const [passwordValidationState, setPasswordValidationState] = useState(
    PasswordValidationState.EMPTY,
  )
  useEffect(() => {
    setIsFormValid(passwordValidationState === PasswordValidationState.VALID)
  }, [currentPwd, passwordValidationState, setIsFormValid])
  return (
    <Flex
      sx={{
        flexDirection: ['column', 'column', 'row'],
      }}
    >
      <Flex
        sx={{
          flex: 1,
          mr: [0, 0, '1.6rem'],
          mb: ['1.6rem', '1.6rem', 0],
          alignItems: 'flex-start',
        }}
      >
        <Input
          data-testid="old-password"
          placeholder="Current Password"
          aria-label="current password"
          autoComplete="old-password"
          type="password"
          variant="primary"
          id="edit-profile-old-password"
          label="Current Password"
          hideLabel
          name="oldPassword"
          backgroundColor="white10"
          sx={{
            borderRadius: '45px',
          }}
          onChange={(e): void => setCurrentPwd(e.target.value)}
        />
      </Flex>
      <Flex sx={{ flex: 1, flexDirection: 'column' }}>
        <PasswordInput
          data-testid="new-password"
          variant="primary"
          placeholder="New Password"
          aria-label="new password"
          sx={{
            width: '100%',
            backgroundColor: 'white15',
            borderRadius: '45px',
          }}
          color="white"
          id="edit-profile-new-password"
          type={showPassword ? 'text' : 'password'}
          name="newPassword"
          backgroundColor="white10"
          onChange={(e): void => {
            const password = e.currentTarget.value
            const tempPasswordValidationState = validatePassword(password)
            setPasswordValidationState(tempPasswordValidationState)
            setPasswordHint(tempPasswordValidationState.valueOf())
          }}
          ref={passwordInputRef}
          shouldShowShowPasswordButton
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          sxShowPasswordStyle={{ top: '50%', transform: 'translate(0, -50%)' }}
        />
        <Flex
          sx={{
            fontSize: '1.4rem',
            py: '0.8rem',
          }}
          color={
            passwordValidationState !== PasswordValidationState.VALID ? 'pomegranate' : 'white50'
          }
        >
          {passwordHint}
        </Flex>
      </Flex>
    </Flex>
  )
}
