import React, { ReactElement } from 'react'
import { Text } from 'theme-ui'
import FiltersImage from 'images/tutorialFlows/Filters/filters-slide.svg'
import {
  TutorialBaseSlide,
  Highlight,
  tutorialSlideIcons,
} from 'components/Dashboard/Onboarding/TutorialBaseSlide'
import { CONTROLDText } from 'ui/CONTROLD'

export function FiltersSlide(): ReactElement {
  return (
    <TutorialBaseSlide
      Illustration={FiltersImage}
      description={
        <Text>
          When you toggle a <Highlight svg={tutorialSlideIcons.filters}>Filter</Highlight> on,{' '}
          {CONTROLDText} will <Highlight svg={tutorialSlideIcons.block}>Block</Highlight> all the
          websites and services that are part of the filtered category. <br /> <br /> Some{' '}
          <Highlight svg={tutorialSlideIcons.filters}>Filters</Highlight> have multiple settings
          that allow you to make them more or less strict. <br /> <br />
          If you still want to use a domain or service but it&apos;s blocked by a Filter, you can{' '}
          <Highlight svg={tutorialSlideIcons.bypass}>Bypass</Highlight> the service in the{' '}
          <Highlight svg={tutorialSlideIcons.services}>Services</Highlight> section, or create a{' '}
          <Highlight svg={tutorialSlideIcons.bypass}>Bypass</Highlight>{' '}
          <Highlight svg={tutorialSlideIcons.customRules}>Custom Rule</Highlight>.
        </Text>
      }
    />
  )
}
