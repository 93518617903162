import React, { Dispatch, ReactElement } from 'react'
import AnalyticsIcon from 'images/profileManagement/settings/analytics.svg'
import { ExternalLink } from 'ui'
import { DOCS_CONTROL_D_DOMAIN } from 'gatsby-env-variables'
import { Box, Divider, Flex } from 'theme-ui'
import AnalyticsDropdown from './SettingsDropdowns/AnalyticsDropdown'
import SettingsItem from './SettingsItem'
import RegionDropdown from './SettingsDropdowns/RegionDropdown'
import { ActionType, DeviceActionType } from './SettingsState'
import { StatLevel } from 'store/api/analytics/analytics.interface'

export default function AnalyticsSettingsItem({
  settingsStats,
  regionSettingsStats,
  settingsDispatch,
  shouldShowStorageRegionSelection,
}: {
  settingsStats?: StatLevel
  regionSettingsStats?: string
  settingsDispatch: Dispatch<ActionType>
  shouldShowStorageRegionSelection: boolean
}): ReactElement {
  return (
    <SettingsItem
      testId="analytics"
      icon={AnalyticsIcon}
      title="Analytics"
      description="Log DNS queries and generate activity reports."
      descriptionLink={
        <ExternalLink
          to={`${DOCS_CONTROL_D_DOMAIN}/docs/analytics-levels`}
          sx={{
            color: 'white50',
            cursor: 'pointer',
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
          }}
        >
          Learn more
        </ExternalLink>
      }
      actionContent={
        <Box
          data-testid="settings-analytics-dropdown-container"
          sx={{ position: 'relative', flexShrink: 0 }}
          className="reset-tooltip"
        >
          <AnalyticsDropdown
            deviceStats={settingsStats}
            settingsDispatch={settingsDispatch}
            boundaryDataTestId="settings-analytics-dropdown-container"
          />
        </Box>
      }
      expandedContent={
        !!settingsStats &&
        shouldShowStorageRegionSelection && (
          <Flex sx={{ width: '100%', flexDirection: 'column' }}>
            <Divider sx={{ width: '100%', color: 'white10' }} />
            <SettingsItem
              title="Storage Region"
              description="The region you select will be effective for every Endpoint on your account and can be changed in your Account settings."
              actionContent={
                <Box
                  data-testid="settings-region-dropdown-container"
                  sx={{ position: 'relative' }}
                  className="reset-tooltip"
                >
                  <RegionDropdown
                    region={regionSettingsStats}
                    setRegionSettings={payload => {
                      settingsDispatch({
                        type: DeviceActionType.REGION_SETTINGS,
                        payload,
                      })
                    }}
                    boundaryDataTestId="settings-region-dropdown-container"
                  />
                </Box>
              }
              sx={{
                backgroundColor: 'transparent',
                borderRadius: 'unset',
                boxShadow: 'none',
                pb: 0,
                '& > div:first-of-type': {
                  p: 0,
                },
              }}
            />
          </Flex>
        )
      }
    />
  )
}
