import React, { ReactElement } from 'react'
import { Box, Flex } from 'theme-ui'
import VerticalDivider from 'ui/VerticalDivider'
import { foregroundStyle } from 'components/Dashboard/PlaceholderElements'

export function QueriesCardLoaderTabletAndDesktop(): ReactElement {
  return (
    <Flex sx={{ display: ['none', 'flex'], height: '100%', justifyContent: 'space-between' }}>
      <Flex
        sx={{
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Flex sx={{ flexDirection: 'row', gap: '1.6rem', mb: '1.6rem' }}>
          <Box
            sx={{
              ...foregroundStyle,
              width: '4.9rem',
              height: '2.6rem',
            }}
          />
          <Box
            sx={{
              ...foregroundStyle,
              width: '12rem',
              height: '2.6rem',
            }}
          />
        </Flex>
        <Flex>
          <Box
            sx={{
              ...foregroundStyle,
              width: '2.4rem',
              height: '2.4rem',
              borderRadius: '50%',
            }}
          />
          <VerticalDivider sx={{ width: '1px', mx: '1.6rem' }} />
          <Box
            sx={{
              ...foregroundStyle,
              width: '2.4rem',
              height: '2.4rem',
              borderRadius: '50%',
            }}
          />
          <VerticalDivider sx={{ width: '1px', mx: '1.6rem' }} />
          <Box
            sx={{
              ...foregroundStyle,
              width: '2.4rem',
              height: '2.4rem',
              borderRadius: '50%',
            }}
          />
          <VerticalDivider sx={{ width: '1px', mx: '1.6rem' }} />
          <Box
            sx={{
              ...foregroundStyle,
              width: '8.7rem',
              height: '2.4rem',
              borderRadius: '1.6rem',
            }}
          />
          <VerticalDivider sx={{ width: '1px', mx: '1.6rem' }} />
          <Box
            sx={{
              ...foregroundStyle,
              width: '8.7rem',
              height: '2.4rem',
              borderRadius: '1.6rem',
            }}
          />
          <VerticalDivider sx={{ width: '1px', mx: '1.6rem' }} />
          <Box
            sx={{
              ...foregroundStyle,
              width: '8.7rem',
              height: '2.4rem',
              borderRadius: '1.6rem',
            }}
          />
        </Flex>
      </Flex>

      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            gap: '1.6rem',
            alignItems: 'flex-end',
          }}
        >
          <Box
            sx={{
              ...foregroundStyle,
              width: '20rem',
              height: '2.4rem',
            }}
          />
          <Box
            sx={{
              ...foregroundStyle,
              width: '2.4rem',
              height: '2.4rem',
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
