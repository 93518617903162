import React, { ReactElement, ReactNode } from 'react'
import { Flex, Text } from 'theme-ui'
import { Svg } from 'ui'
import CheckmarkIcon from 'images/checkmark.svg'

export interface DropdownItemProps {
  children: ReactNode
  icon?: ReactNode
  isSelected?: boolean
}
function DropdownItem({ children, icon, isSelected, ...props }: DropdownItemProps): ReactElement {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      {...props}
    >
      <Flex sx={{ alignItems: 'center' }}>
        {icon && (
          <Flex
            sx={{
              width: '2.4rem',
              height: '2.4rem',
              borderRadius: '50%',
              backgroundColor: 'white15',
              alignItems: 'center',
              justifyContent: 'center',
              mr: '1.6rem',
              flexShrink: 0,
              position: 'relative',
            }}
          >
            {icon}
          </Flex>
        )}
        <Text
          sx={{
            fontSize: '1.6rem',
            wordBreak: 'break-all',
            textAlign: 'left',
            pr: '2.4rem',
          }}
        >
          {children}
        </Text>
      </Flex>
      {isSelected && (
        <Svg svg={CheckmarkIcon} sx={{ flexShrink: 0, fill: 'white', opacity: 0.5 }} />
      )}
    </Flex>
  )
}
export default DropdownItem
