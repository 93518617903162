import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import PlatformView from 'components/Dashboard/Devices/DeviceTrayOrModalDialog/PlatformView'
import AddOrEditDeviceView from './AddOrEditDevice/AddOrEditDevice/AddOrEditDeviceView'
import useQueryString from 'utils/useQueryString'
import useBreakpointIndex from 'ui/Theme/useBreakpointIndex'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import { useAppSelector } from 'store/hooks'
import { SetupOs } from 'components/SetupGuide/SetupGuide.interface'
import ShowAlertOnModalOrTrayWrapper from 'components/Dashboard/ShowAlertOnModalOrTrayWrapper'
import { DesktopAndTabletModal, MobileTray } from 'components/TrayOrModalDialog'
import { useGetDevicesQuery } from 'store/api/devices'
import useGetUserState from 'store/api/user/useGetUserState'

export enum DeviceDialogType {
  Platform = 'platform',
  ADD = 'add',
  EDIT = 'edit',
}
const DeviceTrayOrModalDialog = (): ReactElement => {
  const { qs, nav } = useQueryString()
  const breakpoint = useBreakpointIndex()
  const isMobile = breakpoint === 0
  const { data: devicesData } = useGetDevicesQuery('')
  const { editDeviceId } = useAppSelector(s => s.devices)
  const device = useMemo(
    () => devicesData?.devices.find(d => d.PK === editDeviceId),
    [devicesData?.devices, editDeviceId],
  )
  const { isOrganization } = useGetUserState()

  const onClose = useCallback(() => {
    nav({
      ...omit(
        qs,
        'deviceDialog',
        'setupOs',
        'deviceName',
        'parentDeviceId',
        'clientId',
        'legacyIpv4Status',
        'learnIp',
        'stats',
      ),
    })
  }, [qs, nav])

  const currentView = useMemo(() => {
    switch (qs.deviceDialog) {
      case DeviceDialogType.Platform:
        return {
          title: 'Add Endpoint',
          content: <PlatformView />,
        }
      case DeviceDialogType.ADD:
        const parentDevice = devicesData?.devices.find(d => d.PK === qs.parentDeviceId)
        const clientDevice = {
          ...pick(parentDevice, ['profile', 'profile2', 'stats']),
          remap_device_id: parentDevice?.PK,
          remap_client_id: qs.clientId,
        }
        return {
          title: `Add ${qs.deviceName || SetupOs.OTHER_DEVICE}`,
          content: <AddOrEditDeviceView dismiss={onClose} device={parentDevice && clientDevice} />,
        }
      case DeviceDialogType.EDIT:
        return {
          title: 'Edit Endpoint',
          content: <AddOrEditDeviceView dismiss={onClose} device={device} />,
        }
      default:
        return
    }
  }, [
    qs.deviceDialog,
    qs.clientId,
    qs.deviceName,
    qs.parentDeviceId,
    devicesData?.devices,
    onClose,
    device,
  ])

  // show the device dialog in edit mode if the device exist
  // this is used to prevent the modal from showing on page reload.
  useEffect(() => {
    if (qs.deviceDialog === DeviceDialogType.EDIT && !device) {
      nav(omit(qs, 'deviceDialog'))
    }
  }, [device, nav, qs])

  const shouldShowDeviceDialog = useMemo(
    () => (qs.deviceDialog === DeviceDialogType.EDIT ? !!device : true),
    [device, qs.deviceDialog],
  )

  const isOpen = !!qs.deviceDialog && shouldShowDeviceDialog

  return (
    <ShowAlertOnModalOrTrayWrapper shouldShowAlertOnModal={isOpen}>
      {isMobile ? (
        <MobileTray
          isOpen={isOpen}
          title={currentView?.title}
          onCloseClick={onClose}
          dataTestId="device-tray"
          infoUrl={isOrganization ? 'docs/add-multiple-devices' : ''}
        >
          {currentView?.content}
        </MobileTray>
      ) : (
        <DesktopAndTabletModal
          title={currentView?.title}
          onCloseClick={onClose}
          dataTestId="device-dialog"
          isOpen={isOpen}
          infoUrl={isOrganization ? 'docs/add-multiple-devices' : ''}
        >
          {currentView?.content}
        </DesktopAndTabletModal>
      )}
    </ShowAlertOnModalOrTrayWrapper>
  )
}

export default DeviceTrayOrModalDialog
